import React from 'react';
import { Routes, Route } from 'react-router-dom';

import NotFound from '../layout/NotFound';
import PrivateRoute from './PrivateRoute';
import DefaultRoute from './DefaultRoute';
import Dashboard from '../dashboard/Dashboard';
import UpdateInfo from '../dashboard/UpdateInfo';
import Landing from '../layout/Landing';
import Console from '../admin/Console';
import IngredientForm from '../admin/forms/IngredientForm';
import ProductForm from '../admin/forms/ProductForm';
import Login from '../auth/Login';
import Register from '../auth/Register';
import VerifyEmail from '../auth/VerifyEmail';
import ForgotPassword from '../auth/ForgotPassword';
import ResetPassword from '../auth/ResetPassword';
import About from '../layout/About';
import Terms from '../layout/Terms';
import ProfileForm from '../profile/forms/ProfileForm';
import Profiles from '../profile/Profiles';
import Profile from '../profile/Profile';
import Journey from '../profile/Journey';
import ProfileRegimens from '../profile/ProfileRegimens';
import JourneyEntryForm from '../profile/forms/JourneyEntryForm';
import RegimenForm from '../regimens/forms/RegimenForm';
import RegimenPage from '../regimens/RegimenPage';
import Regimens from '../regimens/Regimens';
import Ingredient from '../ingredients/Ingredient';
import Ingredients from '../ingredients/Ingredients';
import Products from '../products/Products';
import Product from '../products/Product';
import CompanyForm from '../admin/forms/CompanyForm';
import CompaniesAdmin from '../admin/CompaniesAdmin';
import RegimensAdmin from '../admin/RegimensAdmin';
import UsersAdmin from '../admin/UsersAdmin';
// import Company from '../company/Company';

const AppRoutes = () => {
    return (
        <Routes>
            {/* react-router-dom v6 needs to wrap Route components as follows */}
            <Route element={<DefaultRoute />}>
                <Route exact path='/' element={<Landing />} />
                <Route exact path='/register' element={<Register />} />
                <Route exact path='/login' element={<Login />} />
                <Route exact path='/verifyemail' element={<VerifyEmail />} />
                <Route
                    exact
                    path='/forgotpassword'
                    element={<ForgotPassword />}
                />
                <Route
                    exact
                    path='/resetpassword'
                    element={<ResetPassword />}
                />
                <Route exact path='/about' element={<About />} />
                <Route exact path='/terms' element={<Terms />} />
                <Route exact path='/profile/:id' element={<Profile />} />
                <Route exact path='/journey/:id' element={<Journey />} />
                <Route
                    exact
                    path='/regimens/:id'
                    element={<ProfileRegimens />}
                />
                <Route exact path='/profiles' element={<Profiles />} />
                <Route exact path='/regimen/:id' element={<RegimenPage />} />
                <Route exact path='/regimens' element={<Regimens />} />
                <Route exact path='/ingredient/:id' element={<Ingredient />} />
                <Route exact path='/ingredients' element={<Ingredients />} />
                <Route exact path='/products' element={<Products />} />
                <Route exact path='/product/:id' element={<Product />} />
                {/* <Route exact path='/company/:id' element={<Company />} /> */}
                <Route exact path='/dashboard' element={<Dashboard />} />
            </Route>

            <Route element={<PrivateRoute />}>
                <Route
                    exact
                    path='/dashboard/updateinfo'
                    element={<UpdateInfo />}
                />
                <Route exact path='/createprofile' element={<ProfileForm />} />
                <Route exact path='/editprofile' element={<ProfileForm />} />
                <Route
                    exact
                    path='/addjourneyentry'
                    element={<JourneyEntryForm />}
                />
                <Route
                    exact
                    path='/editjourneyentry/:id'
                    element={<JourneyEntryForm />}
                />
                <Route exact path='/addregimen' element={<RegimenForm />} />
                <Route
                    exact
                    path='/editregimen/:id'
                    element={<RegimenForm />}
                />
            </Route>

            <Route element={<PrivateRoute adminPage />}>
                {/* admin */}
                <Route adminPage exact path='/admin' element={<Console />} />
                <Route
                    adminPage
                    exact
                    path='/admin/addingredient'
                    element={<IngredientForm />}
                />
                <Route
                    adminPage
                    exact
                    path='/admin/editingredient/:id'
                    element={<IngredientForm />}
                />
                <Route
                    adminPage
                    exact
                    path='/admin/addproduct'
                    element={<ProductForm />}
                />
                <Route
                    adminPage
                    exact
                    path='/admin/editproduct/:id'
                    element={<ProductForm />}
                />
                <Route
                    adminPage
                    exact
                    path='/admin/addcompany'
                    element={<CompanyForm />}
                />
                <Route
                    adminPage
                    exact
                    path='/admin/editcompany/:id'
                    element={<CompanyForm />}
                />
                <Route
                    adminPage
                    exact
                    path='/admin/viewcompanies'
                    element={<CompaniesAdmin />}
                />
                <Route
                    adminPage
                    exact
                    path='/admin/viewmembers'
                    element={<UsersAdmin />}
                />
            </Route>

            <Route element={<PrivateRoute superAdminPage />}>
                {/* super admin */}
                <Route
                    exact
                    path='/admin/viewregimens'
                    element={<RegimensAdmin />}
                />
            </Route>
            <Route element={<DefaultRoute />}>
                <Route element={<NotFound />} />
            </Route>

            {/* <DefaultRoute component={NotFound} /> */}
        </Routes>
    );
};

export default AppRoutes;
