import React, { Fragment, useEffect, useState, useCallback } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import { APP_NAME } from '../../utils/constants';
import Spinner from '../layout/Spinner';
import Pagination from '../layout/Pagination';
import Searchbar from '../layout/Searchbar';
import Tag from '../layout/Tag';
import IngredientItem from './IngredientItem';
import {
    getIngredients,
    filterIngredients,
    updateIngPageNumber,
    updateIngQueryParam,
    sortIngredients,
} from '../../actions/ingredient';
import { URLhelpers } from '../../utils/helpers';
import { IngredientPropertiesMap } from './helpers/helpers';

const numToShow = 50;

const initialState = {
    // used to keep track of default filter properties
    sort_ingredients_by: 'name',
    show_page_num: 1,
    filter_ingredients_by: {
        bools: {
            needsreview: null,
        },
        properties: [],
    },
};

const validkeys = ['pg', 'sortby', 'ss', 'properties', 'needsreview'];

const filterTypes = {
    needsreview: 'Needs Review',
    properties: '',
    ss: '',
};

const Ingredients = ({
    getIngredients,
    filterIngredients,
    sortIngredients,
    updateIngPageNumber,
    updateIngQueryParam,
    ingredient: {
        ingredients,
        ingredients_searched,
        show_page_num,
        ingredient_query_str,
        sort_ingredients_by,
        filter_ingredients_by,
        loading,
        loading_searched_ingredients,
    },
    auth: { isAdmin, isAuthenticated },
}) => {
    let location = useLocation();
    let history = useNavigate();
    const [filterTags, setFilterTags] = useState([]);

    // parses the url to get the filter params
    // this uses a callback so the function isn't recreated every time it is called in a useEffect call
    // see this for more info https://stackoverflow.com/questions/56492261/the-function-makes-the-dependencies-of-useeffect-hook
    const deleteURLparam = useCallback(
        (key) => {
            URLhelpers.deleteURLparam(history, location, key);
        },
        [history, location]
    );

    const getURLParam = useCallback(
        (param) => {
            let searchstr = URLhelpers.getURLParam(param, location);

            return searchstr;
        },
        [location]
    );

    const getPageNumParam = useCallback(() => {
        return URLhelpers.getPageNumParam(history, location);
    }, [history, location]);

    const setURLparam = useCallback(
        (kvobj) => {
            URLhelpers.setURLparam(history, location, kvobj);
        },
        [history, location]
    );

    const checkURLParams = useCallback((url) => {
        const searchParams = new URLSearchParams(url);
        return URLhelpers.checkURLParams(searchParams, validkeys);
    }, []);

    const getFilterParams = useCallback(
        (searchParams) => {
            let origurl = searchParams.toString();
            // do basic validation
            searchParams = checkURLParams(searchParams);

            let filter_ingredients_by = {
                ss: '',
                bools: {
                    needsreview: null,
                },
                properties: [],
            };

            if (!origurl.length) return { filter_ingredients_by, searchParams };

            let validkeys = ['pg', 'sortby', 'ss', 'properties', 'needsreview'];

            // remove empty keys
            searchParams.forEach(function (value, key) {
                if (!value || !validkeys.includes(key)) {
                    deleteURLparam(key); // empty str
                }
            });

            let val = searchParams.get('ss');
            if (val && val.length) {
                filter_ingredients_by.ss = val;
            } else {
                deleteURLparam('ss');
            }

            val = searchParams.get('needsreview');
            if (val === 'true' && isAdmin) {
                filter_ingredients_by.bools.needsreview = 'true';
            } else {
                deleteURLparam('needsreview');
            }

            val = searchParams.get('properties');
            if (val) {
                let valsplitintoarr = val.split(',');
                valsplitintoarr.forEach((property) => {
                    if (
                        Object.keys(IngredientPropertiesMap).includes(property)
                    ) {
                        filter_ingredients_by.properties.push(property);
                    } else {
                        // at least 1 wrong so remove them all
                        deleteURLparam('properties');
                        return;
                    }
                });
            }

            return { filter_ingredients_by, searchParams };
        },
        [checkURLParams, deleteURLparam, isAdmin]
    );

    const getSortParamAndSort = useCallback(() => {
        let queryparam = location.search;
        if (!queryparam) return;

        const searchParams = new URLSearchParams(location.search);

        let sortby = searchParams.get('sortby');
        let sortbyvals = ['created', 'name', 'rating'];
        if (sortbyvals.includes(sortby)) {
            sortIngredients(sortby);
        }
    }, [location.search, sortIngredients]);

    const getTitle = () => {
        let start = ingredients_searched.length
            ? ingredients_searched.length.toString() + ' '
            : '0 ';
        let middle = URLhelpers.dataIsFiltered(ingredient_query_str)
            ? 'Matching '
            : '';
        let end = 'Ingredient' + (ingredients_searched.length === 1 ? '' : 's');
        return start + middle + end;
    };

    const generateFilterTags = useCallback(() => {
        // e.g. location: company=60904bcc88922608fa3f26e9&pg=1&isfragrancefree=false
        const searchParams = new URLSearchParams(location.search);
        let tags = [];

        for (var key of searchParams.keys()) {
            let typeval = filterTypes[key];
            // don't support quick-clearing of the following filter types:
            // (quick clearing shows selected tags at top for easy removal)
            if (['pg', 'sortby', 'ss'].includes(key) || typeval === null)
                continue;

            let val = searchParams.get(key);
            let string = '';

            if (typeof typeval === 'object' && typeval !== null) {
                string += typeval[val];
                let obj = { key, value: string };

                tags.push(obj);
            } else if (['needsreview'].includes(key)) {
                string += typeval;
                let obj = { key, value: string };
                tags.push(obj);
            } else if (key === 'properties') {
                for (var cat of val.split(',')) {
                    let obj = { key, value: IngredientPropertiesMap[cat] };
                    tags.push(obj);
                }
            } else {
                string +=
                    typeval +
                    (val[0] !== null && val.length > 0 // key !== 'ss'
                        ? val[0].toUpperCase() + val.substring(1)
                        : val);
                let obj = { key, value: string };
                tags.push(obj);
            }
        }
        // console.log(tags);
        setFilterTags(tags);
    }, [location.search]);

    const onChangePage = (pg) => {
        updateIngPageNumber(pg, ingredients_searched.length);
    };

    // takes the user-selected filters and updates URL to reflect that change, which will trigger the component to re-render in the appropriate useEffect call above (because of the changed location)
    const changeFilters = (e) => {
        /* filter_ingredients_by gets updated via the filterIngredients call (via a call to getFilterParams which parses location.search to generate this object) whenever url location.search is updated
        filter_ingredients_by properties:
            ss: ''
            bools:  needsreview
            properties: []
            sort_ingredients_by: 'name',
            show_page_num: 1,
        */
        let property = e.target.name;

        if (property === 'sortby') {
            let sortbyval = e.target.value;
            setURLparam({ sortby: sortbyval, pg: 1 });
        } else if (property === 'properties') {
            let currentprops = [...filter_ingredients_by.properties]; // make a copy so we don't alter state outside of redux store
            let val = e.target.checked ? e.target.value : null;

            if (val) {
                currentprops.push(e.target.value); // add property to array
            } else {
                currentprops = currentprops.filter((p) => p !== e.target.value);
            }

            if (currentprops.length) {
                let o = { pg: 1 };
                o[property] = currentprops.toString();
                setURLparam(o);
            } else deleteURLparam(property);
        } else if (property === 'needsreview') {
            let val = e.target.checked && isAdmin ? 'true' : null;

            if (val === 'true') {
                let o = { pg: 1 };
                o[property] = val.toString();
                setURLparam(o);
            } else deleteURLparam(property);
        }
    };

    useEffect(() => {
        getIngredients();
    }, [getIngredients]);

    useEffect(() => {
        if (!loading) {
            if (
                !location.search &&
                ingredient_query_str.length &&
                ingredient_query_str !== '?'
            ) {
                // update browser URL if user navigated away and came back to this pg
                let params = new URLSearchParams(ingredient_query_str);

                let data = getFilterParams(params); // makes key-based param updates
                params = data.searchParams;
                let newurl = params.toString();

                newurl.length &&
                    history(`?${newurl}`, {
                        replace: true,
                        isActive: true,
                    });
            } else {
                let params = new URLSearchParams(location.search);
                let origurl = params.toString();

                let data = getFilterParams(params);
                let filters = data.filter_ingredients_by;
                params = data.searchParams;
                let updated = params.toString(); // doesn't include leading '?'
                let updatedwq = '?' + updated;

                if (origurl !== updated) {
                    updateIngQueryParam(updated.length ? updatedwq : updated);

                    history(`?${updated}`, {
                        replace: true,
                        isActive: true,
                    });
                }

                let pgnum = getPageNumParam(updatedwq);
                updateIngPageNumber(pgnum);

                filterIngredients(filters, isAdmin, updatedwq);
                getSortParamAndSort();
                generateFilterTags();
            }
        }
    }, [
        filterIngredients,
        updateIngPageNumber,
        ingredient_query_str,
        loading,
        history,
        location,
        isAdmin,
        show_page_num,
        generateFilterTags,
        getURLParam,
        getPageNumParam,
        checkURLParams,
        getFilterParams,
        getSortParamAndSort,
        updateIngQueryParam,
    ]);

    useEffect(() => {
        window.scrollTo(0, 0); // scroll to top of form on mount
    }, []);

    return (
        <div className='containermiddle'>
            <Helmet>
                <title>{APP_NAME} | Ingredients</title>
            </Helmet>
            {loading || loading_searched_ingredients || !ingredients.length ? (
                <Spinner />
            ) : (
                <Fragment>
                    <div className='sticky-header-wrapper'>
                        <div className='header-with-button'>
                            <h1 className='mb-0'>{getTitle()}</h1>
                            {isAuthenticated && isAdmin ? (
                                <Link
                                    to={'/admin/addingredient'}
                                    className='btn btn-primary mr-0'
                                >
                                    <i className='fas fa-plus text-white' />{' '}
                                    <span className='hide-on-mobile'>
                                        Add Ingredient
                                    </span>
                                </Link>
                            ) : (
                                ''
                            )}
                        </div>
                        <Searchbar searchParam='ss' searchName='ingredients' />
                    </div>

                    <div className='data-container'>
                        <div className='data-search'>
                            <h2 className='mb'>Filters</h2>
                            {filterTags.map(
                                (tag, idx) =>
                                    tag.value && (
                                        <Tag
                                            name={tag.value}
                                            key={'filter-tag-' + idx}
                                            deleteHandler={() => {
                                                if (tag.key === 'ss') {
                                                    setURLparam({
                                                        ss: tag.value,
                                                        pg: 1,
                                                    });
                                                } else if (
                                                    tag.key === 'properties'
                                                ) {
                                                    // since property tag is listed with friendly name,
                                                    // need to look up obj key (@todo this could be cleaner?)
                                                    let arrofvalues =
                                                        Object.values(
                                                            IngredientPropertiesMap
                                                        );
                                                    let idxofvalue =
                                                        arrofvalues.indexOf(
                                                            tag.value
                                                        );
                                                    let arrofkeys = Object.keys(
                                                        IngredientPropertiesMap
                                                    );
                                                    let val =
                                                        arrofkeys[idxofvalue];
                                                    changeFilters({
                                                        target: {
                                                            name: tag.key,
                                                            value: val,
                                                        },
                                                    });
                                                } else {
                                                    changeFilters({
                                                        target: {
                                                            name: tag.key,
                                                            value: tag.value,
                                                        },
                                                    });
                                                }
                                            }}
                                        />
                                    )
                            )}
                            {filterTags.length > 0 && <hr className='my' />}
                            <form className='form' autoComplete='off'>
                                {isAuthenticated && isAdmin && (
                                    <Fragment>
                                        <p className='form-header'>Admin</p>
                                        <span className='block mb'>
                                            <input
                                                type='checkbox'
                                                name='needsreview'
                                                checked={
                                                    filter_ingredients_by.bools
                                                        .needsreview === 'true' // a string instead of bool to avoid (un)controlled component issues
                                                }
                                                onChange={changeFilters}
                                                onKeyPress={(e) => {
                                                    e.key === 'Enter' &&
                                                        e.preventDefault();
                                                }}
                                            />{' '}
                                            Needs Review <br />
                                        </span>
                                    </Fragment>
                                )}

                                <div className='form-group'>
                                    <p className='form-header'>Sort By</p>
                                    <select
                                        name='sortby'
                                        value={
                                            sort_ingredients_by ||
                                            initialState.sort_ingredients_by
                                        }
                                        onChange={changeFilters}
                                        onKeyPress={(e) => {
                                            e.key === 'Enter' &&
                                                e.preventDefault();
                                        }}
                                    >
                                        <option value='name'>
                                            Ingredient Name
                                        </option>
                                        <option value='rating'>Rating</option>
                                        {isAuthenticated && isAdmin && (
                                            <option value='created'>
                                                Date Added
                                            </option>
                                        )}
                                    </select>
                                </div>

                                <div>
                                    <p className='form-header'>Properties</p>
                                    <p className='small'>
                                        Match <strong>all</strong> of the
                                        following:
                                    </p>

                                    <span className='block mb-1'>
                                        {Object.keys(
                                            IngredientPropertiesMap
                                        ).map((property) => (
                                            <Fragment key={property.toString()}>
                                                <input
                                                    type='checkbox'
                                                    name='properties'
                                                    value={property}
                                                    checked={filter_ingredients_by.properties.includes(
                                                        property
                                                    )}
                                                    onChange={changeFilters}
                                                    onKeyPress={(e) => {
                                                        e.key === 'Enter' &&
                                                            e.preventDefault();
                                                    }}
                                                />{' '}
                                                {
                                                    IngredientPropertiesMap[
                                                        property
                                                    ]
                                                }{' '}
                                                <br />
                                            </Fragment>
                                        ))}
                                    </span>
                                </div>
                            </form>
                        </div>
                        {ingredients_searched && ingredients_searched.length ? (
                            <div>
                                <Pagination
                                    data={ingredients_searched}
                                    startOnPage={show_page_num}
                                    onChangeHandler={(pg) => {
                                        onChangePage(pg);
                                    }}
                                    RenderComponent={IngredientItem}
                                    wrapperClass=''
                                    title={getTitle()}
                                    pageLimit={5}
                                    dataLimit={numToShow}
                                >
                                    <div className='ingredient-header hide-on-mobile'>
                                        <div>Name</div>
                                        <div>Rating</div>
                                        <div>Properties</div>
                                        <div></div>
                                    </div>
                                </Pagination>
                            </div>
                        ) : (
                            <h4>No ingredients found.</h4>
                        )}
                    </div>
                </Fragment>
            )}
        </div>
    );
};

Ingredients.propTypes = {
    getIngredients: PropTypes.func.isRequired,
    filterIngredients: PropTypes.func.isRequired,
    sortIngredients: PropTypes.func.isRequired,
    updateIngPageNumber: PropTypes.func.isRequired,
    updateIngQueryParam: PropTypes.func.isRequired,
    ingredient: PropTypes.object.isRequired,
    auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    ingredient: state.ingredient,
    auth: state.auth,
});

export default connect(mapStateToProps, {
    getIngredients,
    filterIngredients,
    sortIngredients,
    updateIngPageNumber,
    updateIngQueryParam,
})(Ingredients);
