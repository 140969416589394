// since this is a form,  each input should be a piece of state, so we need useState hook
import React, { Fragment, useState, useEffect } from 'react';
import { Link, Navigate, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import { APP_NAME } from '../../../utils/constants';
import { createProfile, getCurrentProfile } from '../../../actions/profile';
import { getIngredients } from '../../../actions/ingredient';
import Autocomplete from '../../layout/Autocomplete';
import Spinner from '../../layout/Spinner';
import UploadProfilePic from './UploadProfilePic'; // @todo14 delete acct should delete associated s3 media
import pic1 from '../../../img/hairtypes/1@2x.png';
import pic2a from '../../../img/hairtypes/2A@2x.png';
import pic2b from '../../../img/hairtypes/2B@2x.png';
import pic2c from '../../../img/hairtypes/2C@2x.png';
import pic3a from '../../../img/hairtypes/3A@2x.png';
import pic3b from '../../../img/hairtypes/3B@2x.png';
import pic3c from '../../../img/hairtypes/3C@2x.png';
import pic4a from '../../../img/hairtypes/4A@2x.png';
import pic4b from '../../../img/hairtypes/4B@2x.png';
import pic4c from '../../../img/hairtypes/4C@2x.png';

// Notes: fields use pattern "fieldname || initialState.fieldname" because certain fields may not be in database for user (namely, when models change). This will help when fetching data for profile when editing it.
// set default state values that will appear in form
const initialState = {
    allergens: [],
    location: '',
    weather: '',
    bio: '',
    website: '',
    hairtypeprimary: '',
    hairtypesecondary: '',
    scalphealth: 0,
    hassensitivescalp: false,
    porosity: 0,
    thickness: 0,
    health: 0,
    iscolortreated: false,
    stage: 0,
    density: 0,
    strandtype: 0,
    hasgrayhairs: false,
    length: 0,
    youtube: '',
    twitter: '',
    facebook: '',
    instagram: '',
    linkedin: '',
};

const ProfileForm = ({
    // prop types
    profile: { profile, loading_profile },
    ingredient, // for allergens: ingredients_autocomplete, loading_autocomplete
    getIngredients,
    createProfile,
    getCurrentProfile, // to fill in fields for editing
    auth,
    // (history prop is the history API which is used to navigate user to other view programatically). used to directly manipulate URL in the browser without reloading the page. route props (match, location and history) are automatically passed on to the component
}) => {
    let history = useNavigate();

    // format: [state, function to update state]
    // Two states added: formData to store form stuff and
    // displaySocialInputs to determine whether to show or hide the social media section
    const [formData, setFormData] = useState(initialState);
    const [displaySocialInputs, toggleSocialInputs] = useState(false); // default: hide section

    // this will allow us to run getCurrentProfile
    useEffect(() => {
        if (!profile && loading_profile) getCurrentProfile(); // if profile prop not set, fetch it by updating the profile state which will update the prop too
        if (!loading_profile && profile) {
            // update form fields with current profile if it exists :)
            const profileData = { ...initialState };
            for (const key in profile) {
                if (key in profileData) profileData[key] = profile[key];
            }
            // handle subsections of data model
            for (const key in profile.hairproperties) {
                if (key in profileData)
                    profileData[key] = profile.hairproperties[key];
            }
            for (const key in profile.social) {
                if (key in profileData) profileData[key] = profile.social[key];
            }
            setFormData(profileData);
        }
    }, [loading_profile, getCurrentProfile, profile]);

    useEffect(() => {
        window.scrollTo(0, 0); // scroll to top of form on mount
    }, []);

    useEffect(() => {
        getIngredients(true /* get autocomplete data */);
    }, [getIngredients]);

    // this array at the end is a dependency list:
    // if present, the effect will only activate if the values in the list change:
    // if loading or done loading, if fetched current profile, and if profile is updated

    const {
        allergens,
        location,
        weather,
        bio,
        website,
        hairtypeprimary,
        hairtypesecondary,
        scalphealth,
        hassensitivescalp,
        porosity,
        thickness,
        health,
        iscolortreated,
        stage,
        density,
        strandtype,
        hasgrayhairs,
        length,
        youtube,
        twitter,
        facebook,
        instagram,
        linkedin,
    } = formData;

    const updateAutocompleteFields = (e) => {
        e.stopPropagation();

        // value is stringified array; parse into array
        const parsed = JSON.parse(e.target.value);
        if (e.target.name === 'allergens') {
            setFormData({ ...formData, allergens: parsed });
        }
    };

    const onChange = (e) => {
        e.stopPropagation(); // to prevent lastpass bug from throwing error in console

        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const onSubmit = (e) => {
        e.preventDefault();
        createProfile(
            formData,
            history,
            profile ? true : false /* edit param */
        );
    };

    if (!auth.isAuthenticated) {
        return <Navigate to='/login' />;
    }

    return (
        <div className='containermiddle'>
            <Helmet>
                <title>{APP_NAME} | Edit Profile</title>
            </Helmet>
            {loading_profile ? (
                // wait for profile to load
                <Spinner />
            ) : (
                <Fragment>
                    <div className='header-with-button'>
                        <h1 className='mb'>Edit Profile</h1>
                        {profile ? (
                            <Link
                                to={`/profile/${profile.user.username_lower}`}
                                className='btn btn-primary mr-0'
                            >
                                View Profile
                            </Link>
                        ) : (
                            ''
                        )}
                    </div>
                    {profile === null && (
                        <h2>
                            Welcome to the club! Create a profile about you and
                            your curls.
                        </h2>
                    )}

                    <UploadProfilePic />
                    <p className='italicsmsg m0 pt-0'>* = required field</p>

                    <form className='form' onSubmit={onSubmit}>
                        <div className='form-group'>
                            <p className='form-header'>Bio</p>
                            <small className='form-text mb'>
                                Tell us a little about yourself: your natural
                                hair journey, hair care routine, etc.
                            </small>
                            <textarea
                                name='bio'
                                value={bio || initialState.bio}
                                rows={4}
                                onChange={onChange}
                            />
                        </div>

                        <div className='form-group row-split-two'>
                            <div>
                                <p className='form-header'>Location</p>
                                <small className='form-text mb'>
                                    City &amp; state/country suggested (e.g.
                                    Miami, FL or Kingston, Jamaica)
                                </small>
                                <input
                                    type='text'
                                    placeholder='Location'
                                    name='location'
                                    value={location || initialState.location}
                                    onChange={onChange}
                                    onKeyPress={(e) => {
                                        e.key === 'Enter' && e.preventDefault();
                                    }}
                                />
                            </div>
                            <div>
                                <p className='form-header'>Weather Climate</p>
                                <small className='form-text mb'>
                                    This will help us make hair product
                                    recommendations.
                                </small>
                                <select
                                    name='weather'
                                    value={weather || initialState.weather}
                                    onChange={onChange}
                                    onKeyPress={(e) => {
                                        e.key === 'Enter' && e.preventDefault();
                                    }}
                                >
                                    <option>Select climate</option>
                                    <option value='Dry'>Dry</option>
                                    <option value='Humid'>Humid</option>
                                </select>
                            </div>
                        </div>

                        <div className='form-group'>
                            <p className='form-header'>Website</p>
                            <small className='form-text mb'>
                                Personal blog, etc.
                            </small>
                            <input
                                type='text'
                                placeholder='Website'
                                name='website'
                                value={website || initialState.website}
                                onChange={onChange}
                                onKeyPress={(e) => {
                                    e.key === 'Enter' && e.preventDefault();
                                }}
                            />
                        </div>

                        <div className='my-1'>
                            <button
                                onClick={() =>
                                    toggleSocialInputs(!displaySocialInputs)
                                }
                                type='button'
                                className='btn btn-light'
                            >
                                {displaySocialInputs ? 'Hide' : 'Show'} Social
                                Network Links...
                            </button>
                            <span className='italicsmsg'>Optional</span>
                        </div>

                        {displaySocialInputs && (
                            <Fragment>
                                <div className='form-group social-input'>
                                    <i className='fab fa-twitter fa-2x' />
                                    <input
                                        type='text'
                                        placeholder='Twitter URL'
                                        name='twitter'
                                        value={twitter || initialState.twitter}
                                        onChange={onChange}
                                        onKeyPress={(e) => {
                                            e.key === 'Enter' &&
                                                e.preventDefault();
                                        }}
                                    />
                                </div>

                                <div className='form-group social-input'>
                                    <i className='fab fa-facebook fa-2x' />
                                    <input
                                        type='text'
                                        placeholder='Facebook URL'
                                        name='facebook'
                                        value={
                                            facebook || initialState.facebook
                                        }
                                        onChange={onChange}
                                        onKeyPress={(e) => {
                                            e.key === 'Enter' &&
                                                e.preventDefault();
                                        }}
                                    />
                                </div>

                                <div className='form-group social-input'>
                                    <i className='fab fa-youtube fa-2x' />
                                    <input
                                        type='text'
                                        placeholder='YouTube URL'
                                        name='youtube'
                                        value={youtube || initialState.youtube}
                                        onChange={onChange}
                                        onKeyPress={(e) => {
                                            e.key === 'Enter' &&
                                                e.preventDefault();
                                        }}
                                    />
                                </div>

                                <div className='form-group social-input'>
                                    <i className='fab fa-linkedin fa-2x' />
                                    <input
                                        type='text'
                                        placeholder='Linkedin URL'
                                        name='linkedin'
                                        value={
                                            linkedin || initialState.linkedin
                                        }
                                        onChange={onChange}
                                        onKeyPress={(e) => {
                                            e.key === 'Enter' &&
                                                e.preventDefault();
                                        }}
                                    />
                                </div>

                                <div className='form-group social-input'>
                                    <i className='fab fa-instagram fa-2x' />
                                    <input
                                        type='text'
                                        placeholder='Instagram URL'
                                        name='instagram'
                                        value={
                                            instagram || initialState.instagram
                                        }
                                        onChange={onChange}
                                        onKeyPress={(e) => {
                                            e.key === 'Enter' &&
                                                e.preventDefault();
                                        }}
                                    />
                                </div>
                            </Fragment>
                        )}
                        <hr />
                        <h3>Hair Properties</h3>
                        <div className='form-group'>
                            <p className='form-header'>* Hair type</p>
                            <p className='form-text mb-0'>
                                Your hair type is a way to categorize the
                                pattern of your hair. Use the below images as a
                                guide.
                            </p>
                            <div className='hairtypes-wrapper'>
                                <img src={pic1} alt='1' />
                                <img src={pic2a} alt='2A' />
                                <img src={pic2b} alt='2B' />
                                <img src={pic2c} alt='2C' />
                                <img src={pic3a} alt='3A' />
                                <img src={pic3b} alt='3B' />
                                <img src={pic3c} alt='3C' />
                                <img src={pic4a} alt='4A' />
                                <img src={pic4b} alt='4B' />
                                <img src={pic4c} alt='4C' />
                            </div>

                            <select
                                name='hairtypeprimary'
                                value={
                                    hairtypeprimary ||
                                    initialState.hairtypeprimary
                                }
                                onChange={onChange}
                                onKeyPress={(e) => {
                                    e.key === 'Enter' && e.preventDefault();
                                }}
                            >
                                <option>Enter your primary hair type</option>
                                <option value='1'>1 (straight)</option>
                                <option value='2A'>
                                    2A (loose, stretched waves)
                                </option>
                                <option value='2B'>
                                    2B (more defined stretched waves)
                                </option>
                                <option value='2C'>
                                    2C (distinct S-waves with some spirals)
                                </option>
                                <option value='3A'>
                                    3A (big, loose spiral curls)
                                </option>
                                <option value='3B'>3B (bouncy ringlets)</option>
                                <option value='3C'>
                                    3C (semi-tight corkscrews)
                                </option>
                                <option value='4A'>
                                    4A (tightly coiled S-curls)
                                </option>
                                <option value='4B'>
                                    4B (z-patterned, tightly coiled, sharply
                                    angled)
                                </option>
                                <option value='4C'>4C (tightly kinked)</option>
                            </select>
                        </div>
                        <div className='form-group'>
                            <small className='form-text mb'>
                                If you only have one hair type on your head, you
                                can ignore this.
                            </small>
                            <select
                                name='hairtypesecondary'
                                value={
                                    hairtypesecondary ||
                                    initialState.hairtypesecondary
                                }
                                onChange={onChange}
                                onKeyPress={(e) => {
                                    e.key === 'Enter' && e.preventDefault();
                                }}
                            >
                                <option value=''>
                                    Enter your secondary hair type
                                </option>
                                <option value='1'>1 (straight)</option>
                                <option value='2A'>
                                    2A (loose, stretched waves)
                                </option>
                                <option value='2B'>
                                    2B (more defined stretched waves)
                                </option>
                                <option value='2C'>
                                    2C (distint S-waves with some spirals)
                                </option>
                                <option value='3A'>
                                    3A (big, loose spiral curls)
                                </option>
                                <option value='3B'>3B (bouncy ringlets)</option>
                                <option value='3C'>
                                    3C (semi-tight corkscrews)
                                </option>
                                <option value='4A'>
                                    4A (tightly coiled S-curls)
                                </option>
                                <option value='4B'>
                                    4B (z-patterned, tightly coiled, sharply
                                    angled)
                                </option>
                                <option value='4C'>4C (tightly kinked)</option>
                            </select>
                        </div>
                        <div className='form-group'>
                            <p className='form-header'>* Hair porosity</p>
                            <small className='form-text mb'>
                                To determine your porosity, take some shedded
                                strands of hair and put them in a bowl of water.
                                If it floats, you have low porosity hair. If it
                                sinks, you have high porosity hair. If it sort
                                of sinks and floats, you have normal porosity
                                hair.
                            </small>
                            <select
                                name='porosity'
                                value={porosity || initialState.porosity}
                                onChange={onChange}
                                onKeyPress={(e) => {
                                    e.key === 'Enter' && e.preventDefault();
                                }}
                            >
                                <option>Enter your hair porosity</option>
                                <option value='1'>Low</option>
                                <option value='2'>Normal</option>
                                <option value='3'>High</option>
                            </select>
                        </div>

                        <div className='form-group row-split-two'>
                            <div>
                                <p className='form-header'>* Scalp Health</p>
                                <select
                                    name='scalphealth'
                                    value={
                                        scalphealth || initialState.scalphealth
                                    }
                                    onChange={onChange}
                                    onKeyPress={(e) => {
                                        e.key === 'Enter' && e.preventDefault();
                                    }}
                                >
                                    <option>Enter your scalp health</option>
                                    <option value={1}>Dry</option>
                                    <option value={2}>Normal</option>
                                    <option value={3}>Oily</option>
                                </select>

                                <p className='pt'>
                                    Scalp is sensitive{' '}
                                    <input
                                        type='checkbox'
                                        name='hassensitivescalp'
                                        value={
                                            hassensitivescalp ||
                                            initialState.hassensitivescalp
                                        }
                                        checked={
                                            hassensitivescalp ||
                                            initialState.hassensitivescalp
                                        }
                                        onChange={() => {
                                            setFormData({
                                                ...formData,
                                                hassensitivescalp:
                                                    !hassensitivescalp,
                                            });
                                        }}
                                        onKeyPress={(e) => {
                                            e.key === 'Enter' &&
                                                e.preventDefault();
                                        }}
                                    />
                                </p>
                            </div>

                            <div>
                                <p className='form-header'>Hair Stage</p>
                                <select
                                    name='stage'
                                    value={stage || initialState.stage}
                                    onChange={onChange}
                                    onKeyPress={(e) => {
                                        e.key === 'Enter' && e.preventDefault();
                                    }}
                                >
                                    <option>Enter your hair stage</option>
                                    <option value='1'>Fully natural</option>
                                    <option value='2'>
                                        Transitioning (relaxed with natural
                                        roots)
                                    </option>
                                    <option value='3'>
                                        Relaxed (chemically straightened)
                                    </option>
                                </select>
                            </div>
                        </div>

                        <div className='form-group row-split-two'>
                            <div>
                                <p className='form-header'>* Hair Length</p>
                                <select
                                    name='length'
                                    value={length || initialState.length}
                                    onChange={onChange}
                                    onKeyPress={(e) => {
                                        e.key === 'Enter' && e.preventDefault();
                                    }}
                                >
                                    <option>Enter your hair length</option>
                                    <option value='1'>
                                        Short (1-3 inches when stretched)
                                    </option>
                                    <option value='2'>
                                        Medium-short (3-6 inches when stretched)
                                    </option>
                                    <option value='3'>
                                        Medium (6-10 inches when stretched)
                                    </option>
                                    <option value='4'>
                                        Long (10+ inches when stretched)
                                    </option>
                                </select>
                            </div>

                            <div>
                                <p className='form-header'>Hair strand type</p>
                                <select
                                    name='strandtype'
                                    value={
                                        strandtype || initialState.strandtype
                                    }
                                    onChange={onChange}
                                    onKeyPress={(e) => {
                                        e.key === 'Enter' && e.preventDefault();
                                    }}
                                >
                                    <option>Enter your hair strand type</option>
                                    <option value='1'>
                                        Fine (soft and almost translucent when
                                        held up to light)
                                    </option>
                                    <option value='2'>
                                        Medium (neither soft nor hard)
                                    </option>
                                    <option value='3'>
                                        Coarse (hard and wiry to the touch)
                                    </option>
                                </select>
                            </div>
                        </div>

                        <div className='form-group row-split-two'>
                            <div>
                                <p className='form-header'>Hair thickness</p>
                                <select
                                    name='thickness'
                                    value={thickness || initialState.thickness}
                                    onChange={onChange}
                                    onKeyPress={(e) => {
                                        e.key === 'Enter' && e.preventDefault();
                                    }}
                                >
                                    <option>Enter your hair thickness</option>
                                    <option value='1'>Thin</option>
                                    <option value='2'>Normal</option>
                                    <option value='3'>Thick</option>
                                </select>
                            </div>
                            <div>
                                <p className='form-header'>Hair health</p>
                                <select
                                    name='health'
                                    value={health || initialState.health}
                                    onChange={onChange}
                                    onKeyPress={(e) => {
                                        e.key === 'Enter' && e.preventDefault();
                                    }}
                                >
                                    <option>Enter your hair health</option>
                                    <option value='1'>Healthy</option>
                                    <option value='2'>Average</option>
                                    <option value='3'>Damaged</option>
                                </select>
                            </div>
                        </div>

                        <div className='form-group'>
                            <p className='form-header'>Hair density</p>
                            <select
                                name='density'
                                value={density || initialState.density}
                                onChange={onChange}
                                onKeyPress={(e) => {
                                    e.key === 'Enter' && e.preventDefault();
                                }}
                            >
                                <option>Enter your hair density</option>
                                <option value='1'>
                                    Low (parting hair shows a lot of scalp)
                                </option>
                                <option value='2'>Normal</option>
                                <option value='3'>
                                    High (parting hair shows very little scalp)
                                </option>
                            </select>
                        </div>

                        <div className='form-group'>
                            <p className='form-header'>
                                Allergens (ingredients you're allergic to) or
                                ingredients that you try to avoid
                            </p>
                            {ingredient.ingredient_loading_autocomplete ? (
                                // wait for ingredient suggestions to load
                                <Spinner size={0} />
                            ) : (
                                <Autocomplete
                                    cssID={'0'}
                                    stringsName=''
                                    objectsName='allergens'
                                    objectsData={
                                        allergens || initialState.allergens
                                    }
                                    placeholder='Enter allergens'
                                    onChangeHandler={updateAutocompleteFields}
                                    suggestions={
                                        ingredient.ingredients_autocomplete
                                    }
                                    allowNotFoundSuggestions={false}
                                />
                            )}
                        </div>

                        <div className='form-group'>
                            <span>
                                Hair is color-treated{' '}
                                <input
                                    type='checkbox'
                                    name='iscolortreated'
                                    value={
                                        iscolortreated ||
                                        initialState.iscolortreated
                                    }
                                    checked={
                                        iscolortreated ||
                                        initialState.iscolortreated
                                    }
                                    onChange={() => {
                                        setFormData({
                                            ...formData,
                                            iscolortreated: !iscolortreated,
                                        });
                                    }}
                                    onKeyPress={(e) => {
                                        e.key === 'Enter' && e.preventDefault();
                                    }}
                                />
                            </span>
                        </div>
                        <div className='form-group'>
                            <span>
                                You have a significant amount (~25% or more) of
                                gray hair{' '}
                                <input
                                    type='checkbox'
                                    name='hasgrayhairs'
                                    value={
                                        hasgrayhairs ||
                                        initialState.hasgrayhairs
                                    }
                                    checked={
                                        hasgrayhairs ||
                                        initialState.hasgrayhairs
                                    }
                                    onChange={() => {
                                        setFormData({
                                            ...formData,
                                            hasgrayhairs: !hasgrayhairs,
                                        });
                                    }}
                                    onKeyPress={(e) => {
                                        e.key === 'Enter' && e.preventDefault();
                                    }}
                                />
                            </span>
                        </div>

                        <div className='admin-form-footer'>
                            <Link
                                className='btn btn-light my-1'
                                to='/dashboard'
                            >
                                <i className={'fa fa-arrow-left'} />{' '}
                                {profile ? 'Back To Dashboard' : 'Cancel'}
                            </Link>
                            <input
                                type='submit'
                                value='Save'
                                className='btn btn-primary my-1 mr-0'
                            />
                        </div>
                    </form>
                </Fragment>
            )}
        </div>
    );
};

ProfileForm.propTypes = {
    createProfile: PropTypes.func.isRequired,
    getCurrentProfile: PropTypes.func.isRequired,
    getIngredients: PropTypes.func.isRequired,
    profile: PropTypes.object.isRequired,
    ingredient: PropTypes.object.isRequired,
    auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    profile: state.profile,
    ingredient: state.ingredient, // for allergens
    auth: state.auth,
});

export default connect(mapStateToProps, {
    createProfile,
    getCurrentProfile,
    getIngredients,
})(ProfileForm);
