import React, { Fragment, useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import IngredientRow from './IngredientRow';
import { getIngredients } from '../../../actions/ingredient';
import { getProducts } from '../../../actions/product';
import { setAlert } from '../../../actions/alert';
import Spinner from '../../layout/Spinner';

const initialState = {
    // used to keep track of ingredient object properties. used as value passed up to caller, kept track of with an input that stores the data and is hidden
    list: [
        {
            listedas: '',
            isactive: false,
            ingredient: [], // ids
            oringredient: [], // ids
            notes: '',
        },
    ],
    approxreleaseyear: '',
};

const IngredientEntry = function ({
    objectsData, // an object with product, fields list, approxreleaseyear, and bools like isfragrancefree and hasprotein that are checked upon data update in API
    onChangeHandler, // function
    onDeleteHandler,
    onMoveHandler,
    numEntries, // for numbering entries and making sure 1 entry left can't be deleted
    entryNum, // current entry number, used as an index
    getIngredients,
    ingredient: { ingredients_autocomplete, loading_autocomplete }, // state
    getProducts,
    product: {
        products_autocomplete,
        loading_autocomplete: prod_loading_autocomp,
    }, // for faves
    setAlert,
}) {
    const [entryData, setEntryData] = useState(initialState);
    const [displayParseBox, toggleParseBox] = useState(false); // default: hide section
    const { list, approxreleaseyear } = entryData;

    useEffect(() => {
        if (!products_autocomplete.length) {
            getProducts(true /* get autocomplete data */);
        }
    }, [getProducts, products_autocomplete.length]);

    useEffect(() => {
        // this will run every time the objectsData prop changes, which will happen whenver parent gets updated state and rerenders this component

        if (objectsData) {
            // update form fields with current product
            const data = { ...initialState };

            for (const key in objectsData) {
                if (key in data) data[key] = objectsData[key];
            }

            // handle subsections of data model @todo5 does this even matter here/elsewhere?
            for (const key in objectsData.list) {
                if (key in data.list) data.list[key] = objectsData.list[key];
            }

            // console.log('UPDATING STATE: DATA ' + JSON.stringify(data));
            setEntryData(data);
        }
    }, [objectsData]);

    useEffect(() => {
        getIngredients(true /* get autocomplete data */);
    }, [getIngredients]);

    // used to send a change in input data to caller
    const bubbleUpInputChange = useCallback(
        (data) => {
            // trigger onChange in hidden input which stores value to save in caller's state
            var input = document.querySelector('#ingentry-' + entryNum);
            var nativeInputValueSetter = Object.getOwnPropertyDescriptor(
                window.HTMLInputElement.prototype,
                'value'
            ).set;
            nativeInputValueSetter.call(input, JSON.stringify(data));

            var inputEvent = new Event('input', { bubbles: true });
            input.dispatchEvent(inputEvent);
        },
        [entryNum]
    );

    const deleteIngredientRow = (e, idx) => {
        if (e) e.preventDefault(); // don't submit form. check if e b/c this is also used as helper method
        if (list.length === 1) return; // don't allow deletion of last one

        let entryCopy = [...list];
        entryCopy = [...entryCopy.slice(0, idx), ...entryCopy.slice(idx + 1)]; // remove that step
        // setEntryData({ ...entryData, list: entryCopy });
        setEntryData((prevState) => ({ ...prevState, list: entryCopy }));

        //console.log('DELETED: data ' + JSON.stringify(entryData));
        bubbleUpInputChange({ ...entryData, list: entryCopy });
    };

    const addIngredientRow = (e) => {
        e.preventDefault(); // don't submit form

        let entryCopy = { ...entryData }; // copy current ...
        entryCopy.list.push({
            listedas: '',
            isactive: false,
            ingredient: [], // ids
            oringredient: [], // ids
            notes: '',
        });

        setEntryData({ ...entryData, list: entryCopy.list });
        //console.log('ADDED: data ' + JSON.stringify(entryData));
        bubbleUpInputChange(entryData);
    };

    const addIngredientRows = (text) => {
        if (!text.length) {
            setAlert(
                'Pleae add a list of comma-separated ingredients to parse.',
                'danger'
            );
            return;
        }
        // let removeFirst = false;
        // if (list.length === 1 && list[0].listedas === '') {
        //     removeFirst = true;
        // }

        let arr = text.trim().split(',');
        let entryCopy = { ...entryData }; // copy current ...

        /*  ingredients_autocomplete array element example:
            { 
                casnumber: ["6920-22-5"]
                name: "1,2-hexanediol"
                name_lower: "1,2-hexanediol"
                synonyms: []
                _id: "5f6563148d5e1f27176c58d7"
            }
        */
        for (var i = 0; i < arr.length; i++) {
            let ingorig = arr[i].trim();
            let ing = ingorig.toLowerCase();

            let matchFound = false;

            for (var j = 0; j < ingredients_autocomplete.length; j++) {
                let ingobj = ingredients_autocomplete[j];
                let autoing = ingobj.name_lower.trim();

                // obj may have synonyms array or company obj depending on obj type (ing or product)
                let synonyms = ingobj.synonyms;

                let hasInputMatches = autoing === ing;
                let matchesSynonym = synonyms
                    ? synonyms.some((synonym) => synonym.toLowerCase() === ing)
                    : false;

                if (hasInputMatches || matchesSynonym) {
                    entryCopy.list.push({
                        listedas: ingorig,
                        isactive: false,
                        ingredient: [ingobj], // ids
                        oringredient: [], // ids
                        notes: '',
                    });
                    matchFound = true;
                    break;
                }
            }
            // if here, then ingredient not found
            if (!matchFound) {
                entryCopy.list.push({
                    listedas: ingorig,
                    isactive: false,
                    ingredient: [], // ids
                    oringredient: [], // ids
                    notes: '',
                });
            }
        }

        setEntryData({ ...entryData, list: entryCopy.list });
        bubbleUpInputChange(entryData);

        setAlert(
            'Ingredients successfully populated. Please review each one to confirm accuracy.',
            'success',
            false,
            false,
            true
        );

        // if (removeFirst && entryCopy.list.length > 1) {
        //     deleteIngredientRow(null, 0);
        // }
    };

    const moveIngredientRowUp = (e, idx) => {
        e.preventDefault(); // don't submit form

        let entryCopy = { ...entryData }; // copy current ...
        if (entryCopy.list.length === 1 || idx === 0) return; // don't allow move of only one

        var ingToMove = entryCopy.list[idx];
        entryCopy.list.splice(idx, 1);
        entryCopy.list.splice(idx - 1, 0, ingToMove);

        //entryCopy.list.push(entryCopy.list.splice(idx, 1)[0]); // move to end

        setEntryData({ ...entryData, list: entryCopy.list });

        bubbleUpInputChange(entryData);
    };

    const onChangeListItem = (e, idx) => {
        //e.stopPropagation(); // to prevent lastpass bug from throwing error in console

        let ingentryobj = { ...entryData };

        if (e.target.name === 'listedas' || e.target.name === 'notes') {
            // need to parse data into array for state
            // value is stringified array; parse into array
            ingentryobj.list[idx][e.target.name] = e.target.value;
        } else if (e.target.name === 'isactive') {
            ingentryobj.list[idx][e.target.name] = e.target.checked;
        } else if (
            e.target.name === 'ingredient' ||
            e.target.name === 'oringredient'
        ) {
            // need to parse autocomplete data
            ingentryobj.list[idx][e.target.name] = JSON.parse(e.target.value);
        } else if (e.target.name === 'product') {
            // need to parse autocomplete data
            let parsed = JSON.parse(e.target.value);

            let newState = {
                ...entryData,
                product: parsed,
            };
            setEntryData(newState);
            bubbleUpInputChange(newState);
            return;
        } else if (e.target.name === 'approxreleaseyear') {
            let newState = {
                ...entryData,
                approxreleaseyear: e.target.value,
            };
            setEntryData(newState);
            bubbleUpInputChange(newState);
            return;
        }

        setEntryData({
            ...entryData,
            entryData: ingentryobj,
        });

        // console.log('EDITED ' + JSON.stringify(entryData));
        bubbleUpInputChange(entryData);
    };

    return (
        <Fragment>
            <h3>Version {entryNum + 1}</h3>

            <Fragment>
                <p className='form-header'>Year released</p>
                <input
                    type='text'
                    placeholder='Year released'
                    name='approxreleaseyear'
                    value={approxreleaseyear || initialState.approxreleaseyear}
                    onChange={(e) => {
                        onChangeListItem(e, entryNum);
                    }}
                    onKeyPress={(e) => {
                        e.key === 'Enter' && e.preventDefault();
                    }}
                    className='mb-1'
                />

                <div className='parse-ing-adv'>
                    <h3 onClick={(e) => toggleParseBox(!displayParseBox)}>
                        Advanced: Parse Ingredients &#8250;
                    </h3>
                    {displayParseBox && (
                        <Fragment>
                            <small className='form-text mb'>
                                Copy a comma-separated list of ingredients to
                                parse. Be sure to remove symbols like * and
                                anything else that won't result in an exact
                                match for each ingredient, otherwise, you'll
                                have to update those ingredients yourself.
                            </small>
                            <textarea
                                className='mt'
                                placeholder='Auto-parse a list of ingredients by pasting them in here.'
                                id={'autoparse' + entryNum}
                                rows={5}
                                defaultValue=''
                            />
                            <button
                                className='btn btn-dark mt'
                                onClick={(e) => {
                                    e.key === 'Enter' && e.preventDefault();
                                    var txt = document.getElementById(
                                        'autoparse' + entryNum
                                    ).value;
                                    addIngredientRows(txt);
                                }}
                            >
                                Parse List
                            </button>
                        </Fragment>
                    )}
                </div>

                <p className='form-header'>* Ingredients List</p>

                {loading_autocomplete ? (
                    <Spinner size={0} />
                ) : (
                    list.map((listItem, idx) => {
                        return (
                            <IngredientRow
                                key={'row-' + idx}
                                autocomplete={ingredients_autocomplete}
                                entryNum={entryNum}
                                rowData={listItem}
                                numRows={list.length}
                                rowNum={idx}
                                onChangeHandler={(e) =>
                                    onChangeListItem(e, idx)
                                }
                                onDeleteHandler={(e) =>
                                    deleteIngredientRow(e, idx)
                                }
                                onMoveHandler={(e) =>
                                    moveIngredientRowUp(e, idx)
                                }
                            />
                        );
                    })
                )}

                <button
                    className='btn btn-light my-1'
                    onClick={(e) => addIngredientRow(e)}
                >
                    Add Another Ingredient
                </button>
            </Fragment>

            <input
                type='text'
                style={{
                    display: 'none',
                }}
                id={'ingentry-' + entryNum}
                onChange={(e) => {
                    onChangeHandler(e);
                }}
                value={entryData}
                name='ingredients'
            />

            {numEntries > 1 ? (
                <Fragment>
                    <button
                        type='button'
                        className='btn btn-light my mb-1'
                        onClick={onMoveHandler}
                    >
                        <i className='fas fa-arrow-down' /> Move Version To End
                    </button>

                    <button
                        type='button'
                        className='btn btn-danger my mb-1'
                        onClick={onDeleteHandler}
                    >
                        <i className='far fa-trash-alt' /> Delete Version
                    </button>
                </Fragment>
            ) : (
                ''
            )}
            <hr />
        </Fragment>
    );
};

IngredientEntry.propTypes = {
    onChangeHandler: PropTypes.func.isRequired,
    onDeleteHandler: PropTypes.func.isRequired,
    onMoveHandler: PropTypes.func.isRequired,
    objectsData: PropTypes.object.isRequired, // used to get and set form data
    numEntries: PropTypes.number.isRequired,
    entryNum: PropTypes.number.isRequired,

    // mapped w/state
    getIngredients: PropTypes.func.isRequired,
    ingredient: PropTypes.object.isRequired,
    product: PropTypes.object.isRequired,
    getProducts: PropTypes.func.isRequired,
    setAlert: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
    ingredient: state.ingredient,
    product: state.product,
});

export default connect(mapStateToProps, {
    getIngredients,
    getProducts,
    setAlert,
})(IngredientEntry);
