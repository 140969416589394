import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import NavbarLink from '../layout/NavbarLink';

const DashboardNav = ({ user }) => {
    return (
        <Fragment>
            <div className='header-with-button'>
                <h1>Dashboard </h1>
                <div>
                    <Link
                        to={`/journey/${user.username_lower}`}
                        className='btn btn-light'
                    >
                        <i className='fas fa-timeline text-primary' /> My
                        Journey
                    </Link>
                    {!user.profile && (
                        <Link to='/editprofile' className='btn btn-light'>
                            <i className='fas fa-edit text-primary' /> Create
                            Profile
                        </Link>
                    )}
                    {user.profile && (
                        <Link
                            to={`/profile/${user.username_lower}`}
                            className='btn btn-light mr-0'
                        >
                            <i className='fas fa-address-card text-primary' />{' '}
                            My Profile
                        </Link>
                    )}
                </div>
            </div>

            <nav className='tabbar mb-1'>
                <ul>
                    <li>
                        <NavbarLink path='/dashboard'>Summary</NavbarLink>
                    </li>
                    <li>
                        <NavbarLink path='/dashboard/updateinfo'>
                            <i className='fas fa-user-cog' /> My Account
                        </NavbarLink>
                    </li>
                </ul>
            </nav>
        </Fragment>
    );
};

export default DashboardNav;
