import api from '../utils/api';
import upload_api from '../utils/upload_api';
import { setAlert } from './alert';
import { checkAppVersion } from '../utils/helpers';
import actionErrorHandler from './helpers/actionErrorHandler';

import {
    ADD_REGIMEN,
    GET_REGIMEN, // fetch regimen when it is created or updated
    GET_REGIMENS,
    GET_REGIMENS_ADMIN,
    GET_REGIMENS_AUTOCOMPLETE,
    REGIMEN_ERROR,
    DELETE_REGIMEN,
    LOADING_REGIMENS,
    UPDATE_REGIMEN_LIKES, // handles likes and dislikes
    LOADING_REGIMEN_PIC,
    DONE_LOADING_REGIMEN_PIC,
    UPDATE_REGIMEN_PIC,
    LOADING_REGIMEN_STEP_PIC,
    DONE_LOADING_REGIMEN_STEP_PIC,
    UPDATE_REGIMEN_STEP_PIC,
} from './types';

// Dashboard is set up to load user's regimen info.

// Get all public regimens, or just logged in user's regimens if onlyMine is true
export const getRegimens =
    (onlyMine = false) =>
    async (dispatch) => {
        dispatch({ type: LOADING_REGIMENS });

        try {
            const res = onlyMine
                ? await api.get('/regimens/mine')
                : await api.get('/regimens');

            dispatch(checkAppVersion(res));

            dispatch({
                type: GET_REGIMENS,
                payload: res.data,
            });
        } catch (err) {
            actionErrorHandler(dispatch, err, REGIMEN_ERROR);
        }
    };

// get ALL regimens (admin only)
export const getRegimensAdmin = () => async (dispatch) => {
    dispatch({ type: LOADING_REGIMENS });

    try {
        const res = await api.get('/regimens/all');

        dispatch(checkAppVersion(res));

        dispatch({
            type: GET_REGIMENS_ADMIN,
            payload: res.data,
        });
    } catch (err) {
        actionErrorHandler(dispatch, err, REGIMEN_ERROR);
    }
};

// Get all public regimens for given user
export const getRegimensByUsername = (username) => async (dispatch) => {
    dispatch({ type: LOADING_REGIMENS });

    try {
        const res = await api.get(`/regimens/user/${username}`);

        dispatch({
            type: GET_REGIMENS,
            payload: res.data,
        });
    } catch (err) {
        actionErrorHandler(dispatch, err, REGIMEN_ERROR);
    }
};

export const getRegimensAutocomplete = () => async (dispatch) => {
    dispatch({ type: LOADING_REGIMENS });

    try {
        const res = await api.get('/regimens/autocomplete');

        dispatch({
            type: GET_REGIMENS_AUTOCOMPLETE,
            payload: res.data,
        });
    } catch (err) {
        actionErrorHandler(dispatch, err, REGIMEN_ERROR);
    }
};

// Get regimen by ID
export const getRegimenById = (regimenid, history) => async (dispatch) => {
    dispatch({ type: LOADING_REGIMENS });

    try {
        const res = await api.get(`/regimens/${regimenid}`);

        dispatch({
            type: GET_REGIMEN,
            payload: res.data,
        });
    } catch (err) {
        actionErrorHandler(dispatch, err, REGIMEN_ERROR);
    }
};

// Delete a regimen @todo3 if user is on list of all their regimens, keep on page. if on details, push to regimens page. use history if it is set?
export const deleteRegimen = (id, history) => async (dispatch) => {
    if (window.confirm('Are you sure? This can NOT be undone!')) {
        // @todo change to modal confirmation with overlay
        try {
            const res = await api.delete(`/regimens/${id}`);

            dispatch({
                type: DELETE_REGIMEN,
                payload: res.data /* deleted regimen */,
            });

            if (history) history('/dashboard'); // @todo4 this does not work for some reason
            dispatch(
                setAlert(
                    'Regimen deleted',
                    'success',
                    true /* persist on page changes */
                )
            );
        } catch (err) {
            actionErrorHandler(dispatch, err, REGIMEN_ERROR);
        }
    }
};

// Create or update regimen. If id is set, update regimen, otherwise create it
export const createRegimen = (formData, history, id) => async (dispatch) => {
    // dispatch({ type: CLEAR_REGIMEN }); // clear current one

    try {
        const res =
            id !== undefined // return regimen
                ? await api.put(`/regimens/${id}`, formData)
                : await api.put('/regimens', formData); //  @todo make push not post?

        dispatch({
            type: id === undefined ? ADD_REGIMEN : GET_REGIMEN,
            payload: res.data,
        });

        dispatch(
            setAlert(
                'Regimen saved',
                'success',
                true /* persist on page changes */
            )
        );

        if (!id) {
            // note: redirecting in an action is different from redirecing in a component where Redirect can be used. that's why history is used here
            history(`/regimen/${res.data._id}`);
        }
    } catch (err) {
        actionErrorHandler(dispatch, err, REGIMEN_ERROR);
    }
};

// @todo9 highlight whether I've liked or rated something and show count for num ratings
// Add/remove like. If liked = true, like action; else, dislike action.
export const likeOrDislikeRegimen =
    (id, liked = true) =>
    async (dispatch) => {
        try {
            // returns array of likes
            const res = liked
                ? await api.put(`/regimens/like/${id}`)
                : await api.put(`/regimens/dislike/${id}`);

            dispatch({
                type: UPDATE_REGIMEN_LIKES,
                payload: {
                    id,
                    likes: res.data.likes,
                    dislikes: res.data.dislikes,
                }, // send regimen ID to know which regimen was liked
            });
        } catch (err) {
            actionErrorHandler(dispatch, err, REGIMEN_ERROR);
        }
    };

// update regimen's main picture
export const uploadRegimenPic = (file, regimenID) => async (dispatch) => {
    try {
        dispatch({ type: LOADING_REGIMEN_PIC });

        const res = await upload_api.post(
            `/regimens/uploadmainphoto/${regimenID}`,
            file
        );

        dispatch({
            // send to regimen edit page
            type: UPDATE_REGIMEN_PIC,
            payload: res.data, // photo path
        });

        dispatch(
            setAlert(
                'Regimen photo updated',
                'success',
                true /* persist on page changes */
            )
        );
    } catch (err) {
        const error = err.response.data;
        if (error) {
            dispatch(setAlert(error, 'danger', false, false, false, true)); // show first error
            dispatch({
                // so spinner can go away and user can try upload again
                type: DONE_LOADING_REGIMEN_PIC,
            });
        }
    }
};

// update regimen's step picture
export const uploadRegimenStepPic =
    (file, regimenID, stepID) => async (dispatch) => {
        try {
            dispatch({ type: LOADING_REGIMEN_STEP_PIC, payload: stepID });

            const res = await upload_api.post(
                `/regimens/uploadstepphoto/${regimenID}/${stepID}`,
                file
            );

            dispatch({
                // send to regimen edit page
                type: UPDATE_REGIMEN_STEP_PIC,
                payload: res.data, // photo path
            });

            dispatch(
                setAlert(
                    'Regimen step updated',
                    'success',
                    true /* persist on page changes */
                )
            );
        } catch (err) {
            const errors = err.response.data.errors;

            if (errors) {
                dispatch(
                    setAlert(errors[0].msg, 'danger', false, false, false, true)
                ); // show first error

                dispatch({
                    // so spinner can go away and user can try upload again
                    type: DONE_LOADING_REGIMEN_STEP_PIC,
                });
            } else if (err.response.data) {
                dispatch(
                    setAlert(
                        err.response.data,
                        'danger',
                        false,
                        false,
                        false,
                        true
                    )
                );

                dispatch({
                    // so spinner can go away and user can try upload again
                    type: DONE_LOADING_REGIMEN_STEP_PIC,
                });
            }
        }
    };
