import { orderBy } from 'lodash';
import {
    ADD_PRODUCT,
    GET_PRODUCT, // fetch product when it is created or updated
    GET_PRODUCTS,
    GET_PRODUCTS_AUTOCOMPLETE,
    PRODUCT_ERROR,
    DELETE_PRODUCT,
    CLEAR_PRODUCT,
    GET_PRODUCT_COMMENTS,
    ADD_PRODUCT_COMMENT,
    REMOVE_PRODUCT_COMMENT,
    RATE_PRODUCT,
    GET_USER_PRODUCT_RATING,
    LOADING_PRODUCT_PIC,
    DONE_LOADING_PRODUCT_PIC,
    UPDATE_PRODUCT_PIC,
    SORT_PRODUCTS,
    FILTER_PRODUCTS,
    LOADING_PRODUCT,
    UPDATE_PRODUCT_PAGE_NUMBER,
    UPDATE_PRODUCT_QUERY_PARAM,
    LOGOUT,
} from '../actions/types';

// consts to define whether a filter property is set or not

const initialState = {
    product: null,
    product_rating_for_current_user: null,
    products: [], // for listing all products
    products_autocomplete: [], // for listing all products, with name and ID only
    product_comments: [],
    // SEARCH & SORT:
    products_searched: [], // filtered and string-searched products
    sort_products_by: 'priority',
    show_page_num: 1,
    filter_products_by: {
        ss: '',
        bools: {
            isfragrancefree: null, // true means it is cared about; false means ignore it
            hasprotein: null,
            hassilicones: null,
            hasparabens: null,
            hassulfates: null,
            hasoils: null,
            needsreview: null,
            isdraft: null,
            hasnoamazonlink: null,
        },
        ingredients: [],
        noningredients: [],
        company: [],
        categories: [], // array of category strings, e.g. Shampoo
    },
    product_query_str: '',
    products_for_ingredient: [], // array of products that match an ingredient

    loading: true,
    loading_product: true,
    loading_autocomplete: true,
    loading_product_comments: true,
    uploading_product_pic: false,
    // loading_searched_products: true,
    error: {}, // for request errors
};

// reducers take a state and an action (the action is dispatched from a file)
// actions have a type and a payload
const productReducer = (state = initialState, action) => {
    const { type, payload } = action; // destructure for simplicity

    switch (type) {
        case GET_PRODUCT:
            // used when updating or fetching a product. when updating, also
            // need to update all products to reflect the change to this product
            return {
                ...state,
                product: payload,
                products: state.products.map((prod) =>
                    prod._id === payload._id ? payload : prod
                ),
                loading_product: false,
            };

        case GET_USER_PRODUCT_RATING:
            return {
                ...state,
                product_rating_for_current_user: payload.rating,
            };

        case GET_PRODUCTS: {
            let dir =
                state.sort_products_by === 'priority' ||
                state.sort_products_by === 'created'
                    ? 'desc'
                    : 'asc';
            const sortedProducts = orderBy(
                payload,
                [state.sort_products_by],
                [dir] // @todo pass in direction to state?
            );

            return {
                ...state,
                products: sortedProducts,
                // products_searched: sortedProducts, // @todo50 not sure why products are empty sometimes...add this?
                loading: false,
                loading_product: false,
            };
        }

        case GET_PRODUCTS_AUTOCOMPLETE:
            return {
                ...state,
                products_autocomplete: payload,
                loading_autocomplete: false,
            };

        case ADD_PRODUCT:
            return {
                ...state,
                product: payload,
                products: [...state.products, payload],
                products_autocomplete: [
                    ...state.products_autocomplete,
                    payload,
                ],
                loading_product: false,
            };

        case SORT_PRODUCTS: {
            const sortedProducts = orderBy(
                state.products,
                [payload.sortBy],
                [payload.sortDir]
            );

            const sortedSearchedProducts = orderBy(
                state.products_searched,
                [payload.sortBy],
                [payload.sortDir]
            );

            return {
                ...state,
                sort_products_by: payload.sortBy, // the searched string
                products: sortedProducts,
                products_searched: sortedSearchedProducts,
            };
        }

        case UPDATE_PRODUCT_QUERY_PARAM:
            return {
                ...state,
                product_query_str: payload,
            };

        case FILTER_PRODUCTS: {
            // first, search based on string
            let filteredProps = payload.filterBy;
            let str = filteredProps ? filteredProps['ss'] : '';
            let filteredProducts = str.length
                ? searchProducts(str, state.products)
                : state.products;

            let isAdmin = payload.isAdmin;

            if (!isAdmin) {
                // only show published products
                filteredProducts = filteredProducts.filter((product) => {
                    return !product.isdraft;
                });
            }

            if (filteredProps) {
                let filtereding = filteredProps.ingredients;
                let filterednoning = filteredProps.noningredients;
                let filteredcompany = filteredProps.company;
                let filteredcollection = filteredProps.collection;
                let filteredcategories = filteredProps.categories;

                let properties = Object.entries(filteredProps.bools);
                filteredProducts = filteredProducts.filter((product) => {
                    // payload is obj with properties to filter. we will decrement count
                    // of properties; if it gets to 0, then the product matches all
                    // criteria and should be included in filter result
                    var numMatchedProperties = properties.length;

                    for (const [property, val] of properties) {
                        if (val === null) {
                            numMatchedProperties--;
                            continue; // ignore this property when filtering
                        } else if (val === 'true') {
                            // handle special nested cases
                            if (
                                property === 'isfragrancefree' ||
                                property === 'hasprotein' ||
                                property === 'hassilicones' ||
                                property === 'hasparabens' ||
                                property === 'hassulfates' ||
                                property === 'hasoils'
                            ) {
                                // search only the latest version for property check
                                if (product.ingredients.length) {
                                    // should always be true, but just in case
                                    const latestversion =
                                        product.ingredients[
                                            product.ingredients.length - 1
                                        ];
                                    if (latestversion[property])
                                        numMatchedProperties--;
                                }
                                // for (const version of product.ingredients) {
                                //     if (version[property]) {
                                //         foundpropertyinversion = true;
                                //         break;
                                //     }
                                // }
                            } else if (property === 'hasnoamazonlink') {
                                if (
                                    !product['amazon'] ||
                                    !product['amazon'].length
                                ) {
                                    numMatchedProperties--;
                                }
                            }
                            // handle generic top-level cases (needsreview, isdraft)
                            else {
                                if (product[property]) {
                                    numMatchedProperties--;
                                }
                            }
                        } else if (val === 'false') {
                            // handle special nested cases
                            if (
                                property === 'isfragrancefree' ||
                                property === 'hasprotein' ||
                                property === 'hassilicones' ||
                                property === 'hasparabens' ||
                                property === 'hassulfates' ||
                                property === 'hasoils'
                            ) {
                                // search only the latest version for property check
                                if (product.ingredients.length) {
                                    // should always be true, but just in case
                                    const latestversion =
                                        product.ingredients[
                                            product.ingredients.length - 1
                                        ];
                                    if (!latestversion[property])
                                        numMatchedProperties--;
                                }
                                // for (const version of product.ingredients) {
                                //     if (!version[property]) {
                                //         foundpropertyinversion = true;
                                //         break;
                                //     }
                                // }
                            } else if (property === 'hasnoamazonlink') {
                                if (
                                    !product['amazon'] ||
                                    !product['amazon'].length
                                ) {
                                    numMatchedProperties--;
                                }
                            }
                            // handle generic top-level cases (needsreview, isdraft)
                            else {
                                if (!product[property]) {
                                    numMatchedProperties--;
                                }
                            }
                        }
                    }

                    // now check ingredients
                    let containsIngredient = searchProductByIngredients(
                        filtereding,
                        product
                    );

                    let containsNoningredient = searchProductByNoningredients(
                        filterednoning,
                        product
                    );

                    // now check company
                    let matchesCompany = filteredcompany.length
                        ? product.company._id === filteredcompany[0]._id
                        : true;

                    // now check company's collection
                    let foundMatchingCollection = filteredcollection.length
                        ? product.line === filteredcollection[0]._id
                        : false;

                    let matchesCollection =
                        filteredcompany.length && filteredcollection.length
                            ? foundMatchingCollection
                            : true; /* nothing to filter so match */

                    // now check categories
                    let matchesCategory = filteredcategories.length
                        ? product.categories.filter((category) =>
                              filteredcategories.includes(category)
                          ).length > 0
                        : true; /* nothing to filter so match */

                    return (
                        containsIngredient &&
                        !containsNoningredient &&
                        matchesCompany &&
                        matchesCollection &&
                        matchesCategory &&
                        numMatchedProperties === 0
                    );
                });
            }

            // now sort
            let dir =
                state.sort_products_by === 'priority' ||
                state.sort_products_by === 'created'
                    ? 'desc'
                    : 'asc';
            filteredProducts = orderBy(
                filteredProducts,
                [state.sort_products_by],
                [dir] // todo5 change all 'asc' strings to variable
            );

            return {
                ...state,
                filter_products_by:
                    payload.filterBy || state.filter_products_by,
                products_searched: filteredProducts,
                product_query_str: payload.queryparam,
            };
        }

        case UPDATE_PRODUCT_PAGE_NUMBER:
            return {
                ...state,
                show_page_num: payload,
                loading: false,
            };

        case GET_PRODUCT_COMMENTS:
            return {
                ...state,
                product_comments: payload,
                loading_product_comments: false,
            };

        case ADD_PRODUCT_COMMENT:
            return {
                ...state,
                product_comments: [payload, ...state.product_comments],
                loading_product_comments: false,
            };

        case REMOVE_PRODUCT_COMMENT:
            return {
                ...state,
                product_comments: state.product_comments.filter(
                    (comment) => comment._id !== payload._id
                ),
                loading_product_comments: false,
            };

        case RATE_PRODUCT:
            return {
                ...state,
                product: payload, // updated rating fields
                loading_product: false,
            };

        case LOADING_PRODUCT_PIC: // todo have loading for other subsections of pages like this
            return {
                ...state,
                uploading_product_pic: true,
            };

        case DONE_LOADING_PRODUCT_PIC:
            return {
                ...state,
                uploading_product_pic: false,
            };

        case UPDATE_PRODUCT_PIC:
            return {
                ...state,
                uploading_product_pic: false,
                product: { ...state.product, mainphoto: payload }, // payload is photo path
            };

        case LOADING_PRODUCT:
            return {
                ...state,
                product: null,
                product_comments: [],
                product_rating_for_current_user: null,
                loading_product: true,
            };

        case CLEAR_PRODUCT /* no payload sent */:
            return {
                ...state,
                product: null,
                product_comments: [],
                product_rating_for_current_user: null,
                // loading_product: true,
                // loading: true,
            };

        case DELETE_PRODUCT:
            // payload is the deleted object
            return {
                ...state,
                products: state.products.filter(
                    (product) => product._id !== payload._id
                ),
                products_autocomplete: state.products_autocomplete.filter(
                    (product) => product._id !== payload._id
                ),
            }; // @todo9 should this null comments and product too?

        case PRODUCT_ERROR:
            return {
                ...state,
                error: payload,
                loading_product: false,
                product: null,
            };

        // when logging out, clear admin filters in case they were set before admin logged out
        case LOGOUT: {
            let newfilter = { ...state.filter_products_by };
            newfilter.bools.needsreview = null;
            newfilter.bools.isdraft = null;
            newfilter.bools.hasnoamazonlink = null;

            return {
                ...state,
                filter_products_by: newfilter,
            };
        }

        default:
            return state;
    }
};

const searchProducts = (searchStr, products) => {
    const filteredData = products.filter(
        (product) =>
            // product name or company name contains search string:
            product.name.toLowerCase().includes(searchStr.toLowerCase()) ||
            product.company.name
                .toLowerCase()
                .includes(searchStr.toLowerCase()) ||
            product.company.shortname
                .toLowerCase()
                .includes(searchStr.toLowerCase())
    );

    return filteredData;
};

const searchProductByIngredients = (ingredientstofilter, product) => {
    if (ingredientstofilter.length === 0) {
        return true; // no ingredients filtered
    }

    let ingredientstofilterCopy = [...ingredientstofilter];

    for (let version of product.ingredients) {
        for (let listitem of version.list) {
            for (let liing of listitem.ingredient) {
                for (let ing of ingredientstofilterCopy) {
                    if (liing._id === ing._id) {
                        // remove ingredient from list of ingredients to match
                        ingredientstofilterCopy =
                            ingredientstofilterCopy.filter(
                                (i) => i._id !== ing._id
                            );

                        if (ingredientstofilterCopy.length === 0) return true;
                    }
                }
            }
            for (let liing of listitem.oringredient) {
                for (let ing of ingredientstofilterCopy) {
                    if (liing._id === ing._id) {
                        // remove ingredient from list of ingredients to match
                        ingredientstofilterCopy =
                            ingredientstofilterCopy.filter(
                                (i) => i._id !== ing._id
                            );

                        if (ingredientstofilterCopy.length === 0) return true;
                    }
                }
            }
        }
    }

    return false;
};

// "contains noningredients": returns true if any of the ingredients are found in the product; false if none are found.
const searchProductByNoningredients = (ingredientstofilter, product) => {
    if (ingredientstofilter.length === 0) {
        return false; // no ingredients filtered
    }

    let ingredientstofilterCopy = [...ingredientstofilter];

    for (let version of product.ingredients) {
        for (let listitem of version.list) {
            for (let liing of listitem.ingredient) {
                for (let ing of ingredientstofilterCopy) {
                    if (liing._id === ing._id) {
                        return true;
                    }
                }
            }
            for (let liing of listitem.oringredient) {
                for (let ing of ingredientstofilterCopy) {
                    if (liing._id === ing._id) {
                        return true;
                    }
                }
            }
        }
    }

    return false;
};

export default productReducer;
