import React, { Fragment } from 'react';
import NavbarLink from '../layout/NavbarLink';

const AdminNav = ({ isSuperAdmin = false }) => {
    return (
        <Fragment>
            <h1>Admin Console</h1>
            <nav className='tabbar mb-2'>
                <ul>
                    <li>
                        <NavbarLink path='/admin'>Dashboard</NavbarLink>
                    </li>
                    <li>
                        <NavbarLink path='/admin/viewmembers'>
                            Members
                        </NavbarLink>
                    </li>
                    {isSuperAdmin && (
                        <li>
                            <NavbarLink path='/admin/viewregimens'>
                                Regimens
                            </NavbarLink>
                        </li>
                    )}

                    <li>
                        <NavbarLink
                            path='/admin/viewcompanies'
                            relatedpaths={[
                                '/admin/editcompany',
                                '/admin/addcompany',
                            ]}
                        >
                            Companies
                        </NavbarLink>
                    </li>
                </ul>
            </nav>
        </Fragment>
    );
};

export default AdminNav;
