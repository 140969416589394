// since this is a form,  each input should be a piece of state, so we need useState hook
import React, { useState, useEffect, Fragment } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import { APP_NAME } from '../../../utils/constants';
import DayJS from 'react-dayjs';
import AdminFormFooter from '../AdminFormFooter';
import {
    createIngredient,
    getIngredientById,
    getIngredients,
} from '../../../actions/ingredient';
import Autocomplete from '../../layout/Autocomplete';
import Spinner from '../../layout/Spinner';
import { parseArrayOrNewLineList } from '../../../utils/helpers';

// Notes: fields use pattern "fieldname || initialState.fieldname" because certain fields may not be in database for user (namely, when models change). This will help when fetching data for ingredient when editing it.
// set default state values that will appear in form
const initialState = {
    name: '',
    urlparam: '',
    synonyms: [],
    casnumber: '',
    privatenotes: '',
    usageratemin: '',
    usageratemax: '',
    needsreview: false,
    description: [],
    sources: [],
    contains: [],
    mightcontain: [],
    relatedto: [],
    isprotein: false,
    containsprotein: false,
    isaminoacid: false,
    ishumectant: false,
    issolvent: false,
    isantihumectant: false,
    isparaben: false,
    issilicone: false,
    ispreservative: false,
    isabsorbent: false,
    isadsorbent: false,
    isfilmforming: false,
    isantimicrobial: false,
    isskinsoothing: false,
    isantiinflammatory: false,
    isexfoliant: false,
    isorganic: false,
    issynthetic: false,
    isantidandruff: false,
    isantioxidant: false,
    iscolorant: false,
    ishaircolorant: false,
    ischelant: false,
    ismoisturizer: false,
    isantistatic: false,
    isgeneralconditioner: false,
    isdepilatory: false,
    isfixative: false,
    isoxidizer: false,
    isphadjuster: false,
    ispropellant: false,
    issunscreen: false,
    isthickener: false,
    isgeneralsurfactant: false,
    iscleansingagent: false,
    isemulsifier: false,
    isfoambooster: false,
    isfragrance: false,
    isslipagent: false,
    issensitizer: false,
    isbadalcohol: false,
    issulfate: false,
    isreducingagent: false,
    isoil: false,
    rating: '',
};

const IngredientForm = ({
    // prop types
    ingredient: {
        ingredient,
        ingredients_autocomplete,
        loading,
        loading_autocomplete,
    },
    createIngredient,
    getIngredientById, // to fill in fields for editing
    getIngredients,
}) => {
    let params = useParams();
    let history = useNavigate();

    // format: [state, function to update state]
    const [formData, setFormData] = useState(initialState);

    useEffect(() => {
        if (!ingredient && params.id) {
            getIngredientById(params.id, history);
        }

        if (!loading && ingredient && params.id) {
            // update form fields with current ingredient
            const ingredientData = { ...initialState };

            for (const key in ingredient) {
                if (key in ingredientData)
                    ingredientData[key] = ingredient[key];
            }

            // handle subsections of data model
            for (const key in ingredient.properties) {
                if (key in ingredientData)
                    ingredientData[key] = ingredient.properties[key];
            }

            setFormData(ingredientData);
        }
    }, [
        ingredient,
        loading,
        getIngredientById,
        ingredients_autocomplete,
        params.id,
        history,
    ]);

    useEffect(() => {
        getIngredients(true /* get autocomplete data */);
    }, [getIngredients]);

    useEffect(() => {
        window.scrollTo(0, 0); // scroll to top of form on mount
    }, []);

    const {
        name,
        synonyms,
        contains,
        urlparam,
        mightcontain,
        relatedto,
        casnumber,
        privatenotes,
        usageratemin,
        usageratemax,
        needsreview,
        description,
        sources,
        isprotein,
        containsprotein,
        isaminoacid,
        ishumectant,
        issolvent,
        isantihumectant,
        isparaben,
        issilicone,
        ispreservative,
        isabsorbent,
        isadsorbent,
        isfilmforming,
        isantimicrobial,
        isskinsoothing,
        isantiinflammatory,
        isexfoliant,
        isorganic,
        issynthetic,
        isantidandruff,
        isantioxidant,
        iscolorant,
        ishaircolorant,
        ischelant,
        ismoisturizer,
        isantistatic,
        isgeneralconditioner,
        isdepilatory,
        isfixative,
        isoxidizer,
        isphadjuster,
        ispropellant,
        issunscreen,
        isthickener,
        isgeneralsurfactant,
        iscleansingagent,
        isemulsifier,
        isfoambooster,
        isfragrance,
        isslipagent,
        issensitizer,
        isbadalcohol,
        issulfate,
        isreducingagent,
        isoil,
        rating,
    } = formData;

    const updateArrayFromText = (e) => {
        e.stopPropagation();

        // note: if empty lines are found, they are removed in API before saving
        let arraytosend = parseArrayOrNewLineList(e.target.value); // parses text into array

        if (e.target.name === 'casnumber_box') {
            setFormData({ ...formData, casnumber: arraytosend });
        } else if (e.target.name === 'synonyms_box') {
            setFormData({ ...formData, synonyms: arraytosend });
        } else if (e.target.name === 'description_box') {
            setFormData({ ...formData, description: arraytosend });
        } else if (e.target.name === 'sources_box') {
            setFormData({ ...formData, sources: arraytosend });
        }
    };

    const updateAutocompleteFields = (e) => {
        e.stopPropagation();

        // value is stringified array; parse into array
        const parsed = JSON.parse(e.target.value);
        if (e.target.name === 'contains') {
            setFormData({ ...formData, contains: parsed });
        } else if (e.target.name === 'mightcontain') {
            setFormData({ ...formData, mightcontain: parsed });
        } else if (e.target.name === 'relatedto') {
            setFormData({ ...formData, relatedto: parsed });
        }
    };

    const onChange = (e) => {
        e.stopPropagation(); // to prevent lastpass bug from throwing error in console
        setFormData({ ...formData, [e.target.name]: e.target.value }); // [] used to specify property properly
    };

    const onSubmit = (e) => {
        e.preventDefault();
        createIngredient(formData, history, params.id || undefined);
    };

    return (
        <div className='containermiddle'>
            <Helmet>
                <title>
                    {APP_NAME} | {params.id ? 'Update' : 'Add'} Ingredient
                    {ingredient ? ': ' + ingredient.name : ''}
                </title>
            </Helmet>

            <div className='header-with-button mb'>
                <h1>{params.id ? 'Update' : 'Add'} Ingredient</h1>
                {!loading && ingredient && params.id ? (
                    <Link
                        to={`/ingredient/${ingredient.urlparam}`}
                        className='btn btn-primary mr-0'
                    >
                        View Ingredient
                    </Link>
                ) : (
                    ''
                )}
            </div>
            {!loading && ingredient && params.id && (
                <Fragment>
                    <p className='allcaps small'>
                        Added by{' '}
                        {ingredient &&
                            ingredient.createdby &&
                            ingredient.createdby.username}{' '}
                        on{' '}
                        {ingredient && ingredient.created && (
                            <DayJS format='ddd, MMM DD YYYY [at] hh:mm:ssa'>
                                {ingredient.created}
                            </DayJS>
                        )}
                    </p>
                    <p className='allcaps small'>
                        Last edited by{' '}
                        {ingredient &&
                            ingredient.lasteditedby &&
                            ingredient.lasteditedby.username}{' '}
                        on{' '}
                        {ingredient && ingredient.updated && (
                            <DayJS format='ddd, MMM DD YYYY [at] hh:mm:ssa'>
                                {ingredient.updated}
                            </DayJS>
                        )}
                    </p>
                </Fragment>
            )}
            <p className='italicsmsg m0 pt-0'>* = required field</p>

            <form className='form' onSubmit={onSubmit}>
                <div className='form-group'>
                    <p className='form-header'>* INCI name</p>
                    <input
                        type='text'
                        placeholder='Ingredient INCI name'
                        name='name'
                        value={name || initialState.name}
                        onChange={onChange}
                        onKeyPress={(e) => {
                            e.key === 'Enter' && e.preventDefault();
                        }}
                    />
                </div>

                <div className='form-group'>
                    <p className='form-header'>URL parameter</p>
                    <small className='form-text mb'>
                        Leaving this blank is fine; it will be autogenerated on
                        save
                    </small>
                    <input
                        type='text'
                        placeholder='Ingredient URL param'
                        name='urlparam'
                        value={urlparam || initialState.urlparam}
                        onChange={onChange}
                        onKeyPress={(e) => {
                            e.key === 'Enter' && e.preventDefault();
                        }}
                    />
                </div>

                <div className='form-group'>
                    <p className='form-header'>CAS number</p>
                    <textarea
                        placeholder='Enter each CAS number on a new line'
                        name='casnumber_box'
                        value={parseArrayOrNewLineList(
                            casnumber || initialState.casnumber
                        )}
                        onChange={updateArrayFromText}
                    />
                    <input
                        type='text'
                        style={{ display: 'none' }}
                        onChange={onChange}
                        value={casnumber || initialState.casnumber}
                        name='casnumber'
                    />
                </div>

                <div className='form-group'>
                    <p className='form-header'>Synonyms</p>
                    <p className='form-text mb'>
                        Enter each synonym on a new line
                    </p>
                    <textarea
                        placeholder='Enter each synonym on a new line'
                        name='synonyms_box'
                        value={parseArrayOrNewLineList(
                            synonyms || initialState.synonyms
                        )}
                        rows={5}
                        onChange={updateArrayFromText}
                    />
                    <input
                        type='text'
                        style={{ display: 'none' }}
                        onChange={onChange}
                        value={synonyms || initialState.synonyms}
                        name='synonyms'
                    />
                </div>

                <div className='form-group'>
                    <p className='form-header'>Description</p>
                    <textarea
                        placeholder='Enter each description paragraph/bullet on a new line'
                        name='description_box'
                        value={parseArrayOrNewLineList(
                            description || initialState.description
                        )}
                        rows={5}
                        onChange={updateArrayFromText}
                    />
                    <input
                        type='text'
                        style={{ display: 'none' }}
                        onChange={onChange}
                        value={description || initialState.description}
                        name='description'
                    />
                </div>

                <div className='form-group'>
                    <p className='form-header'>Usage Rate</p>
                    <small className='form-text mb'>
                        Ingredient suppliers often include a recommended usage
                        rate for products. Add the percentage value (without the
                        % sign). There is usually a minimum and maximum provided
                        in a range (e.g. 0.5-2%), but if there is just one value
                        (e.g. 2%), then enter the same value for the minimum and
                        maximum.
                    </small>
                    <div className='twocol'>
                        <div>
                            <p className='form-header allcaps'>MIN</p>
                            <input
                                type='text'
                                placeholder='recommended ingredient usage rate (min % range)'
                                name='usageratemin'
                                value={
                                    usageratemin || initialState.usageratemin
                                }
                                onChange={onChange}
                                onKeyPress={(e) => {
                                    e.key === 'Enter' && e.preventDefault();
                                }}
                            />
                        </div>
                        <div>
                            <p className='form-header allcaps'>MAX</p>
                            <input
                                type='text'
                                placeholder='recommended ingredient usage rate (max % range)'
                                name='usageratemax'
                                value={
                                    usageratemax || initialState.usageratemax
                                }
                                onChange={onChange}
                                onKeyPress={(e) => {
                                    e.key === 'Enter' && e.preventDefault();
                                }}
                            />
                        </div>
                    </div>
                </div>

                <div className='form-group'>
                    <p className='form-header'>Sources</p>
                    <textarea
                        placeholder='Enter each source on a new line'
                        name='sources_box'
                        value={parseArrayOrNewLineList(
                            sources || initialState.sources
                        )}
                        rows={5}
                        onChange={updateArrayFromText}
                    />
                    <input
                        type='text'
                        style={{ display: 'none' }}
                        onChange={onChange}
                        value={sources || initialState.sources}
                        name='sources'
                    />
                </div>

                <div className='form-group'>
                    <p className='form-header'>Private Notes</p>
                    <small className='form-text mb'>
                        This is only visible in this form, not to users when
                        viewing ingredient page.
                    </small>
                    <textarea
                        placeholder='Enter notes'
                        name='privatenotes'
                        value={privatenotes || initialState.privatenotes}
                        onChange={onChange}
                    />
                </div>

                <div className='form-group'>
                    <p className='form-header'>Other ingredients in this one</p>
                    {loading_autocomplete ? (
                        // wait for ingredient suggestions to load
                        <Spinner size={0} />
                    ) : (
                        <Autocomplete
                            cssID={'ing0'}
                            stringsName=''
                            objectsName='contains'
                            objectsData={contains || initialState.contains}
                            placeholder='Enter sub-ingredients'
                            onChangeHandler={updateAutocompleteFields}
                            suggestions={ingredients_autocomplete}
                            allowNotFoundSuggestions={false}
                        />
                    )}
                </div>

                <div className='form-group'>
                    <p className='form-header'>
                        Other ingredients that <strong>might</strong> be in this
                        one
                    </p>
                    {loading_autocomplete ? (
                        // wait for ingredient suggestions to load
                        <Spinner size={0} />
                    ) : (
                        <Autocomplete
                            cssID={'poss0'}
                            stringsName=''
                            objectsName='mightcontain'
                            objectsData={
                                mightcontain || initialState.mightcontain
                            }
                            placeholder='Enter possible sub-ingredients'
                            onChangeHandler={updateAutocompleteFields}
                            suggestions={ingredients_autocomplete}
                            allowNotFoundSuggestions={false}
                        />
                    )}
                </div>

                <div className='form-group'>
                    <p className='form-header'>
                        Other ingredients that this ingredient is related to
                    </p>
                    {loading_autocomplete ? (
                        // wait for ingredient suggestions to load
                        <Spinner size={0} />
                    ) : (
                        <Autocomplete
                            cssID={'related0'}
                            stringsName=''
                            objectsName='relatedto'
                            objectsData={relatedto || initialState.relatedto}
                            placeholder='Enter related ingredients'
                            onChangeHandler={updateAutocompleteFields}
                            suggestions={ingredients_autocomplete}
                            allowNotFoundSuggestions={false}
                        />
                    )}
                </div>

                <div className='form-group'>
                    <p className='form-header'>Rating</p>
                    <select
                        name='rating'
                        value={rating || initialState.rating}
                        onChange={onChange}
                        onKeyPress={(e) => {
                            e.key === 'Enter' && e.preventDefault();
                        }}
                    >
                        <option value='Not yet rated'>
                            Select ingredient rating
                        </option>
                        <option value='Great'>Great</option>
                        <option value='Good'>Good</option>
                        <option value='Average'>Average</option>
                        <option value='Poor'>Poor</option>
                    </select>
                </div>

                <div className='ingredient-property-checklist'>
                    <div>
                        <div className='form-group'>
                            <span>
                                Protein{' '}
                                <input
                                    type='checkbox'
                                    name='isprotein'
                                    value={isprotein || initialState.isprotein}
                                    checked={
                                        isprotein || initialState.isprotein
                                    }
                                    onChange={() => {
                                        setFormData({
                                            ...formData,
                                            isprotein: !isprotein,
                                        });
                                    }}
                                    onKeyPress={(e) => {
                                        e.key === 'Enter' && e.preventDefault();
                                    }}
                                />
                                <span className='form-text mb'>
                                    Check this if this ingredient is a protein.
                                </span>
                            </span>
                        </div>

                        <div className='form-group'>
                            <span>
                                Contains Protein{' '}
                                <input
                                    type='checkbox'
                                    name='containsprotein'
                                    value={
                                        containsprotein ||
                                        initialState.containsprotein
                                    }
                                    checked={
                                        containsprotein ||
                                        initialState.containsprotein
                                    }
                                    onChange={() => {
                                        setFormData({
                                            ...formData,
                                            containsprotein: !containsprotein,
                                        });
                                    }}
                                    onKeyPress={(e) => {
                                        e.key === 'Enter' && e.preventDefault();
                                    }}
                                />
                                <span className='form-text mb'>
                                    Check this if this ingredient contains
                                    protein but isn't a protein itself.
                                </span>
                            </span>
                        </div>

                        <div className='form-group'>
                            <span>
                                Amino Acid{' '}
                                <input
                                    type='checkbox'
                                    name='isaminoacid'
                                    value={
                                        isaminoacid || initialState.isaminoacid
                                    }
                                    checked={
                                        isaminoacid || initialState.isaminoacid
                                    }
                                    onChange={() => {
                                        setFormData({
                                            ...formData,
                                            isaminoacid: !isaminoacid,
                                        });
                                    }}
                                    onKeyPress={(e) => {
                                        e.key === 'Enter' && e.preventDefault();
                                    }}
                                />
                            </span>
                        </div>

                        <div className='form-group'>
                            <span>
                                Paraben{' '}
                                <input
                                    type='checkbox'
                                    name='isparaben'
                                    value={isparaben || initialState.isparaben}
                                    checked={
                                        isparaben || initialState.isparaben
                                    }
                                    onChange={() => {
                                        setFormData({
                                            ...formData,
                                            isparaben: !isparaben,
                                        });
                                    }}
                                    onKeyPress={(e) => {
                                        e.key === 'Enter' && e.preventDefault();
                                    }}
                                />
                            </span>
                        </div>

                        <div className='form-group'>
                            <span>
                                Silicone{' '}
                                <input
                                    type='checkbox'
                                    name='issilicone'
                                    value={
                                        issilicone || initialState.issilicone
                                    }
                                    checked={
                                        issilicone || initialState.issilicone
                                    }
                                    onChange={() => {
                                        setFormData({
                                            ...formData,
                                            issilicone: !issilicone,
                                        });
                                    }}
                                    onKeyPress={(e) => {
                                        e.key === 'Enter' && e.preventDefault();
                                    }}
                                />
                            </span>
                        </div>

                        <div className='form-group'>
                            <span>
                                Found in nature{' '}
                                <input
                                    type='checkbox'
                                    name='isorganic'
                                    value={isorganic || initialState.isorganic}
                                    checked={
                                        isorganic || initialState.isorganic
                                    }
                                    onChange={() => {
                                        setFormData({
                                            ...formData,
                                            isorganic: !isorganic,
                                        });
                                    }}
                                    onKeyPress={(e) => {
                                        e.key === 'Enter' && e.preventDefault();
                                    }}
                                />
                            </span>
                        </div>

                        <div className='form-group'>
                            <span>
                                Synthetic{' '}
                                <input
                                    type='checkbox'
                                    name='issynthetic'
                                    value={
                                        issynthetic || initialState.issynthetic
                                    }
                                    checked={
                                        issynthetic || initialState.issynthetic
                                    }
                                    onChange={() => {
                                        setFormData({
                                            ...formData,
                                            issynthetic: !issynthetic,
                                        });
                                    }}
                                    onKeyPress={(e) => {
                                        e.key === 'Enter' && e.preventDefault();
                                    }}
                                />
                            </span>
                        </div>

                        <div className='form-group'>
                            <span>
                                Solvent{' '}
                                <input
                                    type='checkbox'
                                    name='issolvent'
                                    value={issolvent || initialState.issolvent}
                                    checked={
                                        issolvent || initialState.issolvent
                                    }
                                    onChange={() => {
                                        setFormData({
                                            ...formData,
                                            issolvent: !issolvent,
                                        });
                                    }}
                                    onKeyPress={(e) => {
                                        e.key === 'Enter' && e.preventDefault();
                                    }}
                                />
                            </span>
                        </div>

                        <div className='form-group'>
                            <span>
                                Preservative{' '}
                                <input
                                    type='checkbox'
                                    name='ispreservative'
                                    value={
                                        ispreservative ||
                                        initialState.ispreservative
                                    }
                                    checked={
                                        ispreservative ||
                                        initialState.ispreservative
                                    }
                                    onChange={() => {
                                        setFormData({
                                            ...formData,
                                            ispreservative: !ispreservative,
                                        });
                                    }}
                                    onKeyPress={(e) => {
                                        e.key === 'Enter' && e.preventDefault();
                                    }}
                                />
                            </span>
                        </div>

                        <div className='form-group'>
                            <span>
                                Antimicrobial{' '}
                                <input
                                    type='checkbox'
                                    name='isantimicrobial'
                                    value={
                                        isantimicrobial ||
                                        initialState.isantimicrobial
                                    }
                                    checked={
                                        isantimicrobial ||
                                        initialState.isantimicrobial
                                    }
                                    onChange={() => {
                                        setFormData({
                                            ...formData,
                                            isantimicrobial: !isantimicrobial,
                                        });
                                    }}
                                    onKeyPress={(e) => {
                                        e.key === 'Enter' && e.preventDefault();
                                    }}
                                />
                            </span>
                        </div>

                        <div className='form-group'>
                            <span>
                                Anti-inflammatory{' '}
                                <input
                                    type='checkbox'
                                    name='isantiinflammatory'
                                    value={
                                        isantiinflammatory ||
                                        initialState.isantiinflammatory
                                    }
                                    checked={
                                        isantiinflammatory ||
                                        initialState.isantiinflammatory
                                    }
                                    onChange={() => {
                                        setFormData({
                                            ...formData,
                                            isantiinflammatory:
                                                !isantiinflammatory,
                                        });
                                    }}
                                    onKeyPress={(e) => {
                                        e.key === 'Enter' && e.preventDefault();
                                    }}
                                />
                            </span>
                        </div>

                        <div className='form-group'>
                            <span>
                                Antidandruff{' '}
                                <input
                                    type='checkbox'
                                    name='isantidandruff'
                                    value={
                                        isantidandruff ||
                                        initialState.isantidandruff
                                    }
                                    checked={
                                        isantidandruff ||
                                        initialState.isantidandruff
                                    }
                                    onChange={() => {
                                        setFormData({
                                            ...formData,
                                            isantidandruff: !isantidandruff,
                                        });
                                    }}
                                    onKeyPress={(e) => {
                                        e.key === 'Enter' && e.preventDefault();
                                    }}
                                />
                            </span>
                        </div>

                        <div className='form-group'>
                            <span>
                                Antioxidant{' '}
                                <input
                                    type='checkbox'
                                    name='isantioxidant'
                                    value={
                                        isantioxidant ||
                                        initialState.isantioxidant
                                    }
                                    checked={
                                        isantioxidant ||
                                        initialState.isantioxidant
                                    }
                                    onChange={() => {
                                        setFormData({
                                            ...formData,
                                            isantioxidant: !isantioxidant,
                                        });
                                    }}
                                    onKeyPress={(e) => {
                                        e.key === 'Enter' && e.preventDefault();
                                    }}
                                />
                            </span>
                        </div>

                        <div className='form-group'>
                            <span>
                                Colorant{' '}
                                <input
                                    type='checkbox'
                                    name='iscolorant'
                                    value={
                                        iscolorant || initialState.iscolorant
                                    }
                                    checked={
                                        iscolorant || initialState.iscolorant
                                    }
                                    onChange={() => {
                                        setFormData({
                                            ...formData,
                                            iscolorant: !iscolorant,
                                        });
                                    }}
                                    onKeyPress={(e) => {
                                        e.key === 'Enter' && e.preventDefault();
                                    }}
                                />
                            </span>
                        </div>

                        <div className='form-group'>
                            <span>
                                Hair colorant{' '}
                                <input
                                    type='checkbox'
                                    name='ishaircolorant'
                                    value={
                                        ishaircolorant ||
                                        initialState.ishaircolorant
                                    }
                                    checked={
                                        ishaircolorant ||
                                        initialState.ishaircolorant
                                    }
                                    onChange={() => {
                                        setFormData({
                                            ...formData,
                                            ishaircolorant: !ishaircolorant,
                                        });
                                    }}
                                    onKeyPress={(e) => {
                                        e.key === 'Enter' && e.preventDefault();
                                    }}
                                />
                            </span>
                        </div>
                    </div>

                    <div>
                        <div className='form-group'>
                            <span>
                                Absorbent{' '}
                                <input
                                    type='checkbox'
                                    name='isabsorbent'
                                    value={
                                        isabsorbent || initialState.isabsorbent
                                    }
                                    checked={
                                        isabsorbent || initialState.isabsorbent
                                    }
                                    onChange={() => {
                                        setFormData({
                                            ...formData,
                                            isabsorbent: !isabsorbent,
                                        });
                                    }}
                                    onKeyPress={(e) => {
                                        e.key === 'Enter' && e.preventDefault();
                                    }}
                                />
                                <span className='form-text mb'>
                                    An absorbent is a material which will take
                                    in the liquid or gas uniformly.
                                </span>
                            </span>
                        </div>

                        <div className='form-group'>
                            <span>
                                Adsorbent{' '}
                                <input
                                    type='checkbox'
                                    name='isadsorbent'
                                    value={
                                        isadsorbent || initialState.isadsorbent
                                    }
                                    checked={
                                        isadsorbent || initialState.isadsorbent
                                    }
                                    onChange={() => {
                                        setFormData({
                                            ...formData,
                                            isadsorbent: !isadsorbent,
                                        });
                                    }}
                                    onKeyPress={(e) => {
                                        e.key === 'Enter' && e.preventDefault();
                                    }}
                                />
                                <span className='form-text mb'>
                                    An adsorbent is a material which will allow
                                    a liquid, gas or dissolved solid to adhere
                                    to its surface.
                                </span>
                            </span>
                        </div>

                        <div className='form-group'>
                            <span>
                                Chelant{' '}
                                <input
                                    type='checkbox'
                                    name='ischelant'
                                    value={ischelant || initialState.ischelant}
                                    checked={
                                        ischelant || initialState.ischelant
                                    }
                                    onChange={() => {
                                        setFormData({
                                            ...formData,
                                            ischelant: !ischelant,
                                        });
                                    }}
                                    onKeyPress={(e) => {
                                        e.key === 'Enter' && e.preventDefault();
                                    }}
                                />
                            </span>
                        </div>

                        <div className='form-group'>
                            <span>
                                Antihumectant{' '}
                                <input
                                    type='checkbox'
                                    name='isantihumectant'
                                    value={
                                        isantihumectant ||
                                        initialState.isantihumectant
                                    }
                                    checked={
                                        isantihumectant ||
                                        initialState.isantihumectant
                                    }
                                    onChange={() => {
                                        setFormData({
                                            ...formData,
                                            isantihumectant: !isantihumectant,
                                        });
                                    }}
                                    onKeyPress={(e) => {
                                        e.key === 'Enter' && e.preventDefault();
                                    }}
                                />
                            </span>
                        </div>
                        <div className='form-group'>
                            <span>
                                Conditioner: humectant{' '}
                                <input
                                    type='checkbox'
                                    name='ishumectant'
                                    value={
                                        ishumectant || initialState.ishumectant
                                    }
                                    checked={
                                        ishumectant || initialState.ishumectant
                                    }
                                    onChange={() => {
                                        setFormData({
                                            ...formData,
                                            ishumectant: !ishumectant,
                                        });
                                    }}
                                    onKeyPress={(e) => {
                                        e.key === 'Enter' && e.preventDefault();
                                    }}
                                />
                            </span>
                        </div>
                        <div className='form-group'>
                            <span>
                                Conditioner: moisturizer{' '}
                                <input
                                    type='checkbox'
                                    name='ismoisturizer'
                                    value={
                                        ismoisturizer ||
                                        initialState.ismoisturizer
                                    }
                                    checked={
                                        ismoisturizer ||
                                        initialState.ismoisturizer
                                    }
                                    onChange={() => {
                                        setFormData({
                                            ...formData,
                                            ismoisturizer: !ismoisturizer,
                                        });
                                    }}
                                    onKeyPress={(e) => {
                                        e.key === 'Enter' && e.preventDefault();
                                    }}
                                />
                            </span>
                        </div>

                        <div className='form-group'>
                            <span>
                                Conditioner: antistatic{' '}
                                <input
                                    type='checkbox'
                                    name='isantistatic'
                                    value={
                                        isantistatic ||
                                        initialState.isantistatic
                                    }
                                    checked={
                                        isantistatic ||
                                        initialState.isantistatic
                                    }
                                    onChange={() => {
                                        setFormData({
                                            ...formData,
                                            isantistatic: !isantistatic,
                                        });
                                    }}
                                    onKeyPress={(e) => {
                                        e.key === 'Enter' && e.preventDefault();
                                    }}
                                />
                            </span>
                        </div>
                        <div className='form-group'>
                            <span>
                                Conditioner: general (emollient){' '}
                                <input
                                    type='checkbox'
                                    name='isgeneralconditioner'
                                    value={
                                        isgeneralconditioner ||
                                        initialState.isgeneralconditioner
                                    }
                                    checked={
                                        isgeneralconditioner ||
                                        initialState.isgeneralconditioner
                                    }
                                    onChange={() => {
                                        setFormData({
                                            ...formData,
                                            isgeneralconditioner:
                                                !isgeneralconditioner,
                                        });
                                    }}
                                    onKeyPress={(e) => {
                                        e.key === 'Enter' && e.preventDefault();
                                    }}
                                />
                            </span>
                        </div>

                        <div className='form-group'>
                            <span>
                                Exfoliant{' '}
                                <input
                                    type='checkbox'
                                    name='isexfoliant'
                                    value={
                                        isexfoliant || initialState.isexfoliant
                                    }
                                    checked={
                                        isexfoliant || initialState.isexfoliant
                                    }
                                    onChange={() => {
                                        setFormData({
                                            ...formData,
                                            isexfoliant: !isexfoliant,
                                        });
                                    }}
                                    onKeyPress={(e) => {
                                        e.key === 'Enter' && e.preventDefault();
                                    }}
                                />
                            </span>
                        </div>

                        <div className='form-group'>
                            <span>
                                Depilatory{' '}
                                <input
                                    type='checkbox'
                                    name='isdepilatory'
                                    value={
                                        isdepilatory ||
                                        initialState.isdepilatory
                                    }
                                    checked={
                                        isdepilatory ||
                                        initialState.isdepilatory
                                    }
                                    onChange={() => {
                                        setFormData({
                                            ...formData,
                                            isdepilatory: !isdepilatory,
                                        });
                                    }}
                                    onKeyPress={(e) => {
                                        e.key === 'Enter' && e.preventDefault();
                                    }}
                                />
                            </span>
                        </div>
                        <div className='form-group'>
                            <span>
                                Fixative{' '}
                                <input
                                    type='checkbox'
                                    name='isfixative'
                                    value={
                                        isfixative || initialState.isfixative
                                    }
                                    checked={
                                        isfixative || initialState.isfixative
                                    }
                                    onChange={() => {
                                        setFormData({
                                            ...formData,
                                            isfixative: !isfixative,
                                        });
                                    }}
                                    onKeyPress={(e) => {
                                        e.key === 'Enter' && e.preventDefault();
                                    }}
                                />
                            </span>
                        </div>
                        <div className='form-group'>
                            <span>
                                Oxidizer{' '}
                                <input
                                    type='checkbox'
                                    name='isoxidizer'
                                    value={
                                        isoxidizer || initialState.isoxidizer
                                    }
                                    checked={
                                        isoxidizer || initialState.isoxidizer
                                    }
                                    onChange={() => {
                                        setFormData({
                                            ...formData,
                                            isoxidizer: !isoxidizer,
                                        });
                                    }}
                                    onKeyPress={(e) => {
                                        e.key === 'Enter' && e.preventDefault();
                                    }}
                                />
                            </span>
                        </div>
                        <div className='form-group'>
                            <span>
                                Oil{' '}
                                <input
                                    type='checkbox'
                                    name='isoil'
                                    value={isoil || initialState.isoil}
                                    checked={isoil || initialState.isoil}
                                    onChange={() => {
                                        setFormData({
                                            ...formData,
                                            isoil: !isoil,
                                        });
                                    }}
                                    onKeyPress={(e) => {
                                        e.key === 'Enter' && e.preventDefault();
                                    }}
                                />
                            </span>
                        </div>
                    </div>

                    <div>
                        <div className='form-group'>
                            <span>
                                pH adjuster{' '}
                                <input
                                    type='checkbox'
                                    name='isphadjuster'
                                    value={
                                        isphadjuster ||
                                        initialState.isphadjuster
                                    }
                                    checked={
                                        isphadjuster ||
                                        initialState.isphadjuster
                                    }
                                    onChange={() => {
                                        setFormData({
                                            ...formData,
                                            isphadjuster: !isphadjuster,
                                        });
                                    }}
                                    onKeyPress={(e) => {
                                        e.key === 'Enter' && e.preventDefault();
                                    }}
                                />
                            </span>
                        </div>

                        <div className='form-group'>
                            <span>
                                Propellant{' '}
                                <input
                                    type='checkbox'
                                    name='ispropellant'
                                    value={
                                        ispropellant ||
                                        initialState.ispropellant
                                    }
                                    checked={
                                        ispropellant ||
                                        initialState.ispropellant
                                    }
                                    onChange={() => {
                                        setFormData({
                                            ...formData,
                                            ispropellant: !ispropellant,
                                        });
                                    }}
                                    onKeyPress={(e) => {
                                        e.key === 'Enter' && e.preventDefault();
                                    }}
                                />
                            </span>
                        </div>
                        <div className='form-group'>
                            <span>
                                Sunscreen{' '}
                                <input
                                    type='checkbox'
                                    name='issunscreen'
                                    value={
                                        issunscreen || initialState.issunscreen
                                    }
                                    checked={
                                        issunscreen || initialState.issunscreen
                                    }
                                    onChange={() => {
                                        setFormData({
                                            ...formData,
                                            issunscreen: !issunscreen,
                                        });
                                    }}
                                    onKeyPress={(e) => {
                                        e.key === 'Enter' && e.preventDefault();
                                    }}
                                />
                            </span>
                        </div>
                        <div className='form-group'>
                            <span>
                                Thickener{' '}
                                <input
                                    type='checkbox'
                                    name='isthickener'
                                    value={
                                        isthickener || initialState.isthickener
                                    }
                                    checked={
                                        isthickener || initialState.isthickener
                                    }
                                    onChange={() => {
                                        setFormData({
                                            ...formData,
                                            isthickener: !isthickener,
                                        });
                                    }}
                                    onKeyPress={(e) => {
                                        e.key === 'Enter' && e.preventDefault();
                                    }}
                                />
                            </span>
                        </div>

                        <div className='form-group'>
                            <span>
                                Film-forming{' '}
                                <input
                                    type='checkbox'
                                    name='isfilmforming'
                                    value={
                                        isfilmforming ||
                                        initialState.isfilmforming
                                    }
                                    checked={
                                        isfilmforming ||
                                        initialState.isfilmforming
                                    }
                                    onChange={() => {
                                        setFormData({
                                            ...formData,
                                            isfilmforming: !isfilmforming,
                                        });
                                    }}
                                    onKeyPress={(e) => {
                                        e.key === 'Enter' && e.preventDefault();
                                    }}
                                />
                            </span>
                        </div>

                        <div className='form-group'>
                            <span>
                                Surfactant: general{' '}
                                <input
                                    type='checkbox'
                                    name='isgeneralsurfactant'
                                    value={
                                        isgeneralsurfactant ||
                                        initialState.isgeneralsurfactant
                                    }
                                    checked={
                                        isgeneralsurfactant ||
                                        initialState.isgeneralsurfactant
                                    }
                                    onChange={() => {
                                        setFormData({
                                            ...formData,
                                            isgeneralsurfactant:
                                                !isgeneralsurfactant,
                                        });
                                    }}
                                    onKeyPress={(e) => {
                                        e.key === 'Enter' && e.preventDefault();
                                    }}
                                />
                            </span>
                        </div>
                        <div className='form-group'>
                            <span>
                                Surfactant: cleansing agent{' '}
                                <input
                                    type='checkbox'
                                    name='iscleansingagent'
                                    value={
                                        iscleansingagent ||
                                        initialState.iscleansingagent
                                    }
                                    checked={
                                        iscleansingagent ||
                                        initialState.iscleansingagent
                                    }
                                    onChange={() => {
                                        setFormData({
                                            ...formData,
                                            iscleansingagent: !iscleansingagent,
                                        });
                                    }}
                                    onKeyPress={(e) => {
                                        e.key === 'Enter' && e.preventDefault();
                                    }}
                                />
                            </span>
                        </div>
                        <div className='form-group'>
                            <span>
                                Surfactant: emulsifier{' '}
                                <input
                                    type='checkbox'
                                    name='isemulsifier'
                                    value={
                                        isemulsifier ||
                                        initialState.isemulsifier
                                    }
                                    checked={
                                        isemulsifier ||
                                        initialState.isemulsifier
                                    }
                                    onChange={() => {
                                        setFormData({
                                            ...formData,
                                            isemulsifier: !isemulsifier,
                                        });
                                    }}
                                    onKeyPress={(e) => {
                                        e.key === 'Enter' && e.preventDefault();
                                    }}
                                />
                            </span>
                        </div>
                        <div className='form-group'>
                            <span>
                                Surfactant: foam booster{' '}
                                <input
                                    type='checkbox'
                                    name='isfoambooster'
                                    value={
                                        isfoambooster ||
                                        initialState.isfoambooster
                                    }
                                    checked={
                                        isfoambooster ||
                                        initialState.isfoambooster
                                    }
                                    onChange={() => {
                                        setFormData({
                                            ...formData,
                                            isfoambooster: !isfoambooster,
                                        });
                                    }}
                                    onKeyPress={(e) => {
                                        e.key === 'Enter' && e.preventDefault();
                                    }}
                                />
                            </span>
                        </div>

                        <div className='form-group'>
                            <span>
                                Sulfate{' '}
                                <input
                                    type='checkbox'
                                    name='issulfate'
                                    value={issulfate || initialState.issulfate}
                                    checked={
                                        issulfate || initialState.issulfate
                                    }
                                    onChange={() => {
                                        setFormData({
                                            ...formData,
                                            issulfate: !issulfate,
                                        });
                                    }}
                                    onKeyPress={(e) => {
                                        e.key === 'Enter' && e.preventDefault();
                                    }}
                                />
                            </span>
                        </div>

                        <div className='form-group'>
                            <span>
                                Bad/Drying alcohol{' '}
                                <input
                                    type='checkbox'
                                    name='isbadalcohol'
                                    value={
                                        isbadalcohol ||
                                        initialState.isbadalcohol
                                    }
                                    checked={
                                        isbadalcohol ||
                                        initialState.isbadalcohol
                                    }
                                    onChange={() => {
                                        setFormData({
                                            ...formData,
                                            isbadalcohol: !isbadalcohol,
                                        });
                                    }}
                                    onKeyPress={(e) => {
                                        e.key === 'Enter' && e.preventDefault();
                                    }}
                                />
                            </span>
                        </div>

                        <div className='form-group'>
                            <span>
                                Sensitizer{' '}
                                <input
                                    type='checkbox'
                                    name='issensitizer'
                                    value={
                                        issensitizer ||
                                        initialState.issensitizer
                                    }
                                    checked={
                                        issensitizer ||
                                        initialState.issensitizer
                                    }
                                    onChange={() => {
                                        setFormData({
                                            ...formData,
                                            issensitizer: !issensitizer,
                                        });
                                    }}
                                    onKeyPress={(e) => {
                                        e.key === 'Enter' && e.preventDefault();
                                    }}
                                />
                            </span>
                        </div>

                        <div className='form-group'>
                            <span>
                                Fragrance{' '}
                                <input
                                    type='checkbox'
                                    name='isfragrance'
                                    value={
                                        isfragrance || initialState.isfragrance
                                    }
                                    checked={
                                        isfragrance || initialState.isfragrance
                                    }
                                    onChange={() => {
                                        setFormData({
                                            ...formData,
                                            isfragrance: !isfragrance,
                                        });
                                    }}
                                    onKeyPress={(e) => {
                                        e.key === 'Enter' && e.preventDefault();
                                    }}
                                />
                            </span>
                        </div>
                        <div className='form-group'>
                            <span>
                                Slip agent{' '}
                                <input
                                    type='checkbox'
                                    name='isslipagent'
                                    value={
                                        isslipagent || initialState.isslipagent
                                    }
                                    checked={
                                        isslipagent || initialState.isslipagent
                                    }
                                    onChange={() => {
                                        setFormData({
                                            ...formData,
                                            isslipagent: !isslipagent,
                                        });
                                    }}
                                    onKeyPress={(e) => {
                                        e.key === 'Enter' && e.preventDefault();
                                    }}
                                />
                            </span>
                        </div>
                        <div className='form-group'>
                            <span>
                                Reducing (hair waving and straightening) agent{' '}
                                <input
                                    type='checkbox'
                                    name='isreducingagent'
                                    value={
                                        isreducingagent ||
                                        initialState.isreducingagent
                                    }
                                    checked={
                                        isreducingagent ||
                                        initialState.isreducingagent
                                    }
                                    onChange={() => {
                                        setFormData({
                                            ...formData,
                                            isreducingagent: !isreducingagent,
                                        });
                                    }}
                                    onKeyPress={(e) => {
                                        e.key === 'Enter' && e.preventDefault();
                                    }}
                                />
                            </span>
                        </div>
                        <div className='form-group'>
                            <span>
                                Skin-Soothing{' '}
                                <input
                                    type='checkbox'
                                    name='isskinsoothing'
                                    value={
                                        isskinsoothing ||
                                        initialState.isskinsoothing
                                    }
                                    checked={
                                        isskinsoothing ||
                                        initialState.isskinsoothing
                                    }
                                    onChange={() => {
                                        setFormData({
                                            ...formData,
                                            isskinsoothing: !isskinsoothing,
                                        });
                                    }}
                                    onKeyPress={(e) => {
                                        e.key === 'Enter' && e.preventDefault();
                                    }}
                                />
                            </span>
                        </div>
                    </div>
                </div>
                <hr />
                <div className='form-group'>
                    <span>
                        Needs review{' '}
                        <input
                            type='checkbox'
                            name='needsreview'
                            value={needsreview || initialState.needsreview}
                            checked={needsreview || initialState.needsreview}
                            onChange={() => {
                                setFormData({
                                    ...formData,
                                    needsreview: !needsreview,
                                });
                            }}
                            onKeyPress={(e) => {
                                e.key === 'Enter' && e.preventDefault();
                            }}
                        />
                        <span className='form-text mb'>
                            Check this if this ingredient needs additional
                            information or research.
                        </span>
                    </span>
                </div>

                <AdminFormFooter />
            </form>
        </div>
    );
};

IngredientForm.propTypes = {
    // mapped w/state
    createIngredient: PropTypes.func.isRequired,
    getIngredientById: PropTypes.func.isRequired,
    getIngredients: PropTypes.func.isRequired,
    ingredient: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    ingredient: state.ingredient,
});

export default connect(mapStateToProps, {
    createIngredient,
    getIngredientById,
    getIngredients,
})(IngredientForm);
