import {
    REGISTER_SUCCESS,
    USER_LOADED,
    AUTH_ERROR,
    LOGIN_SUCCESS,
    LOGOUT,
    ACCOUNT_DELETED,
    LOADING_PROFILE_PIC,
    UPDATE_PROFILE_PIC,
    DONE_LOADING_PROFILE_PIC,
    VERIFYING_EMAIL,
    DONE_VERIFYING_EMAIL,
    RESETTING_PWD,
    DONE_RESETTING_PWD,
    UPDATE_PROFILE,
} from '../actions/types';

const initialState = {
    token: localStorage.getItem('token'), // store token in localStorage
    isAuthenticated: false,
    isAdmin: false,
    isSuperAdmin: false,
    loading: true, // for initial loading when the app first runs or if user refreshes the browser
    uploading_profile_pic: false,
    verifying_email: false,
    verification_message: '', // msg to show if verifying email succeeds or fails
    resetting_pwd: false,
    pwd_verification_message: '',
    user: null,
    // error: {}, // for request errors
};

// reducers take a state and an action (the action is dispatched from a file)
// actions have a type and a payload
const authReducer = (state = initialState, action) => {
    const { type, payload } = action; // pulled out to shorthand the code

    switch (type) {
        case USER_LOADED:
            return {
                ...state,
                isAuthenticated: true,
                isAdmin: payload.role > 0,
                isSuperAdmin: payload.role === 2, // can do things like view and edit all user regimens
                loading: false,
                user: payload,
            };

        case REGISTER_SUCCESS:
        case LOGIN_SUCCESS:
            // payload is just an object with the token
            return {
                ...state, // keep what's currently in the state
                ...payload, // updates the token since payload just has the token:
                // { token } which  is shorthand for {token: token}
                isAuthenticated: true,
                loading: true, // not false until user is logged in
            };

        case VERIFYING_EMAIL:
            return {
                ...state,
                verifying_email: true,
            };

        case DONE_VERIFYING_EMAIL:
            return {
                ...state,
                verifying_email: false,
                verification_message: payload.msg, // payload has message to display
            };

        // used for getting reset email link and for the form that submits new pwd
        case RESETTING_PWD:
            return {
                ...state,
                resetting_pwd: true,
            };

        case DONE_RESETTING_PWD:
            return {
                ...state,
                resetting_pwd: false,
                pwd_verification_message: payload.msg, // payload has message to display
            };

        case LOADING_PROFILE_PIC:
            return {
                ...state,
                uploading_profile_pic: true,
            };

        case DONE_LOADING_PROFILE_PIC:
            return {
                ...state,
                uploading_profile_pic: false,
            };

        case UPDATE_PROFILE_PIC:
            return {
                ...state,
                uploading_profile_pic: false,
                user: { ...state.user, avatar: payload }, // payload is user avatar
            };

        case UPDATE_PROFILE:
            return {
                ...state,
                user: { ...state.user, profile: payload }, // payload is user profile
            };

        case AUTH_ERROR:
        case LOGOUT:
        case ACCOUNT_DELETED:
            return {
                ...state, // keep what's currently in the state
                token: null,
                isAuthenticated: false,
                isAdmin: false,
                isSuperAdmin: false,
                loading: false,
                user: null,
                // error: payload, @todo why don't I use this here?
            };

        default:
            return state;
    }
};

export default authReducer;
