import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { parseLikeCount } from '../../utils/helpers';
import { likeOrDislikeRegimen } from '../../actions/regimen';

const RateRegimenButtons = ({
    likeOrDislikeRegimen,
    id,
    likes,
    dislikes,
    readOnly,
}) => {
    return !readOnly ? (
        <Fragment>
            <button
                onClick={() => {
                    likeOrDislikeRegimen(id);
                }}
                type='button'
                className='btn btn-light btn-small'
            >
                <i className='fas fa-thumbs-up' />
                <span>
                    {likes.length > 0 && (
                        <span> {parseLikeCount(likes.length)}</span>
                    )}
                </span>
            </button>
            <button
                onClick={() => likeOrDislikeRegimen(id, /* liked = */ false)}
                type='button'
                className='btn btn-light btn-small'
            >
                <i className='fas fa-thumbs-down' />
                <span>
                    {dislikes.length > 0 && (
                        <span> {parseLikeCount(dislikes.length)}</span>
                    )}
                </span>
            </button>
        </Fragment>
    ) : (
        <Fragment>
            <button type='button' className='btn btn-muted btn-small'>
                <i className='fas fa-thumbs-up' />
                <span>
                    <span> {parseLikeCount(likes.length)}</span>
                </span>
            </button>
            <button type='button' className='btn btn-muted btn-small'>
                <i className='fas fa-thumbs-down' />
                <span>
                    <span> {parseLikeCount(dislikes.length)}</span>
                </span>
            </button>
        </Fragment>
    );
};

RateRegimenButtons.propTypes = {
    readOnly: PropTypes.bool.isRequired,
    likeOrDislikeRegimen: PropTypes.func.isRequired,
    id: PropTypes.string.isRequired,
    likes: PropTypes.array.isRequired,
    dislikes: PropTypes.array.isRequired,
};

RateRegimenButtons.defaultProps = {
    readOnly: false,
};

export default connect(null, {
    likeOrDislikeRegimen,
})(RateRegimenButtons);
