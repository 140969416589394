import api from '../utils/api';
import { setAlert } from './alert';
import { checkAppVersion } from '../utils/helpers';
import actionErrorHandler from './helpers/actionErrorHandler';

import {
    ADD_COMPANY,
    GET_COMPANY, // fetch company when it is created or updated
    GET_COMPANIES,
    GET_COMPANIES_AUTOCOMPLETE,
    COMPANY_ERROR,
    DELETE_COMPANY,
    CLEAR_COMPANY,
} from './types';

// Get all public companies
export const getCompanies =
    (autocomplete = false) =>
    async (dispatch) => {
        dispatch({ type: CLEAR_COMPANY }); // clear current one
        try {
            const res = autocomplete
                ? await api.get('/companies/autocomplete')
                : await api.get('/companies');

            dispatch(checkAppVersion(res));

            dispatch({
                type: autocomplete ? GET_COMPANIES_AUTOCOMPLETE : GET_COMPANIES,
                payload: res.data,
            });
        } catch (err) {
            actionErrorHandler(dispatch, err, COMPANY_ERROR);
        }
    };

// Get all public companies for given user
export const getCompanyByURLparam = (urlpram) => async (dispatch) => {
    dispatch({ type: CLEAR_COMPANY }); // clear current one

    try {
        const res = await api.get(`/companies/${urlpram}`);

        dispatch({
            type: GET_COMPANIES,
            payload: res.data,
        });
    } catch (err) {
        actionErrorHandler(dispatch, err, COMPANY_ERROR);
    }
};

// Get company by ID
export const getCompanyById = (companyid, history) => async (dispatch) => {
    try {
        const res = await api.get(`/companies/id/${companyid}`);

        dispatch({
            type: GET_COMPANY,
            payload: res.data,
        });
    } catch (err) {
        history('/dashboard'); // @todo3 check url
        actionErrorHandler(dispatch, err, COMPANY_ERROR);
    }
};

// Delete a company
export const deleteCompany = (id, history) => async (dispatch) => {
    if (window.confirm('Are you sure? This can NOT be undone!')) {
        // @todo change to modal confirmation with overlay
        try {
            const res = await api.delete(`/companies/${id}`);

            dispatch({
                type: DELETE_COMPANY,
                payload: res.data /* the deleted company */,
            });

            if (history) history('/dashboard'); // @todo4 this does not work for some reason
            dispatch(
                setAlert('Company deleted'),
                'success',
                true /* persist on page changes */
            );
        } catch (err) {
            actionErrorHandler(dispatch, err, COMPANY_ERROR);
        }
    }
};

// Create or update company. If id is set, update company, otherwise create it
export const createCompany = (formData, history, id) => async (dispatch) => {
    dispatch({ type: CLEAR_COMPANY }); // clear current one

    try {
        const res =
            id !== undefined // return company
                ? await api.post(`/companies/${id}`, formData)
                : await api.post('/companies', formData); //  @todo make push not post?

        dispatch({
            type: id === undefined ? ADD_COMPANY : GET_COMPANY,
            payload: res.data,
        });

        dispatch(
            setAlert(
                'Company saved',
                'success',
                true /* persist on page changes */
            )
        );

        if (!id) {
            // note: redirecting in an action is different from redirecing in a component where Redirect can be used. that's why history is used here
            history(`/admin/editcompany/${res.data._id}`);
        }
    } catch (err) {
        actionErrorHandler(dispatch, err, COMPANY_ERROR);
    }
};
