// since this is a form,  each input should be a piece of state, so we need useState hook
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Spinner from '../../layout/Spinner';
import FileUpload, { ImgTypeEnum } from '../../layout/FileUpload';
import { getUserPic } from '../../../utils/helpers';

const UploadProfilePic = ({
    // prop types
    auth: { user, loading, uploading_profile_pic },
}) => {
    const [getPhoto, setPhoto] = useState('');

    useEffect(() => {
        if (!loading && user) {
            // add timestamp to force re-render of image
            // in cases where file name stays same but photo is replaced

            let nameToUse =
                user.avatar && user.avatar.length
                    ? `${user.avatar}?${Date.now().toString()}`
                    : ''; // only add timestamp to end of actual paths
            setPhoto(nameToUse);
        }
    }, [loading, user]);
    return loading || !user ? (
        <Spinner />
    ) : (
        <div className='upload-box'>
            <p className='form-header'>Change Profile Picture</p>
            {getPhoto && (
                <img
                    className='round-img'
                    src={getUserPic(getPhoto)}
                    alt={user.username}
                />
            )}
            <small className='form-text mb'>A square photo works best.</small>
            {uploading_profile_pic ? (
                <Spinner />
            ) : (
                <FileUpload
                    formID='profilepic'
                    uploadType={ImgTypeEnum.PROFILE}
                />
            )}
        </div>
    );
};

UploadProfilePic.propTypes = {
    auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    auth: state.auth,
});

export default connect(mapStateToProps)(UploadProfilePic);
