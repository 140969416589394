import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import DayJS from 'react-dayjs';
import { connect } from 'react-redux';
import { deleteRegimen } from '../../actions/regimen';
import Spinner from '../layout/Spinner';
import { getUserPic, getRegimenPic } from '../../utils/helpers';
import { switchStyleType } from './helpers/helpers';

const RegimenTile = ({
    auth,
    regimen_loading,
    // user's avatar and username come from the regimen api which populates that info in the regimen obj
    regimen: {
        _id,
        user /* obj populated from regimen API call (has _id, avatar, name) */,
        name,
        styletype,
        mainphoto,
        created,
    },
}) => {
    return (
        <div className='regimen-tile'>
            {auth.loading || regimen_loading ? (
                <Spinner />
            ) : (
                <Fragment>
                    <div className='header'>
                        <Link to={`/regimen/${_id}`}>
                            <img
                                className='regimen-tile-img'
                                src={getRegimenPic(mainphoto)}
                                alt=''
                            />
                        </Link>
                    </div>
                    <div className='regimen-tile-bottom'>
                        <h3>
                            <Link to={`/regimen/${_id}`}>{name}</Link>
                        </h3>

                        <span className='small regimen-creator mbq mtq'>
                            <img src={getUserPic(user.avatar)} alt='' />
                            <Link to={`/profile/${user.username_lower}`}>
                                {user.username}
                            </Link>
                            <span>
                                {''}
                                on <DayJS format='MM/DD/YYYY'>{created}</DayJS>
                            </span>
                        </span>
                        <p className='allcaps small mb'>
                            {switchStyleType(styletype)}
                        </p>

                        <div>
                            {!auth.loading &&
                                auth.isAuthenticated &&
                                (user._id === auth.user._id ||
                                    auth.isSuperAdmin) && (
                                    <Link
                                        to={`/editregimen/${_id}`}
                                        className='btn btn-secondary-light'
                                    >
                                        <i className='fas fa-edit text-secondary' />{' '}
                                        Edit
                                    </Link>
                                )}
                        </div>
                    </div>
                </Fragment>
            )}
        </div>
    );
};

RegimenTile.propTypes = {
    regimen: PropTypes.object.isRequired, // passed in to prop
    regimen_loading: PropTypes.bool.isRequired,
    auth: PropTypes.object.isRequired,
    deleteRegimen: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
    auth: state.auth,
    regimen_loading: state.regimen.loading,
});

export default connect(mapStateToProps, {
    deleteRegimen,
})(RegimenTile);
