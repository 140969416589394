import React, { useEffect, useState, Fragment } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import Spinner from '../layout/Spinner';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import { APP_NAME } from '../../utils/constants';
import DayJS from 'react-dayjs';
import Markdown from 'markdown-to-jsx';
import Rating from '../layout/Rating';
import SourceItem from '../layout/SourceItem';
import CommentForm from './CommentForm'; // @todo7 add pagination for comments
import Autocomplete from '../layout/Autocomplete';
import { setAlert } from '../../actions/alert';
import { getIngredients } from '../../actions/ingredient';
import {
    getProductByURLparam,
    getProductComments,
    deleteComment,
    addRating,
    getUserRatingForProduct,
    favorite,
    wishlist,
    updateProductIngredients,
} from '../../actions/product';
import { getCurrentProfile } from '../../actions/profile';
import Tag from '../layout/Tag';
import PropertyTags from '../ingredients/PropertyTags';
import { getProductPic, getUserPic } from '../../utils/helpers';
const helpers = require('./helpers/helpers.js');
const inghelpers = require('../ingredients/helpers/helpers');
const profilehelpers = require('../profile/helpers/helpers');

function Product({
    product: {
        product,
        loading_product,
        product_comments,
        loading_product_comments,
        product_rating_for_current_user,
    },
    getProductByURLparam,
    getProductComments,
    deleteComment,
    getUserRatingForProduct,
    updateProductIngredients,
    favorite,
    wishlist,
    addRating,
    auth,
    getCurrentProfile,
    profile: { profile, loading_profile },
    setAlert,
    getIngredients,
    ingredient: { ingredients_autocomplete, loading_autocomplete }, // state
}) {
    let params = useParams();
    let history = useNavigate();

    useEffect(() => {
        getProductByURLparam(params.id, history);
    }, [getProductByURLparam, params.id, history]);

    useEffect(() => {
        getIngredients(true /* get autocomplete data */);
    }, [getIngredients]);

    useEffect(() => {
        if (!loading_product && product) {
            if (!product.product_comments || !product.product_comments.length)
                getProductComments(product._id); // check this to avoid updating state twice
            if (
                auth.isAuthenticated &&
                !product.product_rating_for_current_user // check this to avoid updating state twice
            ) {
                getUserRatingForProduct(product._id);
            }
        }
    }, [
        loading_product,
        product,
        getProductComments,
        auth.isAuthenticated,
        getUserRatingForProduct,
    ]);

    useEffect(() => {
        if (auth.isAuthenticated) {
            getCurrentProfile();
        }
    }, [auth.isAuthenticated, getCurrentProfile]);

    useEffect(() => {
        window.scrollTo(0, 0); // scroll to top of form on mount
    }, []);

    const [showCommentForm, toggleCommentForm] = useState(false);
    const [inlineEdit, toggleInlineEdit] = useState({
        visible: false,
        version: null,
        row: null,
        ingredients: [],
    });

    const inlineEditCancel = () => {
        toggleInlineEdit({
            visible: false,
            version: null,
            row: null,
            ingredients: null,
        });
    };

    const inlineEditOpen = (eidx, idx, currentIngredients) => {
        toggleInlineEdit({
            visible: true,
            version: eidx,
            row: idx,
            ingredients: currentIngredients,
        });
    };

    // user updated selected ingredient tags; update state for a potential save later
    const updateIngTags = (e) => {
        const parsed = JSON.parse(e.target.value);
        toggleInlineEdit({ ...inlineEdit, ingredients: parsed });
    };

    const submitIng = (entryidx, ingredientidx) => {
        // must be true (visible in UI) and have changed ingredients to submit
        if (!inlineEdit.visible || !inlineEdit.ingredients) return;

        let ingsToUpdate = inlineEdit.ingredients;

        let ingredientids = {
            ingredientids: ingsToUpdate,
        };
        updateProductIngredients(
            ingredientids,
            product._id,
            entryidx,
            ingredientidx
        );

        toggleInlineEdit({
            visible: false,
            version: null,
            row: null,
            ingredients: null,
        });
    };

    const favoriteClick = async (productid) => {
        if (auth.isAuthenticated) {
            if (profile) favorite(productid);
            else
                setAlert(
                    'Create your profile in order to save favorites.',
                    'primary',
                    true /* persist on page changes */
                );
        } else
            setAlert(
                'Sign in or register in order to save favorites.',
                'primary',
                true /* persist on page changes */
            );
    };

    const wishlistClick = async (productid) => {
        if (auth.isAuthenticated) {
            if (profile) wishlist(productid);
            else
                setAlert(
                    'Create your profile in order to save products to your wishlist.',
                    'primary',
                    true /* persist on page changes */
                );
        } else
            setAlert(
                'Sign in or register in order to save products to your wishlist.',
                'primary',
                true /* persist on page changes */
            );
    };

    return (
        <div className='containermiddle'>
            <Helmet>
                <title>
                    {APP_NAME} | Product{product ? ': ' + product.name : ''}
                </title>
            </Helmet>
            {loading_product || (auth.user && loading_profile) || !product ? (
                <Spinner />
            ) : (
                <Fragment>
                    <div className='header-with-button'>
                        <h1 className='mb'>Product Details</h1>
                        <div>
                            {auth.isAuthenticated && auth.isAdmin ? (
                                <Fragment>
                                    <Link
                                        to={`/admin/editcompany/${product.company._id}`}
                                        className='btn btn-light'
                                    >
                                        <i className='fas fa-edit text-primary' />{' '}
                                        Edit Company
                                    </Link>
                                    <Link
                                        to={`/admin/editproduct/${product._id}`}
                                        className='btn btn-primary mr-0'
                                    >
                                        <i className='fas fa-edit text-white' />{' '}
                                        Edit Product
                                    </Link>
                                </Fragment>
                            ) : (
                                ''
                            )}
                        </div>
                    </div>
                    <div className='product-page-header'>
                        <div>
                            <img
                                className='product-img'
                                src={getProductPic(product.mainphoto)}
                                alt={product.name}
                            />
                        </div>

                        <div>
                            <h4 className='mb'>
                                <Link
                                    to={`/products?company=${product.company._id}`}
                                >
                                    {product.company.name}
                                </Link>
                            </h4>
                            <h2 className='mb'>
                                {product.name}{' '}
                                <button
                                    type='button'
                                    className='btn-icon favorite'
                                    title='Favorites'
                                    onClick={() => favoriteClick(product._id)}
                                >
                                    {auth.isAuthenticated &&
                                    profile &&
                                    helpers.isUserFavorite(
                                        profile.favproducts,
                                        product._id
                                    ) ? (
                                        <i className='fas fa-heart' />
                                    ) : (
                                        <i className='far fa-heart' />
                                    )}
                                </button>{' '}
                                <button
                                    type='button'
                                    className='btn-icon wishlist'
                                    title='Wishlist'
                                    onClick={() => wishlistClick(product._id)}
                                >
                                    {auth.isAuthenticated &&
                                    profile &&
                                    helpers.isWishlisted(
                                        auth.user.productwishlist,
                                        product._id
                                    ) ? (
                                        <i className='fas fa-star' />
                                    ) : (
                                        <i className='far fa-star' />
                                    )}
                                </button>
                            </h2>

                            {auth.isAuthenticated &&
                                auth.isAdmin &&
                                product.needsreview && (
                                    <p>
                                        <span className='small allcaps rating-poor'>
                                            <i className='fas fa-exclamation-circle' />{' '}
                                            Needs Review
                                        </span>
                                    </p>
                                )}
                            {auth.isAuthenticated &&
                                auth.isAdmin &&
                                product.isdraft && (
                                    <p>
                                        <span className='small allcaps rating-poor'>
                                            <i className='fas fa-exclamation-circle' />{' '}
                                            Draft
                                        </span>
                                    </p>
                                )}

                            {product.line !== null && (
                                <h4 className='mb'>
                                    Collection:{' '}
                                    <Link
                                        to={`/products?company=${product.company._id}&collection=${product.line}`}
                                    >
                                        {helpers.getProductCollectionName(
                                            product.line,
                                            product.company
                                        )}
                                    </Link>
                                </h4>
                            )}

                            <p className='small allcaps mb-0'>
                                <Rating
                                    currentRating={helpers.getAvgRating(
                                        product.ratetotal,
                                        product.ratecount
                                    )}
                                    onClickHandler={(e, rating) =>
                                        addRating(product._id, rating)
                                    }
                                    cssID='proddetails'
                                    readonly={!auth.isAuthenticated}
                                />{' '}
                                {helpers
                                    .getAvgRating(
                                        product.ratetotal,
                                        product.ratecount
                                    )
                                    .toFixed(1)}{' '}
                                {product_rating_for_current_user ? (
                                    <span>
                                        (Your rating:{' '}
                                        {product_rating_for_current_user})
                                    </span>
                                ) : (
                                    ''
                                )}{' '}
                            </p>

                            <div className='mb'>
                                {product.categories &&
                                    product.categories.map((category, idx) => (
                                        <Tag
                                            key={'cat-' + idx}
                                            name={category}
                                            // slug={
                                            //     '/category/' +
                                            //     category.urlparam
                                            // }
                                        />
                                    ))}
                            </div>

                            <div className='mb'>
                                {product.shelflife !== null &&
                                    product.shelflife.length > 0 && (
                                        <p className='small allcaps'>
                                            Shelflife: {product.shelflife}
                                        </p>
                                    )}
                            </div>
                            <p className='small allcaps'>
                                Consistency: {product.consistency}
                                {product.scentstrength &&
                                    ' | Scent Strength: ' +
                                        product.scentstrength}
                                {product.smellslike &&
                                    product.smellslike.length > 0 &&
                                    product.smellslike[0].length > 0 &&
                                    ' | Smells like: ' + product.smellslike}
                                {product.isforbabies && ' | Safe for babies'}
                                {product.isforkids && ' | Safe for kids'}
                                {product.isformen && ' | Made for men'}
                                {product.isdiscontinued && ' | Discontinued'}
                                {product.iscrueltyfree && ' | Cruelty-Free'}
                                {product.isfordandruff &&
                                    ' | For Dandruff Control'}
                            </p>

                            <p className='small allcaps'>
                                Available Sizes:{' '}
                                {product.availablesizes &&
                                product.availablesizes.length > 0
                                    ? product.availablesizes.map(
                                          (size, idx) =>
                                              size +
                                              '' +
                                              (idx + 1 <
                                              product.availablesizes.length
                                                  ? ' | '
                                                  : '')
                                      )
                                    : 'not provided'}
                            </p>
                            <hr />
                            <h3 className='mb'>Description + Claims</h3>
                            <Markdown>{product.description}</Markdown>

                            <hr className='my-1' />
                            <h3 className='mb'>Purchase</h3>
                            <div className='productpurchaselinks'>
                                {product.website && (
                                    <div>
                                        <a
                                            href={product.website}
                                            target='_blank'
                                            rel='noopener noreferrer'
                                        >
                                            <i className='fas fa-globe fa-2x' />
                                            Product website
                                        </a>
                                    </div>
                                )}
                                {product.amazon && (
                                    <div>
                                        <a
                                            href={product.amazon}
                                            target='_blank'
                                            rel='noopener noreferrer'
                                        >
                                            <i className='fab fa-amazon fa-2x' />{' '}
                                            <div>
                                                <span>Amazon</span>
                                                <span className='allcaps small'>
                                                    (paid affiliate link)
                                                </span>
                                            </div>
                                        </a>{' '}
                                    </div>
                                )}

                                {product.cvs && (
                                    <div>
                                        <a
                                            href={product.cvs}
                                            target='_blank'
                                            rel='noopener noreferrer'
                                        >
                                            CVS
                                        </a>
                                    </div>
                                )}
                                {product.sallys && (
                                    <div>
                                        <a
                                            href={product.sallys}
                                            target='_blank'
                                            rel='noopener noreferrer'
                                        >
                                            Sally's
                                        </a>
                                    </div>
                                )}
                                {product.sephora && (
                                    <div>
                                        <a
                                            href={product.sephora}
                                            target='_blank'
                                            rel='noopener noreferrer'
                                        >
                                            Sephora
                                        </a>
                                    </div>
                                )}
                                {product.target && (
                                    <div>
                                        <a
                                            href={product.target}
                                            target='_blank'
                                            rel='noopener noreferrer'
                                        >
                                            Target
                                        </a>
                                    </div>
                                )}
                                {product.ulta && (
                                    <div>
                                        <a
                                            href={product.ulta}
                                            target='_blank'
                                            rel='noopener noreferrer'
                                        >
                                            Ulta
                                        </a>
                                    </div>
                                )}
                                {product.walgreens && (
                                    <div>
                                        <a
                                            href={product.walgreens}
                                            target='_blank'
                                            rel='noopener noreferrer'
                                        >
                                            Walgreens
                                        </a>
                                    </div>
                                )}
                                {product.walmart && (
                                    <div>
                                        <a
                                            href={product.walmart}
                                            target='_blank'
                                            rel='noopener noreferrer'
                                        >
                                            Walmart
                                        </a>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>

                    <h3 className='mb'>Directions</h3>
                    <div className='directions-list'>
                        <Markdown>{product.directions}</Markdown>
                    </div>
                    {product.notes && (
                        <Fragment>
                            <h3 className='mb mt-1'>{APP_NAME} Notes</h3>
                            <div className='product-notes mb'>
                                <Markdown>{product.notes}</Markdown>
                                {product.sources &&
                                    product.sources.length > 0 &&
                                    product.sources[0].length > 0 && (
                                        <Fragment>
                                            {product.notes && (
                                                <hr className='my-1' />
                                            )}
                                            <h4 className='mb'>Sources</h4>
                                            {product.sources.map(
                                                (source, idx) => (
                                                    <p
                                                        className='ing-source'
                                                        key={'sourcep-' + idx}
                                                    >
                                                        <SourceItem
                                                            src={source}
                                                            srckey={
                                                                'source-' + idx
                                                            }
                                                        />
                                                    </p>
                                                )
                                            )}
                                        </Fragment>
                                    )}
                            </div>
                        </Fragment>
                    )}

                    <hr className='my-1' />
                    <h3 className='mb'>Product Versions</h3>

                    {(product.ingredients &&
                        product.ingredients.map((ingredientEntry, eidx) => (
                            <Fragment key={eidx}>
                                <h4 className='mb'>
                                    Version {eidx + 1}
                                    {product.ingredients.length === 1 && (
                                        <span> (the only known version)</span>
                                    )}
                                </h4>

                                <Fragment key={'entry-' + eidx}>
                                    <p className='small allcaps'>
                                        {ingredientEntry.list.length} Ingredient
                                        {ingredientEntry.list.length === 1
                                            ? ' | '
                                            : 's | '}
                                        Released:{' '}
                                        {ingredientEntry.approxreleaseyear
                                            ? ingredientEntry.approxreleaseyear
                                            : 'unknown'}{' '}
                                    </p>
                                    <p>
                                        {ingredientEntry.hasprotein !== null &&
                                        !ingredientEntry.isfragrancefree ? (
                                            <span className='item-tag tag-product-bool'>
                                                <i className='fas fa-check' />{' '}
                                                Contains Fragrance
                                            </span>
                                        ) : (
                                            <span className='item-tag tag-product-bool'>
                                                <i className='fas fa-xmark' />{' '}
                                                Fragrance-Free
                                            </span>
                                        )}{' '}
                                        {ingredientEntry.hasprotein !== null &&
                                        ingredientEntry.hasprotein ? (
                                            <span className='item-tag tag-product-bool'>
                                                <i className='fas fa-check' />{' '}
                                                Contains Protein or Amino Acids
                                            </span>
                                        ) : (
                                            <span className='item-tag tag-product-bool'>
                                                <i className='fas fa-xmark' />{' '}
                                                No Protein or Amino Acids
                                            </span>
                                        )}{' '}
                                        {ingredientEntry.hassilicones !==
                                            null &&
                                        ingredientEntry.hassilicones ? (
                                            <span className='item-tag tag-product-bool'>
                                                <i className='fas fa-check' />{' '}
                                                Contains Silicones
                                            </span>
                                        ) : (
                                            <span className='item-tag tag-product-bool'>
                                                <i className='fas fa-xmark' />{' '}
                                                No Silicones
                                            </span>
                                        )}{' '}
                                        {ingredientEntry.hasparabens !== null &&
                                        ingredientEntry.hasparabens ? (
                                            <span className='item-tag tag-product-bool'>
                                                <i className='fas fa-check' />{' '}
                                                Contains Parabens
                                            </span>
                                        ) : (
                                            <span className='item-tag tag-product-bool'>
                                                <i className='fas fa-xmark' />{' '}
                                                No Parabens
                                            </span>
                                        )}{' '}
                                        {ingredientEntry.hassulfates !== null &&
                                        ingredientEntry.hassulfates ? (
                                            <span className='item-tag tag-product-bool'>
                                                <i className='fas fa-check' />{' '}
                                                Contains Sulfates
                                            </span>
                                        ) : (
                                            <span className='item-tag tag-product-bool'>
                                                <i className='fas fa-xmark' />{' '}
                                                No Sulfates
                                            </span>
                                        )}{' '}
                                        {ingredientEntry.hasoils !== null &&
                                        ingredientEntry.hasoils ? (
                                            <span className='item-tag tag-product-bool'>
                                                <i className='fas fa-check' />{' '}
                                                Contains Oils
                                            </span>
                                        ) : (
                                            <span className='item-tag tag-product-bool'>
                                                <i className='fas fa-xmark' />{' '}
                                                No Oils
                                            </span>
                                        )}
                                    </p>

                                    {ingredientEntry.list.length > 0 ? (
                                        <div className='ingredient-list-items-wrapper'>
                                            <div className='ingredient-list-header-cell'>
                                                Listed As
                                            </div>
                                            <div className='ingredient-list-header-cell'>
                                                Ingredient
                                            </div>
                                            <div className='ingredient-list-header-cell'>
                                                Rating
                                            </div>
                                            <div className='ingredient-list-header-cell'>
                                                Properties
                                            </div>

                                            {ingredientEntry.list.map(
                                                (ing, idx) => (
                                                    <Fragment
                                                        key={'ing-' + idx}
                                                    >
                                                        <div>
                                                            <span
                                                                className={
                                                                    'block' +
                                                                    (ing.isactive
                                                                        ? ' strong'
                                                                        : '')
                                                                }
                                                            >
                                                                {auth.isAuthenticated &&
                                                                    auth.isAdmin && (
                                                                        <Fragment>
                                                                            {inlineEdit.row ===
                                                                                idx &&
                                                                                inlineEdit.version ===
                                                                                    eidx && (
                                                                                    <Fragment>
                                                                                        <button
                                                                                            onClick={() =>
                                                                                                inlineEditCancel()
                                                                                            }
                                                                                            type='button'
                                                                                            className='btn btn-danger fr mr-0'
                                                                                        >
                                                                                            <i
                                                                                                className={
                                                                                                    'fas fa-times'
                                                                                                }
                                                                                            />
                                                                                        </button>
                                                                                        &nbsp;
                                                                                        <button
                                                                                            onClick={(
                                                                                                e
                                                                                            ) => {
                                                                                                e.preventDefault();
                                                                                                submitIng(
                                                                                                    eidx,
                                                                                                    idx
                                                                                                );
                                                                                            }}
                                                                                            type='button'
                                                                                            className='btn btn-primary fr mr-0'
                                                                                        >
                                                                                            <i
                                                                                                className={
                                                                                                    'far fa-save'
                                                                                                }
                                                                                            />
                                                                                        </button>
                                                                                    </Fragment>
                                                                                )}
                                                                            {inlineEdit.row ===
                                                                                null && (
                                                                                <button
                                                                                    onClick={() =>
                                                                                        inlineEditOpen(
                                                                                            eidx,
                                                                                            idx,
                                                                                            ing.ingredient
                                                                                        )
                                                                                    }
                                                                                    type='button'
                                                                                    className='btn btn-dark fr mr-0'
                                                                                >
                                                                                    <i
                                                                                        className={
                                                                                            'fas fa-edit'
                                                                                        }
                                                                                    />
                                                                                </button>
                                                                            )}
                                                                        </Fragment>
                                                                    )}
                                                                {ing.listedas +
                                                                    (ing.isactive
                                                                        ? '*'
                                                                        : '')}
                                                            </span>
                                                            {ing.notes &&
                                                                ing.notes
                                                                    .split('\n')
                                                                    .map(
                                                                        (
                                                                            note,
                                                                            idx
                                                                        ) => (
                                                                            <span
                                                                                className='italicsmsg small p-0'
                                                                                key={
                                                                                    idx
                                                                                }
                                                                            >
                                                                                {
                                                                                    note
                                                                                }
                                                                            </span>
                                                                        )
                                                                    )}
                                                        </div>
                                                        <div>
                                                            {auth.isAuthenticated &&
                                                            auth.isAdmin &&
                                                            inlineEdit.visible &&
                                                            inlineEdit.row ===
                                                                idx &&
                                                            inlineEdit.version ===
                                                                eidx ? (
                                                                <Fragment>
                                                                    {!loading_autocomplete && (
                                                                        <span className='form mb-1'>
                                                                            <Autocomplete
                                                                                cssID={
                                                                                    'updateing-' +
                                                                                    eidx +
                                                                                    '-' +
                                                                                    idx
                                                                                }
                                                                                objectsName='ingredients'
                                                                                objectsData={
                                                                                    ing.ingredient
                                                                                }
                                                                                onChangeHandler={(
                                                                                    e
                                                                                ) =>
                                                                                    updateIngTags(
                                                                                        e
                                                                                    )
                                                                                }
                                                                                suggestions={
                                                                                    ingredients_autocomplete
                                                                                }
                                                                                selectOneMax={
                                                                                    false
                                                                                }
                                                                            />
                                                                        </span>
                                                                    )}
                                                                </Fragment>
                                                            ) : ing.ingredient
                                                                  .length >
                                                              0 ? (
                                                                ing.ingredient.map(
                                                                    (
                                                                        ingredient,
                                                                        idx
                                                                    ) => (
                                                                        <Fragment
                                                                            key={
                                                                                'ing-' +
                                                                                eidx +
                                                                                '-' +
                                                                                idx
                                                                            }
                                                                        >
                                                                            {idx >
                                                                                0 && (
                                                                                <hr className='mb-0' />
                                                                            )}
                                                                            <Tag
                                                                                name={
                                                                                    ingredient.name
                                                                                }
                                                                                slug={
                                                                                    '/ingredient/' +
                                                                                    ingredient.urlparam
                                                                                }
                                                                            />
                                                                        </Fragment>
                                                                    )
                                                                )
                                                            ) : (
                                                                <span className='allcaps small'>
                                                                    Coming soon
                                                                </span>
                                                            )}

                                                            {ing.oringredient
                                                                .length > 0 && (
                                                                <Fragment>
                                                                    <p className='allcaps small mt mb-0'>
                                                                        Another
                                                                        possibility:
                                                                    </p>

                                                                    {ing.oringredient.map(
                                                                        (
                                                                            ingredient,
                                                                            idx
                                                                        ) => (
                                                                            <Fragment
                                                                                key={
                                                                                    'ing-' +
                                                                                    eidx +
                                                                                    '-' +
                                                                                    idx
                                                                                }
                                                                            >
                                                                                {idx >
                                                                                    0 && (
                                                                                    <hr className='mb-0' />
                                                                                )}
                                                                                <Tag
                                                                                    name={
                                                                                        ingredient.name
                                                                                    }
                                                                                    slug={
                                                                                        '/ingredient/' +
                                                                                        ingredient.urlparam
                                                                                    }
                                                                                />
                                                                            </Fragment>
                                                                        )
                                                                    )}
                                                                </Fragment>
                                                            )}
                                                        </div>
                                                        <div>
                                                            {ing.ingredient
                                                                .length > 0 &&
                                                                ing.ingredient.map(
                                                                    (
                                                                        ingredient,
                                                                        idx
                                                                    ) => (
                                                                        <Fragment
                                                                            key={
                                                                                'rating-' +
                                                                                ingredient.name
                                                                            }
                                                                        >
                                                                            {idx >
                                                                                0 && (
                                                                                <hr className='mb-0' />
                                                                            )}
                                                                            {ingredient.rating ? (
                                                                                <span
                                                                                    className={
                                                                                        'block rating' +
                                                                                        inghelpers.getRatingClass(
                                                                                            ingredient.rating
                                                                                        )
                                                                                    }
                                                                                >
                                                                                    {
                                                                                        ingredient.rating
                                                                                    }
                                                                                </span>
                                                                            ) : (
                                                                                <span className='block rating'>
                                                                                    Not
                                                                                    yet
                                                                                    rated
                                                                                </span>
                                                                            )}
                                                                        </Fragment>
                                                                    )
                                                                )}

                                                            {ing.oringredient
                                                                .length > 0 && (
                                                                <Fragment>
                                                                    <p className='allcaps small mb-0'>
                                                                        &nbsp;
                                                                    </p>

                                                                    {ing.oringredient.map(
                                                                        (
                                                                            ingredient,
                                                                            idx
                                                                        ) => (
                                                                            <Fragment
                                                                                key={
                                                                                    'rating-' +
                                                                                    ingredient.name
                                                                                }
                                                                            >
                                                                                {ing
                                                                                    .ingredient
                                                                                    .length >
                                                                                    1 &&
                                                                                    idx ===
                                                                                        0 && (
                                                                                        <p className='allcaps small mb-0'>
                                                                                            &nbsp;
                                                                                        </p>
                                                                                    )}
                                                                                {idx >
                                                                                    0 && (
                                                                                    <hr className='mb-0' />
                                                                                )}
                                                                                {ingredient.rating ? (
                                                                                    <span
                                                                                        className={
                                                                                            'block rating' +
                                                                                            inghelpers.getRatingClass(
                                                                                                ingredient.rating
                                                                                            )
                                                                                        }
                                                                                    >
                                                                                        {
                                                                                            ingredient.rating
                                                                                        }
                                                                                    </span>
                                                                                ) : (
                                                                                    <span className='block rating'>
                                                                                        Not
                                                                                        yet
                                                                                        rated
                                                                                    </span>
                                                                                )}
                                                                            </Fragment>
                                                                        )
                                                                    )}
                                                                </Fragment>
                                                            )}
                                                        </div>
                                                        <div>
                                                            <div>
                                                                {ing.ingredient
                                                                    .length >
                                                                    0 &&
                                                                    ing.ingredient.map(
                                                                        (
                                                                            ingredient,
                                                                            idx
                                                                        ) => (
                                                                            <Fragment
                                                                                key={
                                                                                    'tags-' +
                                                                                    ingredient.name
                                                                                }
                                                                            >
                                                                                {idx >
                                                                                    0 && (
                                                                                    <hr className='mb-0' />
                                                                                )}
                                                                                <PropertyTags
                                                                                    ingredient={
                                                                                        ingredient
                                                                                    }
                                                                                    max={
                                                                                        3
                                                                                    }
                                                                                />
                                                                            </Fragment>
                                                                        )
                                                                    )}

                                                                {ing
                                                                    .oringredient
                                                                    .length >
                                                                    0 && (
                                                                    <Fragment>
                                                                        <p className='allcaps small mb-0'>
                                                                            &nbsp;
                                                                        </p>

                                                                        {ing.oringredient.map(
                                                                            (
                                                                                ingredient,
                                                                                idx
                                                                            ) => (
                                                                                <Fragment
                                                                                    key={
                                                                                        'tags-' +
                                                                                        ingredient.name
                                                                                    }
                                                                                >
                                                                                    {ing
                                                                                        .ingredient
                                                                                        .length >
                                                                                        1 &&
                                                                                        idx ===
                                                                                            0 && (
                                                                                            <p className='allcaps small mb-0'>
                                                                                                &nbsp;
                                                                                            </p>
                                                                                        )}
                                                                                    {idx >
                                                                                        0 && (
                                                                                        <hr className='mb-0' />
                                                                                    )}
                                                                                    <PropertyTags
                                                                                        ingredient={
                                                                                            ingredient
                                                                                        }
                                                                                        max={
                                                                                            3
                                                                                        }
                                                                                    />
                                                                                </Fragment>
                                                                            )
                                                                        )}
                                                                    </Fragment>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </Fragment>
                                                )
                                            )}
                                            {ingredientEntry.list.filter(
                                                (ing) => ing.isactive
                                            ).length > 0 && (
                                                <div className='full-width'>
                                                    <span className='strong'>
                                                        * Active ingredient
                                                    </span>
                                                </div>
                                            )}
                                        </div>
                                    ) : (
                                        'No ingredients found...please email us!'
                                    )}
                                </Fragment>
                            </Fragment>
                        ))) || <p>'None'</p>}

                    {product.externalreviews.length > 0 &&
                        product.externalreviews[0].length > 0 && (
                            <Fragment>
                                <h3>Influencer Reviews</h3>
                                <ul>
                                    {product.externalreviews.map(
                                        (review, idx) => (
                                            <li key={`review-${idx}`}>
                                                -{' '}
                                                <a
                                                    href={review}
                                                    target='_blank'
                                                    rel='noopener noreferrer'
                                                >
                                                    Review {idx + 1}
                                                </a>
                                            </li>
                                        )
                                    )}
                                </ul>
                            </Fragment>
                        )}

                    <div className='header-with-button'>
                        <h3 className='mb-1'>Reviews</h3>
                        {!auth.loading &&
                            auth.isAuthenticated &&
                            auth.user.profile && (
                                <button
                                    onClick={() =>
                                        toggleCommentForm(!showCommentForm)
                                    }
                                    type='button'
                                    className='btn btn-dark mr-0'
                                >
                                    <i className='fas fa-plus' /> Add Review
                                </button>
                            )}
                    </div>
                    {showCommentForm && (
                        <CommentForm
                            product={product}
                            currentUserRating={product_rating_for_current_user}
                        />
                    )}
                    {loading_product_comments ? (
                        <Spinner />
                    ) : product_comments && product_comments.length > 0 ? (
                        product_comments.map((comment, idx) => (
                            <div
                                key={'comment-' + idx}
                                className='product-comment'
                            >
                                <div>
                                    <Link
                                        to={`/profile/${comment.user.username_lower}`}
                                    >
                                        <img
                                            className='round-img-sml'
                                            src={getUserPic(
                                                comment.user.avatar
                                            )}
                                            alt='reviewer'
                                        />
                                    </Link>
                                </div>
                                <div>
                                    <p className='post-date'>
                                        <Link
                                            to={`/profile/${comment.user.username_lower}`}
                                        >
                                            <span>{comment.user.username}</span>
                                        </Link>{' '}
                                        on{' '}
                                        <DayJS format='MM/DD/YYYY [at] hh:mma'>
                                            {comment.created}
                                        </DayJS>
                                    </p>
                                    {comment.ratingvalue && (
                                        <Rating
                                            currentRating={comment.ratingvalue}
                                            cssID='proddetails'
                                            readonly={true}
                                        />
                                    )}
                                    <p>{comment.text}</p>
                                    <p className='post-hairprops'>
                                        {profilehelpers.parseHairTypes(
                                            comment.user.profile.hairproperties
                                                .hairtypeprimary,
                                            comment.user.profile.hairproperties
                                                .hairtypesecondary
                                        )}{' '}
                                        |{' '}
                                        {profilehelpers.parsePorosity(
                                            comment.user.profile.hairproperties
                                                .porosity
                                        )}{' '}
                                        porosity |{' '}
                                        {profilehelpers.parseScalpHealth(
                                            comment.user.profile.hairproperties
                                                .scalphealth
                                        )}{' '}
                                        scalp |{' '}
                                        {profilehelpers.parseHairColored(
                                            comment.user.profile.hairproperties
                                                .iscolortreated
                                        )}
                                    </p>

                                    {!auth.loading &&
                                        auth.isAuthenticated &&
                                        comment.user._id === auth.user._id && (
                                            <button
                                                onClick={() =>
                                                    deleteComment(
                                                        product._id,
                                                        comment._id
                                                    )
                                                }
                                                type='button'
                                                className='btn btn-danger round'
                                            >
                                                <i className='far fa-trash-alt' />
                                            </button>
                                        )}
                                </div>
                            </div>
                        ))
                    ) : (
                        <p>
                            No reviews yet. Be the first to add one!{' '}
                            {!auth.isAuthenticated && (
                                <Fragment>
                                    <Link to={'/login'}>Sign in</Link>
                                </Fragment>
                            )}
                            {auth.isAuthenticated &&
                                (!auth.user.profile ||
                                    !auth.user.emailverified) && (
                                    <Fragment>
                                        (In order to add comments, make sure
                                        you've{' '}
                                        <Link to={'/createprofile'}>
                                            created a profile
                                        </Link>{' '}
                                        and{' '}
                                        <Link to={'/dashboard/updateinfo'}>
                                            verified your email address
                                        </Link>
                                        .)
                                    </Fragment>
                                )}
                        </p>
                    )}
                </Fragment>
            )}
        </div>
    );
}

Product.propTypes = {
    product: PropTypes.object.isRequired,
    getProductByURLparam: PropTypes.func.isRequired,
    getProductComments: PropTypes.func.isRequired,
    deleteComment: PropTypes.func.isRequired,
    addRating: PropTypes.func.isRequired,
    getUserRatingForProduct: PropTypes.func.isRequired,
    updateProductIngredients: PropTypes.func.isRequired,
    favorite: PropTypes.func.isRequired,
    wishlist: PropTypes.func.isRequired,
    getCurrentProfile: PropTypes.func.isRequired,
    setAlert: PropTypes.func.isRequired,
    ingredient: PropTypes.object.isRequired,
    getIngredients: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
    product: state.product,
    auth: state.auth,
    profile: state.profile,
    ingredient: state.ingredient,
});

export default connect(mapStateToProps, {
    getProductByURLparam,
    getProductComments,
    deleteComment,
    addRating,
    getUserRatingForProduct,
    updateProductIngredients,
    getCurrentProfile,
    favorite,
    wishlist,
    setAlert,
    getIngredients,
})(Product);
