import api from '../utils/api';
import upload_api from '../utils/upload_api';
import { setAlert } from './alert';
import { checkAppVersion } from '../utils/helpers';
import actionErrorHandler from './helpers/actionErrorHandler';

import {
    GET_PROFILE, // fetch profile when it is created or updated
    UPDATE_PROFILE,
    // LOADING_PROFILE,
    UPDATE_JOURNEY_ENTRY,
    GET_PROFILES,
    PROFILE_ERROR,
    CLEAR_PROFILE,
    ACCOUNT_DELETED,
    UPDATE_PROFILE_PIC,
    LOADING_PROFILE_PIC,
    DONE_LOADING_PROFILE_PIC,
    LOADING_JOURNEY_ENTRY_PIC,
    UPDATE_JOURNEY_ENTRY_PIC,
    DONE_LOADING_JOURNEY_ENTRY_PIC,
} from './types';

// Get current user's profile. Dashboard is set up to load user's profile info.
export const getCurrentProfile = (/* no params */) => async (dispatch) => {
    try {
        dispatch({ type: CLEAR_PROFILE }); // clear current one

        const res = await api.get('/profile/me');
        dispatch({
            type: GET_PROFILE,
            payload: res.data,
        });
    } catch (err) {
        actionErrorHandler(dispatch, err, PROFILE_ERROR, false);
    }
};

// Get all profiles
export const getProfiles = (/* no params */) => async (dispatch) => {
    //dispatch({ type: CLEAR_PROFILE }); // clear current one to prevent flashing of current usr profile

    try {
        const res = await api.get('/profile');
        dispatch(checkAppVersion(res));

        dispatch({
            type: GET_PROFILES,
            payload: res.data,
        });
    } catch (err) {
        actionErrorHandler(dispatch, err, PROFILE_ERROR);
    }
};

// Get profile by username
export const getProfileByUsername = (username) => async (dispatch) => {
    try {
        // dispatch({ type: LOADING_PROFILE });
        const res = await api.get(`/profile/user/${username}`);

        dispatch({
            type: GET_PROFILE,
            payload: res.data,
        });
    } catch (err) {
        actionErrorHandler(dispatch, err, PROFILE_ERROR, false);
    }
};

// update profile picture
export const uploadProfilePic = (file) => async (dispatch) => {
    try {
        dispatch({ type: LOADING_PROFILE_PIC });

        const res = await upload_api.post(`/users/uploadavatar`, file);

        dispatch({
            // send to profile
            type: UPDATE_PROFILE_PIC,
            payload: res.data, // avatar
        });

        dispatch(
            setAlert(
                'Profile photo updated',
                'success',
                true /* persist on page changes */
            )
        );
    } catch (err) {
        actionErrorHandler(dispatch, err, DONE_LOADING_PROFILE_PIC);
    }
};

// Create or update profile
export const createProfile =
    (
        formData,
        history, // redirect after updating the entry using push method. updates browser history
        edit = false // know whether editing or updating. create by default if this isn't passed in
    ) =>
    async (dispatch) => {
        try {
            const res = await api.post('/profile', formData); // returns profile

            dispatch({
                // send to profile
                type: UPDATE_PROFILE,
                payload: res.data,
            });

            // redirect only if creating profile (edit can stay on same page)
            if (!edit) {
                // note: redirecting in an action is different from redirecing in a component where Redirect can be used. that's why history is used here
                history('/dashboard');
            }

            dispatch(
                setAlert(
                    'Profile saved',
                    'success',
                    true /* persist on page changes */
                )
            );
        } catch (err) {
            actionErrorHandler(dispatch, err, PROFILE_ERROR);
        }
    };

// Create or update journey entry for user profile's journey
export const updateJourneyEntry =
    (formData, entryid, history) => async (dispatch) => {
        try {
            const url = '/profile/journey/editentry/';
            const res = await api.post(
                url + (entryid ? entryid : 'create'),
                formData
            ); // returns profile

            dispatch({
                // send to profile
                type: UPDATE_JOURNEY_ENTRY,
                payload: res.data,
            });

            history('/journey/' + res.data.user.username_lower);

            dispatch(
                setAlert(
                    'Journey entry saved',
                    'success',
                    true /* persist on page changes */
                )
            );
        } catch (err) {
            actionErrorHandler(dispatch, err, PROFILE_ERROR);
        }
    };

// Delete journey entry
export const deleteJourneyEntry = (entryid, history) => async (dispatch) => {
    if (window.confirm('Are you sure? This can NOT be undone!')) {
        // @todo change to modal confirmation with overlay
        try {
            const url = '/profile/journey/deleteentry/';
            const res = await api.post(url + entryid); // returns profile

            dispatch({
                // send to profile
                type: UPDATE_JOURNEY_ENTRY,
                payload: res.data,
            });

            history('/journey/' + res.data.user.username_lower);

            dispatch(
                setAlert(
                    'Journey entry deleted',
                    'success',
                    true /* persist on page changes */
                )
            );
        } catch (err) {
            actionErrorHandler(dispatch, err, PROFILE_ERROR);
        }
    }
};

// update journey entry's main picture
export const uploadJourneyEntryPic = (file, entryID) => async (dispatch) => {
    try {
        dispatch({ type: LOADING_JOURNEY_ENTRY_PIC });

        const res = await upload_api.post(
            `/profile/uploadjourneyentryphoto/${entryID}`,
            file
        );

        dispatch({
            // send info to journey entry edit page
            type: UPDATE_JOURNEY_ENTRY_PIC,
            payload: res.data, // photo path
        });

        dispatch(
            setAlert(
                'Journey entry photo updated',
                'success',
                true /* persist on page changes */
            )
        );
    } catch (err) {
        actionErrorHandler(dispatch, err, PROFILE_ERROR);

        dispatch({ type: DONE_LOADING_JOURNEY_ENTRY_PIC }); // to stop spinner @todo add this for other photo upload errors too
    }
};

// Delete account & profile
export const deleteAccount = (formData, history) => async (dispatch) => {
    if (window.confirm('Are you sure? This can NOT be undone!')) {
        // @todo change to modal confirmation with overlay
        try {
            await api.post('/profile/deleteaccount', formData);

            dispatch({ type: CLEAR_PROFILE });
            dispatch({ type: ACCOUNT_DELETED }); // removes user token too

            if (history) history('/');
            dispatch(
                setAlert(
                    'Your account has been permanently deleted',
                    'success',
                    true /* persist on page changes */
                )
            );
        } catch (err) {
            actionErrorHandler(dispatch, err, PROFILE_ERROR);
        }
    }
};
