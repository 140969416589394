import React, { useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { connect } from 'react-redux'; // connects component to redux
import { Helmet } from 'react-helmet-async';
import { APP_NAME } from '../../utils/constants';
import { verifyEmail } from '../../actions/auth';
import Spinner from '../layout/Spinner';
import PropTypes from 'prop-types';

// create function component
const VerifyEmail = ({
    verifyEmail,
    auth: { loading, isAuthenticated, verifying_email, verification_message },
}) => {
    let location = useLocation();

    useEffect(() => {
        if (!loading) {
            let queryparams = location.search;
            const params = new URLSearchParams(queryparams);
            let u = params.get('u');
            let c = params.get('c');
            verifyEmail(u, c);
        }
    }, [loading, location.search, verifyEmail]);

    return (
        <section className='landing'>
            <Helmet>
                <title>{APP_NAME} | Verify Email Address</title>
            </Helmet>
            <div className='landing-overlay'>
                <div className='landing-form'>
                    {verifying_email ? (
                        <Spinner />
                    ) : (
                        <h2 className='catchy mb-0'>{verification_message}</h2>
                    )}

                    {!loading && !isAuthenticated && (
                        <div className='landing-buttons'>
                            <Link
                                to='/login'
                                className='btn btn-primary btn-large mr-0'
                            >
                                Sign In
                            </Link>
                        </div>
                    )}
                </div>
            </div>
        </section>
    );
};

VerifyEmail.propTypes = {
    verifyEmail: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    auth: state.auth,
});

export default connect(mapStateToProps, { verifyEmail })(VerifyEmail);
