import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import Tag from './Tag';

// suggestions prop is an array of objects that have a name and an ID. this works for products but is generalized to anything with the same object structure (name, _id fields). Note: data passed in must have name and id fields for this to work. Otherwise I'll need a prop to specify tag field name to display in UI.
const Autocomplete = ({
    suggestions,
    stringsName,
    objectsName,
    placeholder,
    onChangeHandler,
    allowNotFoundSuggestions, // whether to let user select tags that don't match the suggestions
    showAllOptions,
    stringsData,
    objectsData,
    selectOneMax, // whether only one max can be entered
    cssID,
}) => {
    const initialState = {
        // The active selection's index
        activeSuggestion: 0,
        // The suggestions that match the user's input
        filteredSuggestions: [],
        // Whether or not the suggestion list is shown
        showSuggestions: false,
        // whether to show all suggestions in list by default vs filtering first:
        showAllSuggestions: false,
        // What the user has entered
        userInput: '',
        selectedTagStrings: '', // used to keep track of tags user confirmed. used as value passed up to caller, kept track of with an input that stores the tags and is hidden
        selectedTagObjects: [],
    };

    const [completionData, setCompletionData] = useState(initialState);

    // used to send a change in input data to caller
    const bubbleUpInputChange = useCallback(
        (data, isstringdata = true) => {
            // trigger onChange in hidden input which stores value to save in caller's state
            // to understand below, see https://hustle.bizongo.in/simulate-react-on-change-on-controlled-components-baa336920e04

            var nametype = isstringdata ? stringsName : objectsName;
            var input = document.querySelector(
                '#autocomplete-' + cssID + '-' + nametype
            );
            var nativeInputValueSetter = Object.getOwnPropertyDescriptor(
                window.HTMLInputElement.prototype,
                'value'
            ).set;
            nativeInputValueSetter.call(input, JSON.stringify(data));

            var inputEvent = new Event('input', { bubbles: true });
            input.dispatchEvent(inputEvent);
        },
        [stringsName, objectsName, cssID]
    );

    useEffect(() => {
        if (stringsData) {
            setCompletionData((completionData) => {
                return {
                    ...completionData,
                    selectedTagStrings: stringsData,
                };
            });
        }

        if (objectsData) {
            setCompletionData((completionData) => {
                return {
                    ...completionData,
                    selectedTagObjects: objectsData,
                };
            });
        }
    }, [stringsData, objectsData]);

    const {
        activeSuggestion,
        filteredSuggestions,
        showSuggestions,
        showAllSuggestions,
        userInput,
        selectedTagStrings,
        selectedTagObjects,
    } = completionData;

    const getFilteredSuggestions = (userInput) => {
        // Filter out suggestions: keep the ones that contain the user's input (either in name or synonyms, if synonyms string array exists) and also
        // aren't already tagged
        let filteredSuggestions = [];
        if (suggestions && suggestions.length)
            filteredSuggestions = suggestions.filter((suggestionobj) => {
                let suggestion = suggestionobj.name;
                // obj may have synonyms array or company obj depending on obj type (ing or product)
                let synonyms = suggestionobj.synonyms;
                let companyName = suggestionobj.company
                    ? suggestionobj.company.name
                    : null;

                let hasInputMatches =
                    suggestion.toLowerCase().indexOf(userInput.toLowerCase()) >
                    -1;
                let isNotAlreadySelected = !selectedTagObjects.filter(
                    (obj) => obj.name === suggestion
                ).length;

                let matchesSynonym = synonyms
                    ? synonyms.some((synonym) =>
                          synonym
                              .toLowerCase()
                              .includes(userInput.toLowerCase())
                      )
                    : false;

                let matchesCoName = companyName
                    ? companyName
                          .toLowerCase()
                          .includes(userInput.toLowerCase())
                    : false;
                return (
                    (hasInputMatches || matchesSynonym || matchesCoName) &&
                    isNotAlreadySelected
                );
            });

        return filteredSuggestions;
    };

    // Event fired when the input value is changed
    const onChange = (e) => {
        const userInput = e.currentTarget.value;
        const filteredSuggestions = getFilteredSuggestions(userInput);

        // Update the user input and filtered suggestions, reset the active
        // suggestion and make sure the suggestions are shown
        // @todo7 if user arrows down past visible index, it should scroll

        setCompletionData({
            ...completionData, // to keep current selectedTagStrings and selectedTagObjects
            activeSuggestion: 0,
            filteredSuggestions,
            showSuggestions: true,
            userInput: e.currentTarget.value,
        });
    };

    // Event fired when the user clicks on a suggestion, which means it is an object since it is in
    // suggestions list. Note that we get the index from the li element that was clicked and use
    // that value to get correct selection. The active suggestion is not accurate for mouse selections!
    const onClick = (_e, index) => {
        // Update the user input and reset the rest of the state.
        let selected = filteredSuggestions[index];

        let updatedTags = selectOneMax
            ? [selected] // select just this one
            : [...selectedTagObjects, selected]; // add this one to rest

        // get filtered list in case showAllOptions is true
        let filtered = getFilteredSuggestions('').filter(
            (_sug, idx) => idx !== index
        );

        // see if I need to swap the selected item with the one previously selected
        if (showAllOptions && selectOneMax)
            filtered = [...filtered, ...selectedTagObjects].sort((a, b) =>
                a.name.localeCompare(b.name)
            );

        setCompletionData({
            ...completionData, // impt! need this here so I don't get (un)controlled component err
            activeSuggestion: 0,
            // if showAllOptions is true, then remove selected item from filtered list and show the rest.
            // otherwise, clear suggestions list. note: I explicitly remove the selected item from list
            // here because the selectedTabObjects that is used in the getFilteredSuggestions function
            // won't yet be updated at the time of that function call so this is a hack :/
            filteredSuggestions: showAllOptions ? filtered : [],
            showSuggestions: showAllOptions,
            userInput: '', // clear field
            selectedTagObjects: updatedTags,
        });

        bubbleUpInputChange(updatedTags, false /* isstringdata */);
    };

    // Event fired when the user presses a key down
    const onKeyDown = (e) => {
        // User pressed the enter key AND a match is found (if allowNotFoundSuggestions is true),
        // update the input and close the suggestions
        if (e.keyCode === 13) {
            if (
                // if there is no exact match and user can't select non-matching tags, do nothing
                filteredSuggestions.length === 0 &&
                !allowNotFoundSuggestions
            ) {
                return;
            }

            let updatedTags;
            if (filteredSuggestions.length) {
                // match found so add object
                let tagToSelect = filteredSuggestions[activeSuggestion];

                updatedTags = selectOneMax
                    ? [tagToSelect] // select just this one
                    : [...selectedTagObjects, tagToSelect]; // add this one to rest

                // get filtered list in case showAllOptions is true
                let filtered = getFilteredSuggestions('').filter(
                    (_sug, idx) => idx !== activeSuggestion
                );

                // see if I need to swap the selected item with the one previously selected
                if (showAllOptions && selectOneMax)
                    filtered = [...filtered, ...selectedTagObjects].sort(
                        (a, b) => a.name.localeCompare(b.name)
                    );

                setCompletionData({
                    ...completionData,
                    activeSuggestion: 0,
                    filteredSuggestions: showAllOptions
                        ? filtered
                        : filteredSuggestions,
                    showSuggestions: showAllOptions,
                    userInput: '', // clear field
                    selectedTagObjects: updatedTags,
                });

                // call the passed in change handler
                bubbleUpInputChange(updatedTags, false /* isstringdata */);
            } else {
                // match not found so add string
                let tagToSelect = userInput.trim();
                if (tagToSelect.length === 0) return; // don't add empty strings

                updatedTags = selectOneMax
                    ? [tagToSelect] // select just this one
                    : [...selectedTagStrings, tagToSelect]; // add this one to rest

                setCompletionData({
                    ...completionData,
                    activeSuggestion: 0,
                    showSuggestions: showAllOptions,
                    userInput: '', // clear field
                    selectedTagStrings: updatedTags,
                });

                // call the passed in change handler
                bubbleUpInputChange(updatedTags, true /* isstringdata */);
            }
        }
        // User pressed the up arrow, decrement the index
        else if (e.keyCode === 38) {
            if (activeSuggestion === 0) {
                return;
            }

            setCompletionData({
                ...completionData,
                activeSuggestion: activeSuggestion - 1,
            });
        }
        // User pressed the down arrow, increment the index
        else if (e.keyCode === 40) {
            if (activeSuggestion === filteredSuggestions.length - 1) {
                return;
            }

            setCompletionData({
                ...completionData,
                activeSuggestion: activeSuggestion + 1,
            });
        }
    };

    const deleteStringTag = (tagname) => {
        let updatedTags = selectedTagStrings.filter((tag) => tag !== tagname);

        setCompletionData({
            ...completionData, // keep current state
            selectedTagStrings: updatedTags,
        });

        // call the passed in change handler
        bubbleUpInputChange(updatedTags, true /* isstringdata */);
    };

    const deleteObjectTag = (tagobj) => {
        let updatedTags = selectedTagObjects.filter(
            (tag) => tag.name !== tagobj.name
        );

        setCompletionData({
            ...completionData, // keep current state
            selectedTagObjects: updatedTags,
        });

        // call the passed in change handler
        bubbleUpInputChange(updatedTags, false /* isstringdata */);
    };

    let suggestionsListComponent;

    const blu = () => {
        setCompletionData({
            ...completionData,
            showSuggestions: false,
            showAllSuggestions: false,
        });
    };

    const foc = () => {
        if (showAllOptions) {
            setCompletionData({
                ...completionData,
                // activeSuggestion: 0,
                filteredSuggestions: getFilteredSuggestions(userInput),
                showAllSuggestions: true,
            });
        } else if (userInput) {
            setCompletionData({
                ...completionData,
                showSuggestions: true,
            });
        }
    };

    if (showAllSuggestions || (showSuggestions && userInput)) {
        if (filteredSuggestions.length) {
            suggestionsListComponent = (
                <ul className='suggestions'>
                    {filteredSuggestions.map((suggestion, index) => {
                        let className = '';

                        // Flag the active suggestion with a class
                        if (index === activeSuggestion) {
                            className = 'suggestion-active';
                        }

                        let matchedSynonym;
                        let synonyms = suggestion.synonyms;
                        if (synonyms) {
                            // get first match, if any
                            matchedSynonym = synonyms.find((synonym) =>
                                synonym
                                    .toLowerCase()
                                    .includes(userInput.toLowerCase())
                            );
                        }

                        let matchedCompany;
                        let companyName = suggestion.company
                            ? suggestion.company.name
                            : null;
                        if (companyName)
                            matchedCompany = companyName
                                .toLowerCase()
                                .includes(userInput.toLowerCase());

                        return (
                            <li
                                className={className}
                                key={suggestion._id}
                                onMouseDown={(e) => {
                                    e.preventDefault(); // to not interfere with onBlur of main input field
                                }}
                                onClick={(e) => {
                                    onClick(e, index);
                                }}
                            >
                                <span>{suggestion.name}</span>
                                {matchedSynonym && (
                                    <span className='subtext'>
                                        {matchedSynonym}
                                    </span>
                                )}
                                {matchedCompany && (
                                    <span className='subtext'>
                                        {companyName}
                                    </span>
                                )}
                            </li>
                        );
                    })}
                </ul>
            );
        } else {
            suggestionsListComponent = allowNotFoundSuggestions ? (
                <div className='no-suggestions'>
                    <em>
                        No options available. Press 'enter' to add custom one.
                    </em>
                </div>
            ) : (
                <div className='no-suggestions'>
                    <em>No options available.</em>
                </div>
            );
        }
    }

    // note that the hidden input below as well as the 'offISay!' value for autocomplete is a hack
    // to prevent Chrome from showing autocomplete dropdowns.
    // src: https://medium.com/paul-jaworski/turning-off-autocomplete-in-chrome-ee3ff8ef0908
    return (
        <div className='rel'>
            <input type='hidden' value='something' />
            <input
                type='text'
                autoComplete='offISay!'
                onBlur={blu}
                onFocus={foc}
                onChange={onChange}
                onKeyDown={onKeyDown}
                onKeyPress={(e) => {
                    e.key === 'Enter' && e.preventDefault();
                }}
                value={userInput}
                placeholder={placeholder}
            />
            {stringsData && (
                <input
                    type='text'
                    style={{
                        display: 'none',
                    }}
                    id={'autocomplete-' + cssID + '-' + stringsName}
                    onChange={(e) => {
                        onChangeHandler(e);
                    }}
                    value={selectedTagStrings}
                    name={stringsName}
                />
            )}
            {objectsData && (
                <input
                    type='text'
                    style={{
                        display: 'none',
                    }}
                    id={'autocomplete-' + cssID + '-' + objectsName}
                    onChange={(e) => {
                        onChangeHandler(e);
                    }}
                    value={selectedTagObjects}
                    name={objectsName}
                />
            )}
            {suggestionsListComponent}
            <div className='mb'>
                {selectedTagObjects &&
                    selectedTagObjects.map((tagobj, idx) => {
                        // if this is a product w/company name, add company to front for easy recognition
                        let selectedTitle = tagobj.company
                            ? tagobj.company.name + ' ' + tagobj.name
                            : tagobj.name;

                        return (
                            <Tag
                                name={selectedTitle}
                                deleteHandler={() => deleteObjectTag(tagobj)}
                                key={selectedTitle + '-obj-' + idx}
                            />
                        );
                    })}
                {selectedTagStrings &&
                    selectedTagStrings.map((tag, idx) => (
                        <Tag
                            name={tag}
                            deleteHandler={() => deleteStringTag(tag)}
                            key={tag + '-string-' + idx}
                        />
                    ))}
            </div>
        </div>
    );
};

// either stringsName and stringsData or objectsName and objectsData are required
Autocomplete.propTypes = {
    suggestions: PropTypes.array.isRequired,
    stringsName: PropTypes.string, // name identifier of field that stores string array
    objectsName: PropTypes.string, // name identifier of field that stores object array
    placeholder: PropTypes.string, // input ghost text
    onChangeHandler: PropTypes.func.isRequired, // passed in: what to call when change happens
    allowNotFoundSuggestions: PropTypes.bool,
    showAllOptions: PropTypes.bool,
    stringsData: PropTypes.array, // used to reflect changes and store array of data
    objectsData: PropTypes.array,
    selectOneMax: PropTypes.bool,
    cssID: PropTypes.string.isRequired, // used to get unique ID for input
};

Autocomplete.defaultProps = {
    suggestions: [],
    selectedTagStrings: [],
    allowNotFoundSuggestions: false,
    showAllOptions: false,
    selectOneMax: false,
    placeholder: '',
};

export default Autocomplete;
