import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

// Component that supports star rating using FontAwesome icons. @todo12 add rating count
function Rating({
    cssID /* unique ID */,
    currentRating,
    onClickHandler,
    readonly,
}) {
    const initialState = {
        hoverIndex: -1,
        rating: 0, // current value passed in
    };

    const [completionData, setCompletionData] = useState(initialState);
    const { rating, hoverIndex } = completionData;

    function roundToNearestHalf(rating) {
        return (Math.round(rating * 2) / 2).toFixed(1);
    }

    // update static display of current rating when it changes in parent (and is thus changed as prop passed in to this component)
    useEffect(() => {
        setCompletionData((completionData) => {
            return {
                ...completionData,
                rating: roundToNearestHalf(currentRating),
            };
        });
    }, [currentRating]);

    // Event fired when the div is clicked
    const onMouseOver = (_e, idx) => {
        setCompletionData({
            ...completionData,
            hoverIndex: idx,
        });
    };

    const onMouseOut = (_e) => {
        setCompletionData({
            ...completionData,
            hoverIndex: -1,
        });
    };

    return (
        <span
            className='rating-wrapper'
            id={'rating-' + cssID}
            onMouseOut={(e) => onMouseOut(e)}
        >
            {[...Array(5)].map((e, index) => {
                let classAddon = '';
                // hover style
                if (!readonly && index <= hoverIndex)
                    classAddon += '  fa-star fa-1x rating-hover';
                // filled style for current rating
                else if (rating > 0 && rating - index > 0) {
                    // see if this star should be full or half full
                    classAddon +=
                        rating - index === 0.5
                            ? ' fa-star-half-alt fa-1x rating-filled'
                            : ' fa-star fa-1x rating-filled';
                }
                // regular empty star
                else classAddon += '  fa-star-o fa-1x';

                return (
                    <i
                        key={'star-' + index}
                        className={'fa' + classAddon}
                        onMouseOver={(e) => !readonly && onMouseOver(e, index)}
                        onClick={(e) =>
                            !readonly && onClickHandler(e, index + 1)
                        }
                    />
                );
            })}
        </span>
    );
}

Rating.propTypes = {
    cssID: PropTypes.string.isRequired /* unique ID */,
    currentRating: PropTypes.number.isRequired,
    onClickHandler: PropTypes.func, // not needed for permanently read-only situations
    readonly: PropTypes.bool,
};

Rating.defaultProps = {
    readonly: false,
};

export default Rating;
