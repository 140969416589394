import React, { Fragment, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { logout } from '../../actions/auth';
import { APP_NAME } from '../../utils/constants';
import Logo from '../../img/logo.svg';
import NavbarLink from './NavbarLink';

const Navbar = ({
    auth: { isAuthenticated, isAdmin, loading, user },
    logout,
}) => {
    // useHistory to access history in this component since it isn't a routed component page
    let history = useNavigate();

    // when the state updates, useEffect will keep running in a constant loop, unless
    // you add the [] specified at the end (2nd arg) so that it runs only once when app is mounted
    // This behaves like componentDidMount
    useEffect(() => {
        window.addEventListener('scroll', () => handleScroll2());
        return function cleanup() {
            // behaves like componentWillUnmount
            window.removeEventListener('scroll', handleScroll2());
        };
    }, []);

    // Declare a new state variable, which we'll call "showScrollShadow" @todo50 fix update state in useeffect bug
    const [showScrollShadow, setshowScrollShadow] = useState(false);

    const handleScroll2 = () => {
        setshowScrollShadow(window.pageYOffset > 0); // 60 is header height
    };

    const authLinks = (
        <Fragment>
            <li>|</li>
            <li>
                <NavbarLink
                    path='/dashboard'
                    relatedpaths={[
                        '/dashboard',
                        '/editprofile',
                        '/editjourneyentry',
                        '/addjourneyentry',
                        '/updateinfo',
                    ]}
                >
                    <i className='fas fa-user' />{' '}
                    <span className='hide-on-mobile'>Dashboard</span>
                </NavbarLink>
            </li>

            {isAdmin ? (
                <li>
                    <NavbarLink
                        path='/admin'
                        relatedpaths={[
                            '/admin/viewmembers',
                            '/admin/viewcompanies',
                            '/admin/editcompany',
                            '/admin/addcompany',
                        ]}
                    >
                        <i className='fas fa-tools' />{' '}
                        <span className='hide-on-mobile'>Admin</span>
                    </NavbarLink>
                </li>
            ) : (
                ''
            )}

            <li>|</li>

            <li>
                <a onClick={() => logout(user, history)} href='#!'>
                    <i className='fas fa-sign-out-alt' />{' '}
                    <span className='hide-on-mobile'>Sign Out</span>
                </a>
            </li>
        </Fragment>
    );

    const guestLinks = (
        <Fragment>
            <li>|</li>
            <li>
                <NavbarLink path='/register'>Register</NavbarLink>
            </li>
            <li>
                <NavbarLink path='/login'>Sign In</NavbarLink>
            </li>
        </Fragment>
    );

    return (
        <Fragment>
            <header
                className={showScrollShadow ? ' navshadow' : 'emptyclass'} // emptyclass just to fill it in
            >
                <nav className='navbar bg-light'>
                    <h1 className='title'>
                        <Link to='/' className='flex'>
                            <img src={Logo} alt='logo' className='logo-nav' />
                            <span>{APP_NAME}</span>
                            <span className='sm'>&#174;</span>
                        </Link>
                    </h1>

                    <ul>
                        <li>
                            <NavbarLink
                                path='/regimens'
                                relatedpaths={[
                                    '/regimen',
                                    '/editregimen',
                                    '/addregimen',
                                ]}
                            >
                                Regimens
                            </NavbarLink>
                        </li>
                        <li>
                            <NavbarLink
                                path='/products'
                                relatedpaths={[
                                    '/product',
                                    // '/admin/editproduct',
                                    // '/admin/addproduct',
                                ]}
                            >
                                Products
                            </NavbarLink>
                        </li>
                        <li>
                            <NavbarLink
                                path='/ingredients'
                                relatedpaths={['/ingredient']}
                            >
                                Ingredients
                            </NavbarLink>
                        </li>
                        <li>
                            <NavbarLink
                                path='/profiles'
                                relatedpaths={['/profile', '/journey/']}
                            >
                                Members
                            </NavbarLink>
                        </li>

                        {!loading && isAuthenticated ? authLinks : guestLinks}
                    </ul>
                </nav>
            </header>
        </Fragment>
    );
};

Navbar.propTypes = {
    logout: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    auth: state.auth,
});

export default connect(mapStateToProps, { logout })(Navbar);
