// since this is a form,  each input should be a piece of state, so we need useState hook
import React, { Fragment, useState, useEffect } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import { APP_NAME } from '../../../utils/constants';
import DayJS from 'react-dayjs';
import { cloneDeep } from 'lodash';
import AdminFormFooter from '../AdminFormFooter';
import { createProduct, getProductById } from '../../../actions/product';
import { getIngredients } from '../../../actions/ingredient';
import { getCompanies } from '../../../actions/company';
import IngredientEntry from './IngredientEntry';
import Autocomplete from '../../layout/Autocomplete';
import { parseArrayOrNewLineList } from '../../../utils/helpers';
import Spinner from '../../layout/Spinner';
import Multiselect from '../../layout/Multiselect';
import UploadProductPic from './UploadProductPic';
const helpers = require('../../products/helpers/helpers.js');

// set default state values that will appear in form
const initialState = {
    initialized: false, // used to see if product has been loaded into state
    name: '',
    company: '',
    isdraft: false,
    urlparam: '', // @todo7 autogenerate
    description: '',
    directions: '',
    categories: ['Not Specified'],
    line: null,
    pH: '',
    notes: '',
    consistency: 'Not Specified',
    privatenotes: '',
    needsreview: false,
    availablesizes: [],
    sources: [],
    shelflife: '',
    scentstrength: 'Not Specified',
    smellslike: [],
    externalreviews: [],
    priority: 0,
    isdiscontinued: false,
    iscrueltyfree: false,
    isfordandruff: false,
    isforbabies: false,
    isforkids: false,
    isformen: false,
    ingredients: [
        {
            list: [
                {
                    listedas: '',
                    isactive: false,
                    ingredient: [], // ids
                    oringredient: [], // ids
                    notes: '',
                },
            ],
            approxreleaseyear: '',
        },
    ],
    // purchase subfields
    website: '',
    amazon: '',
    walgreens: '',
    cvs: '',
    target: '',
    sallys: '',
    walmart: '',
    ulta: '',
    sephora: '',
};

const ProductForm = ({
    // prop types
    product: { product, loading_product },
    company: { companies_autocomplete, loading_autocomplete },
    createProduct,
    getProductById, // to fill in fields for editing
    getCompanies,
    getIngredients,
}) => {
    let params = useParams();
    let history = useNavigate();

    // clone nested js object for correct clearing/loading of ingredient array of objects.
    // if I don't deep clone here, then previously added ingredients may appear in list when
    // adding a new product. JSON.parse(JSON.stringify(initialState))
    const [formData, setFormData] = useState(cloneDeep(initialState));

    const {
        initialized,
        name,
        company,
        isdraft,
        urlparam,
        description,
        directions,
        categories,
        line,
        pH,
        notes,
        consistency,
        privatenotes,
        needsreview,
        availablesizes,
        sources,
        shelflife,
        scentstrength,
        smellslike,
        isdiscontinued,
        ingredients, // sent as a stringified JSON object
        iscrueltyfree,
        isfordandruff,
        isforbabies,
        isforkids,
        isformen,
        website,
        amazon,
        walgreens,
        cvs,
        target,
        sallys,
        walmart,
        ulta,
        sephora,
        externalreviews,
        priority,
    } = formData;

    const [displayWebLinks, toggleWebLinks] = useState(false); // default: hide section
    const showProductCollectionState = useState(false); // default: hide section
    const toggleProductCollection = showProductCollectionState[1]; // no need for getter so just using setter

    useEffect(() => {
        window.scrollTo(0, 0); // scroll to top of form on mount
    }, []);

    useEffect(() => {
        getIngredients(true /* get autocomplete data */);
    }, [getIngredients]);

    useEffect(() => {
        getCompanies(true /* get autocomplete data */);
    }, [getCompanies]); // @todo8 product not found, when editing a product: redirect

    useEffect(() => {
        if (params.id) {
            getProductById(params.id);
        }
    }, [getProductById, params.id]); // @todo8 product not found, when editing a product: redirect

    useEffect(() => {
        if (params.id && !loading_product && product) {
            // update form fields with current product
            const productData = { ...initialState };

            for (const key in product) {
                if (key in productData) productData[key] = product[key];
            }

            // handle subsections of data model
            for (const key in product.ingredients) {
                if (key in productData.ingredients)
                    productData.ingredients[key] = product.ingredients[key];
            }

            productData.initialized = true;
            setFormData(productData);
        }
    }, [loading_product, product, params.id]);

    // used to parse new lines in text boxes into array
    const updateArrayFromText = (e) => {
        e.stopPropagation();

        // note: if empty lines are found, they are removed in API before saving
        let arraytosend = parseArrayOrNewLineList(e.target.value); // parses text into array

        if (e.target.name === 'availablesizes_box') {
            setFormData({ ...formData, availablesizes: arraytosend });
        } else if (e.target.name === 'externalreviews_box') {
            setFormData({ ...formData, externalreviews: arraytosend });
        } else if (e.target.name === 'sources_box') {
            setFormData({ ...formData, sources: arraytosend });
        } else if (e.target.name === 'smellslike_box') {
            setFormData({ ...formData, smellslike: arraytosend });
        }
    };

    const addIngredientsEntry = (e) => {
        e.preventDefault(); // don't submit form

        let ingredients = cloneDeep(formData.ingredients);

        ingredients.push({
            list: [
                {
                    listedas: '',
                    isactive: false,
                    ingredient: [], // ids
                    oringredient: [], // ids
                    notes: '',
                },
            ],
            approxreleaseyear: '',
        });

        setFormData({ ...formData, ingredients: ingredients });
    };

    const deleteIngredientEntry = (e, idx) => {
        e.preventDefault(); // don't submit form

        let ingredients = [...formData.ingredients]; // copy array because we don't want to mutate the orig
        if (ingredients.length === 1) return; // don't allow deletion of last one

        ingredients = ingredients.filter((_, i) => i !== idx); // remove that step  @todo slice is more efficient

        setFormData({ ...formData, ingredients: ingredients });
    };

    const moveIngredientEntryToEnd = (e, idx) => {
        e.preventDefault(); // don't submit form

        let ingredients = [...formData.ingredients]; // copy array because we don't want to mutate the orig
        if (ingredients.length === 1) return; // don't allow deletion of last one

        ingredients.push(ingredients.splice(idx, 1)[0]); // move to end
        setFormData({ ...formData, ingredients: ingredients });
    };

    const updateIngredientEntry = (e, idx) => {
        if (e.target.name === 'ingredients') {
            // copy array because we don't want to mutate the orig
            // @todo13 find out why product ingredient entry 'listed as' puts cursor at end of field when editing middle
            const ingredients = [...formData.ingredients];
            // need to parse data into array for formData state
            // value is stringified array; parse into array
            const parsed = JSON.parse(e.target.value);
            ingredients[idx] = parsed;
            setFormData({ ...formData, ingredients: ingredients });
        }
    };

    const onChange = (e) => {
        if (e.target.name === 'company') {
            let companyIdBeforeUpdating = company ? company._id : undefined;
            const parsed = JSON.parse(e.target.value);

            let coobj = parsed.length > 0 ? parsed[0] : null; // returns company object with ID field.

            let newLine = line;
            if (!coobj || companyIdBeforeUpdating !== coobj._id) {
                // clear any previously selected product lines that may have been there for
                // a different company
                newLine = null;
            }

            // since autocomplete sets data as object array,
            // but db field is string with ID, the db will actually just save the id field as desired.
            setFormData({
                ...formData, // impt! need this here so I don't get (un)controlled component err
                company: coobj,
                line: newLine,
            });

            // show product line if company is set (meaning a company was selected)
            toggleProductCollection(coobj ? true : false);
        } else if (e.target.name === 'line') {
            const parsed = JSON.parse(e.target.value);

            let lineobj = parsed[0]; // returns company object with ID field.
            // since autocomplete sets data as object array,
            // but db field is string with ID, the db will actually just save the id field as desired.
            setFormData({
                ...formData, // impt! need this here so I don't get (un)controlled component err
                line: lineobj ? lineobj._id : null,
            });
        } else if (e.target.name === 'categories') {
            const parsed = JSON.parse(e.target.value); // since mutliselect returns data as array
            setFormData({
                ...formData, // impt! need this here so I don't get (un)controlled component err
                categories: parsed,
            });
        } else setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const onSubmit = (e) => {
        e.preventDefault();
        createProduct(formData, history, params.id || undefined);
    };

    let category_list = [
        { name: 'Pre-Poo' },
        { name: 'Detangler' },
        { name: 'Shampoo' },
        { name: 'Conditioner' },
        { name: 'Leave-In Conditioner' },
        { name: 'Deep Conditioner' },
        { name: 'Co-Wash' },
        { name: 'Scalp Reliever' },
        { name: 'Hair Treatment' },
        { name: 'Gel' },
        { name: 'Styling Cream' },
        { name: 'Hair Mask' },
        { name: 'Hair Rinse' },
        { name: 'Hairspray' },
        { name: 'Heat Protectant' },
        { name: 'Spritz' },
        { name: 'Serum' },
        { name: 'Oil' },
        { name: 'Gloss' },
        { name: 'Mousse' },
        { name: 'Tonic' },
        { name: 'Other' },
        { name: 'Not Specified' },
    ];

    return (
        <div className='containermiddle'>
            <Helmet>
                <title>
                    {APP_NAME} | {params.id ? 'Update' : 'Add'} Product
                    {product ? ': ' + product.name : ''}
                </title>
            </Helmet>

            <div className='header-with-button mb'>
                <h1>{params.id ? 'Update' : 'Add'} Product</h1>
                {!loading_product && product && params.id ? (
                    <Link
                        to={`/product/${product.urlparam}`}
                        className='btn btn-primary mr-0'
                    >
                        View Product
                    </Link>
                ) : (
                    ''
                )}
            </div>
            {
                // only loading if there's a product to load, meaning editing (id in url)
                params.id && (!initialized || loading_product) ? (
                    // wait for product data to load
                    <Spinner />
                ) : (
                    <Fragment>
                        {params.id &&
                            initialized &&
                            !loading_product &&
                            product && (
                                <Fragment>
                                    <p className='allcaps small'>
                                        Added by{' '}
                                        {product.createdby &&
                                            product.createdby.username}{' '}
                                        on{' '}
                                        {product.created && (
                                            <DayJS format='ddd, MMM DD YYYY [at] hh:mm:ssa'>
                                                {product.created}
                                            </DayJS>
                                        )}{' '}
                                        | Last edited by{' '}
                                        {product.lasteditedby &&
                                            product.lasteditedby.username}{' '}
                                        on{' '}
                                        {product.updated && (
                                            <DayJS format='ddd, MMM DD YYYY [at] hh:mm:ssa'>
                                                {product.updated}
                                            </DayJS>
                                        )}
                                    </p>
                                </Fragment>
                            )}

                        {params.id && initialized && <UploadProductPic />}
                        <p className='italicsmsg m0 pt-0'>* = required field</p>

                        <form className='form' onSubmit={onSubmit}>
                            <div className='form-group'>
                                <p className='form-header'>* Name</p>
                                <input
                                    type='text'
                                    placeholder='Product name'
                                    name='name'
                                    value={name || initialState.name}
                                    onChange={onChange}
                                    onKeyPress={(e) => {
                                        e.key === 'Enter' && e.preventDefault();
                                    }}
                                />
                            </div>

                            <div className='row-split-two'>
                                <div>
                                    <p className='form-header'>* Company</p>
                                    {loading_autocomplete ? (
                                        // wait for company suggestions to load
                                        <Spinner size={1} />
                                    ) : (
                                        <Autocomplete
                                            cssID={'co0'} // index 0 since there is only 1 possible company field.
                                            objectsName='company'
                                            objectsData={
                                                company ? [company] : []
                                            }
                                            placeholder='Select company'
                                            onChangeHandler={onChange}
                                            suggestions={companies_autocomplete}
                                            selectOneMax={true}
                                        />
                                    )}
                                </div>

                                <div>
                                    <p className='form-header'>
                                        Product Collection{' '}
                                        {company && !company.loading && (
                                            <a
                                                href={`/admin/editcompany/${company._id}`}
                                                target='_blank'
                                                rel='noreferrer'
                                            >
                                                [edit company's collections]
                                            </a>
                                        )}
                                    </p>

                                    {!company || company.loading ? (
                                        // wait for suggestions/company to load
                                        <p>
                                            Select a company to see a list of
                                            its collections.
                                        </p>
                                    ) : (
                                        <Autocomplete
                                            cssID={'collection0'} // index 0 since there is only 1 possible line field.
                                            objectsName='line'
                                            objectsData={helpers.findMatchingProductCollectionArray(
                                                line,
                                                company
                                            )}
                                            placeholder='Select product collection'
                                            onChangeHandler={onChange}
                                            showAllOptions={true}
                                            suggestions={
                                                company.productcollections || []
                                            }
                                            selectOneMax={true}
                                        />
                                    )}
                                </div>
                            </div>

                            <div className='row-split-two form-group'>
                                <div>
                                    <p className='form-header'>
                                        * URL parameter
                                    </p>
                                    <input
                                        type='text'
                                        placeholder='Product URL param'
                                        name='urlparam'
                                        value={
                                            urlparam || initialState.urlparam
                                        }
                                        onChange={onChange}
                                        onKeyPress={(e) => {
                                            e.key === 'Enter' &&
                                                e.preventDefault();
                                        }}
                                    />
                                    <small className='block mt'>
                                        Format: name of brand followed by name
                                        of product. Make sure it is all
                                        lowercase with no spaces or special
                                        characters (like commas or colons).
                                        Example:{' '}
                                        <strong>
                                            name-of-brand-name-of-product
                                        </strong>
                                    </small>
                                </div>

                                <div>
                                    <p className='form-header'>Website</p>
                                    <input
                                        type='text'
                                        placeholder='Website'
                                        name='website'
                                        value={website || initialState.website}
                                        onChange={onChange}
                                        onKeyPress={(e) => {
                                            e.key === 'Enter' &&
                                                e.preventDefault();
                                        }}
                                    />
                                </div>
                            </div>

                            <div className='my-1'>
                                <button
                                    onClick={() =>
                                        toggleWebLinks(!displayWebLinks)
                                    }
                                    type='button'
                                    className='btn btn-light'
                                >
                                    {displayWebLinks ? 'Hide' : 'Show'} Purchase
                                    Links...
                                </button>
                            </div>
                            {displayWebLinks && (
                                <div className='row-split-two'>
                                    <div>
                                        <div>
                                            <input
                                                type='text'
                                                placeholder='Amazon URL'
                                                name='amazon'
                                                value={
                                                    amazon ||
                                                    initialState.amazon
                                                }
                                                onChange={onChange}
                                                onKeyPress={(e) => {
                                                    e.key === 'Enter' &&
                                                        e.preventDefault();
                                                }}
                                            />
                                        </div>

                                        <div className='form-group'>
                                            <input
                                                type='text'
                                                placeholder='Walgreens URL'
                                                name='walgreens'
                                                value={
                                                    walgreens ||
                                                    initialState.walgreens
                                                }
                                                onChange={onChange}
                                                onKeyPress={(e) => {
                                                    e.key === 'Enter' &&
                                                        e.preventDefault();
                                                }}
                                            />
                                        </div>

                                        <div className='form-group'>
                                            <input
                                                type='text'
                                                placeholder='Target URL'
                                                name='target'
                                                value={
                                                    target ||
                                                    initialState.target
                                                }
                                                onChange={onChange}
                                                onKeyPress={(e) => {
                                                    e.key === 'Enter' &&
                                                        e.preventDefault();
                                                }}
                                            />
                                        </div>

                                        <div className='form-group'>
                                            <input
                                                type='text'
                                                placeholder='Walmart URL'
                                                name='walmart'
                                                value={
                                                    walmart ||
                                                    initialState.walmart
                                                }
                                                onChange={onChange}
                                                onKeyPress={(e) => {
                                                    e.key === 'Enter' &&
                                                        e.preventDefault();
                                                }}
                                            />
                                        </div>
                                    </div>

                                    <div>
                                        <div>
                                            <input
                                                type='text'
                                                placeholder='CVS URL'
                                                name='cvs'
                                                value={cvs || initialState.cvs}
                                                onChange={onChange}
                                                onKeyPress={(e) => {
                                                    e.key === 'Enter' &&
                                                        e.preventDefault();
                                                }}
                                            />
                                        </div>

                                        <div className='form-group'>
                                            <input
                                                type='text'
                                                placeholder='Sallys URL'
                                                name='sallys'
                                                value={
                                                    sallys ||
                                                    initialState.sallys
                                                }
                                                onChange={onChange}
                                                onKeyPress={(e) => {
                                                    e.key === 'Enter' &&
                                                        e.preventDefault();
                                                }}
                                            />
                                        </div>

                                        <div className='form-group'>
                                            <input
                                                type='text'
                                                placeholder='Ulta URL'
                                                name='ulta'
                                                value={
                                                    ulta || initialState.ulta
                                                }
                                                onChange={onChange}
                                                onKeyPress={(e) => {
                                                    e.key === 'Enter' &&
                                                        e.preventDefault();
                                                }}
                                            />
                                        </div>

                                        <div className='form-group'>
                                            <input
                                                type='text'
                                                placeholder='Sephora URL'
                                                name='sephora'
                                                value={
                                                    sephora ||
                                                    initialState.sephora
                                                }
                                                onChange={onChange}
                                                onKeyPress={(e) => {
                                                    e.key === 'Enter' &&
                                                        e.preventDefault();
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            )}

                            <div className='form-group'>
                                <p className='form-header'>* Categories</p>

                                <Multiselect
                                    stringsName='categories'
                                    options={category_list}
                                    selectedOptions={
                                        categories || initialState.categories
                                    }
                                    cssID='cat'
                                    onChangeHandler={onChange}
                                />
                            </div>

                            <div className='row-split-two'>
                                <div>
                                    <p className='form-header'>* Description</p>
                                    <textarea
                                        placeholder='Enter description'
                                        name='description'
                                        value={
                                            description ||
                                            initialState.description
                                        }
                                        rows={5}
                                        onChange={onChange}
                                    />
                                </div>

                                <div>
                                    <p className='form-header'>* Directions</p>
                                    <textarea
                                        placeholder='Enter directions'
                                        name='directions'
                                        value={
                                            directions ||
                                            initialState.directions
                                        }
                                        rows={5}
                                        onChange={onChange}
                                    />
                                </div>
                            </div>

                            <div className='row-split-two'>
                                <div className='form-group'>
                                    <p className='form-header'>Product pH</p>
                                    <input
                                        type='text'
                                        placeholder='Enter product pH'
                                        name='pH'
                                        value={pH || initialState.pH}
                                        onChange={onChange}
                                        onKeyPress={(e) => {
                                            e.key === 'Enter' &&
                                                e.preventDefault();
                                        }}
                                    />
                                </div>

                                <div className='form-group'>
                                    <p className='form-header'>* Priority</p>
                                    <input
                                        type='text'
                                        placeholder='Priority'
                                        name='priority'
                                        value={
                                            priority || initialState.priority
                                        }
                                        onChange={onChange}
                                        onKeyPress={(e) => {
                                            e.key === 'Enter' &&
                                                e.preventDefault();
                                        }}
                                    />
                                    <small className='block mt'>
                                        Only Roz should specify this. It is used
                                        to sort products in featured/priority
                                        order.
                                    </small>
                                </div>
                            </div>

                            <div>
                                <p className='form-header'>Notes</p>
                                <textarea
                                    placeholder='Enter notes'
                                    name='notes'
                                    value={notes || initialState.notes}
                                    onChange={onChange}
                                    rows={4}
                                />
                            </div>

                            <div className='form-group'>
                                <p className='form-header'>Sources for Notes</p>
                                <textarea
                                    placeholder='Enter each source on a new line'
                                    name='sources_box'
                                    value={parseArrayOrNewLineList(
                                        sources || initialState.sources
                                    )}
                                    onChange={updateArrayFromText}
                                />
                                <input
                                    type='text'
                                    style={{ display: 'none' }}
                                    onChange={onChange}
                                    value={sources || initialState.sources}
                                    name='sources'
                                />
                            </div>

                            <div className='form-group'>
                                <p className='form-header'>Private Notes</p>
                                <textarea
                                    placeholder='Enter private notes (only admins can see them)'
                                    name='privatenotes'
                                    value={
                                        privatenotes ||
                                        initialState.privatenotes
                                    }
                                    onChange={onChange}
                                    rows={4}
                                />
                            </div>

                            <div className='form-group'>
                                <p className='form-header'>Shelflife</p>
                                <input
                                    type='text'
                                    placeholder='Enter product shelflife'
                                    name='shelflife'
                                    value={shelflife || initialState.shelflife}
                                    onChange={onChange}
                                    onKeyPress={(e) => {
                                        e.key === 'Enter' && e.preventDefault();
                                    }}
                                />
                            </div>

                            <div className='row-split-two form-group'>
                                <div>
                                    <p className='form-header'>
                                        Available product sizes
                                    </p>
                                    <textarea
                                        placeholder='Enter each product size on a new line'
                                        name='availablesizes_box'
                                        value={parseArrayOrNewLineList(
                                            availablesizes ||
                                                initialState.availablesizes
                                        )}
                                        onChange={updateArrayFromText}
                                    />
                                    <input
                                        type='text'
                                        style={{ display: 'none' }}
                                        onChange={onChange}
                                        value={
                                            availablesizes ||
                                            initialState.availablesizes
                                        }
                                        name='availablesizes'
                                    />
                                </div>

                                <div>
                                    <p className='form-header'>
                                        External reviews
                                    </p>
                                    <textarea
                                        placeholder='Enter each review link on a new line'
                                        name='externalreviews_box'
                                        value={parseArrayOrNewLineList(
                                            externalreviews ||
                                                initialState.externalreviews
                                        )}
                                        onChange={updateArrayFromText}
                                    />
                                    <input
                                        type='text'
                                        style={{ display: 'none' }}
                                        onChange={onChange}
                                        value={
                                            externalreviews ||
                                            initialState.externalreviews
                                        }
                                        name='externalreviews'
                                    />
                                </div>
                            </div>

                            <div className='row-split-two'>
                                <div>
                                    <p className='form-header'>Consistency</p>
                                    <select
                                        name='consistency'
                                        value={
                                            consistency ||
                                            initialState.consistency
                                        }
                                        onChange={onChange}
                                        onKeyPress={(e) => {
                                            e.key === 'Enter' &&
                                                e.preventDefault();
                                        }}
                                    >
                                        <option value='Not specified'>
                                            Select consistency
                                        </option>
                                        <option value='Thick'>Thick</option>
                                        <option value='Liquid'>Liquid</option>
                                        <option value='Creamy'>Creamy</option>
                                        <option value='Oily'>Oily</option>
                                        <option value='Gel'>Gel</option>
                                        <option value='Powder'>Powder</option>
                                        <option value='Other'>Other</option>
                                    </select>
                                </div>

                                <div>
                                    <p className='form-header'>
                                        Scent Strength
                                    </p>
                                    <select
                                        name='scentstrength'
                                        value={
                                            scentstrength ||
                                            initialState.scentstrength
                                        }
                                        onChange={onChange}
                                        onKeyPress={(e) => {
                                            e.key === 'Enter' &&
                                                e.preventDefault();
                                        }}
                                    >
                                        <option value='Not specified'>
                                            Select scent strength
                                        </option>
                                        <option value='None'>None</option>
                                        <option value='Mild'>Mild</option>
                                        <option value='Medium'>Medium</option>
                                        <option value='Strong'>Strong</option>
                                    </select>
                                </div>
                            </div>

                            <div className='form-group'>
                                <p className='form-header'>Smells Like</p>
                                <textarea
                                    placeholder='Enter each scent (e.g. apples, roses) on a new line'
                                    name='smellslike_box'
                                    value={parseArrayOrNewLineList(
                                        smellslike || initialState.smellslike
                                    )}
                                    onChange={updateArrayFromText}
                                />
                                <input
                                    type='text'
                                    style={{ display: 'none' }}
                                    onChange={onChange}
                                    value={
                                        smellslike || initialState.smellslike
                                    }
                                    name='smellslike'
                                />
                            </div>

                            <div className='row-split-two'>
                                <div className='form-group'>
                                    <div className='form-group'>
                                        <span>
                                            Is discontinued?{' '}
                                            <input
                                                type='checkbox'
                                                name='isdiscontinued'
                                                value={
                                                    isdiscontinued ||
                                                    initialState.isdiscontinued
                                                }
                                                checked={
                                                    isdiscontinued ||
                                                    initialState.isdiscontinued
                                                }
                                                onChange={() => {
                                                    setFormData({
                                                        ...formData,
                                                        isdiscontinued:
                                                            !isdiscontinued,
                                                    });
                                                }}
                                                onKeyPress={(e) => {
                                                    e.key === 'Enter' &&
                                                        e.preventDefault();
                                                }}
                                            />
                                        </span>
                                    </div>

                                    <div className='form-group'>
                                        <span>
                                            For dandruff?{' '}
                                            <input
                                                type='checkbox'
                                                name='isfordandruff'
                                                value={
                                                    isfordandruff ||
                                                    initialState.isfordandruff
                                                }
                                                checked={
                                                    isfordandruff ||
                                                    initialState.isfordandruff
                                                }
                                                onChange={() => {
                                                    setFormData({
                                                        ...formData,
                                                        isfordandruff:
                                                            !isfordandruff,
                                                    });
                                                }}
                                                onKeyPress={(e) => {
                                                    e.key === 'Enter' &&
                                                        e.preventDefault();
                                                }}
                                            />
                                        </span>
                                    </div>

                                    <div className='form-group'>
                                        <span>
                                            Cruelty-free?{' '}
                                            <input
                                                type='checkbox'
                                                name='iscrueltyfree'
                                                value={
                                                    iscrueltyfree ||
                                                    initialState.iscrueltyfree
                                                }
                                                checked={
                                                    iscrueltyfree ||
                                                    initialState.iscrueltyfree
                                                }
                                                onChange={() => {
                                                    setFormData({
                                                        ...formData,
                                                        iscrueltyfree:
                                                            !iscrueltyfree,
                                                    });
                                                }}
                                                onKeyPress={(e) => {
                                                    e.key === 'Enter' &&
                                                        e.preventDefault();
                                                }}
                                            />
                                        </span>
                                    </div>
                                </div>
                                <div className='form-group'>
                                    <div className='form-group'>
                                        <span>
                                            Safe For Babies{' '}
                                            <input
                                                type='checkbox'
                                                name='isforbabies'
                                                value={
                                                    isforbabies ||
                                                    initialState.isforbabies
                                                }
                                                checked={
                                                    isforbabies ||
                                                    initialState.isforbabies
                                                }
                                                onChange={() => {
                                                    setFormData({
                                                        ...formData,
                                                        isforbabies:
                                                            !isforbabies,
                                                    });
                                                }}
                                                onKeyPress={(e) => {
                                                    e.key === 'Enter' &&
                                                        e.preventDefault();
                                                }}
                                            />
                                        </span>
                                    </div>

                                    <div className='form-group'>
                                        <span>
                                            Safe For Kids{' '}
                                            <input
                                                type='checkbox'
                                                name='isforkids'
                                                value={
                                                    isforkids ||
                                                    initialState.isforkids
                                                }
                                                checked={
                                                    isforkids ||
                                                    initialState.isforkids
                                                }
                                                onChange={() => {
                                                    setFormData({
                                                        ...formData,
                                                        isforkids: !isforkids,
                                                    });
                                                }}
                                                onKeyPress={(e) => {
                                                    e.key === 'Enter' &&
                                                        e.preventDefault();
                                                }}
                                            />
                                        </span>
                                    </div>

                                    <div className='form-group'>
                                        <span>
                                            Made For Men{' '}
                                            <input
                                                type='checkbox'
                                                name='isformen'
                                                value={
                                                    isformen ||
                                                    initialState.isformen
                                                }
                                                checked={
                                                    isformen ||
                                                    initialState.isformen
                                                }
                                                onChange={() => {
                                                    setFormData({
                                                        ...formData,
                                                        isformen: !isformen,
                                                    });
                                                }}
                                                onKeyPress={(e) => {
                                                    e.key === 'Enter' &&
                                                        e.preventDefault();
                                                }}
                                            />
                                        </span>
                                    </div>
                                </div>
                            </div>

                            <div className='form-group'>
                                <hr />
                                <h2 className='form-header mb-0'>
                                    ADD PRODUCT VERSIONS
                                </h2>

                                <span className='block italicsmsg mb'>
                                    A product version has a list of ingredients
                                    that correspond to one particular version of
                                    this product. Sometimes, manufacturers
                                    change ingredient lists and this is
                                    important for our users to know. Most of the
                                    time though, there will likely only be one
                                    version needed here.{' '}
                                </span>
                                {ingredients.map((ing, idx) => {
                                    return (
                                        <IngredientEntry
                                            key={'entry-' + idx}
                                            objectsData={ing}
                                            onChangeHandler={(e) =>
                                                updateIngredientEntry(e, idx)
                                            }
                                            onDeleteHandler={(e) =>
                                                deleteIngredientEntry(e, idx)
                                            }
                                            onMoveHandler={(e) =>
                                                moveIngredientEntryToEnd(e, idx)
                                            }
                                            numEntries={ingredients.length}
                                            entryNum={idx}
                                        />
                                    );
                                })}
                                <button
                                    className='btn btn-light my-1'
                                    onClick={(e) => addIngredientsEntry(e)}
                                >
                                    Add Ingredient List version
                                </button>
                                <button
                                    className='btn btn-light my-1'
                                    onClick={(e) => addIngredientsEntry(e)}
                                >
                                    Add Product Version
                                </button>
                            </div>

                            <hr />
                            <div className='form-group'>
                                <span>
                                    Needs review{' '}
                                    <input
                                        type='checkbox'
                                        name='needsreview'
                                        value={
                                            needsreview ||
                                            initialState.needsreview
                                        }
                                        checked={
                                            needsreview ||
                                            initialState.needsreview
                                        }
                                        onChange={() => {
                                            setFormData({
                                                ...formData,
                                                needsreview: !needsreview,
                                            });
                                        }}
                                        onKeyPress={(e) => {
                                            e.key === 'Enter' &&
                                                e.preventDefault();
                                        }}
                                    />
                                    <span className='form-text mb'>
                                        Check this if this product needs
                                        additional information or research.
                                    </span>
                                </span>
                            </div>

                            <div className='form-group'>
                                <span>
                                    Draft{' '}
                                    <input
                                        type='checkbox'
                                        name='isdraft'
                                        value={isdraft || initialState.isdraft}
                                        checked={
                                            isdraft || initialState.isdraft
                                        }
                                        onChange={() => {
                                            setFormData({
                                                ...formData,
                                                isdraft: !isdraft,
                                            });
                                        }}
                                        onKeyPress={(e) => {
                                            e.key === 'Enter' &&
                                                e.preventDefault();
                                        }}
                                    />
                                    <span className='form-text mb'>
                                        If checked, it will only be visible to
                                        admins. Otherwise, it is published and
                                        visible to everyone.
                                    </span>
                                </span>
                            </div>

                            <AdminFormFooter />
                        </form>
                    </Fragment>
                )
            }
        </div>
    );
};

ProductForm.propTypes = {
    createProduct: PropTypes.func.isRequired,
    getProductById: PropTypes.func.isRequired,
    getIngredients: PropTypes.func.isRequired,
    getCompanies: PropTypes.func.isRequired,
    product: PropTypes.object.isRequired,
    ingredient: PropTypes.object.isRequired,
    company: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    product: state.product,
    ingredient: state.ingredient,
    company: state.company,
});

export default connect(mapStateToProps, {
    createProduct,
    getProductById,
    getIngredients,
    getCompanies,
})(ProductForm);
