// View All Companies
import React, { useEffect, Fragment } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import { APP_NAME } from '../../utils/constants';
import { getAllUsers } from '../../actions/admin';
import DayJS from 'react-dayjs';
import AdminNav from './AdminNav';
import Spinner from '../layout/Spinner';
import { getUserPic } from '../../utils/helpers';

const UsersAdmin = ({ getAllUsers, auth, admin: { loading, users } }) => {
    useEffect(() => {
        getAllUsers();
    }, [getAllUsers]);

    useEffect(() => {
        window.scrollTo(0, 0); // scroll to top of form on mount
    }, []);

    const getAdmins = (users) => {
        let admins = users.filter((u) => u.role > 0);
        return admins;
    };

    const getMembersWithProfiles = (users) => {
        let members = users.filter((u) => u.profile);
        return members;
    };

    const getMembersWithVerifiedEmails = (users) => {
        let members = users.filter((u) => u.emailverified === true);
        return members;
    };

    return (
        <div className='containermiddle'>
            <Helmet>
                <title>{APP_NAME} | Users</title>
            </Helmet>
            <AdminNav isSuperAdmin={auth.isSuperAdmin} />

            {loading || !users ? (
                // wait for product data to load
                <Spinner />
            ) : (
                <Fragment>
                    <div className='admin-member-dashboard-boxes mb-2'>
                        <div className='admin-dashboard-box-small'>
                            <span>Members</span> <strong>{users.length}</strong>
                        </div>

                        <div className='admin-dashboard-box-small'>
                            <span>Admins</span>{' '}
                            <strong>{getAdmins(users).length}</strong>
                        </div>

                        <div className='admin-dashboard-box-small'>
                            <span>Created Profiles</span>{' '}
                            <strong>
                                {getMembersWithProfiles(users).length}
                            </strong>
                        </div>

                        <div className='admin-dashboard-box-small'>
                            <span>Verified Emails</span>{' '}
                            <strong>
                                {getMembersWithVerifiedEmails(users).length}
                            </strong>
                        </div>
                    </div>

                    <h2>Members</h2>
                    <div className='admin-members-boxes'>
                        {users.map((user) => {
                            return (
                                <div
                                    className='admin-members-box'
                                    key={user.username_lower}
                                >
                                    <img
                                        className='round-img-sml'
                                        src={getUserPic(user.avatar)}
                                        alt='reviewer'
                                    />
                                    <p>
                                        <strong>
                                            {user.profile ? (
                                                <Link
                                                    to={`/profile/${user.username_lower}`}
                                                >
                                                    {user.username}
                                                </Link>
                                            ) : (
                                                user.username
                                            )}
                                        </strong>
                                    </p>
                                    {user.role > 0 && (
                                        <p>
                                            <small>
                                                <i className='fas fa-lock' />{' '}
                                                Admin
                                            </small>
                                        </p>
                                    )}
                                    <p>
                                        <small>
                                            <i className='fas fa-user' />{' '}
                                            {user.name}
                                        </small>
                                    </p>

                                    <p style={{ hyphens: 'auto' }}>
                                        <small>
                                            {user.emailverified ? (
                                                <i className='fas fa-envelope-circle-check' />
                                            ) : (
                                                <i className='fas fa-envelope' />
                                            )}{' '}
                                            {user.email}
                                        </small>
                                    </p>
                                    <p>
                                        <small>
                                            Registered on{' '}
                                            <DayJS format='MMM DD YYYY HH:mm'>
                                                {user.created}
                                            </DayJS>
                                        </small>
                                    </p>
                                    <p>
                                        <small>
                                            Last logged in{' '}
                                            {user.lastlogin ? (
                                                <DayJS format='MMM DD YYYY HH:m'>
                                                    {user.lastlogin}
                                                </DayJS>
                                            ) : (
                                                <span>N/A</span>
                                            )}
                                        </small>
                                    </p>
                                </div>
                            );
                        })}
                    </div>
                </Fragment>
            )}
        </div>
    );
};

UsersAdmin.propTypes = {
    getAllUsers: PropTypes.func.isRequired,
    admin: PropTypes.object.isRequired,
    auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    admin: state.admin,
    auth: state.auth,
});

export default connect(mapStateToProps, { getAllUsers })(UsersAdmin);
