import React, { useState, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';

const initialState = {
    name: '',
    description: '',
    launchyear: '',
};

const CollectionRow = function ({
    rowData, // an object with fields name, description, and launchyear
    onChangeHandler, // function for changing row property
    onDeleteHandler, // function for row deletion
    numRows, // for numbering entries and making sure 1 entry left can't be deleted
    rowNum, // current entry number, used as an index
}) {
    const [entryData, setEntryData] = useState(initialState);

    useEffect(() => {
        if (rowData) {
            // update form fields with current product
            const data = { ...initialState };

            for (const key in rowData) {
                // key: _id, name, description, launchyear
                if (key in data) data[key] = rowData[key];
            }

            setEntryData(data);
        }
    }, [rowData]);

    const { name, description, launchyear } = entryData;

    return (
        <div className='ingredient_row_wrapper'>
            <p className='form-header'>Product Collection {rowNum + 1}</p>

            <div className='row-split-two form-header'>
                <div>
                    <p>Name *</p>
                    <input
                        type='text'
                        placeholder='Product collection name'
                        name='collection-name'
                        value={name || initialState.name}
                        onChange={(e) => onChangeHandler(e)}
                        onKeyPress={(e) => {
                            e.key === 'Enter' && e.preventDefault();
                        }}
                        required
                    />
                </div>

                <div>
                    <p>Launch Year</p>
                    <div>
                        <small className='form-text mb'></small>
                        <input
                            type='text'
                            placeholder='Year released'
                            name='collection-launchyear'
                            value={launchyear || initialState.launchyear}
                            onChange={(e) => onChangeHandler(e)}
                            onKeyPress={(e) => {
                                e.key === 'Enter' && e.preventDefault();
                            }}
                        />
                    </div>
                </div>
            </div>

            <div className='form-group'>
                <p className='form-header'>Description</p>
                <textarea
                    placeholder='Enter each description paragraph/bullet on a new line'
                    name='collection-description'
                    value={description || initialState.description}
                    rows={2}
                    onChange={(e) => onChangeHandler(e)}
                />
            </div>

            {numRows > 1 && (
                <Fragment>
                    <small className='block mb'>
                        <strong>Note</strong>: once a collection has been saved,
                        do NOT delete it without confirming with Roz first!
                    </small>
                    <button
                        type='button'
                        className='btn btn-danger my mb-1'
                        onClick={(e) => onDeleteHandler(e)}
                    >
                        <i className='far fa-trash-alt' /> Delete Product
                        Collection
                    </button>
                </Fragment>
            )}
        </div>
    );
};

CollectionRow.propTypes = {
    onChangeHandler: PropTypes.func.isRequired,
    onDeleteHandler: PropTypes.func.isRequired,
    rowData: PropTypes.object.isRequired, // used to get and set form data
    numRows: PropTypes.number.isRequired,
    rowNum: PropTypes.number.isRequired,
};

export default CollectionRow;
