// since this is a form,  each input should be a piece of state, so we need useState hook
import React, { useState, useEffect, Fragment } from 'react';
import { Navigate, useParams, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import { APP_NAME } from '../../../utils/constants';
import CollectionRow from './CollectionRow';
import DayJS from 'react-dayjs';
import { createCompany, getCompanyById } from '../../../actions/company';
import AdminFormFooter from '../AdminFormFooter';
import AdminNav from '../AdminNav';

// Notes: fields use pattern "fieldname || initialState.fieldname" because certain fields may not be in database for user (namely, when models change). This will help when fetching data for ingredient when editing it.
// set default state values that will appear in form

// const initialCollection = {
//     name: '',
//     description: '',
//     launchyear: null,
// };

const initialState = {
    name: '',
    shortname: '',
    website: '',
    urlparam: '',
    description: '',
    isblackowned: false,
    productcollections: [], // [initialCollection]
};

const CompanyForm = ({
    // prop types
    company: { company, companies_autocomplete, loading },
    createCompany,
    getCompanyById, // to fill in fields for editing
    auth,
}) => {
    let params = useParams();
    let history = useNavigate();

    // format: [state, function to update state]
    const [formData, setFormData] = useState(initialState);

    useEffect(() => {
        if (!company && params.id) {
            getCompanyById(params.id, history);
        }

        if (!loading && company && params.id) {
            // update form fields with current company
            const companyData = { ...initialState };

            for (const key in company) {
                if (key in companyData) companyData[key] = company[key];
            }

            // handle subsections of data model
            for (const key in company.productcollections) {
                if (key in companyData.productcollections)
                    companyData[key] = company.productcollections[key];
            }

            setFormData(companyData);
        }
    }, [
        company,
        loading,
        getCompanyById,
        companies_autocomplete,
        params.id,
        history,
    ]);

    useEffect(() => {
        window.scrollTo(0, 0); // scroll to top of form on mount
    }, []);

    const {
        name,
        shortname,
        website,
        urlparam,
        description,
        isblackowned,
        productcollections,
    } = formData;

    const onChange = (e) => {
        e.stopPropagation(); // to prevent lastpass bug from throwing error in console
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const onSubmit = (e) => {
        e.preventDefault();
        createCompany(formData, history, params.id || undefined);
    };

    const deleteProductCollection = (e, idx) => {
        if (e) e.preventDefault(); // don't submit form. check if e b/c this is also used as helper method
        if (productcollections.length === 1) return; // don't allow deletion of last one

        let entryCopy = [...productcollections];
        entryCopy = [...entryCopy.slice(0, idx), ...entryCopy.slice(idx + 1)]; // remove that step
        // setFormData({ ...formData, productcollections: entryCopy });
        setFormData((prevState) => ({
            ...prevState,
            productcollections: entryCopy,
        }));
    };

    const addProductCollection = (e) => {
        e.preventDefault(); // don't submit form

        let entryCopy = { ...formData }; // copy current ...
        entryCopy.productcollections.push({
            name: '',
            description: '',
            launchyear: null,
        });

        setFormData({
            ...formData,
            productcollections: entryCopy.productcollections,
        });
    };

    const onChangeLine = (e, idx) => {
        e.stopPropagation(); // to prevent lastpass bug from throwing error in console

        // let entryCopy = [...productcollections];
        // I need to use this method to copy the objects in the array
        let entryCopy = formData.productcollections.map((l) =>
            Object.assign({}, l)
        );

        if (
            e.target.name === 'collection-name' ||
            e.target.name === 'collection-launchyear' ||
            e.target.name === 'collection-description'
        ) {
            // need to parse data into array for state
            // value is stringified array; parse into array
            let target = e.target.name.replace('collection-', '');
            entryCopy[idx][target] = e.target.value;
            if (target === 'launchyear' && e.target.value.trim().length === 0)
                entryCopy[idx][target] = null;

            setFormData((prevState) => ({
                ...prevState,
                productcollections: entryCopy,
            }));
        }
    };

    if (!auth.isAuthenticated) {
        return <Navigate to='/login' />;
    }

    return (
        <div className='containermiddle'>
            <Helmet>
                <title>
                    {APP_NAME} | {params.id ? 'Update' : 'Add'} Company
                    {company ? ': ' + company.name : ''}
                </title>
            </Helmet>
            <AdminNav isSuperAdmin={auth.isSuperAdmin} />
            <h2>{params.id ? 'Update' : 'Add'} Company</h2>
            {!loading && company && params.id && (
                <Fragment>
                    <p className='allcaps small'>
                        Added by{' '}
                        {company &&
                            company.createdby &&
                            company.createdby.username}{' '}
                        on{' '}
                        {company && company.created && (
                            <DayJS format='ddd, MMM DD YYYY [at] hh:mm:ssa'>
                                {company.created}
                            </DayJS>
                        )}
                    </p>
                    <p className='allcaps small'>
                        Last edited by{' '}
                        {company &&
                            company.lasteditedby &&
                            company.lasteditedby.username}{' '}
                        on{' '}
                        {company && company.updated && (
                            <DayJS format='ddd, MMM DD YYYY [at] hh:mm:ssa'>
                                {company.updated}
                            </DayJS>
                        )}
                    </p>
                </Fragment>
            )}

            <p className='italicsmsg m0 pt-0'>* = required field</p>

            <form className='form' onSubmit={onSubmit}>
                <div className='form-group row-split-two'>
                    <div>
                        <p className='form-header'>* Company name</p>
                        <input
                            type='text'
                            placeholder='Company name'
                            name='name'
                            value={name || initialState.name}
                            onChange={onChange}
                            onKeyPress={(e) => {
                                e.key === 'Enter' && e.preventDefault();
                            }}
                        />
                    </div>

                    <div>
                        <p className='form-header'>* Company nickname</p>
                        <input
                            type='text'
                            placeholder='Company nickname'
                            name='shortname'
                            value={shortname || initialState.shortname}
                            onChange={onChange}
                            onKeyPress={(e) => {
                                e.key === 'Enter' && e.preventDefault();
                            }}
                        />
                    </div>
                </div>

                <div className='form-group row-split-two'>
                    <div>
                        <p className='form-header'>Website</p>
                        <input
                            type='text'
                            placeholder='Company website'
                            name='website'
                            value={website || initialState.website}
                            onChange={onChange}
                            onKeyPress={(e) => {
                                e.key === 'Enter' && e.preventDefault();
                            }}
                        />
                    </div>

                    <div>
                        <p className='form-header'>* URL parameter</p>
                        <input
                            type='text'
                            placeholder='Company URL param'
                            name='urlparam'
                            value={urlparam || initialState.urlparam}
                            onChange={onChange}
                            onKeyPress={(e) => {
                                e.key === 'Enter' && e.preventDefault();
                            }}
                        />
                    </div>
                </div>

                <div className='form-group'>
                    <p className='form-header'>Description</p>
                    <textarea
                        placeholder='Enter each description paragraph/bullet on a new line'
                        name='description'
                        value={description || initialState.description}
                        rows={5}
                        onChange={onChange}
                    />
                </div>

                <p className='form-header'>Product Collections</p>
                <small className='block mb'>
                    <strong>Note</strong>: Saving the company will sort the
                    added product collections alphabetically.
                </small>
                {productcollections.map((line, idx) => {
                    return (
                        <CollectionRow
                            key={'line-' + idx}
                            rowData={line}
                            numRows={productcollections.length}
                            rowNum={idx}
                            onChangeHandler={(e) => onChangeLine(e, idx)}
                            onDeleteHandler={(e) =>
                                deleteProductCollection(e, idx)
                            }
                        />
                    );
                })}

                <button
                    className='btn btn-light my-1'
                    onClick={(e) => addProductCollection(e)}
                >
                    Add Product Collection
                </button>

                <div className='ingredient-property-checklist'>
                    <div>
                        <div className='form-group'>
                            <span>
                                Black-Owned{' '}
                                <input
                                    type='checkbox'
                                    name='isblackowned'
                                    value={
                                        isblackowned ||
                                        initialState.isblackowned
                                    }
                                    checked={
                                        isblackowned ||
                                        initialState.isblackowned
                                    }
                                    onChange={() => {
                                        setFormData({
                                            ...formData,
                                            isblackowned: !isblackowned,
                                        });
                                    }}
                                    onKeyPress={(e) => {
                                        e.key === 'Enter' && e.preventDefault();
                                    }}
                                />
                            </span>
                        </div>
                    </div>
                </div>

                <AdminFormFooter />
            </form>
        </div>
    );
};

CompanyForm.propTypes = {
    // mapped w/state
    createCompany: PropTypes.func.isRequired,
    getCompanyById: PropTypes.func.isRequired,
    company: PropTypes.object.isRequired,
    auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    company: state.company,
    auth: state.auth,
});

export default connect(mapStateToProps, {
    createCompany,
    getCompanyById,
    // getCompanies,
})(CompanyForm);
