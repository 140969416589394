import { orderBy } from 'lodash';
import {
    ADD_INGREDIENT,
    GET_INGREDIENT, // fetch when it is created or updated
    GET_INGREDIENTS,
    GET_INGREDIENTS_AUTOCOMPLETE,
    INGREDIENT_ERROR,
    DELETE_INGREDIENT,
    CLEAR_INGREDIENT,
    FILTER_INGREDIENTS,
    UPDATE_ING_PAGE_NUMBER,
    UPDATE_ING_QUERY_PARAM,
    SORT_INGREDIENTS,
    LOGOUT,
} from '../actions/types';

const initialState = {
    ingredient: null,
    ingredients: [], // for listing all ingredients.
    ingredients_searched: [],
    ingredients_autocomplete: [], // for listing all ingredients, with name and ID only
    show_page_num: 1,
    ingredient_query_str: '',
    loading: true,
    loading_autocomplete: true,
    loading_searched_ingredients: true,
    sort_ingredients_by: 'name',
    filter_ingredients_by: {
        ss: '',
        bools: {
            needsreview: null,
        },
        properties: [], // array of property strings, e.g. Anti-Static
    },
    error: {}, // for request errors
};

// reducers take a state and an action (the action is dispatched from a file)
// actions have a type and a payload
const ingredientReducer = (state = initialState, action) => {
    const { type, payload } = action; // destructure for simplicity

    switch (type) {
        // used when fetching or updating an ingredient
        case GET_INGREDIENT:
            return {
                ...state,
                ingredient: payload,
                ingredients: state.ingredients.map((ing) =>
                    ing._id === payload._id ? payload : ing
                ),
                loading: false,
            };

        case GET_INGREDIENTS:
            const sortedIngredients = getSortedData(
                payload,
                state.sort_ingredients_by
            );

            return {
                ...state,
                ingredients: sortedIngredients,
                loading: false,
            };

        case FILTER_INGREDIENTS: {
            // first, search based on string
            let filteredProps = payload.filterBy;
            let str = filteredProps ? filteredProps['ss'] : '';
            let filteredData = str.length
                ? searchIngredients(str, state.ingredients)
                : state.ingredients;

            if (filteredProps) {
                let filteredingproperties = filteredProps.properties;

                let properties = Object.entries(filteredProps.bools);

                filteredData = filteredData.filter((ingredient) => {
                    // payload is obj with properties to filter. we will decrement count
                    // of properties; if it gets to 0, then the ingredient matches all
                    // criteria and should be included in filter result
                    var numMatchedProperties = properties.length;

                    for (const [property, val] of properties) {
                        if (val === null) {
                            numMatchedProperties--;
                            continue; // ignore this property when filtering
                        } else if (val === 'true') {
                            // handle generic top-level cases (needsreview)

                            if (ingredient[property]) {
                                numMatchedProperties--;
                            }
                        } else if (val === 'false') {
                            // handle generic top-level cases (needsreview)

                            if (!ingredient[property]) {
                                numMatchedProperties--;
                            }
                        }
                    }
                    // now check ingredient properties. ALL must match!
                    // console.log(Object.keys(ingredient.properties));
                    let matchesProperty = true;
                    for (let p = 0; p < filteredingproperties.length; p++) {
                        let propertytocheck = filteredingproperties[p];
                        let ingpropertyistrue =
                            ingredient.properties[propertytocheck];
                        // console.log(ingredient.properties[property]);
                        if (!ingpropertyistrue) {
                            matchesProperty = false;
                            break;
                        }
                    }

                    return matchesProperty && numMatchedProperties === 0;
                });
            }

            // now sort
            filteredData = getSortedData(
                filteredData,
                state.sort_ingredients_by
            );

            return {
                ...state,
                filter_ingredients_by:
                    payload.filterBy || state.filter_ingredients_by,
                ingredients_searched: filteredData,
                loading_searched_ingredients: false,
                ingredient_query_str: payload.queryparam,
            };
        }

        case UPDATE_ING_PAGE_NUMBER: {
            return {
                ...state,
                show_page_num: payload,
                loading: false,
            };
        }

        case UPDATE_ING_QUERY_PARAM:
            return {
                ...state,
                ingredient_query_str: payload,
            };

        case SORT_INGREDIENTS: {
            const sortedIngredients = getSortedData(
                state.ingredients,
                payload.sortBy,
                payload.sortDir
            );

            const sortedSearchedIngredients = getSortedData(
                state.ingredients_searched,
                payload.sortBy,
                payload.sortDir
            );

            return {
                ...state,
                sort_ingredients_by: payload.sortBy, // the searched string
                ingredients: sortedIngredients,
                ingredients_searched: sortedSearchedIngredients,
            };
        }

        case GET_INGREDIENTS_AUTOCOMPLETE: // @todo7 add/remove data for autocomplete in other reducers
            return {
                ...state,
                ingredients_autocomplete: payload,
                loading_autocomplete: false,
            };

        case INGREDIENT_ERROR:
            return {
                ...state,
                error: payload,
                loading: false,
                ingredient: null,
            };
        case ADD_INGREDIENT:
            return {
                ...state,
                ingredient: payload,
                ingredients: [...state.ingredients, payload],
                ingredients_autocomplete: [
                    ...state.ingredients_autocomplete,
                    payload,
                ],
                loading: false,
            };
        case CLEAR_INGREDIENT /* no payload sent */:
            return {
                ...state,
                ingredient: null,
            };

        case DELETE_INGREDIENT:
            return {
                ...state,
                ingredients: state.ingredients.filter(
                    (ingredient) => ingredient._id !== payload._id
                ),
                ingredients_autocomplete: state.ingredients_autocomplete.filter(
                    (ingredient) => ingredient._id !== payload._id
                ),
            };

        // when logging out, clear admin filters in case they were set before admin logged out
        case LOGOUT: {
            let newfilter = { ...state.filter_ingredients_by };
            newfilter.bools.needsreview = null;

            return {
                ...state,
                filter_ingredients_by: newfilter,
            };
        }

        default:
            return state;
    }
};

const searchIngredients = (searchStr, ingredients) => {
    const filteredData = ingredients.filter(
        (ing) =>
            // ingredient name or one of its synonyms contains search string:
            ing.name.toLowerCase().includes(searchStr.toLowerCase()) ||
            ing.synonyms.some((synonym) =>
                synonym.toLowerCase().includes(searchStr.toLowerCase())
            )
    );

    return filteredData;
};

const getSortedData = (filteredData, sort_ingredients_by /*, sortdir */) => {
    let sortedData;
    const filteredDataCopy = [...filteredData];

    if (sort_ingredients_by === 'rating') {
        var priority = ['not yet rated', 'great', 'good', 'average', 'poor'];

        sortedData = filteredDataCopy.sort(
            (a, b) =>
                // sort by rating then by name
                priority.indexOf(a.rating.toLowerCase()) -
                    priority.indexOf(b.rating.toLowerCase()) ||
                a.name.toLowerCase() - b.name.toLowerCase()
        );
    } else {
        let dir =
            /* sortdir || */ sort_ingredients_by === 'created' ? 'desc' : 'asc';
        sortedData = orderBy(
            filteredDataCopy,
            [
                sort_ingredients_by === 'name'
                    ? 'name_lower'
                    : sort_ingredients_by,
            ],
            [dir]
        );
    }

    return sortedData;
};

export default ingredientReducer;
