import {
    REGIMEN_ERROR,
    DELETE_REGIMEN,
    LOADING_REGIMENS,
    ADD_REGIMEN,
    GET_REGIMEN,
    GET_REGIMENS,
    GET_REGIMENS_ADMIN,
    UPDATE_REGIMEN_LIKES,
    LOADING_REGIMEN_PIC,
    DONE_LOADING_REGIMEN_PIC,
    UPDATE_REGIMEN_PIC,
    LOADING_REGIMEN_STEP_PIC,
    DONE_LOADING_REGIMEN_STEP_PIC,
    UPDATE_REGIMEN_STEP_PIC,
    GET_REGIMENS_AUTOCOMPLETE,
    LOGOUT,
} from '../actions/types';

const initialState = {
    regimen: null,
    regimens: [], // for listing all regimens
    regimens_admin: [],
    regimens_autocomplete: [],
    loading_autocomplete: true,
    loading: true,
    uploading_regimen_pic: false,
    uploading_regimen_step_pic: null, // null if no step is being updated; otherwise = id of step being updated
    error: {}, // for request errors
};

// reducers take a state and an action (the action is dispatched from a file)
// actions have a type and a payload
const regimenReducer = (state = initialState, action) => {
    const { type, payload } = action; // destructure for simplicity

    switch (type) {
        case GET_REGIMEN:
            return {
                ...state,
                regimen: payload,
                loading: false,
            };

        case GET_REGIMENS:
            return {
                ...state,
                regimens: payload,
                loading: false,
            };

        case GET_REGIMENS_ADMIN:
            return {
                ...state,
                regimens_admin: payload,
                loading: false,
            };

        case GET_REGIMENS_AUTOCOMPLETE:
            return {
                ...state,
                regimens_autocomplete: payload,
                loading_autocomplete: false,
            };

        case UPDATE_REGIMEN_LIKES:
            // payload has ID of liked regimen and the array of all its likes and dislikes
            return {
                ...state,
                regimens: state.regimens.map((regimen) =>
                    regimen._id === payload.id
                        ? {
                              ...regimen,
                              likes: payload.likes,
                              dislikes: payload.dislikes,
                          }
                        : regimen
                ),
                // if regimen is set (b/c viewing regimen details page), update it too if it is the liked/disliked regimen:
                regimen:
                    state.regimen && state.regimen._id === payload.id
                        ? {
                              ...state.regimen,
                              likes: payload.likes,
                              dislikes: payload.dislikes,
                          }
                        : state.regimen,
                loading: false,
            };

        case REGIMEN_ERROR:
            return {
                ...state,
                error: payload,
                loading: false,
                regimen: null,
                regimens: [],
            };

        case ADD_REGIMEN:
            return {
                ...state,
                regimen: payload,
                regimens: [payload, ...state.regimens], // sort descending by creation date
                regimens_autocomplete: [
                    ...state.regimens_autocomplete,
                    payload,
                ],
                loading: false,
            };

        case LOADING_REGIMENS /* no payload sent */:
            return {
                ...state,
                loading: true,
            };

        case LOADING_REGIMEN_PIC: // todo have loading for other subsections of pages like this
            return {
                ...state,
                uploading_regimen_pic: true,
            };

        case DONE_LOADING_REGIMEN_PIC: // todo have loading for other subsections of pages like this
            return {
                ...state,
                uploading_regimen_pic: false,
            };

        case UPDATE_REGIMEN_PIC:
            return {
                ...state,
                uploading_regimen_pic: false,
                regimen: { ...state.regimen, mainphoto: payload }, // payload is photo path
            };

        case LOADING_REGIMEN_STEP_PIC: // todo have loading for other subsections of pages like this
            return {
                ...state,
                uploading_regimen_step_pic: payload,
            };

        case DONE_LOADING_REGIMEN_STEP_PIC: // todo have loading for other subsections of pages like this
            return {
                ...state,
                uploading_regimen_step_pic: null,
            };

        case UPDATE_REGIMEN_STEP_PIC:
            return {
                ...state,
                uploading_regimen_step_pic: null,
                regimen: { ...state.regimen, steps: payload }, // payload is steps array @todo see if payload should be more specific
            };

        case DELETE_REGIMEN:
            return {
                ...state,
                regimens: state.regimens.filter(
                    (regimen) => regimen._id !== payload._id
                ),
                regimens_autocomplete: state.regimens_autocomplete.filter(
                    (regimen) => regimen._id !== payload._id
                ),
            };

        case LOGOUT: {
            return {
                ...state,
                regimens_admin: [], // don't store admin data when logged out
            };
        }

        default:
            return state;
    }
};

export default regimenReducer;
