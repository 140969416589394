import alert from './alert';
import auth from './auth';
import profile from './profile';
import regimen from './regimen';
import product from './product';
import ingredient from './ingredient';
import company from './company';
import admin from './admin';

// an object that stores any reducers we create
const rootReducer = {
    alert,
    auth,
    profile,
    regimen,
    product,
    ingredient,
    company,
    admin,
};
export default rootReducer;
