import React from 'react';
import { Link } from 'react-router-dom';

const AdminFormFooter = () => {
    return (
        <div className='admin-form-footer'>
            <div className='header-with-button'>
                <Link className='btn btn-light my-1' to='/admin'>
                    <i className={'fa fa-gears'} /> Admin Console
                </Link>
                <input
                    type='submit'
                    value='Save'
                    className='btn btn-primary my-1 mr-0'
                />
            </div>
        </div>
    );
};

export default AdminFormFooter;
