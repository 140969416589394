// since this is a form,  each input should be a piece of state, so we need useState hook
import React, { Fragment, useState, useEffect } from 'react';
import { Link, Navigate, useParams, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import { APP_NAME } from '../../../utils/constants';
import { createRegimen, getRegimenById } from '../../../actions/regimen';
import { getProducts } from '../../../actions/product';
import UploadRegimenPic from '../forms/UploadRegimenPic';
import Step from '../Step';
import Spinner from '../../layout/Spinner';

// Notes: fields use pattern "fieldname || initialState.fieldname" because certain fields may not be in database for user (namely, when models change). This will help when fetching data for regimen when editing it.
// set default state values that will appear in form
const initialState = {
    initialized: false, // used to see if regimen has been loaded into state
    name: '',
    description: '',
    ispublic: false,
    styletype: '',
    youtube: '',
    steps: [
        {
            stepid: null,
            details: '',
            productobjects: [],
            productnames: [],
            timeminutes: 0,
        },
    ],
    privatenotes: '',
};

const RegimenForm = ({
    // prop types
    regimen: { regimen, loading },
    createRegimen,
    getRegimenById, // to fill in fields for editing
    getProducts,
    product: { products_autocomplete, loading_autocomplete },
    auth,
}) => {
    let params = useParams();
    let history = useNavigate();

    // format: [state, function to update state]
    // Two states added: formData to store form stuff and step data
    // displaySocialInputs to determine whether to show or hide the social media section
    const [formData, setFormData] = useState(initialState);

    useEffect(() => {
        if (params.id) {
            getRegimenById(params.id, history); // if there's a regimen id in url, fetch it by updating the regimen state which will update the prop too
        }
    }, [params.id, history, getRegimenById]);

    useEffect(() => {
        if (!loading && regimen && params.id) {
            // update form fields with current regimen
            const regimenData = { ...initialState };
            for (const key in regimen) {
                if (key in regimenData) regimenData[key] = regimen[key];
            }
            // handle subsections of data model
            for (const key in regimen.steps) {
                if (key in regimenData.steps)
                    regimenData.steps[key] = regimen.steps[key];
            }

            regimenData.initialized = true;

            setFormData(regimenData);
        }
    }, [regimen, loading, params.id]);
    // this array at the end is a dependency list:
    // if present, the effect will only activate if the values in the list change:
    // if loading or done loading, if fetched current regimen, and if regimen is updated

    useEffect(() => {
        getProducts(true /* get autocomplete data */);
    }, [getProducts]);

    useEffect(() => {
        window.scrollTo(0, 0); // scroll to top of form on mount
    }, []);

    const {
        initialized,
        name,
        description,
        ispublic,
        styletype,
        youtube,
        steps,
        privatenotes,
    } = formData;

    const addStep = (e) => {
        e.preventDefault(); // don't submit form

        const steps = [...formData.steps]; // copy array because we don't want to mutate the orig

        steps.push({
            stepid: null, // set if step has been saved (and would thus have an ID field)
            details: '',
            productobjects: [],
            productnames: [],
            timeminutes: 0,
        });

        setFormData({ ...formData, steps: steps });
    };

    const deleteStep = (e, idx) => {
        e.preventDefault(); // don't submit form

        let steps = [...formData.steps]; // copy array because we don't want to mutate the orig
        if (steps.length === 1) return; // don't allow deletion of last one

        steps = steps.filter((_, i) => i !== idx); // remove that step

        setFormData({ ...formData, steps: steps });
    };

    const updateStep = (e, idx) => {
        e.stopPropagation();
        //console.log('updating step ' + idx);

        const steps = [...formData.steps]; // copy array because we don't want to mutate the orig

        if (
            e.target.name === 'productnames' ||
            e.target.name === 'productobjects'
        ) {
            const parsed = JSON.parse(e.target.value);
            steps[idx][e.target.name] = parsed;
            // for objects, this saves just the object id in db as desired
            // (other fields in obj are ignored)
        } else {
            // other step fields can be interpreted literally
            steps[idx][e.target.name] = e.target.value;
        }

        setFormData({ ...formData, steps: steps });
    };

    const onChange = (e) => {
        e.stopPropagation(); // to prevent lastpass bug from throwing error in console
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const onSubmit = (e) => {
        e.preventDefault();
        createRegimen(formData, history, params.id || undefined);
    };

    if (!auth.isAuthenticated) {
        return <Navigate to='/login' />;
    }

    return (
        <div className='containermiddle'>
            <Helmet>
                <title>
                    {APP_NAME} | {params.id ? 'Update' : 'Add'} Regimen
                    {regimen ? ': ' + regimen.name : ''}
                </title>
            </Helmet>
            {params.id && (!initialized || loading) ? (
                //{params.id && loading ? (
                <Spinner />
            ) : (
                <Fragment>
                    <div className='header-with-button'>
                        <h1 className='mb'>
                            {params.id ? 'Update' : 'Add'} Regimen
                        </h1>
                        {params.id && regimen ? (
                            <Link
                                to={`/regimen/${regimen._id}`}
                                className='btn btn-primary mr-0'
                            >
                                View Regimen
                            </Link>
                        ) : (
                            ''
                        )}
                    </div>

                    {params.id && initialized && <UploadRegimenPic />}
                    <p className='italicsmsg m0 pt-0'>* = required field</p>

                    <form className='form' onSubmit={onSubmit}>
                        <div className='form-group row-split-two'>
                            <div>
                                <p className='form-header'>* Name</p>
                                <input
                                    type='text'
                                    placeholder='Name your regimen'
                                    name='name'
                                    value={name || initialState.name}
                                    onChange={onChange}
                                    onKeyPress={(e) => {
                                        e.key === 'Enter' && e.preventDefault();
                                    }}
                                />
                            </div>
                            <div>
                                <p className='form-header'>* Hair Style</p>
                                <select
                                    name='styletype'
                                    value={styletype || initialState.styletype}
                                    onChange={onChange}
                                    onKeyPress={(e) => {
                                        e.key === 'Enter' && e.preventDefault();
                                    }}
                                >
                                    <option>Select a hair style</option>
                                    <option value='1'>Wash-and-go</option>
                                    <option value='2'>Twist-out</option>
                                    <option value='3'>Braid-out</option>
                                    <option value='6'>Bantu Knots</option>
                                    <option value='7'>
                                        Blow-Out/Silk Press
                                    </option>
                                    <option value='4'>Bun up-do</option>
                                    <option value='5'>
                                        Extensions (braids, twists, faux locs)
                                    </option>
                                    <option value='0'>Other</option>
                                </select>
                            </div>
                        </div>

                        <div className='form-group'>
                            <p className='form-header'>* Description</p>
                            <textarea
                                placeholder='Describe your regimen'
                                name='description'
                                value={description || initialState.description}
                                onChange={onChange}
                            />
                        </div>

                        <div className='form-group'>
                            <p className='form-header'>Personal Notes</p>
                            <textarea
                                placeholder='Add private notes your regimen'
                                name='privatenotes'
                                value={
                                    privatenotes || initialState.privatenotes
                                }
                                onChange={onChange}
                            />
                        </div>

                        <div className='form-group'>
                            <p className='form-header'>YouTube URL</p>
                            <p className='small'>
                                Add a video of your regimen if you have one!
                            </p>
                        </div>
                        <div className='form-group social-input'>
                            <i className='fab fa-youtube fa-2x' />
                            <input
                                type='text'
                                placeholder='YouTube URL'
                                name='youtube'
                                value={youtube || initialState.youtube}
                                onChange={onChange}
                                onKeyPress={(e) => {
                                    e.key === 'Enter' && e.preventDefault();
                                }}
                            />
                        </div>
                        <div className='form-group'>
                            <hr />
                            <h2 className='form-header pb'>REGIMEN STEPS</h2>
                            {loading_autocomplete ? (
                                // wait for ingredient suggestions to load
                                <Spinner size={0} />
                            ) : (
                                <Fragment>
                                    {steps.map((step, idx) => {
                                        return (
                                            <Step
                                                key={'step-' + idx}
                                                details={step.details || ''}
                                                regimen={regimen}
                                                stepid={
                                                    (step._id &&
                                                        step._id.toString()) ||
                                                    null
                                                }
                                                photo={step.photo || null}
                                                video={step.video || null}
                                                productobjects={
                                                    step.productobjects || []
                                                }
                                                productnames={
                                                    step.productnames || []
                                                }
                                                products_autocomplete={
                                                    products_autocomplete
                                                }
                                                timeminutes={
                                                    step.timeminutes || 0
                                                }
                                                onChange={(e) =>
                                                    updateStep(e, idx)
                                                }
                                                onClick={(e) =>
                                                    deleteStep(e, idx)
                                                }
                                                numSteps={steps.length}
                                                stepNum={idx}
                                            />
                                        );
                                    })}
                                    <button
                                        className='btn btn-light my-1'
                                        onClick={(e) => addStep(e)}
                                    >
                                        Add another step
                                    </button>
                                </Fragment>
                            )}
                        </div>

                        <div className='form-group'>
                            <span>
                                Make this a public regimen{' '}
                                <input
                                    type='checkbox'
                                    name='ispublic'
                                    value={ispublic || initialState.ispublic}
                                    checked={ispublic || initialState.ispublic}
                                    onChange={() => {
                                        setFormData({
                                            ...formData,
                                            ispublic: !ispublic,
                                        });
                                    }}
                                    onKeyPress={(e) => {
                                        e.key === 'Enter' && e.preventDefault();
                                    }}
                                />
                            </span>
                        </div>

                        <div className='admin-form-footer'>
                            <Link
                                className='btn btn-light my-1'
                                to='/dashboard'
                            >
                                <i className={'fa fa-arrow-left'} /> Back To
                                Dashboard
                            </Link>
                            <input
                                type='submit'
                                value='Save'
                                className='btn btn-primary my-1 mr-0'
                            />
                        </div>
                    </form>
                </Fragment>
            )}
        </div>
    );
};

RegimenForm.propTypes = {
    getProducts: PropTypes.func.isRequired,
    product: PropTypes.object.isRequired,
    auth: PropTypes.object.isRequired,
    createRegimen: PropTypes.func.isRequired,
    getRegimenById: PropTypes.func.isRequired,
    regimen: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    regimen: state.regimen,
    product: state.product,
    auth: state.auth,
});

export default connect(mapStateToProps, {
    createRegimen,
    getRegimenById,
    getProducts,
})(RegimenForm);
