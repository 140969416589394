import React, { Fragment, useEffect, useCallback } from 'react';
import { Link, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import { APP_NAME } from '../../utils/constants';
import Spinner from '../layout/Spinner';
import Searchbar from '../layout/Searchbar';
import Pagination from '../layout/Pagination';
import ProductsFilters from './ProductsFilters';
import ProductBox from './ProductBox';
import { getProducts, updateProductPageNumber } from '../../actions/product';
import { URLhelpers } from '../../utils/helpers';

const Products = ({
    getProducts,
    updateProductPageNumber,
    product: {
        products_searched,
        product_query_str,
        show_page_num,
        loading,
        loading_searched_products,
    },
    auth,
}) => {
    let location = useLocation();

    const getURLParam = useCallback(
        (param) => {
            return URLhelpers.getURLParam(param, location);
        },
        [location]
    );

    // this sometimes renders an empty product list...
    useEffect(() => {
        getProducts();
    }, [getProducts]);

    useEffect(() => {
        window.scrollTo(0, 0); // scroll to top of form on mount
    }, []);

    const getTitle = () => {
        let start = products_searched.length
            ? products_searched.length.toString() + ' '
            : '0 ';
        let middle = URLhelpers.dataIsFiltered(product_query_str)
            ? 'Matching '
            : '';
        let end = 'Product' + (products_searched.length === 1 ? '' : 's');
        return start + middle + end;
    };

    return (
        <div className='containermiddle'>
            <Helmet>
                <title>{APP_NAME} | Products</title>
            </Helmet>
            {loading || loading_searched_products ? (
                <Spinner />
            ) : (
                <Fragment>
                    <div className='sticky-header-wrapper'>
                        <div className='header-with-button'>
                            <h1 className='mb-0'>{getTitle()}</h1>
                            {auth.isAuthenticated && auth.isAdmin ? (
                                <Link
                                    to={'/admin/addproduct'}
                                    className='btn btn-primary mr-0'
                                >
                                    <i className='fas fa-plus text-white' />{' '}
                                    <span className='hide-on-mobile'>
                                        Add Product
                                    </span>
                                </Link>
                            ) : (
                                ''
                            )}
                        </div>
                        <Searchbar searchParam='ss' searchName='products' />
                    </div>
                    <div className='data-container'>
                        <ProductsFilters />

                        {products_searched.length ? (
                            <div>
                                {/* this div is needed for css */}
                                <Pagination
                                    data={products_searched}
                                    startOnPage={show_page_num}
                                    onChangeHandler={(num) => {
                                        updateProductPageNumber(num);
                                    }}
                                    RenderComponent={ProductBox}
                                    wrapperClass='products-wrapper'
                                    title={getTitle()}
                                    pageLimit={5}
                                    dataLimit={20}
                                />
                            </div>
                        ) : URLhelpers.dataIsFiltered(product_query_str) ||
                          getURLParam('ss', location).length ? (
                            <div>
                                <h2 className='products_start'>
                                    No products match your criteria.
                                </h2>
                            </div>
                        ) : (
                            <div>
                                <h2 className='products_start'>
                                    Start your product search
                                </h2>
                            </div>
                        )}
                    </div>
                </Fragment>
            )}
        </div>
    );
};

Products.propTypes = {
    getProducts: PropTypes.func.isRequired,
    updateProductPageNumber: PropTypes.func.isRequired,
    product: PropTypes.object.isRequired,
    auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    product: state.product,
    auth: state.auth,
});

export default connect(mapStateToProps, {
    getProducts,
    updateProductPageNumber,
})(Products);
