// Boilerplate store code
import { configureStore } from '@reduxjs/toolkit';
import rootReducer from './reducers'; // the root (base) reducer
import setAuthToken from './utils/setAuthToken';

const initialState = {}; // all initial state will be in the reducer

// comes with the redux browser devtools and thunk middleware already configured
const store = configureStore({
    reducer: rootReducer,
    initialState,
});

// set up a store subscription listener
// to store the users token in localStorage

// initialize current state from redux store for subscription comparison
// preventing undefined error
let currentState = store.getState();

// Redux provides us with a `store.subscribe` listener that runs every time a state change occurs.
store.subscribe(() => {
    // keep track of the previous and current state to compare changes
    let previousState = currentState;
    currentState = store.getState();
    // if the token changes set the value in localStorage and axios headers
    if (previousState.auth.token !== currentState.auth.token) {
        const token = currentState.auth.token;
        setAuthToken(token);
    }
});

export default store;
