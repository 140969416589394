import React, { Fragment, useEffect } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import { APP_NAME } from '../../utils/constants';
import Spinner from '../layout/Spinner';
import { getProfiles, getCurrentProfile } from '../../actions/profile';
import { getUserPic } from '../../utils/helpers';
const helpers = require('./helpers/helpers.js');

const Profiles = ({
    getProfiles,
    profile: { profiles, loading_profiles },
    auth: { loading, isAuthenticated, user },
}) => {
    useEffect(() => {
        getProfiles();
    }, [getProfiles]); // run it just once

    useEffect(() => {
        window.scrollTo(0, 0); // scroll to top of form on mount
    }, []);

    return (
        <div className='containermiddle'>
            <Helmet>
                <title>{APP_NAME} | Members</title>
            </Helmet>
            {loading || loading_profiles ? (
                <Spinner />
            ) : (
                <Fragment>
                    <h1>
                        {!loading_profiles && profiles && profiles.length}{' '}
                        Members
                    </h1>
                    <p className='lead'>
                        <i className='fas fa-users' /> Browse and connect with
                        other members
                    </p>
                    {isAuthenticated && !user.profile && (
                        <Fragment>
                            <p>
                                Create your profile to be listed on this page!{' '}
                                <Link
                                    to='/createprofile'
                                    className='btn btn-primary my-1'
                                >
                                    Create Profile
                                </Link>
                            </p>
                            <hr />
                        </Fragment>
                    )}
                    <div className='profiles'>
                        {profiles && profiles.length > 0 ? (
                            profiles.map((profile) => (
                                <div
                                    className='profile'
                                    key={profile.user.username}
                                >
                                    <img
                                        src={getUserPic(profile.user.avatar)}
                                        alt=''
                                        className='round-img'
                                    />
                                    <div>
                                        <h2>{profile.user.username}</h2>
                                        <p className='my'>
                                            {profile.location && (
                                                <span>{profile.location}</span>
                                            )}
                                        </p>
                                        <Link
                                            to={`/profile/${profile.user.username_lower}`}
                                            className='btn btn-primary'
                                        >
                                            View Profile
                                        </Link>
                                    </div>
                                    <ul>
                                        <li>
                                            <strong>Type:</strong>{' '}
                                            {helpers.parseHairTypes(
                                                profile.hairproperties
                                                    .hairtypeprimary,
                                                profile.hairproperties
                                                    .hairtypesecondary
                                            )}
                                        </li>
                                        <li>
                                            <strong>Porosity: </strong>
                                            {helpers.parsePorosity(
                                                profile.hairproperties.porosity
                                            )}
                                        </li>
                                        <li>
                                            <strong>Length: </strong>
                                            {helpers.parseHairLength(
                                                profile.hairproperties.length
                                            )}
                                        </li>
                                        <li>
                                            <strong>Density: </strong>
                                            {helpers.parseHairDensity(
                                                profile.hairproperties.density
                                            )}
                                        </li>
                                        <li>
                                            <strong>Strand Type: </strong>
                                            {helpers.parseHairStrandType(
                                                profile.hairproperties
                                                    .strandtype
                                            )}
                                        </li>
                                        <li>
                                            <strong>Scalp: </strong>
                                            {helpers.parseScalpHealth(
                                                profile.hairproperties
                                                    .scalphealth
                                            )}
                                            {profile.hairproperties
                                                .hassensitivescalp &&
                                                ', sensitive '}
                                        </li>
                                    </ul>
                                </div>
                            ))
                        ) : (
                            <h4>No profiles found.</h4>
                        )}
                    </div>
                </Fragment>
            )}
        </div>
    );
};

Profiles.propTypes = {
    getProfiles: PropTypes.func.isRequired,
    getCurrentProfile: PropTypes.func.isRequired,
    profile: PropTypes.object.isRequired,
    auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    profile: state.profile,
    auth: state.auth,
});

export default connect(mapStateToProps, { getProfiles, getCurrentProfile })(
    Profiles
);
