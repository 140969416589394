import React from 'react';
import PropTypes from 'prop-types';

// sees if a source string matches url regex; if so, returns a tag for it
const SourceItem = ({ src, srckey }) => {
    const URL_REGEX = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/;

    let result = src.split(' ').map((part) =>
        URL_REGEX.test(part) ? (
            <a
                href={part}
                target='_blank'
                rel='noopener noreferrer'
                key={srckey}
            >
                {part}
            </a>
        ) : (
            part + ' '
        )
    );

    return result;
    // return URL_REGEX.test(src) ? (
    //     <a href={src} target='_blank' rel='noopener noreferrer'>
    //         {src}{' '}
    //     </a>
    // ) : (
    //     <span>{src}</span>
    // );
};

SourceItem.propTypes = {
    src: PropTypes.string.isRequired,
    srckey: PropTypes.string.isRequired,
};

export default SourceItem;
