// View All Companies
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import { APP_NAME } from '../../utils/constants';
import { getRegimensAdmin } from '../../actions/regimen';
import AdminNav from './AdminNav';
import RegimenItem from '../regimens/RegimenItem';
import Spinner from '../layout/Spinner';

const RegimensAdmin = ({
    getRegimensAdmin,
    regimen: { regimens_admin, loading },
    auth, // loading obj from Auth reducer
}) => {
    useEffect(() => {
        if (!auth.loading) getRegimensAdmin();
    }, [auth.loading, getRegimensAdmin]);

    // useEffect(() => {
    //     window.scrollTo(0, 0); // scroll to top of form on mount
    // }, []);

    return (
        <div className='containermiddle'>
            <Helmet>
                <title>{APP_NAME} | Regimens</title>
            </Helmet>
            <AdminNav isSuperAdmin={auth.isSuperAdmin} />
            <div className='header-with-button'>
                <h2>
                    {!loading && regimens_admin && regimens_admin.length}{' '}
                    Regimens
                </h2>
            </div>

            {auth.loading || loading ? (
                <Spinner />
            ) : (
                <div className='regimens-container'>
                    {regimens_admin.map((regimen) => (
                        <RegimenItem regimen={regimen} key={regimen._id} />
                    ))}
                </div>
            )}
        </div>
    );
};

RegimensAdmin.propTypes = {
    getRegimensAdmin: PropTypes.func.isRequired,
    regimen: PropTypes.object.isRequired,
    auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    regimen: state.regimen,
    auth: state.auth,
});

export default connect(mapStateToProps, { getRegimensAdmin })(RegimensAdmin);
