import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useLocation, useNavigate } from 'react-router-dom';
import { URLhelpers } from '../../utils/helpers';

// Search bar component
function Searchbar({
    searchParam, // param name used to identify this search query
    searchName, // to identify name field
    autoSearch,
}) {
    let history = useNavigate();
    let location = useLocation();

    // the [] specified at the end (2nd arg) lets it run only once when app is mounted
    // This behaves like componentDidMount
    useEffect(() => {
        const handleScroll2 = () => {
            setshowScrollLine(window.pageYOffset > 30);
        };

        window.addEventListener('scroll', handleScroll2);

        return () => {
            window.removeEventListener('scroll', handleScroll2); // acts like componentWillUnmount
        };
    }, []);

    // Declare a new state variable, which we'll call "showScrollLine"
    const [showScrollLine, setshowScrollLine] = useState(false);
    const [searchText, setSearchText] = useState('');

    useEffect(() => {
        let ss = URLhelpers.getURLParam(searchParam, location);
        setSearchText(ss);
    }, [location, searchParam]);

    const onChangeHandler = (str) => {
        var o = { pg: 1 };
        o[searchParam] = str;
        URLhelpers.setURLparam(history, location, o);
    };

    const clearField = () => {
        setSearchText('');
        onChangeHandler('');
        let input = document.querySelector('#searchbar-' + searchName);
        input.focus();
    };

    // updates searchText as user types
    const prepForSearch = (e) => {
        let str = e.target.value;
        setSearchText(str);
        // process changed text, even if user manually clears field with delete button so text update can happen in caller
        if (autoSearch || str === '') onChangeHandler(str);
    };

    return (
        <div
            className={
                (autoSearch ? 'mt' : 'search-wrapper mt') +
                (showScrollLine ? ' border-bottom' : '')
            }
        >
            <div className='searchbar-wrapper'>
                <div className='search-icon'>
                    <i className='fas fa-search' />
                </div>
                <input
                    type='text'
                    autoComplete='off'
                    className='searchbar'
                    id={'searchbar-' + searchName}
                    onChange={(e) => {
                        prepForSearch(e);
                    }}
                    onKeyPress={(e) => {
                        e.key === 'Enter' &&
                            !autoSearch &&
                            onChangeHandler(searchText);
                    }}
                    value={searchText}
                    name={searchName}
                    placeholder={'Search ' + searchName}
                />
                {searchText.length > 0 ? (
                    <button
                        type='button'
                        className='btn-icon'
                        onClick={(_e) => {
                            clearField();
                        }}
                    >
                        <i className='far fa-times-circle' />
                    </button>
                ) : (
                    <div></div>
                    // above empty div is so grid column css specs are still valid
                )}
            </div>
            {!autoSearch && (
                <button
                    type='button'
                    className='btn btn-tertiary mr-0'
                    onClick={() => onChangeHandler(searchText)}
                >
                    Search
                </button>
            )}
        </div>
    );
}

Searchbar.propTypes = {
    searchParam: PropTypes.string.isRequired, // param to identify this search query use
    searchName: PropTypes.string.isRequired, // name used to identify input field
    autoSearch: PropTypes.bool,
};

Searchbar.defaultProps = {
    searchParam: 'ss',
    autoSearch: false,
};

export default Searchbar;
