import React, { useEffect, Fragment } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import Markdown from 'markdown-to-jsx';
import PropTypes from 'prop-types';
import Spinner from '../layout/Spinner';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import { APP_NAME } from '../../utils/constants';
import { getIngredientByURLparam } from '../../actions/ingredient';
import PropertyTags from './PropertyTags';
import Tag from '../layout/Tag';
import SourceItem from '../layout/SourceItem';
const helpers = require('./helpers/helpers.js');

const Ingredient = ({
    ingredient: { ingredient, loading },
    getIngredientByURLparam,
    auth: { isAdmin, isAuthenticated },
}) => {
    let params = useParams();
    let history = useNavigate();

    useEffect(() => {
        getIngredientByURLparam(params.id, history);
    }, [getIngredientByURLparam, params.id, history]);

    useEffect(() => {
        window.scrollTo(0, 0); // scroll to top of form on mount
    }, []);

    return (
        <div className='containermiddle'>
            <Helmet>
                <title>
                    {APP_NAME} | Ingredient
                    {ingredient ? ': ' + ingredient.name : ''}
                </title>
            </Helmet>
            {loading || ingredient === null ? (
                <Spinner />
            ) : (
                <Fragment>
                    <div className='header-with-button'>
                        <h1 className='mb'>Ingredient Details</h1>
                        {isAuthenticated && isAdmin ? (
                            <Link
                                to={`/admin/editingredient/${ingredient._id}`}
                                className='btn btn-primary mr-0'
                            >
                                <i className='fas fa-edit text-white' /> Edit
                                Ingredient
                            </Link>
                        ) : (
                            ''
                        )}
                    </div>
                    <h2 className='mb-0'>{ingredient.name}</h2>

                    {isAuthenticated && isAdmin && ingredient.needsreview && (
                        <p className='mt'>
                            {' '}
                            <span className='small allcaps rating-poor'>
                                <i className='fas fa-exclamation-circle' />{' '}
                                Needs Review
                            </span>
                        </p>
                    )}

                    {ingredient.rating && (
                        <span
                            className={
                                'block rating mt' +
                                helpers.getRatingClass(ingredient.rating)
                            }
                        >
                            {ingredient.rating}
                        </span>
                    )}

                    <div className='mb'>
                        <PropertyTags ingredient={ingredient} />
                    </div>

                    {ingredient.synonyms && ingredient.synonyms.length > 0 && (
                        <Fragment>
                            <span className='inlineblock allcaps small'>
                                Also called
                            </span>
                            {'  '}
                            {ingredient.synonyms.map((syn, idx) => (
                                <span className='synonym' key={'syn-' + idx}>
                                    {syn}
                                </span>
                            ))}
                        </Fragment>
                    )}

                    <div className='mb'>
                        <p className='small allcaps'>
                            CAS Numbers:{' '}
                            {ingredient.casnumber.length > 0
                                ? ingredient.casnumber.map((cas, idx) => (
                                      <span key={idx}>
                                          {cas.trim()}
                                          {idx + 1 <
                                              ingredient.casnumber.length &&
                                              ' | '}
                                      </span>
                                  ))
                                : 'none found'}
                        </p>
                    </div>

                    {ingredient.usageratemin && ingredient.usageratemax && (
                        <div className='mb'>
                            <p className='small allcaps'>
                                Recommended usage rate in products:{' '}
                                <span>
                                    {ingredient.usageratemin ===
                                    ingredient.usageratemax
                                        ? ''
                                        : ingredient.usageratemin > 0
                                        ? ingredient.usageratemin + '-'
                                        : 'Up to '}
                                    {ingredient.usageratemax}%
                                </span>
                            </p>
                        </div>
                    )}
                    <div>
                        {/* this is here to ensure that the span for each desc part doesn't have padding at bottom */}
                        {ingredient.description &&
                            ingredient.description.map((desc, idx) => (
                                <span
                                    className='inlineblock-btmpad'
                                    key={'desc-' + idx}
                                >
                                    <Markdown>{desc}</Markdown>
                                </span>
                            ))}
                    </div>
                    {ingredient.sources && ingredient.sources.length > 0 && (
                        <Fragment>
                            <hr className='my-1' />
                            <h4 className='mb'>Sources</h4>

                            {ingredient.sources.map((source, idx) => (
                                <p
                                    className='ing-source'
                                    key={'sourcep-' + idx}
                                >
                                    <SourceItem
                                        src={source}
                                        srckey={'source-' + idx}
                                    />
                                </p>
                            ))}
                        </Fragment>
                    )}

                    {ingredient.contains && ingredient.contains.length > 0 && (
                        <Fragment>
                            <hr className='my-1' />
                            <span className='inlineblock allcaps small mt'>
                                Contains
                            </span>{' '}
                            {ingredient.contains.map((ing, idx) => (
                                <Tag
                                    name={ing.name}
                                    slug={'/ingredient/' + ing.urlparam}
                                    key={'c-ing-' + idx}
                                />
                            ))}
                        </Fragment>
                    )}

                    {ingredient.mightcontain &&
                        ingredient.mightcontain.length > 0 && (
                            <Fragment>
                                <hr className='my-1' />
                                <span className='inlineblock allcaps small mt'>
                                    May Contain
                                </span>{' '}
                                {ingredient.mightcontain.map((ing, idx) => (
                                    <Tag
                                        name={ing.name}
                                        slug={'/ingredient/' + ing.urlparam}
                                        key={'mc-ing-' + idx}
                                    />
                                ))}
                            </Fragment>
                        )}

                    {ingredient.relatedto && ingredient.relatedto.length > 0 && (
                        <Fragment>
                            <hr className='my-1' />
                            <span className='inlineblock allcaps small mt'>
                                Also see
                            </span>{' '}
                            {ingredient.relatedto.map((ing, idx) => (
                                <Tag
                                    name={ing.name}
                                    slug={'/ingredient/' + ing.urlparam}
                                    key={'rel-ing-' + idx}
                                />
                            ))}
                        </Fragment>
                    )}

                    <hr className='my-1' />
                    <p className='mb'>
                        <Link to={`/products?ingredients=${ingredient._id}`}>
                            View Products With This Ingredient
                        </Link>
                    </p>
                </Fragment>
            )}
        </div>
    );
};

Ingredient.propTypes = {
    ingredient: PropTypes.object.isRequired,
    getIngredientByURLparam: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
    ingredient: state.ingredient,
    auth: state.auth,
});

export default connect(mapStateToProps, {
    getIngredientByURLparam,
})(Ingredient);
