import React from 'react';
import { Helmet } from 'react-helmet-async';
import { APP_NAME } from '../../utils/constants';

const NotFound = () => {
    return (
        <div className='containermiddle center'>
            <Helmet>
                <title>{APP_NAME} | Page Not Found</title>
            </Helmet>
            <h1 className='mb-1'>
                <i className='fas fa-exclamation-triangle' /> Page Not Found
            </h1>
            <h2>Sorry, this page does not exist</h2>
        </div>
    );
};

export default NotFound;
