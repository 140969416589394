import React, { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
// import { Route } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { removeAllNonStickyAlerts } from '../../actions/alert';

const DefaultRoute = ({ removeAllNonStickyAlerts }) => {
    // trigger on component mount
    useEffect(() => {
        removeAllNonStickyAlerts();
    }, [removeAllNonStickyAlerts]);

    return <Outlet />;
};

DefaultRoute.propTypes = {
    removeAllNonStickyAlerts: PropTypes.func.isRequired,
};

export default connect(null, { removeAllNonStickyAlerts })(DefaultRoute);
