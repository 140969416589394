// since this is a form,  each input should be a piece of state, so we need useState hook
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Spinner from '../../layout/Spinner';
import FileUpload, { ImgTypeEnum } from '../../layout/FileUpload';
import { getProductPic } from '../../../utils/helpers';

const UploadProductPic = ({
    // prop types
    product: { product, loading_product, uploading_product_pic },
}) => {
    const [getPhoto, setPhoto] = useState('');

    useEffect(() => {
        if (!loading_product && product) {
            // add timestamp to force re-render of image
            // in cases where file name stays same but photo is replaced
            let nameToUse =
                product.mainphoto && product.mainphoto.length
                    ? `${product.mainphoto}?${Date.now().toString()}`
                    : ''; // only add timestamp to end of actual paths
            setPhoto(nameToUse);
        }
    }, [loading_product, product]);

    return loading_product || !product ? (
        <Spinner />
    ) : (
        <div className='upload-box'>
            <p className='form-header'>Change Main Product Photo</p>
            {getPhoto && (
                <img
                    className='round-img'
                    src={getProductPic(product.mainphoto)}
                    alt={product.name}
                />
            )}
            <small className='form-text mb'>A square photo works best.</small>
            {uploading_product_pic ? (
                <Spinner />
            ) : (
                <FileUpload
                    formID='mainproductpic'
                    uploadType={ImgTypeEnum.PRODUCT}
                    id={product._id}
                />
            )}
        </div>
    );
};

UploadProductPic.propTypes = {
    product: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    product: state.product,
});

export default connect(mapStateToProps)(UploadProductPic);
