export const getRatingClass = (rating) => {
    switch (rating) {
        case 'Great':
            return ' rating-great';

        case 'Good':
            return ' rating-good';

        case 'Average':
            return ' rating-average';

        case 'Poor':
            return ' rating-poor';

        default:
            return '';
    }
};

export const IngredientPropertiesMap = {
    isdepilatory: 'Depilatory',
    issensitizer: 'Sensitizer',
    isbadalcohol: 'Drying Alcohol',
    isparaben: 'Paraben',
    issilicone: 'Silicone',
    issulfate: 'Sulfate',
    isfragrance: 'Fragrance',
    isoil: 'Oil',
    isreducingagent: 'Reducing Agent',
    isorganic: 'Found In Nature',
    iscleansingagent: 'Cleanser',
    isgeneralsurfactant: 'Surfactant',
    ismoisturizer: 'Moisturizer',
    isantistatic: 'Anti-Static',
    isgeneralconditioner: 'Conditioner',
    isslipagent: 'Provides Slip',
    isskinsoothing: 'Skin Soother',
    ischelant: 'Chelant',
    isantimicrobial: 'Antimicrobial',
    isantiinflammatory: 'Anti-inflammatory',
    isantioxidant: 'Antioxidant',
    isantidandruff: 'Anti-Dandruff',
    isprotein: 'Protein',
    containsprotein: 'Contains Protein',
    isemulsifier: 'Emulsifier',
    isfoambooster: 'Foam Booster',
    issolvent: 'Solvent',
    issynthetic: 'Synthetic',
    isaminoacid: 'Amino Acid(s)',
    ispreservative: 'Preservative',
    isabsorbent: 'Absorbent',
    isadsorbent: 'Adsorbent',
    ishumectant: 'Humectant',
    isantihumectant: 'Antihumectant',
    isthickener: 'Product Thickener',
    isfilmforming: 'Film-Forming',
    isexfoliant: 'Exfoliant',
    isphadjuster: 'pH Balancer',
    isfixative: 'Fixative',
    issunscreen: 'Sunscreen',
    isoxidizer: 'Oxidizer',
    iscolorant: 'Colorant',
    ishaircolorant: 'Hair Colorant',
    ispropellant: 'Propellant',
};
