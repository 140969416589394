import React, { Fragment, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import { APP_NAME } from '../../utils/constants';
import { getRegimensByUsername } from '../../actions/regimen';
import RegimenItem from '../../components/regimens/RegimenItem';
import Spinner from '../layout/Spinner';
import ProfileNav from './ProfileNav';

const ProfileRegimens = ({
    getRegimensByUsername,
    regimen, // regimens, loading
    auth,
}) => {
    let params = useParams();

    useEffect(() => {
        getRegimensByUsername(params.id);
    }, [getRegimensByUsername, params.id]);

    useEffect(() => {
        window.scrollTo(0, 0); // scroll to top of form on mount
    }, []);

    return (
        <Fragment>
            <div className='containermiddle'>
                <Helmet>
                    <title>
                        {APP_NAME} |{' '}
                        {!regimen.loading && regimen.regimens && params.id
                            ? params.id + "'s Regimens"
                            : 'Member Regimens'}
                    </title>
                </Helmet>
                {
                    // if loading fresh regimen list OR if loading new list over a previously loaded one:
                    regimen.loading || !regimen.regimens ? (
                        <Spinner />
                    ) : (
                        <Fragment>
                            <ProfileNav
                                auth={auth}
                                username={params.id}
                                id={null}
                            />

                            {regimen.loading ? (
                                <Spinner />
                            ) : (
                                <div className='regimens-container'>
                                    {regimen.regimens.length ? (
                                        regimen.regimens.map((regimen) => (
                                            <RegimenItem
                                                regimen={regimen}
                                                key={regimen._id}
                                            />
                                        ))
                                    ) : (
                                        <h2>No regimens yet.</h2>
                                    )}
                                </div>
                            )}
                        </Fragment>
                    )
                }
            </div>
        </Fragment>
    );
};

ProfileRegimens.propTypes = {
    getRegimensByUsername: PropTypes.func.isRequired,
    regimen: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    regimen: state.regimen,
    auth: state.auth,
});

export default connect(mapStateToProps, {
    getRegimensByUsername,
})(ProfileRegimens);
