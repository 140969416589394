import api from '../utils/api';
import { USERS_LOADED, ADMIN_ERROR } from '../actions/types';
import actionErrorHandler from './helpers/actionErrorHandler';

// Load Users
export const getAllUsers = () => async (dispatch) => {
    try {
        const res = await api.get('/users');

        let users = res.data; // users with all fields except password

        dispatch({
            type: USERS_LOADED,
            payload: users, // payload is the user
        });
    } catch (err) {
        actionErrorHandler(dispatch, err, ADMIN_ERROR);
    }
};
