import React, { Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import Spinner from '../layout/Spinner';
import RegimenTile from './RegimenTile';
import { getRegimens } from '../../actions/regimen';
import { sampleSize } from 'lodash';

const numToDisplay = 4;

const RegimensSample = ({ getRegimens, regimen: { regimens, loading } }) => {
    useEffect(() => {
        if (regimens.length === 0) getRegimens();
        // only get regimens if count changes
    }, [regimens.length, getRegimens]);

    const getRandomRegimens = (regimens) => {
        let filteredRegimens = regimens.filter((regimen) => {
            return regimen.ispublic;
        });

        let data = sampleSize(filteredRegimens, numToDisplay);
        return data;
    };

    // const getNewlyAddedRegimens = (regimens) => {
    //     let filteredRegimens = regimens
    //         .filter((regimen) => {
    //             return !regimen.isdraft;
    //         })
    //         .sort((a, b) => {
    //             let fa = new Date(a.created);
    //             let fb = new Date(b.created);
    //             return fb - fa;
    //         });

    //     let data = filteredRegimens.slice(0, numToDisplay);
    //     return data;
    // };

    return loading ? (
        <Spinner />
    ) : (
        <Fragment>
            <div className='header-with-button mt-2'>
                <h2 className=''>Explore Regimens</h2>
                <Link className='btn btn-primary mr-0' to='/regimens'>
                    View All <i className={'fa fa-arrow-right'} />
                </Link>
            </div>
            <div className='regimens-wrapper mt'>
                {getRandomRegimens(regimens).map((regimen) => (
                    <RegimenTile regimen={regimen} key={regimen.name} />
                ))}
            </div>

            {/* <h2>Recently Added Regimens</h2>
            <div className='regimens-wrapper mt'>
                {getNewlyAddedRegimens(regimens).map((regimen) => (
                    <RegimenTile regimen={regimen} key={regimen.name} />
                ))}
            </div> */}
        </Fragment>
    );
};

RegimensSample.propTypes = {
    getRegimens: PropTypes.func.isRequired,
    regimen: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    regimen: state.regimen,
});

export default connect(mapStateToProps, {
    getRegimens,
})(RegimensSample);
