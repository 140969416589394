import {
    SET_ALERT,
    REMOVE_ALERT,
    REMOVE_ALL_ALERTS,
    REMOVE_ALL_NONSTICKY_ALERTS,
} from './types';
import uuid from 'uuid-random';

// actions have a type and a payload. Actions dispatch type and payload to the reducer,
// and the reducer updates the state.
// => (dispatch) allows us to send out more than one type of alert (possible b/c of thunk middleware)
// The following will send the payload and type to the reducer.
// setAlert: sends an action with alert payload to reducer.
// persistOnRouteChange: whether it remains on route changes.
// timesOut: whether it should disappear after a few seconds or persist
// removeAllOthers: whether to clear other alerts before showing this one
export const setAlert = (
    msg,
    alertType = 'primary', // danger, success, light, dark, white, highlight, primary, secondary. maps to css class 'alert-{type}'
    persistOnRouteChange = false,
    removeAllOthers = false,
    timesOut = true,
    isCloseable = true
) => (dispatch) => {
    const timeout = 5000;
    const id = uuid(); // randomly generated ID

    if (removeAllOthers) dispatch(removeAllAlerts());

    // Dispatch an action to the reducer. This is the only way to trigger a state change.
    dispatch({
        type: SET_ALERT,
        payload: {
            msg,
            alertType,
            id,
            persistOnRouteChange,
            timesOut,
            isCloseable,
        },
    });

    if (timesOut) setTimeout(() => dispatch(removeAlert(id)), timeout);
};

// removeAlert: sends action with alert id to reducer for alert removal
export const removeAlert = (id) => (dispatch) => {
    dispatch({ type: REMOVE_ALERT, payload: id });
};

export const removeAllAlerts = () => (dispatch) => {
    dispatch({ type: REMOVE_ALL_ALERTS });
};

export const removeAllNonStickyAlerts = () => (dispatch) => {
    dispatch({ type: REMOVE_ALL_NONSTICKY_ALERTS });
};
