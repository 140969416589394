import React, { Fragment, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import { APP_NAME } from '../../utils/constants';
import PropTypes from 'prop-types';
import ProductsSample from '../products/ProductsSample';
import RegimensSample from '../regimens/RegimensSample';
import ImageSlider from '../layout/ImageSlider';
import hairPic1 from '../../img/hairmodel4c.jpg';
import hairPic2 from '../../img/hairmodel3b.jpg';
import hairPic3 from '../../img/hairmodel2c.png';
import hairPic4 from '../../img/hairmodel3a.png';
import hairPic5 from '../../img/hairmodel1.png';

const Landing = ({ auth: { isAuthenticated, loading } }) => {
    useEffect(() => {
        window.scrollTo(0, 0); // scroll to top of form on mount
    }, []);

    return (
        <Fragment>
            <Helmet>
                <title>{APP_NAME} | Home</title>
            </Helmet>
            <section className='landing shadow'>
                <div id='landing-overlay-main'>
                    <div className='landing-inner'>
                        <div className='landing-left'>
                            <h1 className='x-large title'>
                                {APP_NAME}
                                <span className='landing-trademark'>
                                    &#174;
                                </span>
                            </h1>
                            <h3 className='intro-tagline mb'>
                                Back to your roots with fabulous curls
                            </h3>
                            <p className='intro'>
                                Own your curly haircare, from understanding
                                product ingredients to finding the best products
                                for your hair. Create and browse hair regimens,
                                track your curl journey, and connect with people
                                with hair just like yours.
                            </p>

                            {!loading && !isAuthenticated && (
                                <div className='landing-buttons'>
                                    <Link
                                        to='/register'
                                        className='btn btn-highlight btn-large'
                                    >
                                        Sign Up
                                    </Link>
                                    <Link
                                        to='/login'
                                        className='btn btn-primary btn-large'
                                    >
                                        Sign In
                                    </Link>
                                </div>
                            )}
                        </div>

                        <ImageSlider
                            images={[hairPic2, hairPic1, hairPic3, hairPic4]}
                            className='landing-img'
                        />
                    </div>
                </div>
            </section>

            <section id='landingabout' className='shadow'>
                <img src={hairPic5} alt='Curls' />
                <div>
                    <h1 className='mb'>Take command of your curls!</h1>
                    <h3>
                        The {APP_NAME} website is inspired by one simple
                        concept: knowing how much of a struggle it can be to
                        find the right products and routine that work for your
                        uniquely curly hair. We aim to solve that challenge by
                        providing information about products, ingredients, and
                        regimens that can help you discover the power of your
                        curls.
                    </h3>
                </div>
            </section>
            <section className='containermiddle'>
                <ProductsSample />
                <RegimensSample />
            </section>
        </Fragment>
    );
};

Landing.propTypes = {
    auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    auth: state.auth,
});

export default connect(mapStateToProps)(Landing);
