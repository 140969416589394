// @todo30 note, I don't currently handle deleting images when steps or the regimen itself is deleted
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Spinner from '../../layout/Spinner';
import FileUpload, { ImgTypeEnum } from '../../layout/FileUpload';
import { getRegimenPic } from '../../../utils/helpers';

const UploadRegimenStepPic = ({
    // prop types
    stepid,
    photopath,
    videopath,
    regimen: { regimen, loading, uploading_regimen_step_pic },
}) => {
    const [getPhoto, setPhoto] = useState('');
    const [getVideo, setVideo] = useState('');

    useEffect(() => {
        if (!loading && regimen) {
            // add timestamp to force re-render of image
            // in cases where file name stays same but photo is replaced
            let nameToUse = photopath
                ? `${photopath}?${Date.now().toString()}`
                : ''; // only add timestamp to end of actual paths
            setPhoto(nameToUse);

            nameToUse = videopath
                ? `${videopath}?${Date.now().toString()}`
                : ''; // only add timestamp to end of actual paths
            setVideo(nameToUse);
        }
    }, [loading, regimen, photopath, videopath]);

    return loading || !regimen ? (
        <Spinner />
    ) : (
        <div className='upload-box'>
            <p className='form-header'>Upload Step Photo or Video</p>
            {getPhoto && !getVideo && (
                <img
                    className='round-img'
                    src={getRegimenPic(photopath)}
                    alt={regimen.name}
                />
            )}
            {getVideo && (
                <video width='160' height='160' controls>
                    <source src={getRegimenPic(videopath)} type='video/mp4' />
                    Your browser does not support the video tag.
                </video>
            )}
            <small className='form-text mb'>
                A square photo works best. For videos, landscape orientation or
                square size is best. Only mp4 videos are supported. Max file
                size: 3MB
            </small>
            {uploading_regimen_step_pic === stepid ? (
                <Spinner />
            ) : (
                <FileUpload
                    formID={'uploadregimensteppic-' + stepid}
                    uploadType={ImgTypeEnum.REGIMENSTEP}
                    id={regimen._id}
                    stepid={stepid}
                    allowVideo={true}
                />
            )}
        </div>
    );
};

UploadRegimenStepPic.propTypes = {
    regimen: PropTypes.object.isRequired,
    stepid: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
    regimen: state.regimen,
});

export default connect(mapStateToProps)(UploadRegimenStepPic);
