// configure RUM application
import { datadogRum } from '@datadog/browser-rum';
import { CLIENT_VERSION } from './utils/constants';

const ruminit = () => {
    datadogRum.init({
        applicationId: '908f9682-4fea-4b33-8548-d9b599aa369a',
        clientToken: 'pub249d56f53a32d32ba36014e10e58528e',
        site: 'datadoghq.com',
        service: 'rootsandcurls',
        env: process.env.NODE_ENV,
        // Specify a version number to identify the deployed version of your application in Datadog
        version: CLIENT_VERSION,
        sampleRate: 100,
        trackInteractions: true,
        silentMultipleInit: true,
    });

    datadogRum.startSessionReplayRecording();
};

export default ruminit;
