import {
    SET_ALERT,
    REMOVE_ALERT,
    REMOVE_ALL_ALERTS,
    REMOVE_ALL_NONSTICKY_ALERTS,
} from '../actions/types';

const initialState = [];

// reducers take a state and an action (the action is dispatched from a file)
// actions have a type and a payload
const alertReducer = (state = initialState, action) => {
    const { type, payload } = action; // pulled out to shorthand the code

    switch (type) {
        case SET_ALERT:
            // copy in any preexisting alerts (since state is immutable)
            // then add the new data payload

            if (state.filter((alert) => alert.msg === payload.msg).length > 0) {
                /* state contains the same message, so return state as is */
                return state;
            }

            return [...state, payload];
        case REMOVE_ALERT:
            // remove alert that matches a specific ID (payload is set to be just the ID here)
            return state.filter((alert) => alert.id !== payload);
        case REMOVE_ALL_ALERTS: // @todo6 remove alerts when page changes
            return initialState;
        case REMOVE_ALL_NONSTICKY_ALERTS:
            return state.filter((alert) => alert.persistOnRouteChange); // keep only alerts that should persist for full time
        default:
            return state;
    }
};

export default alertReducer;
