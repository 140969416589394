import api from '../utils/api';
import { setAlert } from './alert';
import {
    REGISTER_SUCCESS,
    USER_LOADED,
    AUTH_ERROR,
    LOGIN_SUCCESS,
    LOGOUT,
    REMOVE_ALL_ALERTS,
    VERIFYING_EMAIL,
    DONE_VERIFYING_EMAIL,
    RESETTING_PWD,
    DONE_RESETTING_PWD,
} from '../actions/types';
import actionErrorHandler from './helpers/actionErrorHandler';
import { datadogRum } from '@datadog/browser-rum';

// Load User
export const loadUser = () => async (dispatch) => {
    try {
        const res = await api.get('/auth');

        let user = res.data; // user obj with all fields except password

        // set user for datadog RUM session
        datadogRum.setUser({
            id: user._id,
            name: user.username_lower,
            username: user.username_lower,
            email: user.email,
        });

        dispatch({
            type: USER_LOADED,
            payload: user, // payload is the user
        });
    } catch (err) {
        dispatch({
            type: AUTH_ERROR,
        });
    }
};

// register form data, which is object with structure { name, email, username, password, password2 }
export const register = (formData) => async (dispatch) => {
    try {
        const res = await api.post('/users', formData);
        // Dispatch an action to the reducer. This is the only way to trigger a state change.
        dispatch({
            type: REGISTER_SUCCESS,
            payload: res.data, // we get back the token
        });

        // send in case errors were on screen when registering
        dispatch({
            type: REMOVE_ALL_ALERTS, // no payload needed.
        });

        dispatch(loadUser()); // registering will go to sign in user so this is just a precaution
    } catch (err) {
        actionErrorHandler(dispatch, err, AUTH_ERROR);
    }
};

// Login User
export const login = (username, password) => async (dispatch) => {
    const body = { username, password };

    try {
        const res = await api.post('/auth', body);

        dispatch({
            type: LOGIN_SUCCESS,
            payload: res.data, // payload is the token
        });

        dispatch(loadUser());
    } catch (err) {
        actionErrorHandler(dispatch, err, AUTH_ERROR);
    }
};

export const verifyEmail = (username_lower, hash) => async (dispatch) => {
    const data = { hash };

    if (!username_lower || !hash) {
        dispatch({
            type: DONE_VERIFYING_EMAIL,
            payload: { msg: 'Invalid email verification link.' },
        });
        return;
    }

    try {
        dispatch({ type: VERIFYING_EMAIL });

        const res = await api.post(
            `/users/verifyemail/${username_lower}`,
            data
        );

        dispatch({
            type: DONE_VERIFYING_EMAIL,
            payload: res.data, // payload has the result message
        });
    } catch (err) {
        actionErrorHandler(dispatch, err, DONE_VERIFYING_EMAIL);
    }
};

export const forgotPassword = (email) => async (dispatch) => {
    const data = { email };

    try {
        dispatch({ type: RESETTING_PWD });

        const res = await api.post(`/users/forgotpassword`, data);

        dispatch({
            type: DONE_RESETTING_PWD,
            payload: res.data, // payload has the result message
        });
    } catch (err) {
        const errors = err.response.data.errors;

        if (errors) {
            // error will be displayed in UI so not setAlert call needed
            dispatch({
                type: DONE_RESETTING_PWD,
                payload: { msg: errors[0].msg },
            });
            // dispatch(setAlert(errors[0].msg, 'danger', true, false, false)); // show first error
        }
    }
};

// reset pswd. returns true on success; false on fail
export const resetPassword = (formData, hash, history) => async (dispatch) => {
    try {
        dispatch({ type: RESETTING_PWD });

        const res = await api.post(`/users/resetpassword/${hash}`, formData);

        dispatch({
            type: DONE_RESETTING_PWD,
            payload: res.data, // payload has the result message
        });

        // redirect to login page
        if (history) {
            history('/login');
        }
        // show in UI (using res.data.msg because of message format in API)
        dispatch(setAlert(res.data.msg, 'success', true, true, false));
    } catch (err) {
        // actionErrorHandler(dispatch, err, DONE_RESETTING_PWD); // @todo see if I can use this instead
        const errors = err.response.data.errors;

        // show first error
        if (errors) {
            // show in UI: show first error + clear others
            dispatch(setAlert(errors[0].msg, 'danger', true, true, false));
            // clear spinner but don't show error msg in built-in UI box on this pg
            dispatch({
                type: DONE_RESETTING_PWD,
                payload: { msg: '' }, // @todo
            });
        }
    }
};

export const resendVerificationEmail = () => async (dispatch) => {
    try {
        dispatch({ type: VERIFYING_EMAIL });

        const res = await api.post('/users/resendemailverification');

        dispatch({
            type: DONE_VERIFYING_EMAIL,
            payload: res.data, // payload has the result message
        });
    } catch (err) {
        const errors = err.response.data.errors;

        if (errors) {
            // error will be displayed in UI so not setAlert call needed
            dispatch({
                type: DONE_VERIFYING_EMAIL,
                payload: { msg: errors[0].msg },
            });
            // dispatch(setAlert(errors[0].msg, 'danger', true, false, false)); // show first error
        }
    }
};

export const logout = (_user, history) => (dispatch) => {
    dispatch({ type: LOGOUT });

    if (history) {
        history('/');
    }

    dispatch(
        setAlert(
            'Successfully logged out. See you soon!',
            'success',
            true /* persist on page changes */,
            true /* clear all other alerts */
        )
    );

    datadogRum.removeUser();

    // userLogger.info('User ' + user.username_lower + ' logged out', {
    //     username_lower: `${user.username_lower}`,
    //     user_id: `${user._id}`,
    //     app_location: 'auth_action',
    // });
};

// update user info: name, email, username, or password
export const updateInfo = (formData) => async (dispatch) => {
    try {
        await api.post('/users/update', formData);

        // send in case errors were on screen when registering
        dispatch({
            type: REMOVE_ALL_ALERTS, // no payload needed. @todo10 handle this...properly
        });

        dispatch(loadUser()); // fetch new user data
        dispatch(
            setAlert(
                'Account settings updated',
                'success',
                true /* persist on page changes */
            )
        );
    } catch (err) {
        const errors = err.response.data.errors;

        // show first error
        if (errors) {
            dispatch(setAlert(errors[0].msg, 'danger'));
        }

        // no need to dispatch auth error. just show error above
    }
};
