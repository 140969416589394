// Private page.
import React, { Fragment, useEffect /* since we use hooks */ } from 'react';
import { Link, Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';
// connect all state and actions from each reducer to this component :)
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import { APP_NAME } from '../../utils/constants';
import { getCurrentProfile } from '../../actions/profile';
import { getRegimens } from '../../actions/regimen';
import RegimenItem from '../../components/regimens/RegimenItem';
import Spinner from '../layout/Spinner';
import DashboardNav from './DashboardNav';
import Tag from '../layout/Tag';

// @todo don't let user add content (regimens, comments...) until profile is complete
const Dashboard = ({
    getCurrentProfile, // function from Profile action
    getRegimens,
    regimen,
    auth, // loading obj from Auth reducer
    profile: { loading_profile },
}) => {
    // when the state updates, useEffect will keep running in a constant loop, unless
    // you add the [] specified at the end (2nd arg) so that it runs only once when app is mounted
    // This behaves like componentDidMount
    useEffect(() => {
        if (!auth.loading) {
            getCurrentProfile(); // load profile as soon as dashboard mounts
            getRegimens(true /* only show logged in user's regimens */);
        }
    }, [auth.loading, getCurrentProfile, getRegimens]);

    useEffect(() => {
        window.scrollTo(0, 0); // scroll to top of form on mount
    }, []);

    if (!auth.isAuthenticated) {
        return <Navigate to='/login' />;
    }

    return (
        <div className='containermiddle'>
            <Helmet>
                <title>{APP_NAME} | Dashboard</title>
            </Helmet>
            {auth.loading || loading_profile ? (
                <Spinner />
            ) : (
                <Fragment>
                    <DashboardNav isAdmin={auth.isAdmin} user={auth.user} />
                    {!auth.user.profile ? (
                        <Fragment>
                            <p>
                                Time to create your profile so we can customize
                                your hair care!
                            </p>
                            <Link
                                to='/createprofile'
                                className='btn btn-primary my-1'
                            >
                                Create Profile
                            </Link>
                        </Fragment>
                    ) : (
                        ''
                    )}

                    <div>
                        <h2>Product Wishlist</h2>
                        {auth.user.productwishlist &&
                        auth.user.productwishlist.length > 0 ? (
                            <div className='mb-1'>
                                {auth.user.productwishlist.map(
                                    (productobj, idx) => (
                                        <Tag
                                            name={
                                                productobj.company.shortname +
                                                ' ' +
                                                productobj.name
                                            }
                                            slug={
                                                '/product/' +
                                                productobj.urlparam
                                            }
                                            key={'favproduct-' + idx}
                                        />
                                    )
                                )}
                            </div>
                        ) : (
                            <div className='mb-1'>No products wishlisted</div>
                        )}
                    </div>
                    <hr />

                    <div className='header-with-button'>
                        <h2 className='mb-1 mt-1'>My Regimens</h2>
                        <Link to='/addregimen' className='btn btn-primary mr-0'>
                            <i className='fas fa-plus text-light' /> Add Regimen
                        </Link>
                    </div>

                    {regimen.loading ? (
                        <Spinner />
                    ) : regimen.regimens && regimen.regimens.length > 0 ? (
                        <div className='regimens-container'>
                            {regimen.regimens.map((regimen) => (
                                <RegimenItem
                                    regimen={regimen}
                                    key={regimen._id}
                                />
                            ))}
                        </div>
                    ) : (
                        <p>No regimens created</p>
                    )}
                </Fragment>
            )}
        </div>
    );
};

Dashboard.propTypes = {
    getCurrentProfile: PropTypes.func.isRequired,
    getRegimens: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    profile: PropTypes.object.isRequired,
    regimen: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    auth: state.auth,
    profile: state.profile,
    regimen: state.regimen,
});

export default connect(mapStateToProps, {
    getCurrentProfile,
    getRegimens,
})(Dashboard);
