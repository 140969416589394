// View All Regimens
import React, { Fragment, useEffect } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import { APP_NAME } from '../../utils/constants';
import { getRegimens } from '../../actions/regimen';
import RegimenItem from '../../components/regimens/RegimenItem';
import Spinner from '../layout/Spinner';

const Regimens = ({
    getRegimens,
    regimen: { regimens, loading },
    auth, // loading obj from Auth reducer
}) => {
    useEffect(() => {
        if (!auth.loading) getRegimens(false);
    }, [auth.loading, getRegimens]);

    useEffect(() => {
        window.scrollTo(0, 0); // scroll to top of form on mount
    }, []);

    return (
        <div className='containermiddle'>
            <Helmet>
                <title>{APP_NAME} | Regimens</title>
            </Helmet>

            <div className='header-with-button'>
                <h1>{!loading && regimens && regimens.length} Regimens</h1>
                {auth.isAuthenticated ? (
                    <Link to='/addregimen' className='btn btn-light mb-2 mr-0'>
                        <i className='fas fa-plus text-primary' /> Add Regimen
                    </Link>
                ) : (
                    ''
                )}
            </div>

            {auth.loading || loading ? (
                // wait for profile to load
                <Spinner />
            ) : (
                <Fragment>
                    <p className='lead mb'>
                        Browse hair regimens and find your perfect routine!
                    </p>
                    {auth.isAuthenticated && (
                        <p className='small mb-1'>
                            <strong>Note:</strong> only public regimens are
                            shown here. Your private regimens can be found on
                            your dashboard.
                        </p>
                    )}

                    <div className='regimens-container'>
                        {regimens.map((regimen) => (
                            <RegimenItem regimen={regimen} key={regimen._id} />
                        ))}
                    </div>
                </Fragment>
            )}
        </div>
    );
};

Regimens.propTypes = {
    getRegimens: PropTypes.func.isRequired,
    regimen: PropTypes.object.isRequired,
    auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    regimen: state.regimen,
    auth: state.auth,
});

export default connect(mapStateToProps, { getRegimens })(Regimens);
