// @todo note, I don't currently handle deleting images when steps or the regimen itself is deleted
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Spinner from '../../layout/Spinner';
import { getJourneyEntryPic } from '../../../utils/helpers';
import { uploadJourneyEntryPic } from '../../../actions/profile';
import { setAlert } from '../../../actions/alert';

const UploadJourneyEntryPic = ({
    // prop types
    entryid, // entry id
    photopath,
    uploadJourneyEntryPic,
    profile: { profile, loading, uploading_journey_entry_pic },
}) => {
    const [getPhoto, setPhoto] = useState('');
    const [file, setFile] = useState('');

    useEffect(() => {
        if (!loading && profile) {
            // add timestamp to force re-render of image
            // in cases where file name stays same but photo is replaced
            let nameToUse = photopath
                ? `${photopath}?${Date.now().toString()}`
                : ''; // only add timestamp to end of actual paths
            setPhoto(nameToUse);
        }
    }, [loading, profile, photopath]);

    const submitFile = (e) => {
        e.preventDefault(); // don't let browser do its default behavior

        if (!file || file.length === 0) {
            setAlert('Please select a photo to upload', 'danger');
            return;
        }

        const formData = new FormData();
        formData.append('file', file[0]);

        uploadJourneyEntryPic(formData, entryid);
    };

    return loading || !profile ? (
        <Spinner />
    ) : (
        <div className='upload-box'>
            <p className='form-header'>Upload Step Photo</p>
            {getPhoto && (
                <img
                    className='round-img'
                    src={getJourneyEntryPic(photopath)}
                    alt={profile.user.username}
                />
            )}
            <small className='form-text mb'>A square photo works best.</small>
            {uploading_journey_entry_pic ? (
                <Spinner />
            ) : (
                <div
                    className='form'
                    id={'uploadjourneyentrysteppic-' + entryid}
                >
                    <div className='upload-actions'>
                        <input
                            type='file'
                            accept='image/*'
                            onChange={(e) => setFile(e.target.files)}
                        />
                        <button
                            className='btn btn-primary'
                            onClick={(e) => submitFile(e)}
                        >
                            Upload
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
};

UploadJourneyEntryPic.propTypes = {
    profile: PropTypes.object.isRequired,
    entryid: PropTypes.string.isRequired,
    uploadJourneyEntryPic: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
    profile: state.profile,
});

export default connect(mapStateToProps, {
    uploadJourneyEntryPic,
})(UploadJourneyEntryPic);
