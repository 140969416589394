import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Spinner from '../layout/Spinner';
import DayJS from 'react-dayjs';
import PropertyTags from './PropertyTags';
const helpers = require('./helpers/helpers.js');

const IngredientItem = ({
    data, // passed in ingredient
    loading,
    auth: { isAdmin, isAuthenticated },
}) => {
    return (
        <Fragment>
            {loading ? (
                <div className='product'>
                    <Spinner />
                </div>
            ) : (
                <div className='ingredient' key={data._id}>
                    <div>
                        <Link to={`/ingredient/${data.urlparam}`}>
                            <h3 className='text-primary mb-0'>{data.name}</h3>
                        </Link>
                        {isAuthenticated && isAdmin && (
                            <Fragment>
                                <p className='small mb-0'>
                                    Added:{' '}
                                    {data &&
                                        data.createdby &&
                                        data.createdby.username}{' '}
                                    on{' '}
                                    {data && data.created && (
                                        <DayJS format='dd MM|DD|YY hh:mma'>
                                            {data.created}
                                        </DayJS>
                                    )}
                                </p>
                                <p className='small mb-0'>
                                    Last edited:{' '}
                                    {data &&
                                        data.lasteditedby &&
                                        data.lasteditedby.username}{' '}
                                    on{' '}
                                    {data && data.updated && (
                                        <DayJS format='dd MM|DD|YY hh:mma'>
                                            {data.updated}
                                        </DayJS>
                                    )}
                                </p>
                            </Fragment>
                        )}
                    </div>
                    <div>
                        {data.rating ? (
                            <span
                                className={
                                    'block rating' +
                                    helpers.getRatingClass(data.rating)
                                }
                            >
                                {data.rating}
                            </span>
                        ) : (
                            <span className='block rating'>Not yet rated</span>
                        )}
                    </div>
                    <div>
                        <PropertyTags ingredient={data} />
                    </div>
                    <div>
                        {isAuthenticated && isAdmin && (
                            <Link
                                to={`/admin/editingredient/${data._id}`}
                                className='btn btn-light'
                            >
                                <i className='fas fa-edit text-primary' />{' '}
                                <span className='hide-on-mobile'></span>
                            </Link>
                        )}
                    </div>
                </div>
            )}
        </Fragment>
    );
};

IngredientItem.propTypes = {
    data: PropTypes.object.isRequired, // product obj passed in to component
    auth: PropTypes.object.isRequired,
    loading: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
    auth: state.auth,
    ingredient: state.ingredient,
    loading: state.ingredient.loading,
});

export default connect(mapStateToProps)(IngredientItem);
