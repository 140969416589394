import React, { Fragment, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import { APP_NAME } from '../../utils/constants';
import { updateInfo, resendVerificationEmail } from '../../actions/auth';
import { deleteAccount } from '../../actions/profile';
import UploadProfilePic from '../profile/forms/UploadProfilePic';
import Spinner from '../layout/Spinner';
import DashboardNav from './DashboardNav';

const initialState = {
    // default values for the formData state
    name: '',
    email: '',
    username: '',
    currentpassword: '',
    newpassword1: '',
    newpassword2: '',
};

const UpdateInfo = ({
    updateInfo,
    resendVerificationEmail,
    deleteAccount,
    auth: { loading, user, isAdmin, verifying_email, verification_message },
}) => {
    let history = useNavigate();

    const [formData, setFormData] = useState(initialState);

    useEffect(() => {
        if (!loading && user) {
            let formData = { ...initialState };
            formData.name = user.name;
            formData.email = user.email;
            formData.username = user.username;

            setFormData(formData);
        }
    }, [loading, user]);

    useEffect(() => {
        window.scrollTo(0, 0); // scroll to top of form on mount
    }, []);

    const {
        name,
        email,
        username,
        currentpassword,
        newpassword1,
        newpassword2,
    } = formData;

    const onChange = (e) =>
        // ... is the spread operator which makes a copy of formData
        // [e.target.name] generalizes the function so it can apply to any field
        // Update formData by copying existing data then adding the changed value:
        setFormData({ ...formData, [e.target.name]: e.target.value });

    const onSubmit = async (e) => {
        // @todo async needed here?
        e.preventDefault(); // don't let browser do its default behavior
        updateInfo(formData);
    };

    const resendEmailVerification = (e) => {
        e.preventDefault(); // don't submit form
        resendVerificationEmail();
    };

    const deleteAcct = (e) => {
        e.preventDefault(); // don't submit form
        deleteAccount(formData, history);
    };

    return (
        <div className='containermiddle'>
            <Helmet>
                <title>{APP_NAME} | Settings</title>
            </Helmet>
            {loading ? (
                <Spinner />
            ) : (
                <Fragment>
                    <DashboardNav isAdmin={isAdmin} user={user} />
                    <h2>Settings</h2>

                    <UploadProfilePic />
                    <p className='italicsmsg m0 pt-0'>* = required field</p>

                    <form className='form' onSubmit={onSubmit}>
                        <div className='form-group'>
                            <p className='form-header'>* Name</p>
                            <small className='form-text mb'>
                                This is so we can greet you!
                            </small>
                            <input
                                type='text'
                                placeholder='Nickname'
                                name='name'
                                value={name}
                                onChange={onChange}
                                onKeyPress={(e) => {
                                    e.key === 'Enter' && e.preventDefault();
                                }}
                                required
                            />
                        </div>
                        <div className='form-group'>
                            <p className='form-header'>* Email Address</p>
                            <input
                                type='email'
                                placeholder='Email Address'
                                name='email'
                                value={email}
                                onChange={onChange}
                                onKeyPress={(e) => {
                                    e.key === 'Enter' && e.preventDefault();
                                }}
                                required
                            />

                            {!user.emailverified && (
                                <Fragment>
                                    <strong>Verify Email Address</strong>:{' '}
                                    <button
                                        type='button'
                                        className='btn btn-warning mt'
                                        onClick={(e) =>
                                            resendEmailVerification(e)
                                        }
                                    >
                                        Send Email Verification Code
                                    </button>
                                    {verifying_email ? (
                                        <Spinner />
                                    ) : (
                                        <h4 className='catchy mb-0 mt'>
                                            {verification_message}
                                        </h4>
                                    )}
                                </Fragment>
                            )}
                        </div>
                        <div className='form-group'>
                            <p className='form-header'>* Username</p>
                            <small className='form-text mb'>
                                Must be 3 to 30 characters long and can only
                                contain letters and numbers
                            </small>
                            <input
                                type='text'
                                name='username'
                                value={username}
                                onChange={onChange}
                                onKeyPress={(e) => {
                                    e.key === 'Enter' && e.preventDefault();
                                }}
                                required
                            />
                        </div>
                        <div className='form-group'>
                            <p className='form-header'>* Current Password</p>
                            <input
                                type='password'
                                name='currentpassword'
                                value={currentpassword}
                                onKeyPress={(e) => {
                                    e.key === 'Enter' && e.preventDefault();
                                }}
                                onChange={onChange}
                            />
                        </div>
                        <div className='form-group'>
                            <p className='form-header'>New Password</p>
                            <small className='form-text mb'>
                                Must be at least 8 characters long
                            </small>
                            <input
                                type='password'
                                name='newpassword1'
                                value={newpassword1}
                                onChange={onChange}
                                onKeyPress={(e) => {
                                    e.key === 'Enter' && e.preventDefault();
                                }}
                            />
                        </div>
                        <div className='form-group'>
                            <p className='form-header'>Confirm New Password</p>
                            <input
                                type='password'
                                name='newpassword2'
                                value={newpassword2}
                                onChange={onChange}
                                onKeyPress={(e) => {
                                    e.key === 'Enter' && e.preventDefault();
                                }}
                            />
                        </div>

                        <div className='admin-form-footer'>
                            <button
                                className='btn btn-danger my-1'
                                onClick={deleteAcct}
                            >
                                <i className='fas fa-user-minus' /> Delete My
                                Account
                            </button>
                            <input
                                type='submit'
                                value='Save'
                                className='btn btn-primary my-1 mr-0'
                            />
                        </div>
                    </form>
                </Fragment>
            )}
        </div>
    );
};

UpdateInfo.propTypes = {
    deleteAccount: PropTypes.func.isRequired,
    updateInfo: PropTypes.func.isRequired,
    resendVerificationEmail: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    auth: state.auth,
});

export default connect(mapStateToProps, {
    deleteAccount,
    updateInfo,
    resendVerificationEmail,
})(UpdateInfo);
