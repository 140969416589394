import {
    GET_PROFILE,
    PROFILE_ERROR,
    CLEAR_PROFILE,
    LOADING_PROFILE,
    UPDATE_PROFILE, // for adding or updating profiles
    GET_PROFILES,
    UPDATE_JOURNEY_ENTRY,
    LOADING_JOURNEY_ENTRY_PIC,
    DONE_LOADING_JOURNEY_ENTRY_PIC,
    UPDATE_JOURNEY_ENTRY_PIC,
    LOGOUT,
} from '../actions/types';

const initialState = {
    profile: null,
    profiles: [], // for listing all profiles
    loading_profiles: true,
    loading_profile: true,
    uploading_journey_entry_pic: false,
    error: null, // for request errors
};

// reducers take a state and an action (the action is dispatched from a file)
// actions have a type and a payload
const profileReducer = (state = initialState, action) => {
    const { type, payload } = action; // destructure for simplicity

    switch (type) {
        case GET_PROFILE:
        case UPDATE_PROFILE:
        case UPDATE_JOURNEY_ENTRY:
            return {
                ...state,
                error: null,
                profile: payload,
                loading_profile: false,
            };

        case GET_PROFILES:
            return {
                ...state,
                error: null,
                profiles: payload,
                loading_profiles: false,
            };

        case PROFILE_ERROR:
            return {
                ...state,
                error: payload,
                loading_profiles: false,
                loading_profile: false,
                profile: null, // clear profile (which throws this case) in case user registered so a previously viewed profile does not load and let new user edit it!
            };

        case LOADING_PROFILE:
            return {
                ...state,
                loading_profile: true,
            };

        // when logging out, clear user profile info in case they were set before admin logged out
        case LOGOUT:
        case CLEAR_PROFILE:
            return {
                ...state,
                error: null,
                profile: null,
            };

        case LOADING_JOURNEY_ENTRY_PIC:
            return {
                ...state,
                uploading_journey_entry_pic: true,
            };

        case DONE_LOADING_JOURNEY_ENTRY_PIC:
            return {
                ...state,
                uploading_journey_entry_pic: false,
            };

        case UPDATE_JOURNEY_ENTRY_PIC: {
            let journeyCopy = { ...state.profile.journal };
            journeyCopy.entries = payload; // payload is profile.journal.entries

            return {
                ...state,
                uploading_journey_entry_pic: false,
                profile: { ...state.profile, journal: journeyCopy },
            };
        }

        default:
            return state;
    }
};

export default profileReducer;
