import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { addComment, addRating } from '../../actions/product';
import Rating from '../layout/Rating';
import { setAlert } from '../../actions/alert';
const helpers = require('./helpers/helpers.js');

const CommentForm = ({
    product,
    currentUserRating,
    addComment,
    addRating,
    setAlert,
}) => {
    const [text, setText] = useState('');
    const [rating, setRating] = useState(currentUserRating);

    const submitComment = (e) => {
        e.preventDefault();
        if (rating) {
            addComment(product._id, text, rating);
            setText(''); // reset form
        } else {
            setAlert(
                'Please add a product rating in order to save your comment.',
                'danger'
            );
        }
    };

    const submitRating = (e, rating) => {
        setRating(rating);
        addRating(product._id, rating);
    };

    return (
        <div className='post-form'>
            <h4 className='mb-1'>Leave a review</h4>
            <p className='small allcaps'>
                <Rating
                    currentRating={helpers.getAvgRating(
                        product.ratetotal,
                        product.ratecount
                    )}
                    onClickHandler={(e, rating) => submitRating(e, rating)}
                    cssID='proddetails'
                    readonly={false}
                />{' '}
                {helpers
                    .getAvgRating(product.ratetotal, product.ratecount)
                    .toFixed(1)}{' '}
                {currentUserRating ? (
                    <span>(Your rating: {currentUserRating})</span>
                ) : (
                    ''
                )}{' '}
            </p>

            <form className='form my-1' onSubmit={(e) => submitComment(e)}>
                <textarea
                    name='text'
                    rows='5'
                    placeholder='Tell us what you like/dislike about this product'
                    value={text}
                    onChange={(e) => setText(e.target.value)}
                    required
                />
                <input
                    type='submit'
                    className='btn btn-primary my-1'
                    value='Submit'
                />
            </form>
        </div>
    );
};

CommentForm.propTypes = {
    addComment: PropTypes.func.isRequired,
    addRating: PropTypes.func.isRequired,
    setAlert: PropTypes.func.isRequired,
};

export default connect(null, { addComment, addRating, setAlert })(CommentForm);
