import React from 'react';
import PropTypes from 'prop-types';
import { Link, useLocation } from 'react-router-dom';

// accepts a prop 'relatedpaths' that is an array of paths that match the prop 'path'
// when selecting nav item in nav bar (making item bold)
// 'children' is a prop that will take the inner contents provided to the component and use it where specified
const NavbarLink = ({ path, relatedpaths, children }) => {
    let location = useLocation();

    const hasMatchingPath = () => {
        if (path === location.pathname) return true;

        for (let p = 0; p < relatedpaths.length; p++) {
            let path = relatedpaths[p];
            let matches = location.pathname.indexOf(path) === 0; // url starts with a valid path
            if (matches) return true;
        }

        return false;
    };

    return (
        <Link to={path} className={hasMatchingPath() ? 'selected' : ''}>
            {children}
        </Link>
    );
};

NavbarLink.propTypes = {
    path: PropTypes.string.isRequired,
    relatedpaths: PropTypes.array.isRequired,
};

NavbarLink.defaultProps = {
    relatedpaths: [],
};

export default NavbarLink;
