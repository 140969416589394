import api from '../utils/api';
import upload_api from '../utils/upload_api';
import { setAlert } from './alert';
import { checkAppVersion } from '../utils/helpers';
import actionErrorHandler from './helpers/actionErrorHandler';

import {
    ADD_PRODUCT,
    GET_PRODUCT, // fetch product when it is created or updated
    GET_PRODUCTS,
    GET_PRODUCTS_AUTOCOMPLETE,
    PRODUCT_ERROR,
    DELETE_PRODUCT,
    CLEAR_PRODUCT,
    ADD_PRODUCT_COMMENT,
    REMOVE_PRODUCT_COMMENT,
    GET_PRODUCT_COMMENTS,
    RATE_PRODUCT,
    GET_USER_PRODUCT_RATING,
    UPDATE_PROFILE,
    UPDATE_PRODUCT_PIC,
    LOADING_PRODUCT_PIC,
    SORT_PRODUCTS,
    FILTER_PRODUCTS,
    LOADING_PRODUCT,
    UPDATE_PRODUCT_PAGE_NUMBER,
    UPDATE_PRODUCT_QUERY_PARAM,
    USER_LOADED,
} from './types';

// Get all products
export const getProducts =
    (autocomplete = false) =>
    async (dispatch) => {
        // clear current one ONLY if fetching all full products (not autocomplete):
        !autocomplete && dispatch({ type: CLEAR_PRODUCT });
        try {
            const res = autocomplete
                ? await api.get('/products/autocomplete')
                : await api.get('/products');

            dispatch(checkAppVersion(res)); // functions that have a dispatch must be called in a dispatch wrapper

            dispatch({
                type: autocomplete ? GET_PRODUCTS_AUTOCOMPLETE : GET_PRODUCTS,
                payload: res.data,
            });
        } catch (err) {
            actionErrorHandler(dispatch, err, PRODUCT_ERROR);
        }
    };

// Get product by url param
export const getProductByURLparam = (urlparam) => async (dispatch) => {
    try {
        dispatch({ type: CLEAR_PRODUCT }); // clear current one
        dispatch({ type: LOADING_PRODUCT });

        const res = await api.get(`/products/${urlparam}`);

        dispatch({
            type: GET_PRODUCT,
            payload: res.data,
        });
    } catch (err) {
        actionErrorHandler(dispatch, err, PRODUCT_ERROR);
    }
};

// Get product by ID (for admin page)
export const getProductById = (id) => async (dispatch) => {
    try {
        //dispatch({ type: CLEAR_PRODUCT }); // clear current one
        //dispatch({ type: LOADING_PRODUCT });

        const res = await api.get(`/products/id/${id}`);

        dispatch({
            type: GET_PRODUCT,
            payload: res.data,
        });
    } catch (err) {
        actionErrorHandler(dispatch, err, PRODUCT_ERROR);
    }
};

// Delete a product
export const deleteProduct = (id, history) => async (dispatch) => {
    if (window.confirm('Are you sure? This can NOT be undone!')) {
        // @todo change to modal confirmation with overlay
        try {
            const res = await api.delete(`/products/${id}`);

            dispatch({
                type: DELETE_PRODUCT,
                payload: res.data /* deleted product */,
            });

            if (history) history('/products');
            dispatch(
                setAlert(
                    'Product deleted',
                    'success',
                    true /* persist on page changes */
                )
            );
        } catch (err) {
            actionErrorHandler(dispatch, err, PRODUCT_ERROR);
        }
    }
};

// Create or update product. If id is set, update product, otherwise create it
export const createProduct = (formData, history, id) => async (dispatch) => {
    dispatch({ type: CLEAR_PRODUCT }); // clear current one @todo is this needed

    try {
        const res =
            id !== undefined // return product
                ? await api.post(`/products/${id}`, formData)
                : await api.post('/products/create', formData);

        dispatch({
            type: id !== undefined ? GET_PRODUCT : ADD_PRODUCT,
            payload: res.data,
        });

        dispatch(
            setAlert(
                'Product saved',
                'success',
                true /* persist on page changes */
            )
        );

        if (!id) {
            // note: redirecting in an action is different from redirecting in a component where Redirect can be used. that's why history is used here
            history(`/admin/editproduct/${res.data._id}`);
        }
    } catch (err) {
        actionErrorHandler(dispatch, err, PRODUCT_ERROR);
    }
};

// Update tagged ingredients for a product. this is called when inline editing a product on Product deets pg
export const updateProductIngredients =
    (formData, prodid, entryidx, ingidx) => async (dispatch) => {
        try {
            const res = await api.post(
                `/products/updateingredients/${prodid}/${entryidx}/${ingidx}`,
                formData
            );

            dispatch({
                type: GET_PRODUCT,
                payload: res.data, // payload is product
            });

            dispatch(
                setAlert(
                    'Product ingredient updated',
                    'success',
                    true /* persist on page changes */
                )
            );
        } catch (err) {
            actionErrorHandler(dispatch, err, PRODUCT_ERROR);
        }
    };

// (un)favorite product
export const favorite = (productId) => async (dispatch) => {
    try {
        const res = await api.post(`/products/favorite/${productId}`);
        dispatch({
            type: UPDATE_PROFILE,
            payload: res.data.profile, // profile
        });

        let actionText = res.data.favorited ? 'added to' : 'removed from';
        dispatch(
            setAlert(
                'Product ' + actionText + ' your favorites',
                'success',
                true /* persist on page changes */,
                true /* clear all other alerts */
            )
        );
    } catch (err) {
        actionErrorHandler(dispatch, err, PRODUCT_ERROR);
    }
};

// add or remove product to/from wish list productwishlist
export const wishlist = (productId) => async (dispatch) => {
    try {
        const res = await api.post(`/products/wishlist/${productId}`);
        dispatch({
            type: USER_LOADED,
            payload: res.data.user, // user obj
        });

        let actionText = res.data.wishlisted ? 'added to' : 'removed from';
        dispatch(
            setAlert(
                'Product ' + actionText + ' your wishlist',
                'success',
                true /* persist on page changes */,
                true /* clear all other alerts */
            )
        );
    } catch (err) {
        actionErrorHandler(dispatch, err, PRODUCT_ERROR);
    }
};

// rate product
export const addRating = (productId, rating) => async (dispatch) => {
    try {
        const res = await api.post(`/products/rate/${productId}`, {
            rating: rating,
        });
        dispatch({
            type: RATE_PRODUCT,
            payload: res.data, // product object
        });

        dispatch(setAlert('Product rated.', 'success'));
    } catch (err) {
        // actionErrorHandler(dispatch, err, PRODUCT_ERROR); // @todo see if this should show alert?
        const errors = err.response.data.errors;

        dispatch({
            type: PRODUCT_ERROR,
            payload: {
                msg: errors ? errors[0].msg : 'Product Error',
                status: err.response.status,
            },
        });
    }
};

// get logged in user's rating
export const getUserRatingForProduct = (productId) => async (dispatch) => {
    try {
        const res = await api.get(`/products/getrating/${productId}`);
        dispatch({
            type: GET_USER_PRODUCT_RATING,
            payload: res.data, // rating obj with rating field
        });
    } catch (err) {
        actionErrorHandler(dispatch, err, PRODUCT_ERROR);
    }
};

// Add/remove comment
export const addComment = (productId, text, rating) => async (dispatch) => {
    try {
        const formData = { text, rating };
        const res = await api.post(`/products/comment/${productId}`, formData);

        dispatch({
            type: ADD_PRODUCT_COMMENT,
            payload: res.data, // comment object
        });

        dispatch(setAlert('Comment added', 'success'));
    } catch (err) {
        actionErrorHandler(dispatch, err, PRODUCT_ERROR);
    }
};

// Delete comment
export const deleteComment = (productId, commentId) => async (dispatch) => {
    if (window.confirm('Are you sure? This can NOT be undone!')) {
        try {
            const res = await api.delete(
                `/products/comment/${productId}/${commentId}`
            );

            dispatch({
                type: REMOVE_PRODUCT_COMMENT,
                payload: res.data, // deleted comment
            });

            dispatch(setAlert('Comment deleted', 'success'));
        } catch (err) {
            actionErrorHandler(dispatch, err, PRODUCT_ERROR);
        }
    }
};

// Get all comments for a product
export const getProductComments = (productID) => async (dispatch) => {
    try {
        const res = await api.get(`/products/comments/${productID}`);

        dispatch({
            type: GET_PRODUCT_COMMENTS,
            payload: res.data, // array of comments
        });
    } catch (err) {
        actionErrorHandler(dispatch, err, PRODUCT_ERROR);
    }
};

// update product's main picture
export const uploadProductPic = (file, productID) => async (dispatch) => {
    try {
        dispatch({ type: LOADING_PRODUCT_PIC });

        const res = await upload_api.post(
            `/products/uploadmainphoto/${productID}`,
            file
        );

        dispatch({
            // send to profile
            type: UPDATE_PRODUCT_PIC,
            payload: res.data, // photo path
        });

        dispatch(
            setAlert(
                'Product photo updated',
                'success',
                true /* persist on page changes */
            )
        );
    } catch (err) {
        // actionErrorHandler(dispatch, err, PRODUCT_ERROR); // todo see if I need to dispatch payload too
        const errors = err.response.data.errors;
        if (errors) {
            dispatch(setAlert(errors[0].msg, 'danger')); // show first error
        }
    }
};

// PAGE, SORT & FILTER PRODUCTS:

export const sortProducts = (sortBy) => async (dispatch) => {
    // check sortBy against valid list of sortable values:
    let sortbyvals = ['created', 'company.name', 'name', 'priority'];
    if (!sortbyvals.includes(sortBy)) return;

    let dir = sortBy === 'priority' || sortBy === 'created' ? 'desc' : 'asc';
    dispatch({
        type: SORT_PRODUCTS,
        payload: { sortBy, sortDir: dir },
    });
};

// Filter all products by a search string and/or by filter properties
export const filterProducts =
    (filterBy = null, isAdmin = false, queryparam = '') =>
    async (dispatch) => {
        dispatch({
            type: FILTER_PRODUCTS,
            payload: { filterBy, isAdmin, queryparam },
        });
    };

// page number to show for pagination
export const updateProductPageNumber =
    (pgnum = 1) =>
    async (dispatch) => {
        let pg = parseInt(pgnum);
        dispatch({
            type: UPDATE_PRODUCT_PAGE_NUMBER,
            payload: pg < 1 ? 1 : pg,
        });
    };

// used to update the query param for a product's filters so it can persist as user navigates the app
export const updateProductQueryParam =
    (queryparam = '') =>
    async (dispatch) => {
        dispatch({
            type: UPDATE_PRODUCT_QUERY_PARAM,
            payload: queryparam,
        });
    };
