import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Autocomplete from '../../layout/Autocomplete';

const initialState = {
    listedas: '',
    isactive: false,
    ingredient: [], // ids
    oringredient: [], // ids
    autocomplete: [], // autocomplete data
    notes: '',
};

const IngredientRow = function ({
    rowData, // an object with fields listedas, isactive, ingredient, oringredient, and notes
    onChangeHandler, // function for changing row property
    onDeleteHandler, // function for row deletion
    onMoveHandler, // for moving row up one
    numRows, // for numbering entries and making sure 1 entry left can't be deleted
    rowNum, // current entry number, used as an index
    entryNum,
    autocomplete, // array of autocomplete ingredients
}) {
    const [entryData, setEntryData] = useState(initialState);
    const [displayAdvanced, toggleAdvanced] = useState(false); // default: hide section

    useEffect(() => {
        if (rowData) {
            // update form fields with current product
            const data = { ...initialState };

            for (const key in rowData) {
                // key: _id, listedas, isactive, notes, ingredient, and oringredient
                if (key in data) data[key] = rowData[key];
            }

            setEntryData(data);
        }
    }, [rowData]);

    const { listedas, isactive, ingredient, oringredient, notes } = entryData;

    return (
        <div className='ingredient_row_wrapper'>
            <p className='form-header'>Ingredient {rowNum + 1}</p>
            <div className='ingredient-row-split'>
                <div>
                    <p className='form-header'>
                        Listed as (ingredient name given on label)
                    </p>
                    <input
                        type='text'
                        placeholder='Listed ingredient name'
                        name='listedas'
                        value={listedas}
                        onChange={(e) => onChangeHandler(e)}
                        onKeyPress={(e) => {
                            e.key === 'Enter' && e.preventDefault();
                        }}
                        className='mb-1'
                        required
                    />
                </div>
                <div>
                    <p className='form-header'>Ingredient Tag(s)</p>
                    <Autocomplete
                        cssID={'entry' + entryNum + '-row' + rowNum}
                        index={rowNum}
                        objectsName='ingredient'
                        objectsData={ingredient}
                        placeholder='Select ingredient.'
                        onChangeHandler={(e) => onChangeHandler(e)}
                        suggestions={autocomplete}
                        selectOneMax={false}
                    />
                </div>
            </div>

            <div className='ingredient-row-adv'>
                <h4 onClick={(e) => toggleAdvanced(!displayAdvanced)}>
                    Advanced &#8250;
                </h4>
                {displayAdvanced && (
                    <div>
                        <hr className='mt' />
                        <p className='form-header'>
                            Other Possibilities For Ingredient
                        </p>
                        <small className='form-text mb'>
                            Fill this in if there are other ingredients that may
                            match this one (sometimes it is not clear)
                        </small>

                        <Autocomplete
                            cssID={'entry' + entryNum + '-row' + rowNum}
                            index={rowNum}
                            objectsName='oringredient'
                            objectsData={oringredient}
                            placeholder='Select ingredient'
                            onChangeHandler={(e) => onChangeHandler(e)}
                            suggestions={autocomplete}
                            selectOneMax={false}
                        />

                        <p className='form-header pt'>Notes</p>
                        <textarea
                            placeholder='Enter ingredient notes that may be on label'
                            name='notes'
                            value={notes}
                            onChange={(e) => onChangeHandler(e)}
                            className='mb-1'
                        />
                        <div className='form-group'>
                            <span>
                                Active ingredient{' '}
                                <input
                                    type='checkbox'
                                    name='isactive'
                                    value={isactive || initialState.isactive}
                                    checked={isactive || initialState.isactive}
                                    onChange={(e) => onChangeHandler(e)}
                                    onKeyPress={(e) => {
                                        e.key === 'Enter' && e.preventDefault();
                                    }}
                                />
                            </span>
                            <small className='form-text mb'>
                                Medicated products like dandruff shampoos will
                                list the active versus inactive ingredients. Be
                                sure to specify the percentage of the active
                                ingredient in the notes. NOTE: active
                                ingredients should appear at top of ingredient
                                list in order of increasing percentage.
                            </small>
                        </div>
                    </div>
                )}
            </div>
            {numRows > 1 && (
                <button
                    type='button'
                    className='btn btn-danger my mb-1'
                    onClick={(e) => onDeleteHandler(e)}
                >
                    <i className='far fa-trash-alt' /> Delete Ingredient
                </button>
            )}
            {rowNum > 0 && (
                <button
                    type='button'
                    className='btn btn-light my mb-1'
                    onClick={(e) => onMoveHandler(e)}
                >
                    <i className='fas fa-arrow-up' /> Move Ingredient Up
                </button>
            )}
        </div>
    );
};

IngredientRow.propTypes = {
    onChangeHandler: PropTypes.func.isRequired,
    onDeleteHandler: PropTypes.func.isRequired,
    onMoveHandler: PropTypes.func.isRequired,
    rowData: PropTypes.object.isRequired, // used to get and set form data
    numRows: PropTypes.number.isRequired,
    rowNum: PropTypes.number.isRequired,
    entryNum: PropTypes.number.isRequired,
    autocomplete: PropTypes.array.isRequired,
};

export default IngredientRow;
