import api from '../utils/api';
import { setAlert } from './alert';
import { checkAppVersion } from '../utils/helpers';
import actionErrorHandler from './helpers/actionErrorHandler';

import {
    ADD_INGREDIENT,
    GET_INGREDIENT, // fetch when it is created or updated
    GET_INGREDIENTS,
    GET_INGREDIENTS_AUTOCOMPLETE,
    INGREDIENT_ERROR,
    DELETE_INGREDIENT,
    CLEAR_INGREDIENT,
    UPDATE_ING_PAGE_NUMBER,
    FILTER_INGREDIENTS,
    UPDATE_ING_QUERY_PARAM,
    SORT_INGREDIENTS,
} from './types';

// Get all ingredients
export const getIngredients =
    (autocomplete = false) =>
    async (dispatch) => {
        dispatch({ type: CLEAR_INGREDIENT }); // clear current one

        try {
            const res = autocomplete
                ? await api.get('/ingredients/autocomplete')
                : await api.get('/ingredients');

            dispatch(checkAppVersion(res));

            dispatch({
                type: autocomplete
                    ? GET_INGREDIENTS_AUTOCOMPLETE
                    : GET_INGREDIENTS,
                payload: res.data,
            });
        } catch (err) {
            actionErrorHandler(dispatch, err, INGREDIENT_ERROR);
        }
    };

// Get ingredient by url param
export const getIngredientByURLparam = (urlparam) => async (dispatch) => {
    try {
        const res = await api.get(`/ingredients/${urlparam}`);
        dispatch({
            type: GET_INGREDIENT,
            payload: res.data,
        });
    } catch (err) {
        actionErrorHandler(dispatch, err, INGREDIENT_ERROR);
    }
};

// Get ingredient by ID
export const getIngredientById = (ingid, history) => async (dispatch) => {
    try {
        const res = await api.get(`/ingredients/id/${ingid}`);

        dispatch({
            type: GET_INGREDIENT,
            payload: res.data,
        });
    } catch (err) {
        history('/ingredients'); // @todo3 check url - push to ingredients pg? also see what landing page does for redirect; might be better to handle that redirect on actual ingredient form page?
        actionErrorHandler(dispatch, err, INGREDIENT_ERROR);
    }
};

// Delete ingredient
export const deleteIngredient = (id, history) => async (dispatch) => {
    if (window.confirm('Are you sure? This can NOT be undone!')) {
        // @todo change to modal confirmation with overlay
        try {
            const res = await api.delete(`/ingredients/${id}`);

            dispatch({
                type: DELETE_INGREDIENT,
                payload: res.data /* deleted ingredient */,
            });

            if (history) history('/ingredients'); // @todo4 test this
            dispatch(
                setAlert(
                    'Ingredient deleted',
                    'success',
                    true /* persist on page changes */
                )
            );
        } catch (err) {
            actionErrorHandler(dispatch, err, INGREDIENT_ERROR);
        }
    }
};

// Create or update ingredient. If id is set, update ingredient, otherwise create it
export const createIngredient = (formData, history, id) => async (dispatch) => {
    dispatch({ type: CLEAR_INGREDIENT }); // clear current one

    try {
        const res =
            id !== undefined // return ingredient
                ? await api.post(`/ingredients/${id}`, formData)
                : await api.post('/ingredients', formData);

        dispatch({
            type: id === undefined ? ADD_INGREDIENT : GET_INGREDIENT,
            payload: res.data,
        });

        //getIngredients(true); // so autocomplete info can update with new data
        dispatch(
            setAlert(
                'Ingredient saved',
                'success',
                true /* persist on page changes */
            )
        );

        if (!id) {
            // note: redirecting in an action is different from redirecing in a component where Redirect can be used. that's why history is used here
            history(`/admin/editingredient/${res.data._id}`);
        }
    } catch (err) {
        actionErrorHandler(dispatch, err, INGREDIENT_ERROR);
    }
};

// PAGING, SORTING, & FILTERING:

// Search all ingredients
export const filterIngredients =
    (
        filterBy = null,
        isAdmin = false, // not currently used in reducer (yet)
        queryparam = ''
    ) =>
    async (dispatch) => {
        dispatch({
            type: FILTER_INGREDIENTS,
            payload: {
                filterBy,
                isAdmin,
                queryparam,
            },
        });
    };

// page number to show for pagination
export const updateIngPageNumber =
    (pgnum = 1 /*numIngredients*/) =>
    async (dispatch) => {
        let pg = parseInt(pgnum);
        dispatch({
            type: UPDATE_ING_PAGE_NUMBER,
            payload: pg < 1 ? 1 : pg,
        });
    };

// used to update the query param for an ingredient's filters so it can persist as user navigates the app
export const updateIngQueryParam =
    (queryparam = '') =>
    async (dispatch) => {
        dispatch({
            type: UPDATE_ING_QUERY_PARAM,
            payload: queryparam,
        });
    };

export const sortIngredients = (sortBy) => async (dispatch) => {
    let dir = 'asc';
    dispatch({
        type: SORT_INGREDIENTS,
        payload: { sortBy, sortDir: dir },
    });
};
