import { USERS_LOADED, ADMIN_ERROR } from '../actions/types';

const initialState = {
    loading: true, // for initial loading when the app first runs or if user refreshes the browser
    users: [],
    // error: {}, // for request errors
};

// reducers take a state and an action (the action is dispatched from a file)
// actions have a type and a payload
const adminReducer = (state = initialState, action) => {
    const { type, payload } = action; // pulled out to shorthand the code

    switch (type) {
        case USERS_LOADED:
            return {
                ...state,
                loading: false,
                users: payload,
            };

        case ADMIN_ERROR:
            return {
                ...state, // keep what's currently in the state
                loading: false,
                users: null,
                // error: payload, @todo why don't I use this here?
            };

        default:
            return state;
    }
};

export default adminReducer;
