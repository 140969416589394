// constants
// ALERT TYPES (handled only by react)
export const SET_ALERT = 'SET_ALERT';
export const REMOVE_ALERT = 'REMOVE_ALERT';
export const REMOVE_ALL_ALERTS = 'REMOVE_ALL_ALERTS';
export const REMOVE_ALL_NONSTICKY_ALERTS = 'REMOVE_ALL_NONSTICKY_ALERTS';

// AUTH TYPES
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const USER_LOADED = 'USER_LOADED';
export const VERIFYING_EMAIL = 'VERIFYING_EMAIL';
export const DONE_VERIFYING_EMAIL = 'DONE_VERIFYING_EMAIL';
export const RESETTING_PWD = 'RESETTING_PWD';
export const DONE_RESETTING_PWD = 'DONE_RESETTING_PWD';
export const AUTH_ERROR = 'AUTH_ERROR'; // for if something goes wrong
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGOUT = 'LOGOUT';
export const ACCOUNT_DELETED = 'ACCOUNT_DELETED';

export const GET_PROFILE = 'GET_PROFILE';
export const GET_PROFILES = 'GET_PROFILES';
export const UPDATE_PROFILE = 'UPDATE_PROFILE';
export const LOADING_PROFILE = 'LOADING_PROFILE';
export const CLEAR_PROFILE = 'CLEAR_PROFILE'; // clear profile for future user who may not have one
export const PROFILE_ERROR = 'PROFILE_ERROR';
export const UPDATE_PROFILE_PIC = 'UPDATE_PROFILE_PIC';
export const LOADING_PROFILE_PIC = 'LOADING_PROFILE_PIC';
export const DONE_LOADING_PROFILE_PIC = 'DONE_LOADING_PROFILE_PIC';
export const UPDATE_JOURNEY_ENTRY = 'UPDATE_JOURNEY_ENTRY';
export const LOADING_JOURNEY_ENTRY_PIC = 'LOADING_JOURNEY_ENTRY_PIC';
export const DONE_LOADING_JOURNEY_ENTRY_PIC = 'DONE_LOADING_JOURNEY_ENTRY_PIC';
export const UPDATE_JOURNEY_ENTRY_PIC = 'UPDATE_JOURNEY_ENTRY_PIC';

export const ADD_REGIMEN = 'ADD_REGIMEN';
export const GET_REGIMEN = 'GET_REGIMEN';
export const GET_REGIMENS = 'GET_REGIMENS';
export const GET_REGIMENS_ADMIN = 'GET_REGIMENS_ADMIN';
export const GET_REGIMENS_AUTOCOMPLETE = 'GET_REGIMENS_AUTOCOMPLETE';
export const DELETE_REGIMEN = 'DELETE_REGIMEN';
export const LOADING_REGIMENS = 'LOADING_REGIMENS';
export const REGIMEN_ERROR = 'REGIMEN_ERROR';
export const UPDATE_REGIMEN_LIKES = 'UPDATE_REGIMEN_LIKES';
export const LOADING_REGIMEN_PIC = 'LOADING_REGIMEN_PIC';
export const DONE_LOADING_REGIMEN_PIC = 'DONE_LOADING_REGIMEN_PIC';
export const UPDATE_REGIMEN_PIC = 'UPDATE_REGIMEN_PIC';
export const LOADING_REGIMEN_STEP_PIC = 'LOADING_REGIMEN_STEP_PIC';
export const DONE_LOADING_REGIMEN_STEP_PIC = 'DONE_LOADING_REGIMEN_STEP_PIC';
export const UPDATE_REGIMEN_STEP_PIC = 'UPDATE_REGIMEN_STEP_PIC';

export const ADD_INGREDIENT = 'ADD_INGREDIENT';
export const GET_INGREDIENT = 'GET_INGREDIENT';
export const GET_INGREDIENTS = 'GET_INGREDIENTS';
export const GET_INGREDIENTS_AUTOCOMPLETE = 'GET_INGREDIENTS_AUTOCOMPLETE';
export const INGREDIENT_ERROR = 'INGREDIENT_ERROR';
export const DELETE_INGREDIENT = 'DELETE_INGREDIENT';
export const CLEAR_INGREDIENT = 'CLEAR_INGREDIENT';
export const FILTER_INGREDIENTS = 'FILTER_INGREDIENTS';
export const SORT_INGREDIENTS = 'SORT_INGREDIENTS';
export const UPDATE_ING_PAGE_NUMBER = 'UPDATE_ING_PAGE_NUMBER';
export const UPDATE_ING_QUERY_PARAM = 'UPDATE_ING_QUERY_PARAM';

export const GET_PRODUCTS = 'GET_PRODUCTS';
export const GET_PRODUCTS_AUTOCOMPLETE = 'GET_PRODUCTS_AUTOCOMPLETE';
export const GET_PRODUCT = 'GET_PRODUCT';
export const PRODUCT_ERROR = 'PRODUCT_ERROR';
export const DELETE_PRODUCT = 'DELETE_PRODUCT';
export const ADD_PRODUCT = 'ADD_PRODUCT';
export const CLEAR_PRODUCT = 'CLEAR_PRODUCT';
export const SORT_PRODUCTS = 'SORT_PRODUCTS';
export const FILTER_PRODUCTS = 'FILTER_PRODUCTS';
export const LOADING_PRODUCT = 'LOADING_PRODUCT';
export const GET_PRODUCT_COMMENTS = 'GET_PRODUCT_COMMENTS';
export const ADD_PRODUCT_COMMENT = 'ADD_PRODUCT_COMMENT';
export const REMOVE_PRODUCT_COMMENT = 'REMOVE_PRODUCT_COMMENT';
export const RATE_PRODUCT = 'RATE_PRODUCT';
export const GET_USER_PRODUCT_RATING = 'GET_USER_PRODUCT_RATING';
export const UPDATE_PRODUCT_PIC = 'UPDATE_PRODUCT_PIC';
export const LOADING_PRODUCT_PIC = 'LOADING_PRODUCT_PIC';
export const DONE_LOADING_PRODUCT_PIC = 'DONE_LOADING_PRODUCT_PIC';
export const UPDATE_PRODUCT_PAGE_NUMBER = 'UPDATE_PRODUCT_PAGE_NUMBER';
export const UPDATE_PRODUCT_QUERY_PARAM = 'UPDATE_PRODUCT_QUERY_PARAM';

// COMPANY TYPES
export const GET_COMPANIES = 'GET_COMPANIES';
export const GET_COMPANIES_AUTOCOMPLETE = 'GET_COMPANIES_AUTOCOMPLETE';
export const GET_COMPANY = 'GET_COMPANY';
export const COMPANY_ERROR = 'COMPANY_ERROR';
export const DELETE_COMPANY = 'DELETE_COMPANY';
export const ADD_COMPANY = 'ADD_COMPANY';
export const CLEAR_COMPANY = 'CLEAR_COMPANY';

// ADMIN TYPES
export const USERS_LOADED = 'USERS_LOADED';
export const ADMIN_ERROR = 'ADMIN_ERROR';
