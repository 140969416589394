export const getAvgRating = (ratetotal, ratecount) => {
    return ratecount > 0 ? ratetotal / ratecount : 0;
};

export const isUserFavorite = (userfavs, productid) => {
    let matchedFav = userfavs.find((fav) => fav._id === productid);
    return matchedFav;
};

export const isWishlisted = (userwishlist, productid) => {
    let matcheditem = userwishlist
        ? userwishlist.find((prod) => prod._id === productid)
        : false;
    return matcheditem;
};

// @todo30 what if collection is null? as in, what if I delete collection from
// the company array? the UI will simply ignore a non-match according to the below code but ideally,
// the API would update the product to clear the product line.
// a product has a line field which stores the ID of a company's product collection array element.
// this helper will search through that array and return the element.
const findMatchingProductCollection = (line, company) => {
    if (!line || !company) return undefined;

    // get first match, if any
    const match = company.productcollections.find(
        (collection) => collection._id === line
    );

    return match;
};

// returns an array that stores the single found product collection.
// we store it in an array since this is now the autocomplete component needs data to be stored.
export const findMatchingProductCollectionArray = (line, company) => {
    let col = findMatchingProductCollection(line, company);
    return col ? [col] : [];
};

export const getProductCollectionName = (line, company) => {
    const collection = findMatchingProductCollection(line, company);
    return collection ? collection.name : '';
};
