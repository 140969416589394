import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Spinner from '../layout/Spinner';
import Rating from '../layout/Rating';
import DayJS from 'react-dayjs';
// import { deleteProduct } from '../../actions/product';
import Tag from '../layout/Tag';
import { getProductPic } from '../../utils/helpers';
const helpers = require('./helpers/helpers.js');

const Products = ({
    // deleteProduct,
    data, // passed in product
    product_loading,
    auth,
}) => {
    return (
        <Fragment>
            {auth.loading || product_loading ? (
                <div className='product'>
                    <Spinner />
                </div>
            ) : (
                <div className='product' key={data.name}>
                    <Link className='img' to={`/product/${data.urlparam}`}>
                        <img
                            src={getProductPic(data.mainphoto)}
                            alt={data.name}
                        />
                    </Link>

                    <div>
                        <p className='mb-0'>
                            <strong>
                                <Link
                                    className='text-primary-medium'
                                    to={`/products?company=${data.company._id}`}
                                >
                                    {data.company.name}
                                </Link>
                            </strong>
                        </p>
                        <Link to={`/product/${data.urlparam}`}>
                            <h2 className='text-primary mb'>
                                {data.name}{' '}
                                {auth.isAuthenticated &&
                                    auth.isAdmin &&
                                    data.needsreview && (
                                        <span className='small allcaps rating-poor'>
                                            <br />
                                            <i className='fas fa-exclamation-circle' />{' '}
                                            Needs Review
                                        </span>
                                    )}
                                {auth.isAuthenticated &&
                                    auth.isAdmin &&
                                    data.isdraft && (
                                        <span className='small allcaps rating-poor'>
                                            <br />
                                            <i className='fas fa-exclamation-circle' />{' '}
                                            Draft
                                        </span>
                                    )}
                            </h2>
                        </Link>

                        <p className='small allcaps mb-0'>
                            <Rating
                                currentRating={helpers.getAvgRating(
                                    data.ratetotal,
                                    data.ratecount
                                )}
                                readonly={true}
                                cssID={'prod-' + data._id}
                            />{' '}
                            {helpers
                                .getAvgRating(data.ratetotal, data.ratecount)
                                .toFixed(1)}
                        </p>

                        <div className='mb'>
                            {data.categories &&
                                data.categories.map((category, idx) => (
                                    <Tag
                                        key={'cat-' + idx}
                                        name={category}
                                        // slug={
                                        //     '/category/' +
                                        //     category.urlparam
                                        // }
                                    />
                                ))}
                        </div>
                    </div>

                    {auth.isAuthenticated && auth.isAdmin && (
                        <Fragment>
                            <p className='small mb-0'>
                                Added:{' '}
                                {data &&
                                    data.createdby &&
                                    data.createdby.username}{' '}
                                {data && data.created && (
                                    <DayJS format='MM|DD|YY hh:mma'>
                                        {data.created}
                                    </DayJS>
                                )}
                            </p>
                            <p className='small mb'>
                                Edited:{' '}
                                {data &&
                                    data.lasteditedby &&
                                    data.lasteditedby.username}{' '}
                                {data && data.updated && (
                                    <DayJS format='MM|DD|YY hh:mma'>
                                        {data.updated}
                                    </DayJS>
                                )}
                            </p>
                        </Fragment>
                    )}

                    {auth.isAuthenticated && auth.isAdmin && (
                        <div>
                            {/* <button
                        type='button'
                        className='btn btn-danger mt'
                        onClick={() => deleteProduct(product._id, history)}
                    >
                        <i className='far fa-trash-alt' /> Delete
                    </button> */}

                            <Link
                                to={`/admin/editproduct/${data._id}`}
                                className='btn btn-light'
                            >
                                <i className='fas fa-edit' /> Edit
                            </Link>
                        </div>
                    )}
                </div>
            )}
        </Fragment>
    );
};

Products.propTypes = {
    // deleteProduct: PropTypes.func.isRequired,
    data: PropTypes.object.isRequired, // product obj passed in to component
    auth: PropTypes.object.isRequired,
    product_loading: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
    auth: state.auth,
    product_loading: state.product.loading_product,
});

export default connect(
    mapStateToProps /*, {
    deleteProduct,
}*/
)(Products);
