import React from 'react';
import PropTypes from 'prop-types';
import Autocomplete from '../layout/Autocomplete';
import UploadRegimenStepPic from './forms/UploadRegimenStepPic';

const Step = function ({
    regimen,
    details, // regimen step field
    stepid, // regimen step id if it has been saved to db, otherwise null
    photo, // path to pic
    video, // path to video
    productobjects, // regimen step field
    productnames, // regimen step field
    timeminutes, // regimen step field
    onChange, // function
    onClick,
    numSteps, // for numbering steps and making sure 1 step left can't be deleted
    stepNum, // current step number
    products_autocomplete,
}) {
    return (
        <div className='regimen-step-wrapper'>
            <h3 className='mb'>STEP {stepNum + 1}</h3>

            {stepid && (
                <UploadRegimenStepPic
                    stepid={stepid}
                    photopath={photo}
                    videopath={video}
                    regimen={regimen}
                />
            )}
            <p className='form-header pt'>* Details</p>
            <textarea
                placeholder='Step details'
                name='details'
                value={details}
                onChange={onChange}
                className='mb-1'
                required
            />
            <p className='form-header'>Products used</p>

            <Autocomplete
                cssID={'step' + stepNum}
                stringsName='productnames'
                objectsName='productobjects'
                stringsData={productnames /* passed in as array */}
                objectsData={productobjects}
                placeholder='Enter any products used in this step'
                onChangeHandler={onChange}
                suggestions={products_autocomplete}
                allowNotFoundSuggestions={true}
            />

            <p className='form-header'>* Time to complete step (in minutes)</p>
            <input
                type='text'
                placeholder='Number of minutes'
                name='timeminutes'
                value={timeminutes}
                onChange={onChange}
                onKeyPress={(e) => {
                    e.key === 'Enter' && e.preventDefault();
                }}
                className='mb-1'
                required
            />
            {numSteps > 1 ? (
                <button
                    type='button'
                    className='btn btn-danger mt'
                    onClick={onClick}
                >
                    <i className='far fa-trash-alt' /> Delete step
                </button>
            ) : (
                ''
            )}
        </div>
    );
};

Step.propTypes = {
    details: PropTypes.string.isRequired,
    stepid: PropTypes.string,
    photo: PropTypes.string,
    video: PropTypes.string,
    regimen: PropTypes.object,
    // // productobjects: PropTypes.array.isRequired, @todo2 fix proptypes issue...
    // productnames: PropTypes.array.isRequired,
    // timeminutes: PropTypes.number.isRequired,
    onChange: PropTypes.func.isRequired,
    onClick: PropTypes.func.isRequired,
    // numSteps: PropTypes.number.isRequired,
    // stepNum:  PropTypes.number.isRequired,
    products_autocomplete: PropTypes.array.isRequired,
};

export default Step;
