import React, { Fragment, useEffect, useState, useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Spinner from '../layout/Spinner';
import Autocomplete from '../layout/Autocomplete';
import Tag from '../layout/Tag';
import {
    sortProducts,
    filterProducts,
    updateProductPageNumber,
    updateProductQueryParam,
} from '../../actions/product';
import { getIngredients } from '../../actions/ingredient';
import { getCompanies } from '../../actions/company';
import { URLhelpers } from '../../utils/helpers';

const initialState = {
    // used to keep track of default filter properties
    sort_products_by: 'priority',
    show_page_num: 1,
    filter_products_by: {
        bools: {
            isfragrancefree: null, // true means it is cared about; false means ignore it
            hasprotein: null,
            hassilicones: null,
            hasparabens: null,
            hassulfates: null,
            hasoils: null,
            needsreview: null,
            isdraft: null,
            hasnoamazonlink: null,
        },
        ingredients: [],
        noningredients: [],
        company: [],
        collection: [],
        categories: [], // array of category strings, e.g. Shampoo
    },
};

// used to validate the url param keys in location.search
const validkeys = [
    'ingredients',
    'noningredients',
    'company',
    'collection',
    'pg',
    'sortby',
    'ss',
    'categories',
    'isfragrancefree',
    'hasprotein',
    'hassilicones',
    'hasparabens',
    'hassulfates',
    'hasoils',
    'needsreview',
    'isdraft',
    'hasnoamazonlink',
];

// types and the string or prefix that will appear in front of the quick-removal filter at top of page (if supported)
const filterTypes = {
    needsreview: 'Needs Review',
    hasnoamazonlink: 'No Amazon Link',
    isdraft: 'Drafts',
    isfragrancefree: { true: 'Fragrance-Free', false: 'Has Fragrance' },
    hasprotein: { true: 'Has Protein', false: 'Protein-Free' },
    hasparabens: { true: 'Has Parabens', false: 'Paraben-Free' },
    hassilicones: { true: 'Has Silicones', false: 'Silicone-Free' },
    hassulfates: { true: 'Has Sulfates', false: 'Sulfate-Free' },
    hasoils: { true: 'Has Oils', false: 'Oil-Free' },
    company: '',
    collection: '',
    ingredients: 'Has ',
    noningredients: 'No ',
    categories: '',
    ss: 'Search: ',
};

const productCategories = [
    'Shampoo',
    'Conditioner',
    'Leave-In Conditioner',
    'Deep Conditioner',
    'Co-Wash',
    'Detangler',
    'Gel',
    'Styling Cream',
    'Hair Treatment',
    'Pre-Poo',
    'Hair Mask',
    'Hair Rinse',
    'Oil',
    'Tonic',
    'Spritz',
    'Serum',
    'Gloss',
    'Mousse',
    'Hairspray',
    'Heat Protectant',
    'Scalp Reliever',
    'Other',
    'Not Specified',
];

const ProductsFilters = ({
    sortProducts,
    filterProducts,
    updateProductPageNumber,
    updateProductQueryParam,
    product: {
        sort_products_by,
        filter_products_by,
        product_query_str,
        show_page_num,
        loading,
    },
    getIngredients,
    ingredient: { ingredients_autocomplete, loading_autocomplete }, // state
    getCompanies,
    companies_autocomplete,
    loading_companies_autocomplete,
    auth,
}) => {
    let history = useNavigate();
    let location = useLocation();
    const [filterTags, setFilterTags] = useState([]);

    // this uses a callback so the function isn't recreated every time it is called in a useEffect call
    // see this for more info https://stackoverflow.com/questions/56492261/the-function-makes-the-dependencies-of-useeffect-hook
    const deleteURLparam = useCallback(
        (key) => {
            URLhelpers.deleteURLparam(history, location, key);
        },
        [history, location]
    );

    const getURLParam = useCallback(
        (param) => {
            return URLhelpers.getURLParam(param, location);
        },
        [location]
    );

    const getPageNumParam = useCallback(() => {
        return URLhelpers.getPageNumParam(history, location);
    }, [history, location]);

    const setURLparam = useCallback(
        (kvobj) => {
            URLhelpers.setURLparam(history, location, kvobj);
        },
        [history, location]
    );

    const checkURLParams = useCallback((url) => {
        const searchParams = new URLSearchParams(url);
        return URLhelpers.checkURLParams(searchParams, validkeys);
    }, []);

    // Creates the object of filterable data that is passed to the filtering function in the relevant useEffect call
    // params: searchParams, an object that is validated for proper URL (location.search) keys and values
    const getFilterParams = useCallback(
        (searchParams) => {
            let origurl = searchParams.toString();
            // do basic validation
            searchParams = checkURLParams(searchParams);

            let filter_products_by = {
                ss: '',
                bools: {
                    isfragrancefree: null, // true means it is cared about; false means ignore it
                    hasprotein: null,
                    hassilicones: null,
                    hasparabens: null,
                    hassulfates: null,
                    hasoils: null,
                    needsreview: null,
                    isdraft: null,
                    hasnoamazonlink: null,
                },
                ingredients: [],
                noningredients: [],
                company: [],
                collection: [],
                categories: [], // array of category strings, e.g. Shampoo
            };

            if (!origurl.length) return { filter_products_by, searchParams };

            let val = searchParams.get('ss');
            if (val) {
                filter_products_by.ss = val;
            }

            const keys = Object.keys(filter_products_by.bools);
            let adminBools = ['needsreview', 'isdraft', 'hasnoamazonlink'];

            for (let i = 0; i < keys.length; i++) {
                let key = keys[i];
                let needsadmin = adminBools.includes(key);

                let val = searchParams.get(key);
                if (val === 'true' || val === 'false') {
                    if (needsadmin) {
                        if (val === 'true' && auth.isAdmin) {
                            filter_products_by.bools[key] = 'true';
                        } else {
                            searchParams.delete(key);
                        }
                    } else {
                        filter_products_by.bools[key] = val;
                    }
                } else {
                    searchParams.delete(key);
                }
            }

            val = searchParams.get('company');
            if (val) {
                let matchingcoarr = companies_autocomplete.filter(
                    (c) => c._id === val
                );
                if (matchingcoarr.length > 0) {
                    let matchingco = matchingcoarr[0];
                    filter_products_by.company = [matchingco];

                    let valcol = searchParams.get('collection');
                    if (valcol) {
                        let matchingcollarr =
                            matchingco.productcollections.filter(
                                (c) => c._id === valcol
                            );
                        if (matchingcollarr.length > 0) {
                            filter_products_by.collection = [
                                matchingcollarr[0],
                            ];
                        } else {
                            searchParams.delete('collection');
                        }
                    }
                } else {
                    searchParams.delete('company');
                    searchParams.delete('collection'); // in case it is set
                }
            } else {
                searchParams.delete('company');
                searchParams.delete('collection'); // in case it is set
            }

            val = searchParams.get('ingredients');
            if (val) {
                let valsplitintoarr = val.split(',');
                valsplitintoarr.forEach((ingid) => {
                    let matchinging = ingredients_autocomplete.filter(
                        (i) => i._id === ingid
                    );
                    if (matchinging.length > 0) {
                        filter_products_by.ingredients.push(matchinging[0]);
                    } else {
                        // at least 1 wrong so remove them all
                        searchParams.delete('ingredients');
                        return;
                    }
                });
            }

            val = searchParams.get('noningredients');
            if (val) {
                let valsplitintoarr = val.split(',');
                valsplitintoarr.forEach((ingid) => {
                    let matchinging = ingredients_autocomplete.filter(
                        (i) => i._id === ingid
                    );
                    if (matchinging.length > 0) {
                        filter_products_by.noningredients.push(matchinging[0]);
                    } else {
                        // at least 1 wrong so remove them all
                        searchParams.delete('noningredients');
                        return;
                    }
                });
            }

            val = searchParams.get('categories');
            if (val) {
                let valsplitintoarr = val.split(',');
                valsplitintoarr.forEach((cat) => {
                    if (productCategories.includes(cat)) {
                        filter_products_by.categories.push(cat);
                    } else {
                        // at least 1 wrong so remove them all
                        searchParams.delete('categories');
                        return;
                    }
                });
            }

            // if searchParams was updated (e.g. at least one key deleted), update URL:

            return { filter_products_by, searchParams };
        },
        [
            checkURLParams,
            companies_autocomplete,
            ingredients_autocomplete,
            auth.isAdmin,
        ]
    );

    const getSortParamAndSort = useCallback(() => {
        let queryparam = location.search;
        if (!queryparam) return;

        const searchParams = new URLSearchParams(location.search);

        let sortby = searchParams.get('sortby');
        let sortbyvals = ['created', 'company.name', 'name', 'priority'];
        if (sortbyvals.includes(sortby)) {
            sortProducts(sortby);
        }
    }, [location.search, sortProducts]);

    const generateFilterTags = useCallback(() => {
        // e.g. location: company=60904bcc88922608fa3f26e9&pg=1&isfragrancefree=false
        const searchParams = new URLSearchParams(location.search);
        let tags = [];

        for (var key of searchParams.keys()) {
            let typeval = filterTypes[key];
            // don't support quick-clearing of the following filter types:
            // (quick clearing shows selected tags at top for easy removal)
            if (
                [
                    'ingredients',
                    'noningredients',
                    'company',
                    'collection',
                    'pg',
                    'sortby',
                    'ss',
                ].includes(key) ||
                typeval === null
            )
                continue;

            // let sortbyvals = ['created', 'company.name', 'name', 'priority'];
            // if (sortbyvals.includes(sortby)) {
            //     sortProducts(sortby);
            // } else {
            //     deleteURLparam(sortby);
            // }

            let val = searchParams.get(key);
            let string = '';

            if (typeof typeval === 'object' && typeval !== null) {
                string += typeval[val];
                let obj = { key, value: string };

                tags.push(obj);
            } else if (
                ['needsreview', 'isdraft', 'hasnoamazonlink'].includes(key)
            ) {
                string += typeval;
                let obj = { key, value: string };
                tags.push(obj);
            } else if (key === 'categories') {
                for (var cat of val.split(',')) {
                    let obj = { key, value: cat };
                    tags.push(obj);
                }
            } else {
                string +=
                    typeval +
                    (val[0] !== null && val.length > 0 // key !== 'ss'
                        ? val[0].toUpperCase() + val.substring(1)
                        : val);
                let obj = { key, value: string };
                tags.push(obj);
            }
        }

        setFilterTags(tags);
    }, [location.search]);

    // this will run every time the location url changes (when a search string or filter changes)
    useEffect(() => {
        // need to make sure all data is loaded
        // for example, if companies aren't done loading, then the company url param
        // will be deleted if user requests a company filter the first time the products
        // pg is loaded. BAD! so be sure to load all impt stuff here
        if (
            !auth.loading &&
            !loading &&
            !loading_companies_autocomplete &&
            !loading_autocomplete /* for ingredients */
        ) {
            if (
                !location.search &&
                product_query_str.length &&
                product_query_str !== '?'
            ) {
                // update browser URL if user navigated away and came back to this pg
                let params = new URLSearchParams(product_query_str);

                let data = getFilterParams(params); // makes key-based param updates
                params = data.searchParams;
                let newurl = params.toString();

                newurl.length &&
                    history(`?${newurl}`, {
                        replace: true,
                        isActive: true,
                    });
            } else {
                let params = new URLSearchParams(location.search);
                let origurl = params.toString();

                let data = getFilterParams(params);
                let filters = data.filter_products_by;
                params = data.searchParams;
                let updated = params.toString(); // doesn't include leading '?'
                let updatedwq = '?' + updated;

                if (origurl !== updated) {
                    updateProductQueryParam(
                        updated.length ? updatedwq : updated
                    );

                    history(`?${updated}`, {
                        replace: true,
                        isActive: true,
                    });
                }

                let pgnum = getPageNumParam(updatedwq);
                updateProductPageNumber(pgnum);

                filterProducts(filters, auth.isAdmin, updatedwq);
                getSortParamAndSort();
                generateFilterTags();
            }
        }
    }, [
        filterProducts,
        updateProductPageNumber,
        auth.isAdmin,
        product_query_str,
        auth.loading,
        loading,
        loading_companies_autocomplete,
        loading_autocomplete,
        history,
        location,
        getFilterParams,
        checkURLParams,
        getSortParamAndSort,
        getURLParam,
        getPageNumParam,
        generateFilterTags,
        updateProductQueryParam,
    ]);

    // for testing:
    // useEffect(() => {
    //     console.log('url changed: ' + location.search);
    // }, [location.search]);

    useEffect(() => {
        getIngredients(true /* get autocomplete data */);
    }, [getIngredients]);

    useEffect(() => {
        getCompanies(true /* get autocomplete data */);
    }, [getCompanies]);

    useEffect(() => {
        window.scrollTo(0, 0); // scroll to top of form on mount
    }, []);

    // takes the user-selected filters and updates URL to reflect that change, which will trigger the component to re-render in the appropriate useEffect call above (because of the changed location)
    const changeFilters = (e) => {
        /* filter_products_by gets updated via the filterProducts call (via a call to getFilterParams which parses location.search to generate this object) whenever url location.search is updated
        filter_products_by properties:
            ss: ''
            bools:  isfragrancefree
                    hasprotein
                    hassilicones
                    hasparabens
                    hassulfates
                    hasoils
                    needsreview
                    isdraft
                    hasnoamazonlink
            ingredients: [] (contains all listed ingredients)
            noningredients: [] (doesn't contain any of the listed ingredients)
            company: []
            collection: []
            categories: []
        */
        let property = e.target.name;

        if (property === 'sortby') {
            let sortbyval = e.target.value;
            setURLparam({ sortby: sortbyval, pg: 1 });
        } else if (
            property === 'isfragrancefree' ||
            property === 'hasprotein' ||
            property === 'hassilicones' ||
            property === 'hasparabens' ||
            property === 'hassulfates' ||
            property === 'hasoils'
        ) {
            let val = e.target.value;
            // if (e.target.value === 'yes') val = 'true';
            // if (e.target.value === 'no') val = 'false';

            if (val === 'true' || val === 'false') {
                var o = { pg: 1 };
                o[property] = val.toString();
                setURLparam(o);
            } else deleteURLparam(property);
        } else if (
            property === 'ingredients' ||
            property === 'noningredients' ||
            property === 'company' ||
            property === 'collection'
        ) {
            const parsed = JSON.parse(e.target.value);
            let val = parsed.map((obj) => obj._id);
            if (val.length) {
                let o = { pg: 1 };

                o[property] = val.toString();
                setURLparam(o);
            } else deleteURLparam(property);
        } else if (property === 'categories') {
            let currentcats = [...filter_products_by.categories]; // make copy so we don't update redux state here
            let val = e.target.checked ? e.target.value : null;

            if (val) {
                currentcats.push(e.target.value); // add category to array
            } else {
                currentcats = currentcats.filter(
                    (cat) => cat !== e.target.value
                );
            }

            if (currentcats.length) {
                let o = { pg: 1 };
                o[property] = currentcats.toString();
                setURLparam(o);
            } else deleteURLparam(property);
        } else if (
            property === 'needsreview' ||
            property === 'isdraft' ||
            property === 'hasnoamazonlink'
        ) {
            let val = e.target.checked && auth.isAdmin ? 'true' : null;

            if (val === 'true') {
                let o = { pg: 1 };
                o[property] = val.toString();
                setURLparam(o);
            } else deleteURLparam(property);
        }
    };

    return loading ? (
        <Spinner />
    ) : (
        <div className='data-search'>
            <h2 className='mb'>Filters</h2>
            {filterTags.map((tag, idx) => (
                <Tag
                    name={tag.value}
                    key={'filter-tag-' + idx}
                    deleteHandler={() => {
                        tag.key === 'ss'
                            ? setURLparam({
                                  ss: tag.value,
                                  pg: 1,
                              })
                            : changeFilters({
                                  target: {
                                      name: tag.key,
                                      value: tag.value,
                                  },
                              });
                    }}
                />
            ))}
            {filterTags.length > 0 && <hr className='my' />}

            <form className='form' autoComplete='off'>
                {auth.isAuthenticated && auth.isAdmin && (
                    <Fragment>
                        <p className='form-header'>Admin</p>
                        <span className='block mb'>
                            <input
                                type='checkbox'
                                name='needsreview'
                                checked={
                                    filter_products_by.bools.needsreview ===
                                    'true' /* a string instead of bool to avoid (un)controlled component issues */
                                }
                                onChange={changeFilters}
                                onKeyPress={(e) => {
                                    e.key === 'Enter' && e.preventDefault();
                                }}
                            />{' '}
                            Needs Review <br />
                        </span>
                        <span className='block mb'>
                            <input
                                type='checkbox'
                                name='isdraft'
                                checked={
                                    filter_products_by.bools.isdraft ===
                                    'true' /* a string instead of bool to avoid (un)controlled component issues */
                                }
                                onChange={changeFilters}
                                onKeyPress={(e) => {
                                    e.key === 'Enter' && e.preventDefault();
                                }}
                            />{' '}
                            Only Show Drafts <br />
                        </span>
                        <span className='block mb-1'>
                            <input
                                type='checkbox'
                                name='hasnoamazonlink'
                                checked={
                                    filter_products_by.bools.hasnoamazonlink ===
                                    'true' /* a string instead of bool to avoid (un)controlled component issues */
                                }
                                onChange={changeFilters}
                                onKeyPress={(e) => {
                                    e.key === 'Enter' && e.preventDefault();
                                }}
                            />{' '}
                            Missing Amazon Link <br />
                        </span>
                        <hr className='mt' />
                    </Fragment>
                )}

                <div className='form-group'>
                    <p className='form-header'>Sort By</p>
                    <select
                        name='sortby'
                        value={
                            sort_products_by || initialState.sort_products_by
                        }
                        onChange={changeFilters}
                        onKeyPress={(e) => {
                            e.key === 'Enter' && e.preventDefault();
                        }}
                    >
                        <option value='priority'>Featured</option>
                        <option value='name'>Product Name</option>
                        <option value='company.name'>Company Name</option>
                        <option value='created'>Date Added</option>
                    </select>
                </div>

                <div>
                    <p className='form-header'>Brand</p>
                    {loading_companies_autocomplete ? (
                        <Spinner size={0} />
                    ) : (
                        <span className='block mb-1'>
                            <Autocomplete
                                cssID={'productsearch_co'}
                                objectsName='company'
                                objectsData={filter_products_by.company}
                                onChangeHandler={(e) => changeFilters(e)}
                                suggestions={companies_autocomplete}
                                selectOneMax={true}
                            />
                        </span>
                    )}
                </div>

                {filter_products_by.company &&
                    filter_products_by.company.length > 0 && (
                        <div>
                            <p className='form-header'>Collection</p>
                            <span className='block mb-1'>
                                <Autocomplete
                                    cssID={'productsearch_coll'}
                                    objectsName='collection'
                                    objectsData={filter_products_by.collection}
                                    onChangeHandler={(e) => changeFilters(e)}
                                    suggestions={
                                        filter_products_by.company[0]
                                            .productcollections || []
                                    }
                                    selectOneMax={true}
                                    showAllOptions={true}
                                />
                            </span>
                        </div>
                    )}

                <div>
                    <p className='form-header'>Ingredients</p>
                    {loading_autocomplete ? (
                        <Spinner size={0} />
                    ) : (
                        <Fragment>
                            <p className='small allcaps'>Contains:</p>
                            <span className='block mb-1'>
                                <Autocomplete
                                    cssID={'productsearch_ing'}
                                    objectsName='ingredients'
                                    objectsData={filter_products_by.ingredients}
                                    onChangeHandler={(e) => changeFilters(e)}
                                    suggestions={ingredients_autocomplete}
                                    selectOneMax={false}
                                />
                            </span>

                            <p className='small allcaps'>
                                Doesn't Directly Contain:
                            </p>
                            <span className='block mb-1'>
                                <Autocomplete
                                    cssID={'productsearch_noning'}
                                    objectsName='noningredients'
                                    objectsData={
                                        filter_products_by.noningredients
                                    }
                                    onChangeHandler={(e) => changeFilters(e)}
                                    suggestions={ingredients_autocomplete}
                                    selectOneMax={false}
                                />
                            </span>
                        </Fragment>
                    )}
                </div>

                <div>
                    <p className='form-header'>Type</p>
                    <p className='small'>
                        Match <strong>any</strong> of the following:
                    </p>
                    {loading_autocomplete ? (
                        <Spinner size={0} />
                    ) : (
                        <span className='block mb-1'>
                            {productCategories.map((category) => (
                                <Fragment key={category.toString()}>
                                    <input
                                        type='checkbox'
                                        name='categories'
                                        value={category}
                                        checked={filter_products_by.categories.includes(
                                            category
                                        )}
                                        onChange={changeFilters}
                                        onKeyPress={(e) => {
                                            e.key === 'Enter' &&
                                                e.preventDefault();
                                        }}
                                    />{' '}
                                    {category} <br />
                                </Fragment>
                            ))}
                        </span>
                    )}
                </div>

                <div className='form-group'>
                    <p className='form-header'>Fragrance</p>
                    <span className='block mb-1'>
                        <select
                            name='isfragrancefree'
                            value={
                                filter_products_by.bools.isfragrancefree
                                    ? filter_products_by.bools.isfragrancefree
                                    : 'unselected'
                            }
                            onChange={changeFilters}
                            onKeyPress={(e) => {
                                e.key === 'Enter' && e.preventDefault();
                            }}
                        >
                            <option value='unselected'>All</option>
                            <option value='true'>Fragrance-Free</option>
                            <option value='false'>Has Fragrance</option>
                        </select>
                    </span>

                    <p className='form-header'>Protein</p>
                    <span className='block mb-1'>
                        <select
                            name='hasprotein'
                            value={
                                filter_products_by.bools.hasprotein
                                    ? filter_products_by.bools.hasprotein
                                    : 'unselected'
                            }
                            onChange={changeFilters}
                            onKeyPress={(e) => {
                                e.key === 'Enter' && e.preventDefault();
                            }}
                        >
                            <option value='unselected'>All</option>
                            <option value='false'>Protein-Free</option>
                            <option value='true'>
                                Has Protein/Amino Acids
                            </option>
                        </select>
                    </span>

                    <p className='form-header'>Silicones</p>
                    <span className='block mb-1'>
                        <select
                            name='hassilicones'
                            value={
                                filter_products_by.bools.hassilicones
                                    ? filter_products_by.bools.hassilicones
                                    : 'unselected'
                            }
                            onChange={changeFilters}
                            onKeyPress={(e) => {
                                e.key === 'Enter' && e.preventDefault();
                            }}
                        >
                            <option value='unselected'>All</option>
                            <option value='false'>Silicone-Free</option>
                            <option value='true'>Has Silicones</option>
                        </select>
                    </span>
                </div>

                <div>
                    <p className='form-header'>Sulfates</p>
                    <span className='block mb-1'>
                        <select
                            name='hassulfates'
                            value={
                                filter_products_by.bools.hassulfates
                                    ? filter_products_by.bools.hassulfates
                                    : 'unselected'
                            }
                            onChange={changeFilters}
                            onKeyPress={(e) => {
                                e.key === 'Enter' && e.preventDefault();
                            }}
                        >
                            <option value='unselected'>All</option>
                            <option value='false'>Sulfate-Free</option>
                            <option value='true'>Has Sulfates</option>
                        </select>
                    </span>
                </div>

                <div>
                    <p className='form-header'>Oils</p>
                    <span className='block mb-1'>
                        <select
                            name='hasoils'
                            value={
                                filter_products_by.bools.hasoils
                                    ? filter_products_by.bools.hasoils
                                    : 'unselected'
                            }
                            onChange={changeFilters}
                            onKeyPress={(e) => {
                                e.key === 'Enter' && e.preventDefault();
                            }}
                        >
                            <option value='unselected'>All</option>
                            <option value='false'>Oil-Free</option>
                            <option value='true'>Has Oils</option>
                        </select>
                    </span>
                </div>

                <div>
                    <p className='form-header'>Parabens</p>
                    <span className='block mb-1'>
                        <select
                            name='hasparabens'
                            value={
                                filter_products_by.bools.hasparabens
                                    ? filter_products_by.bools.hasparabens
                                    : 'unselected'
                            }
                            onChange={changeFilters}
                            onKeyPress={(e) => {
                                e.key === 'Enter' && e.preventDefault();
                            }}
                        >
                            <option value='unselected'>All</option>
                            <option value='false'>Paraben-Free</option>
                            <option value='true'>Has Parabens</option>
                        </select>
                    </span>
                </div>
            </form>
        </div>
    );
};

ProductsFilters.propTypes = {
    filterProducts: PropTypes.func.isRequired,
    sortProducts: PropTypes.func.isRequired,
    updateProductPageNumber: PropTypes.func.isRequired,
    updateProductQueryParam: PropTypes.func.isRequired,
    product: PropTypes.object.isRequired,
    auth: PropTypes.object.isRequired,
    getIngredients: PropTypes.func.isRequired,
    ingredient: PropTypes.object.isRequired,
    getCompanies: PropTypes.func.isRequired,
    companies_autocomplete: PropTypes.array.isRequired,
    loading_companies_autocomplete: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
    product: state.product,
    ingredient: state.ingredient,
    auth: state.auth,
    companies_autocomplete: state.company.companies_autocomplete,
    loading_companies_autocomplete: state.company.loading_autocomplete,
});

export default connect(mapStateToProps, {
    sortProducts,
    filterProducts,
    updateProductPageNumber,
    updateProductQueryParam,
    getIngredients,
    getCompanies,
})(ProductsFilters);
