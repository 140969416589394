import React, { useEffect } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux'; // so we can interact with our auth state in the auth reducer so we can check authentication
import Spinner from '../layout/Spinner';
import { removeAllNonStickyAlerts } from '../../actions/alert';

// Protects route: certain pages are only for logged in users, and others are only for admins.
const PrivateRoute = ({
    auth: { isAuthenticated, isAdmin, isSuperAdmin, loading },
    adminPage = false, // boolean. pass this prop in if it is a page for only admins.
    superAdminPage = false, // pass this prop in if it is a page for only super admins.
    removeAllNonStickyAlerts,
}) => {
    // trigger on component mount
    useEffect(() => {
        removeAllNonStickyAlerts();
    }, [removeAllNonStickyAlerts]);

    let componentToRender = () => {
        if (loading) {
            // this is important since we need to check auth state, so need it to be loaded first
            return (
                <div className='containermiddle'>
                    <Spinner />
                </div>
            );
        } else {
            // if (isAuthenticated) {
            //     if (
            //         (adminPage && isAdmin) ||
            //         (superAdminPage && isSuperAdmin)
            //     ) {
            //         // load the component and any of its passed in props
            //         return <Outlet />;
            //     } else return <Outlet />; // non-admin auth page
            // } else {
            //     return <Navigate to='/login' />;
            // }
            if (isAuthenticated) {
                if (adminPage) {
                    // load the component and any of its passed in props
                    return isAdmin ? <Outlet /> : <Navigate to='/dashboard' />;
                } else if (superAdminPage) {
                    // load the component and any of its passed in props
                    return isSuperAdmin ? (
                        <Outlet />
                    ) : (
                        <Navigate to='/dashboard' />
                    );
                } else return <Outlet />; // non-admin private page
            } else {
                return <Navigate to='/login' />;
            }
        }
    };

    return componentToRender();
};

PrivateRoute.propTypes = {
    auth: PropTypes.object.isRequired,
    removeAllNonStickyAlerts: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
    auth: state.auth,
});

export default connect(mapStateToProps, { removeAllNonStickyAlerts })(
    PrivateRoute
);
