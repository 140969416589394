import React, { useState } from 'react';
import { Link, Navigate } from 'react-router-dom';
import { connect } from 'react-redux'; // connects component to redux
import { Helmet } from 'react-helmet-async';
import { APP_NAME } from '../../utils/constants';
import { login } from '../../actions/auth';
import PropTypes from 'prop-types';

// create function component
const Login = ({ login, isAuthenticated }) => {
    // format: [state, function to update state]
    const [formData, setFormData] = useState({
        // default values
        username: '',
        password: '',
    });

    // destructure so I don't have to do formData.name, etc...
    // The state will be available everywhere in this file :)
    const { username, password } = formData;

    const onChange = (e) =>
        // ... is the spread operator which makes a copy of formData
        // [e.target.name] generalizes the function so it can apply to any field
        // Update formData:
        setFormData({ ...formData, [e.target.name]: e.target.value });

    const onSubmit = async (e) => {
        e.preventDefault(); // don't let browser do its default behavior
        login(username, password);
    };

    if (isAuthenticated) {
        return <Navigate to='/dashboard' />;
    }

    return (
        <section className='landing'>
            <Helmet>
                <title>{APP_NAME} | Log In</title>
            </Helmet>
            <div className='landing-overlay'>
                <div className='landing-form'>
                    <p className='lead'>
                        <i className='fas fa-user'></i> Sign In
                    </p>
                    <form className='form' onSubmit={onSubmit}>
                        <div className='form-group'>
                            <input
                                type='text'
                                placeholder='Username'
                                name='username'
                                value={username}
                                onChange={onChange}
                                required
                            />
                        </div>
                        <div className='form-group'>
                            <input
                                type='password'
                                placeholder='Password'
                                value={password}
                                onChange={onChange}
                                name='password'
                                required
                            />
                        </div>
                        <input
                            type='submit'
                            className='btn btn-primary mr-0'
                            value='Sign In'
                        />
                    </form>
                    <p className='mt-1 small'>
                        <Link to='/forgotpassword'>Forgot Password</Link>
                    </p>
                    <p>
                        Don't have an account?{' '}
                        <Link to='/register'>Sign Up</Link>
                    </p>
                </div>
            </div>
        </section>
    );
};

Login.propTypes = {
    login: PropTypes.func.isRequired,
    isAuthenticated: PropTypes.bool,
};

// bring in auth state to see if authenticated
// map the redux state to a prop in this component.
// https://react-redux.js.org/using-react-redux/connect-mapstate
// As the first argument passed in to connect, mapStateToProps is used for selecting the part of the data from the store that the connected component needs. It’s frequently referred to as just mapState for short.
// It is called every time the store state changes.
// It receives the entire store state, and should return an object of data this component needs.
// The first argument to a mapStateToProps function is the entire Redux store state (the same value returned by a call to store.getState())
// Your mapStateToProps function should return a plain object that contains the data the component needs:
// Each field in the object will become a prop for your actual component
// The values in the fields will be used to determine if your component needs to re-render
const mapStateToProps = (state) => ({
    isAuthenticated /* prop name */:
        state.auth.isAuthenticated /* gets the auth state */,
});

// to use an action, pass it into connect below to allow us to access props.login so we can use it in this component.
// 1st param is state to connect (not needed here). 2nd param is any actions I want to use
export default connect(mapStateToProps, { login })(Login);
