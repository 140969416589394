import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { APP_NAME } from '../../utils/constants';
import { getAllUsers } from '../../actions/admin';
import { getCompanies } from '../../actions/company';
import { getIngredients } from '../../actions/ingredient';
import { getProducts } from '../../actions/product';
import { getRegimensAdmin } from '../../actions/regimen';
import Spinner from '../layout/Spinner';
import AdminNav from './AdminNav';

const Console = ({
    auth,
    getAllUsers,
    admin,
    company,
    ingredient,
    product,
    regimen,
    getCompanies,
    getIngredients,
    getProducts,
    getRegimensAdmin,
}) => {
    useEffect(() => {
        if (!auth.loading) {
            getAllUsers();
            getCompanies(true);
            getIngredients(true);
            getProducts(true);
            getRegimensAdmin();
        }
    }, [
        auth.loading,
        getAllUsers,
        getCompanies,
        getIngredients,
        getProducts,
        getRegimensAdmin,
    ]);

    return (
        <div className='containermiddle'>
            <Helmet>
                <title>{APP_NAME} | Admin Console</title>
            </Helmet>
            <AdminNav isSuperAdmin={auth.isSuperAdmin} />
            {auth.loading ||
            admin.loading ||
            regimen.loading ||
            !admin.users ||
            ingredient.loading_autocomplete ||
            product.loading_autocomplete ||
            company.loading_autocomplete ? (
                // wait for product data to load
                <Spinner />
            ) : (
                <div className='admin-dashboard-boxes mb-2'>
                    <div className='admin-dashboard-box-small'>
                        <span>Members</span>{' '}
                        <strong>{admin.users.length}</strong>
                    </div>

                    <div className='admin-dashboard-box-small'>
                        <span>Regimens</span>{' '}
                        <strong>{regimen.regimens_admin.length}</strong>
                    </div>

                    <div className='admin-dashboard-box-small'>
                        <span>Products</span>{' '}
                        <strong>{product.products_autocomplete.length}</strong>
                    </div>

                    <div className='admin-dashboard-box-small'>
                        <span>Ingredients</span>{' '}
                        <strong>
                            {ingredient.ingredients_autocomplete.length}
                        </strong>
                    </div>

                    <div className='admin-dashboard-box-small'>
                        <span>Companies</span>{' '}
                        <strong>{company.companies_autocomplete.length}</strong>
                    </div>
                </div>
            )}

            <h2 className='mb-0'>Shortcuts</h2>
            <Link to='/admin/addingredient' className='btn btn-light my-1'>
                <i className='fas fa-plus text-primary' /> Add Ingredient
            </Link>
            <Link to='/admin/addproduct' className='btn btn-light my-1'>
                <i className='fas fa-plus text-primary' /> Add Product
            </Link>
            <Link to='/admin/addcompany' className='btn btn-light my-1'>
                <i className='fas fa-plus text-primary' /> Add Company
            </Link>
        </div>
    );
};

Console.propTypes = {
    getAllUsers: PropTypes.func.isRequired,
    getCompanies: PropTypes.func.isRequired,
    getIngredients: PropTypes.func.isRequired,
    getProducts: PropTypes.func.isRequired,
    getRegimensAdmin: PropTypes.func.isRequired,
    admin: PropTypes.object.isRequired,
    auth: PropTypes.object.isRequired,
    company: PropTypes.object.isRequired,
    regimen: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    admin: state.admin,
    auth: state.auth,
    company: state.company,
    ingredient: state.ingredient,
    product: state.product,
    regimen: state.regimen,
});

export default connect(mapStateToProps, {
    getAllUsers,
    getCompanies,
    getIngredients,
    getProducts,
    getRegimensAdmin,
})(Console);
