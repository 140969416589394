import React, { Fragment, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useLocation, useNavigate } from 'react-router-dom';
import { URLhelpers } from '../../utils/helpers';

const Pagination = ({
    children, // automatically brings in whatever JSX is provided inside the Pagination component tags
    data, // An array of data that should be shown in the paginated form
    wrapperClass, // class that the div will have to wrap around the data rows/boxes/elements/etc.
    RenderComponent, // A component that should be used to show the paginated data
    startOnPage, // determines which page to start on
    pageLimit, // The number of pages to be shown in the pagination. e.g. 3 pages at a time
    dataLimit, // The number of posts to be shown on each page
    onChangeHandler, // function in caller that is called
}) => {
    let history = useNavigate();
    let location = useLocation();

    /* STATE:
    pages: The total # of pages. Calculated by dividing the length of the data array that will be passed to this component as a prop, by the dataLimit which is the number of posts we will show on each page. We also need to round off the result of this division.
currentPage: This is the current page that the user is currently visiting. The initial value will be 1. */
    // const [pages] = useState(Math.round(data.length / dataLimit));
    const [pages, setPages] = useState(Math.ceil(data.length / dataLimit));
    const [currentPage, setCurrentPage] = useState(1); // default to page 1

    // update number of pages when data set changes, and
    // also reset current page to 1
    useEffect(() => {
        setPages(Math.ceil(data.length / dataLimit));
        let pg = startOnPage <= pages && startOnPage > 0 ? startOnPage : 1;
        setCurrentPage(pg); // if data changes, update pages
    }, [dataLimit, data, startOnPage, pages]);

    useEffect(() => {
        window.scrollTo({ behavior: 'smooth', top: '0px' });
    }, [currentPage]);

    function goToNextPage() {
        let newpg = currentPage + 1;
        setCurrentPage((page) => page + 1);
        URLhelpers.setURLparam(history, location, { pg: newpg });
        onChangeHandler(newpg);
    }

    function goToPreviousPage() {
        let newpg = currentPage - 1;
        setCurrentPage((page) => page - 1);
        URLhelpers.setURLparam(history, location, { pg: newpg });
        onChangeHandler(newpg);
    }

    function changePage(event) {
        const newpg = Number(event.target.textContent);
        setCurrentPage(newpg);
        URLhelpers.setURLparam(history, location, { pg: newpg });
        onChangeHandler(newpg);
    }

    function getPaginatedData() {
        const startIndex = currentPage * dataLimit - dataLimit;
        const endIndex = startIndex + dataLimit;
        return data.slice(startIndex, endIndex);
    }

    const getStartIndex = () => {
        let start = Math.floor((currentPage - 1) / pageLimit) * pageLimit;
        let diff = pages - start;
        let shift = diff < pageLimit ? pageLimit - diff : 0;
        if (start >= pageLimit) start -= shift;
        return start;
    };

    const getPaginationGroup = () => {
        let start = getStartIndex();
        let maxToShow = Math.min(pageLimit, pages);
        return new Array(maxToShow).fill().map((_, idx) => start + idx + 1);
    };

    const getPaginationText = (numsearched, show_page_num, numToShow) => {
        let text = 'Results ' + ((show_page_num - 1) * numToShow + 1) + ' - ';
        text +=
            show_page_num === Math.ceil(numsearched / numToShow)
                ? numsearched -
                  (Math.ceil(numsearched / numToShow) - 1) * numToShow +
                  (show_page_num - 1) * numToShow
                : show_page_num * numToShow;
        return text;
    };

    return (
        <Fragment>
            <h2 className='products_start'>
                {getPaginationText(data.length, startOnPage, dataLimit)}
            </h2>
            {children}
            {/* show the posts */}
            <div className={wrapperClass}>
                {getPaginatedData().map((d, idx) => (
                    <RenderComponent key={idx} data={d} />
                ))}
            </div>

            <div className='pagination'>
                {/* previous button */}
                <button
                    onClick={goToPreviousPage}
                    className={`prev ${currentPage === 1 ? 'disabled' : ''}`}
                >
                    &#8249;
                </button>

                {
                    // if group of shown numbers doesn't include 1st page, show #...:
                    !getPaginationGroup().includes(1) && (
                        <Fragment>
                            <button
                                key={1}
                                onClick={changePage}
                                className={'paginationItem'}
                            >
                                <span>1</span>
                            </button>
                            <button key='...' className={'paginationItem dots'}>
                                <span>...</span>
                            </button>
                        </Fragment>
                    )
                }

                {/* show page numbers */}
                {getPaginationGroup().map((item, index) => {
                    return item <= pages ? (
                        <button
                            key={index}
                            onClick={changePage}
                            className={`paginationItem ${
                                currentPage === item ? 'active' : ''
                            }`}
                        >
                            <span>{item}</span>
                        </button>
                    ) : (
                        ''
                    );
                })}

                {
                    // if group of shown numbers doesn't include last page, show ...#:
                    !getPaginationGroup().includes(pages) && (
                        <Fragment>
                            <button key='...' className={'paginationItem dots'}>
                                <span>...</span>
                            </button>
                            <button
                                key={pages}
                                onClick={changePage}
                                className={'paginationItem'}
                            >
                                <span>{pages}</span>
                            </button>
                        </Fragment>
                    )
                }

                {/* next button */}
                <button
                    onClick={goToNextPage}
                    className={`next ${
                        currentPage === pages ? 'disabled' : ''
                    }`}
                >
                    &#8250;
                </button>
            </div>
        </Fragment>
    );
};

Pagination.propTypes = {
    data: PropTypes.array.isRequired,
    startOnPage: PropTypes.number.isRequired,
    RenderComponent: PropTypes.object.isRequired,
    dataLimit: PropTypes.number.isRequired,
    pageLimit: PropTypes.number.isRequired,
    wrapperClass: PropTypes.string.isRequired,
    onChangeHandler: PropTypes.func.isRequired,
};

export default Pagination;
