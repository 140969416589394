// View All Companies
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import { APP_NAME } from '../../utils/constants';
import { getCompanies } from '../../actions/company';
import AdminNav from './AdminNav';
import CompanyItem from '../companies/CompanyItem';
import Spinner from '../layout/Spinner';

const CompaniesAdmin = ({
    getCompanies,
    company: { companies, loading },
    auth, // loading obj from Auth reducer
}) => {
    useEffect(() => {
        if (!auth.loading) getCompanies(false);
    }, [auth.loading, getCompanies]);

    // useEffect(() => {
    //     window.scrollTo(0, 0); // scroll to top of form on mount
    // }, []);

    return (
        <div className='containermiddle'>
            <Helmet>
                <title>{APP_NAME} | Companies</title>
            </Helmet>
            <AdminNav isSuperAdmin={auth.isSuperAdmin} />
            <div className='header-with-button'>
                <h2>{!loading && companies && companies.length} Companies</h2>
                {auth.isAuthenticated ? (
                    <Link
                        to='/admin/addcompany'
                        className='btn btn-primary mb-2 mr-0'
                    >
                        <i className='fas fa-plus text-light' /> Add Company
                    </Link>
                ) : (
                    ''
                )}
            </div>

            {auth.loading || loading ? (
                <Spinner />
            ) : (
                <div>
                    <div className='company-header hide-on-mobile'>
                        <div>Name</div>
                        <div>Website</div>
                        <div>Product Lines</div>
                        <div></div>
                    </div>
                    {companies.map((company) => (
                        <CompanyItem company={company} key={company._id} />
                    ))}
                </div>
            )}
        </div>
    );
};

CompaniesAdmin.propTypes = {
    getCompanies: PropTypes.func.isRequired,
    company: PropTypes.object.isRequired,
    auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    company: state.company,
    auth: state.auth,
});

export default connect(mapStateToProps, { getCompanies })(CompaniesAdmin);
