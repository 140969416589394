import React, { Fragment, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import { APP_NAME } from '../../utils/constants';
import { getProfileByUsername } from '../../actions/profile';
import Spinner from '../layout/Spinner';
import ProfileNav from './ProfileNav';
import Tag from '../layout/Tag';
import { getUserPic } from '../../utils/helpers';
const helpers = require('./helpers/helpers.js');

const productCategories = {
    'Pre-Poo': [],
    Detangler: [],
    Shampoo: [],
    Conditioner: [],
    'Leave-In Conditioner': [],
    'Deep Conditioner': [],
    'Co-Wash': [],
    Gel: [],
    'Styling Cream': [],
    'Hair Treatment': [],
    Oil: [],
    'Hair Mask': [],
    'Hair Rinse': [],
    'Scalp Reliever': [],
    Hairspray: [],
    'Heat Protectant': [],
    Spritz: [],
    Serum: [],
    Gloss: [],
    Mousse: [],
    Tonic: [],
    Other: [],
    'Not Specified': [],
};

const Profile = ({
    getProfileByUsername,
    profile: { profile, loading_profile, error },
    auth,
}) => {
    let params = useParams();

    useEffect(() => {
        getProfileByUsername(params.id); // passes in lower case version of username
    }, [getProfileByUsername, params.id]);

    useEffect(() => {
        window.scrollTo(0, 0); // scroll to top of form on mount
    }, []);

    const groupFavsByCategory = (favproducts) => {
        if (!favproducts.length) return [];
        let favesList = JSON.parse(JSON.stringify(productCategories)); // deep clone
        for (var f = 0; f < favproducts.length; f++) {
            let favprod = favproducts[f];
            let favprodcats = favprod.categories;
            if (!favprodcats || !favprodcats.length) continue;
            for (var c = 0; c < favprodcats.length; c++) {
                let cat = favprodcats[c];
                // see if categories obj contains this category (it always should) but not the product yet
                if (
                    favesList[cat] !== null &&
                    !favesList[cat].includes(favprod)
                ) {
                    // add to list for that category entry
                    favesList[cat].push(favprod);
                }
            }
        }

        let data = [];
        for (const category in favesList) {
            let arr = favesList[category];
            if (arr.length) {
                let obj = (
                    <Fragment key={'favproduct-h3-' + category}>
                        <h3 key={'favproduct-' + <h3>{category}</h3>}>
                            {category}
                        </h3>
                        <div className='mb-1'>
                            {arr.map((productobj, idx) => (
                                <Tag
                                    name={
                                        productobj.company.shortname +
                                        ' ' +
                                        productobj.name
                                    }
                                    slug={'/product/' + productobj.urlparam}
                                    key={'favproduct-' + idx}
                                />
                            ))}
                        </div>
                    </Fragment>
                );
                data.push(obj);
            }
        }

        return data;
    };

    const getHairTypePics = (primary, secondary) => {
        let arr = helpers.parseHairTypesIntoArray(primary, secondary);

        return (
            <div className='hairtypes-wrapper'>
                {arr.map((picpath, idx) => {
                    return (
                        <img
                            src={helpers.getHairTypePicPath(picpath)}
                            alt='hair type'
                            key={'hairtype' + idx}
                        />
                    );
                })}
            </div>
        );
    };

    return (
        <Fragment>
            <div className='containermiddle'>
                <Helmet>
                    <title>
                        {APP_NAME} |{' '}
                        {!loading_profile && profile
                            ? profile.user.username + "'s Profile"
                            : 'Member Profile'}
                    </title>
                </Helmet>
                {
                    // if loading fresh profile OR if loading new profile over a previously loaded one:
                    loading_profile ||
                    (profile && profile.user.username_lower !== params.id) ? (
                        <Spinner />
                    ) : !loading_profile && !profile ? (
                        <Fragment>
                            <ProfileNav
                                auth={auth}
                                username={params.id}
                                id={null}
                            />
                            <h2>Profile not created yet.</h2>
                            {auth.isAuthenticated &&
                                !auth.user.profile &&
                                !auth.loading &&
                                auth.user.username_lower &&
                                auth.user.username_lower === params.id && (
                                    <Link
                                        to='/createprofile'
                                        className='btn btn-primary my-1'
                                    >
                                        Create Profile
                                    </Link>
                                )}
                        </Fragment>
                    ) : error ? (
                        <Fragment>
                            <h2>Error: {error.msg}</h2>
                        </Fragment>
                    ) : (
                        <Fragment>
                            <ProfileNav
                                auth={auth}
                                username={profile.user.username_lower}
                                id={profile.user._id}
                            />

                            <div className='profile-grid'>
                                <div className='profile-top'>
                                    <img
                                        className='round-img my-1'
                                        src={getUserPic(profile.user.avatar)}
                                        alt=''
                                    />
                                    <div className='profile-content'>
                                        {profile.user.role > 0 && (
                                            <p className='lead'>
                                                <strong>Administrator</strong>
                                            </p>
                                        )}

                                        <p className='strong'>
                                            {profile.location && (
                                                <span> {profile.location}</span>
                                            )}

                                            {profile.location &&
                                                profile.weather && (
                                                    <span> | </span>
                                                )}

                                            {profile.weather && (
                                                <span>
                                                    {profile.weather} weather
                                                </span>
                                            )}
                                        </p>

                                        {profile.bio && (
                                            <p className='lead mb-0'>
                                                {profile.bio}
                                            </p>
                                        )}

                                        <div className='icons my-1'>
                                            {profile.website && (
                                                <a
                                                    href={profile.website}
                                                    target='_blank'
                                                    rel='noopener noreferrer'
                                                >
                                                    <i className='fas fa-globe fa-2x' />
                                                </a>
                                            )}
                                            {profile.social &&
                                                profile.social.twitter && (
                                                    <a
                                                        href={
                                                            profile.social
                                                                .twitter
                                                        }
                                                        target='_blank'
                                                        rel='noopener noreferrer'
                                                    >
                                                        <i className='fab fa-twitter fa-2x' />
                                                    </a>
                                                )}
                                            {profile.social &&
                                                profile.social.facebook && (
                                                    <a
                                                        href={
                                                            profile.social
                                                                .facebook
                                                        }
                                                        target='_blank'
                                                        rel='noopener noreferrer'
                                                    >
                                                        <i className='fab fa-facebook fa-2x' />
                                                    </a>
                                                )}
                                            {profile.social &&
                                                profile.social.linkedin && (
                                                    <a
                                                        href={
                                                            profile.social
                                                                .linkedin
                                                        }
                                                        target='_blank'
                                                        rel='noopener noreferrer'
                                                    >
                                                        <i className='fab fa-linkedin fa-2x' />
                                                    </a>
                                                )}
                                            {profile.social &&
                                                profile.social.youtube && (
                                                    <a
                                                        href={
                                                            profile.social
                                                                .youtube
                                                        }
                                                        target='_blank'
                                                        rel='noopener noreferrer'
                                                    >
                                                        <i className='fab fa-youtube fa-2x' />
                                                    </a>
                                                )}
                                            {profile.social &&
                                                profile.social.instagram && (
                                                    <a
                                                        href={
                                                            profile.social
                                                                .instagram
                                                        }
                                                        target='_blank'
                                                        rel='noopener noreferrer'
                                                    >
                                                        <i className='fab fa-instagram fa-2x' />
                                                    </a>
                                                )}
                                        </div>
                                    </div>
                                </div>
                                <div className='profile-hair1 bg-white p-1'>
                                    <h2>Hair Stats</h2>
                                    <div>
                                        <strong>Hair Type: </strong>
                                        {getHairTypePics(
                                            profile.hairproperties
                                                .hairtypeprimary,
                                            profile.hairproperties
                                                .hairtypesecondary
                                        )}

                                        <p>
                                            <strong>Porosity: </strong>{' '}
                                            {helpers.parsePorosity(
                                                profile.hairproperties.porosity
                                            )}
                                        </p>
                                        <p>
                                            <strong>Length: </strong>{' '}
                                            {helpers.parseHairLength(
                                                profile.hairproperties.length
                                            )}
                                        </p>
                                        <p>
                                            <strong>Thickness: </strong>{' '}
                                            {helpers.parseHairThickness(
                                                profile.hairproperties.thickness
                                            )}
                                        </p>
                                        <p>
                                            <strong>Density: </strong>{' '}
                                            {helpers.parseHairDensity(
                                                profile.hairproperties.density
                                            )}
                                        </p>
                                        <p>
                                            <strong>Strand Type: </strong>{' '}
                                            {helpers.parseHairStrandType(
                                                profile.hairproperties
                                                    .strandtype
                                            )}
                                        </p>
                                        <p>
                                            <strong>Stage: </strong>{' '}
                                            {helpers.parseHairStage(
                                                profile.hairproperties.stage
                                            )}
                                        </p>
                                        <p>
                                            <strong>Health: </strong>{' '}
                                            {helpers.parseHairHealth(
                                                profile.hairproperties.health
                                            )}
                                        </p>
                                        <p>
                                            <strong>Scalp: </strong>{' '}
                                            {helpers.parseScalpHealth(
                                                profile.hairproperties
                                                    .scalphealth
                                            )}
                                            {profile.hairproperties
                                                .hassensitivescalp &&
                                                ', sensitive '}
                                        </p>

                                        <p>
                                            <strong>Color Treated: </strong>{' '}
                                            {profile.hairproperties
                                                .iscolortreated
                                                ? 'Yes'
                                                : 'No'}
                                        </p>
                                        <p>
                                            <strong>Has Gray Hair: </strong>{' '}
                                            {profile.hairproperties.hasgrayhairs
                                                ? 'Yes'
                                                : 'No'}
                                        </p>
                                    </div>
                                    <div>
                                        <h2 className='mt-1'>
                                            Allergens/Sensitizers
                                        </h2>
                                        {(profile.allergens &&
                                            profile.allergens.length > 0 &&
                                            profile.allergens.map(
                                                (allergenobj, idx) => (
                                                    <Tag
                                                        name={allergenobj.name}
                                                        slug={
                                                            '/ingredient/' +
                                                            allergenobj.urlparam
                                                        }
                                                        key={
                                                            'favproduct-' + idx
                                                        }
                                                    />
                                                )
                                            )) ||
                                            'None'}
                                    </div>
                                </div>
                                <div className='profile-hair2 bg-white p-1'>
                                    <h2>Favorite Products</h2>

                                    {profile.favproducts &&
                                    profile.favproducts.length
                                        ? groupFavsByCategory(
                                              profile.favproducts
                                          )
                                        : 'None'}
                                </div>
                            </div>
                        </Fragment>
                    )
                }
            </div>
        </Fragment>
    );
};

Profile.propTypes = {
    getProfileByUsername: PropTypes.func.isRequired,
    profile: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    profile: state.profile,
    auth: state.auth,
});

export default connect(mapStateToProps, {
    getProfileByUsername,
})(Profile);
