import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';

// Component that loops through images, either automatically or with buttons.
function ImageSlider({ images, autoplay, showControls, className, duration }) {
    const [index, setIndex] = useState(0); // create state to keep track of images index, set the default index to 0

    const slideRight = useCallback(() => {
        setIndex((index + 1) % images.length); // increases index by 1
    }, [index, images.length]);

    const slideLeft = () => {
        const nextIndex = index - 1;
        if (nextIndex < 0) {
            setIndex(images.length - 1); // returns last index of images array if index is less than 0
        } else {
            setIndex(nextIndex);
        }
    };

    useEffect(() => {
        if (autoplay) {
            let timer1 = setTimeout(() => slideRight(null), duration);

            // this will clear Timeout when component unmounts like in willComponentUnmount
            return () => {
                clearTimeout(timer1);
            };
        }
    }, [autoplay, duration, slideRight]);

    return (
        images.length > 0 && (
            <div className='imgsliderwrapper'>
                <img src={images[index]} alt={index} className={className} />
                {showControls && (
                    <div>
                        <button onClick={slideLeft} className='imgsliderbtn'>
                            <i className='fas fa-chevron-left' />
                        </button>{' '}
                        <button onClick={slideRight} className='imgsliderbtn'>
                            <i className='fas fa-chevron-right' />
                        </button>
                    </div>
                )}
            </div>
        )
    );
}

ImageSlider.propTypes = {
    images: PropTypes.array.isRequired /* unique ID */,
};

ImageSlider.defaultProps = {
    images: [],
    autoplay: true,
    showControls: false,
    className: '',
    duration: 3500,
};

export default ImageSlider;
