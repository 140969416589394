import React, { Fragment, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { APP_NAME } from '../../utils/constants';
import hairPic5 from '../../img/hairmodel1.png';

const About = () => {
    useEffect(() => {
        window.scrollTo(0, 0); // scroll to top of form on mount
    }, []);

    return (
        <Fragment>
            <Helmet>
                <title>{APP_NAME} | About Us</title>
            </Helmet>
            <section id='landingabout' className='shadow'>
                <img src={hairPic5} alt='Curls' />
                <div>
                    <h1>Take command of your curls!</h1>
                    <h3>
                        The {APP_NAME} website is inspired by one simple
                        concept: knowing how much of a struggle it can be to
                        find the right products and routine that work for your
                        uniquely curly hair. We aim to solve that challenge by
                        providing information about products, ingredients, and
                        regimens that can help you discover the power of your
                        curls.
                    </h3>
                </div>
            </section>
            <section className='containermiddle'>
                <p className='intro mb'>About {APP_NAME}</p>
                <h3 className='normalweight mb-1'>
                    The {APP_NAME} website was created to help people who have
                    curly hair to better understand their curls and how to care
                    for them. We believe that having information about products
                    and their ingredients in one centralized place makes it
                    easier to find what does and doesn't work for your hair.
                    With a community of other curly people who are inquisitive
                    about how to master their haircare, our website strives to
                    help you find your perfect haircare regimen. There isn't
                    another website that provides quite this combination of
                    resources, so we hope you'll find our website useful!
                </h3>
                <h3 className='normalweight'>
                    We are always looking to improve our website, and since we
                    launched recently, we'd love to hear any feedback you may
                    have. Reach out to us on Instagram (
                    <a
                        href='https://www.instagram.com/roots.and.curls/'
                        target='_blank'
                        rel='noopener noreferrer'
                    >
                        @roots.and.curls
                    </a>
                    ) with your thoughts!
                </h3>
            </section>
        </Fragment>
    );
};

export default About;
