// since this is a form,  each input should be a piece of state, so we need useState hook
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Spinner from '../../layout/Spinner';
import FileUpload, { ImgTypeEnum } from '../../layout/FileUpload';
import { getRegimenPic } from '../../../utils/helpers';

const UploadRegimenPic = ({
    // prop types
    regimen: { regimen, loading, uploading_regimen_pic },
}) => {
    const [getPhoto, setPhoto] = useState('');

    useEffect(() => {
        if (!loading && regimen) {
            // add timestamp to force re-render of image
            // in cases where file name stays same but photo is replaced
            let nameToUse =
                regimen.mainphoto && regimen.mainphoto.length
                    ? `${regimen.mainphoto}?${Date.now().toString()}`
                    : ''; // only add timestamp to end of actual paths
            setPhoto(nameToUse);
        }
    }, [loading, regimen]);
    return loading || !regimen ? (
        <Spinner />
    ) : (
        <div className='upload-box'>
            <p className='form-header'>Change Regimen Photo</p>
            {getPhoto && (
                <img
                    className='round-img'
                    src={getRegimenPic(regimen.mainphoto)}
                    alt={regimen.name}
                />
            )}
            <small className='form-text mb'>A square photo works best.</small>
            {uploading_regimen_pic ? (
                <Spinner />
            ) : (
                <FileUpload
                    formID='mainregimenpic'
                    uploadType={ImgTypeEnum.REGIMEN}
                    id={regimen._id}
                />
            )}
        </div>
    );
};

UploadRegimenPic.propTypes = {
    regimen: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    regimen: state.regimen,
});

export default connect(mapStateToProps)(UploadRegimenPic);
