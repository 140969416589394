import React, { useState } from 'react';
import { Navigate, useNavigate, useLocation } from 'react-router-dom';
import { connect } from 'react-redux'; // connects component to redux
import { Helmet } from 'react-helmet-async';
import { APP_NAME } from '../../utils/constants';
import { resetPassword } from '../../actions/auth';
import Spinner from '../layout/Spinner';
import PropTypes from 'prop-types';

const initialState = {
    // default values for the formData state
    email: '',
    newpassword1: '',
    newpassword2: '',
};

// create function component
const ResetPassword = ({
    resetPassword,
    auth: { isAuthenticated, loading, resetting_pwd, pwd_verification_message },
}) => {
    let history = useNavigate();
    let location = useLocation();

    const [formData, setFormData] = useState(initialState);

    // destructure so I don't have to do formData.name, etc...
    const { email, newpassword1, newpassword2 } = formData;

    const onChange = (e) =>
        setFormData({ ...formData, [e.target.name]: e.target.value });

    const onSubmit = async (e) => {
        e.preventDefault(); // don't let browser do its default behavior

        const params = new URLSearchParams(location.search);
        let hash = params.get('c');
        resetPassword(formData, hash, history);
    };

    if (loading) {
        return (
            <section className='containerfull'>
                <div className='containermiddle'>
                    <Spinner />
                </div>
            </section>
        );
    } else if (isAuthenticated) {
        return <Navigate to='/dashboard' />;
    } else
        return (
            <section className='landing'>
                <Helmet>
                    <title>{APP_NAME} | Reset Password</title>
                </Helmet>
                <div className='landing-overlay'>
                    <div className='landing-form'>
                        <h1>Reset Password</h1>
                        {resetting_pwd && <Spinner />}
                        {/* {!successful && ( */}
                        <form className='form' onSubmit={onSubmit}>
                            <div className='form-group'>
                                <p className='form-header'>
                                    Account Email Address
                                </p>
                                <input
                                    type='email'
                                    name='email'
                                    value={email}
                                    onChange={onChange}
                                    required
                                />
                            </div>

                            <div className='form-group'>
                                <p className='form-header'>New Password</p>
                                <small className='form-text mb'>
                                    Must be at least 8 characters long
                                </small>
                                <input
                                    type='password'
                                    name='newpassword1'
                                    value={newpassword1}
                                    onChange={onChange}
                                    minLength='8'
                                />
                            </div>
                            <div className='form-group'>
                                <p className='form-header'>
                                    Confirm New Password
                                </p>
                                <input
                                    type='password'
                                    name='newpassword2'
                                    value={newpassword2}
                                    onChange={onChange}
                                    minLength='8' // @todo make 8 a constant and use it in the backend api validator too
                                />
                            </div>
                            <input
                                type='submit'
                                className='btn btn-primary'
                                value='Reset Password'
                            />
                        </form>
                        {/* )} */}
                        {/* {successful && <Link to='/login'>Sign In</Link>} */}
                    </div>
                </div>
            </section>
        );
};

ResetPassword.propTypes = {
    resetPassword: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    isAuthenticated: state.auth.isAuthenticated,
    auth: state.auth,
});

// to use an action, pass it into connect below to allow us to access props.register so we can use it in this component.
// 1st param is state to connect (not needed here). 2nd param is any actions I want to use
export default connect(mapStateToProps, { resetPassword })(ResetPassword);
