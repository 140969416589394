import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Tag from '../layout/Tag';
import Spinner from '../layout/Spinner';

export const PropertyTags = ({
    ingredient: { properties, urlparam, loading },
    max,
}) => {
    let slugprefix = '/ingredients?properties=';
    let tagArray = [];
    if (properties.isdepilatory) {
        tagArray.push(
            <Tag
                name='Depilatory'
                status='ERROR'
                slug={slugprefix + 'isdepilatory'}
            />
        );
    }
    if (properties.issensitizer) {
        tagArray.push(
            <Tag
                name='Sensitizer'
                status='ERROR'
                slug={slugprefix + 'issensitizer'}
            />
        );
    }
    if (properties.isbadalcohol) {
        tagArray.push(
            <Tag
                name='Drying Alcohol'
                status='ERROR'
                slug={slugprefix + 'isbadalcohol'}
            />
        );
    }
    if (properties.isparaben) {
        tagArray.push(
            <Tag
                name='Paraben'
                status='WARNING'
                slug={slugprefix + 'isparaben'}
            />
        );
    }
    if (properties.issilicone) {
        tagArray.push(
            <Tag
                name='Silicone'
                status='WARNING'
                slug={slugprefix + 'issilicone'}
            />
        );
    }
    if (properties.issulfate) {
        tagArray.push(
            <Tag
                name='Sulfate'
                status='WARNING'
                slug={slugprefix + 'issulfate'}
            />
        );
    }
    if (properties.isfragrance) {
        tagArray.push(
            <Tag
                name='Fragrance'
                status='WARNING'
                slug={slugprefix + 'isfragrance'}
            />
        );
    }

    if (properties.isreducingagent) {
        tagArray.push(
            <Tag
                name='Reducing Agent'
                status='WARNING'
                slug={slugprefix + 'isreducingagent'}
            />
        );
    }
    if (properties.isorganic) {
        tagArray.push(
            <Tag
                name='Found In Nature'
                status='SUCCESS'
                slug={slugprefix + 'isorganic'}
            />
        );
    }
    if (properties.iscleansingagent) {
        tagArray.push(
            <Tag
                name='Cleanser'
                status='SUCCESS'
                slug={slugprefix + 'iscleansingagent'}
            />
        );
    }
    if (properties.isgeneralsurfactant) {
        tagArray.push(
            <Tag
                name='Surfactant'
                status='SUCCESS'
                slug={slugprefix + 'isgeneralsurfactant'}
            />
        );
    }
    if (properties.ismoisturizer) {
        tagArray.push(
            <Tag
                name='Moisturizer'
                status='SUCCESS'
                slug={slugprefix + 'ismoisturizer'}
            />
        );
    }
    if (properties.isantistatic) {
        tagArray.push(
            <Tag
                name='Anti-Static'
                status='SUCCESS'
                slug={slugprefix + 'isantistatic'}
            />
        );
    }
    if (properties.isgeneralconditioner) {
        tagArray.push(
            <Tag
                name='Conditioner'
                status='SUCCESS'
                slug={slugprefix + 'isgeneralconditioner'}
            />
        );
    }
    if (properties.isslipagent) {
        tagArray.push(
            <Tag
                name='Provides Slip'
                status='SUCCESS'
                slug={slugprefix + 'isslipagent'}
            />
        );
    }
    if (properties.isskinsoothing) {
        tagArray.push(
            <Tag
                name='Skin Soother'
                status='SUCCESS'
                slug={slugprefix + 'isskinsoothing'}
            />
        );
    }
    if (properties.ischelant) {
        tagArray.push(
            <Tag
                name='Chelant'
                status='SUCCESS'
                slug={slugprefix + 'ischelant'}
            />
        );
    }
    if (properties.isantimicrobial) {
        tagArray.push(
            <Tag
                name='Antimicrobial'
                status='SUCCESS'
                slug={slugprefix + 'isantimicrobial'}
            />
        );
    }
    if (properties.isantiinflammatory) {
        tagArray.push(
            <Tag
                name='Anti-inflammatory'
                status='SUCCESS'
                slug={slugprefix + 'isantiinflammatory'}
            />
        );
    }
    if (properties.isantioxidant) {
        tagArray.push(
            <Tag
                name='Antioxidant'
                status='SUCCESS'
                slug={slugprefix + 'isantioxidant'}
            />
        );
    }
    if (properties.isantidandruff) {
        tagArray.push(
            <Tag name='Anti-Dandruff' slug={slugprefix + 'isantidandruff'} />
        );
    }
    if (properties.isprotein) {
        tagArray.push(<Tag name='Protein' slug={slugprefix + 'isprotein'} />);
    }
    if (properties.containsprotein) {
        tagArray.push(
            <Tag
                name='Contains Protein'
                slug={slugprefix + 'containsprotein'}
            />
        );
    }
    if (properties.isoil) {
        tagArray.push(<Tag name='Oil' slug={slugprefix + 'isoil'} />);
    }
    if (properties.isemulsifier) {
        tagArray.push(
            <Tag name='Emulsifier' slug={slugprefix + 'isemulsifier'} />
        );
    }
    if (properties.isfoambooster) {
        tagArray.push(
            <Tag name='Foam Booster' slug={slugprefix + 'isfoambooster'} />
        );
    }
    if (properties.issolvent) {
        tagArray.push(<Tag name='Solvent' slug={slugprefix + 'issolvent'} />);
    }
    if (properties.issynthetic) {
        tagArray.push(
            <Tag name='Synthetic' slug={slugprefix + 'issynthetic'} />
        );
    }
    if (properties.isaminoacid) {
        tagArray.push(
            <Tag name='Amino Acid(s)' slug={slugprefix + 'isaminoacid'} />
        );
    }
    if (properties.ispreservative) {
        tagArray.push(
            <Tag name='Preservative' slug={slugprefix + 'ispreservative'} />
        );
    }
    if (properties.isabsorbent) {
        tagArray.push(
            <Tag name='Absorbent' slug={slugprefix + 'isabsorbent'} />
        );
    }
    if (properties.isadsorbent) {
        tagArray.push(
            <Tag name='Adsorbent' slug={slugprefix + 'isadsorbent'} />
        );
    }
    if (properties.ishumectant) {
        tagArray.push(
            <Tag name='Humectant' slug={slugprefix + 'ishumectant'} />
        );
    }
    if (properties.isantihumectant) {
        tagArray.push(
            <Tag name='Antihumectant' slug={slugprefix + 'isantihumectant'} />
        );
    }
    if (properties.isthickener) {
        tagArray.push(
            <Tag name='Product Thickener' slug={slugprefix + 'isthickener'} />
        );
    }
    if (properties.isfilmforming) {
        tagArray.push(
            <Tag name='Film-Forming' slug={slugprefix + 'isfilmforming'} />
        );
    }
    if (properties.isexfoliant) {
        tagArray.push(
            <Tag name='Exfoliant' slug={slugprefix + 'isexfoliant'} />
        );
    }
    if (properties.isphadjuster) {
        tagArray.push(
            <Tag name='pH Balancer' slug={slugprefix + 'isphadjuster'} />
        );
    }
    if (properties.isfixative) {
        tagArray.push(<Tag name='Fixative' slug={slugprefix + 'isfixative'} />);
    }
    if (properties.issunscreen) {
        tagArray.push(
            <Tag name='Sunscreen' slug={slugprefix + 'issunscreen'} />
        );
    }
    if (properties.isoxidizer) {
        tagArray.push(<Tag name='Oxidizer' slug={slugprefix + 'isoxidizer'} />);
    }
    if (properties.iscolorant) {
        tagArray.push(<Tag name='Colorant' slug={slugprefix + 'iscolorant'} />);
    }
    if (properties.ishaircolorant) {
        tagArray.push(
            <Tag name='Hair Colorant' slug={slugprefix + 'ishaircolorant'} />
        );
    }
    if (properties.ispropellant) {
        tagArray.push(
            <Tag name='Propellant' slug={slugprefix + 'ispropellant'} />
        );
    }

    // @todo6 add function types object in DB with descriptions and then link the below slugs to routes

    if (max === 0) {
        return loading ? (
            <div className='product'>
                <Spinner />
            </div>
        ) : (
            <Fragment>
                {tagArray.map((tag, idx) => {
                    return <Fragment key={'tag-' + idx}>{tag}</Fragment>;
                })}
            </Fragment>
        );
    } else {
        let numTags = tagArray.length;
        let returnArray = [];
        for (let idx = 0; idx < Math.min(max, numTags); idx++) {
            let extra =
                idx + 1 === max && max < numTags ? (
                    <a className='small' href={'/ingredient/' + urlparam}>
                        {' + ' + (numTags - idx - 1) + ' more'}
                    </a>
                ) : (
                    ''
                );

            returnArray.push(
                <Fragment key={'tag-' + idx}>
                    {tagArray[idx]}
                    {extra}
                </Fragment>
            );
        }

        return loading ? (
            <div className='product'>
                <Spinner />
            </div>
        ) : (
            returnArray.map((retEl) => {
                return retEl;
            })
        );
    }
};

PropertyTags.propTypes = {
    ingredient: PropTypes.object.isRequired,
    max: PropTypes.number,
};

PropertyTags.defaultProps = {
    max: 0,
};

export default PropertyTags;
