import {
    ADD_COMPANY,
    GET_COMPANY, // fetch company when it is created or updated
    GET_COMPANIES,
    GET_COMPANIES_AUTOCOMPLETE,
    COMPANY_ERROR,
    DELETE_COMPANY,
    CLEAR_COMPANY,
} from '../actions/types';

const initialState = {
    company: null,
    companies: [], // for listing all companies
    companies_autocomplete: [], // for listing all companies, with name and ID only
    loading: true,
    loading_autocomplete: true,
    error: {}, // for request errors
};

// reducers take a state and an action (the action is dispatched from a file)
// actions have a type and a payload
const companyReducer = (state = initialState, action) => {
    const { type, payload } = action; // destructure for simplicity

    switch (type) {
        case GET_COMPANY:
            return {
                ...state,
                company: payload,
                loading: false,
            };
        case GET_COMPANIES:
            return {
                ...state,
                companies: payload,
                loading: false,
            };
        case GET_COMPANIES_AUTOCOMPLETE:
            return {
                ...state,
                companies_autocomplete: payload,
                loading_autocomplete: false,
            };
        case COMPANY_ERROR:
            return {
                ...state,
                error: payload,
                loading: false,
                company: null,
            };
        case ADD_COMPANY:
            return {
                ...state,
                company: payload,
                companies: [...state.companies, payload],
                companies_autocomplete: [
                    ...state.companies_autocomplete,
                    payload,
                ], // @todo6 ??
                loading: false,
            };

        case CLEAR_COMPANY /* no payload sent */:
            return {
                ...state,
                company: null,
            };

        case DELETE_COMPANY:
            return {
                ...state,
                companies: state.companies.filter(
                    (company) => company._id !== payload._id
                ),
                companies_autocomplete: state.companies_autocomplete.filter(
                    (company) => company._id !== payload._id
                ),
            };

        default:
            return state;
    }
};

export default companyReducer;
