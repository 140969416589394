import React from 'react';
import PropTypes from 'prop-types';
// anytime you want to interact a component with redux
// (get the state or call an action), use connect
import { connect } from 'react-redux';
import { removeAlert } from '../../actions/alert';

const Alert = ({ alerts, removeAlert }) =>
    alerts !== null &&
    alerts.length > 0 && (
        <div className='alert-container'>
            {alerts.map((alert) => (
                <div
                    key={alert.id}
                    className={`alert alert-${alert.alertType}`}
                >
                    <span>{alert.msg}</span>
                    {alert.isCloseable && (
                        <i
                            className='fas fa-times'
                            onClick={() => removeAlert(alert.id)}
                        ></i>
                    )}
                </div>
            ))}
        </div>
    );

Alert.propTypes = {
    alerts: PropTypes.array.isRequired,
    removeAlert: PropTypes.func.isRequired,
};

// map the redux state to a prop in this component.
// https://react-redux.js.org/using-react-redux/connect-mapstate
// As the first argument passed in to connect, mapStateToProps is used for selecting the part of the data from the store that the connected component needs. It’s frequently referred to as just mapState for short.
// It is called every time the store state changes.
// It receives the entire store state, and should return an object of data this component needs.
// The first argument to a mapStateToProps function is the entire Redux store state (the same value returned by a call to store.getState())
// Your mapStateToProps function should return a plain object that contains the data the component needs:
// Each field in the object will become a prop for your actual component
// The values in the fields will be used to determine if your component needs to re-render
const mapStateToProps = (state) => ({
    alerts /* prop name */: state.alert /* gets the alert state */,
});

export default connect(mapStateToProps, { removeAlert })(Alert);
