import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import DayJS from 'react-dayjs';
import { connect } from 'react-redux';
import Spinner from '../layout/Spinner';
import Tag from '../layout/Tag';
import RateRegimenButtons from './RateRegimenButtons';
import {
    parseTotalTime,
    getUserPic,
    getRegimenPic,
    truncateString,
} from '../../utils/helpers';
import { switchStyleType } from './helpers/helpers';

const RegimenItem = ({
    showFull, // whether to show full details for regimen or not
    auth,
    regimen_loading,
    // user's avatar and username come from the regimen api which populates that info in the regimen obj
    regimen: {
        _id,
        user /* obj populated from regimen API call (has _id, avatar, name) */,
        name,
        description,
        styletype,
        mainphoto,
        steps,
        likes,
        dislikes,
        ispublic,
        privatenotes,
        created,
        youtube,
        productobjects,
    },
}) => {
    const getTotalTime = (steps) => {
        let totalTimeMinutes = 0;
        steps.forEach((step) => {
            totalTimeMinutes += step.timeminutes;
        });

        return parseTotalTime(totalTimeMinutes, true);
    };

    return (
        <Fragment>
            {auth.loading || regimen_loading ? (
                <div className='regimen p-1'>
                    <Spinner />
                </div>
            ) : (
                <Fragment>
                    <div className={`regimen${showFull ? ' p-1' : '-pg-tile'}`}>
                        <div className='regimen-header'>
                            <Link to={`/regimen/${_id}`}>
                                <img
                                    className='regimen-img'
                                    src={getRegimenPic(mainphoto)}
                                    alt=''
                                />
                            </Link>
                        </div>
                        <div className={`${showFull ? '' : 'p-1'}`}>
                            <h3>
                                {showFull ? (
                                    <span>{name}</span>
                                ) : (
                                    <Link to={`/regimen/${_id}`}>{name}</Link>
                                )}
                            </h3>
                            {!ispublic && (
                                <span className='item-tag tag-private'>
                                    <i className='fas fa-lock' /> Private
                                </span>
                            )}

                            <span className='small regimen-creator mb mt'>
                                <img src={getUserPic(user.avatar)} alt='' />
                                <Link to={`/profile/${user.username_lower}`}>
                                    {user.username}
                                </Link>
                                <span>
                                    {''}
                                    on{' '}
                                    <DayJS format='MM/DD/YYYY'>{created}</DayJS>
                                </span>
                            </span>
                            <p className='allcaps small mb'>
                                {switchStyleType(styletype)}
                            </p>
                            <p className='mb'>
                                <i className='far fa-clock mr'></i>
                                {getTotalTime(
                                    steps,
                                    true /* return as string */
                                )}
                            </p>
                            <p className='mb'>
                                <i className='far fa-file-alt mrr mll'></i>

                                {showFull
                                    ? description
                                    : truncateString(description, 250)}
                            </p>
                            {showFull &&
                                !auth.loading &&
                                auth.isAuthenticated &&
                                user._id === auth.user._id && (
                                    <p className='mb'>
                                        <i className='far fa-sticky-note mr'></i>
                                        {privatenotes
                                            ? privatenotes
                                            : 'No personal notes'}
                                    </p>
                                )}
                            {youtube && (
                                <a
                                    href={youtube}
                                    target='_blank'
                                    rel='noopener noreferrer'
                                >
                                    <i className='fab fa-youtube fa-1x' />{' '}
                                    YouTube Video
                                </a>
                            )}

                            <div>
                                {productobjects && productobjects.length > 0 && (
                                    <p className='allcaps small'>
                                        {productobjects.length + ' '}
                                        Products used
                                    </p>
                                )}
                                {showFull &&
                                    productobjects &&
                                    productobjects.map((obj, idx) => {
                                        return obj.company && obj.urlparam ? (
                                            <Tag
                                                name={
                                                    obj.company.shortname +
                                                    ' ' +
                                                    obj.name
                                                }
                                                slug={
                                                    '/product/' + obj.urlparam
                                                }
                                                key={'product' + idx}
                                            />
                                        ) : (
                                            ''
                                        );
                                    })}
                            </div>

                            <div className='mb mt'>
                                <RateRegimenButtons
                                    readOnly={!auth.isAuthenticated}
                                    id={_id}
                                    likes={likes}
                                    dislikes={dislikes}
                                />
                            </div>
                            <div>
                                {!auth.loading &&
                                    auth.isAuthenticated &&
                                    (user._id === auth.user._id ||
                                        auth.isSuperAdmin) &&
                                    !showFull && (
                                        <Link
                                            to={`/editregimen/${_id}`}
                                            className='btn btn-secondary-light'
                                        >
                                            <i className='fas fa-edit text-secondary' />{' '}
                                            Edit
                                        </Link>
                                    )}
                            </div>
                        </div>
                    </div>
                    {showFull ? (
                        <div className='regimen-steps pt-1'>
                            <ol>
                                {steps.map((step, idx) => {
                                    return (
                                        <li key={'step' + idx}>
                                            <div className='regimen-step-content'>
                                                {/* <div> is used to wrap for grid */}
                                                <div className='regimen-step-number'>
                                                    {idx + 1}
                                                </div>
                                                <div>
                                                    <div className='lead'>
                                                        {step.details}
                                                    </div>
                                                    {step.timeminutes > 0 && (
                                                        <div>
                                                            <i className='far fa-clock mr-1 mb'></i>
                                                            {getTotalTime([
                                                                step,
                                                            ])}
                                                        </div>
                                                    )}

                                                    {step.productobjects
                                                        .length ||
                                                    step.productnames.length ? (
                                                        <div className='regimen-step-products'>
                                                            <div
                                                                style={{
                                                                    margin: '5px 5px 5px 0',
                                                                }}
                                                            >
                                                                <i className='fas fa-wine-bottle mr-1'></i>
                                                            </div>
                                                            <div>
                                                                {step.productobjects &&
                                                                    step.productobjects.map(
                                                                        (
                                                                            obj,
                                                                            idx
                                                                        ) => {
                                                                            return obj.company &&
                                                                                obj.urlparam ? (
                                                                                <Tag
                                                                                    name={
                                                                                        obj
                                                                                            .company
                                                                                            .shortname +
                                                                                        ' ' +
                                                                                        obj.name
                                                                                    }
                                                                                    slug={
                                                                                        '/product/' +
                                                                                        obj.urlparam
                                                                                    }
                                                                                    key={
                                                                                        'product' +
                                                                                        idx
                                                                                    }
                                                                                />
                                                                            ) : (
                                                                                ''
                                                                            );
                                                                        }
                                                                    )}

                                                                {step.productnames.map(
                                                                    (
                                                                        name,
                                                                        idx
                                                                    ) => {
                                                                        return (
                                                                            <Tag
                                                                                name={
                                                                                    name
                                                                                }
                                                                                key={
                                                                                    'product' +
                                                                                    idx
                                                                                }
                                                                            />
                                                                        );
                                                                    }
                                                                )}
                                                            </div>
                                                        </div>
                                                    ) : (
                                                        ''
                                                    )}
                                                </div>
                                            </div>
                                            {step.photo && !step.video && (
                                                <img
                                                    className='regimen-img'
                                                    src={getRegimenPic(
                                                        step.photo
                                                    )}
                                                    alt='regimen step'
                                                />
                                            )}
                                            {step.video && (
                                                <video
                                                    width='250'
                                                    height='250'
                                                    controls
                                                >
                                                    <source
                                                        src={getRegimenPic(
                                                            step.video
                                                        )}
                                                        type='video/mp4'
                                                    />
                                                    Your browser does not
                                                    support the video tag.
                                                </video>
                                            )}
                                        </li>
                                    );
                                })}
                            </ol>
                        </div>
                    ) : (
                        ''
                    )}
                </Fragment>
            )}
        </Fragment>
    );
};

RegimenItem.propTypes = {
    regimen: PropTypes.object.isRequired, // passed in to prop
    regimen_loading: PropTypes.bool.isRequired,
    auth: PropTypes.object.isRequired,
    showFull: PropTypes.bool,
};

RegimenItem.defaultProps = {
    showFull: false,
};

const mapStateToProps = (state) => ({
    auth: state.auth,
    regimen_loading: state.regimen.loading,
});

export default connect(mapStateToProps)(RegimenItem);
