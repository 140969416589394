import React, { Fragment, useEffect } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import { APP_NAME } from '../../utils/constants';
import {
    getProfileByUsername,
    deleteJourneyEntry,
} from '../../actions/profile';
import Spinner from '../layout/Spinner';
import ProfileNav from './ProfileNav';
import { getJourneyEntryPic } from '../../utils/helpers';
import DayJS from 'react-dayjs';

const Journey = ({
    getProfileByUsername,
    deleteJourneyEntry,
    profile: { profile, loading_profile, error },
    auth,
}) => {
    let params = useParams();
    let history = useNavigate();

    useEffect(() => {
        getProfileByUsername(params.id); // passes in lower case version of username
    }, [getProfileByUsername, params.id]);

    useEffect(() => {
        window.scrollTo(0, 0); // scroll to top of form on mount
    }, []);

    let sortEntries = (entries) => {
        if (!entries) return [];
        return [...entries].sort((a, b) => {
            // sort in descending order
            let fa = new Date(a.startDate.date);
            let fb = new Date(b.startDate.date);
            return fb - fa;
        });
    };

    const generateEntries = (entries) => {
        // track which years we've generated section headers for
        let seenYears = [];

        const data = sortEntries(entries).map((entry, eidx) => {
            let date = new Date(entry.startDate.date);
            let year = date.getFullYear();
            let header = '';
            if (!seenYears.includes(year)) {
                seenYears.push(year);
                header = (
                    <Fragment>
                        <div className='journeyyear'>
                            <h1>{year}</h1>
                        </div>
                        <div
                            className={`${
                                eidx === 0 ? 'journeylinefirst' : 'journeyline'
                            }`}
                        ></div>
                        <div>&nbsp;</div>
                    </Fragment>
                );
            }

            return (
                <Fragment key={eidx}>
                    {header}
                    <div
                        className={`${
                            eidx === entries.length - 1 &&
                            (entry.startDate.hasmonth || entry.startDate.hasday)
                                ? 'journeytimeline journeytimelinelast'
                                : 'journeytimeline'
                        }`}
                    >
                        {entry.startDate.hasmonth || entry.startDate.hasday ? (
                            <h2 className='journeydate'>
                                <DayJS
                                    format={
                                        (entry.startDate.hasmonth
                                            ? 'MMM '
                                            : '') +
                                        (entry.startDate.hasmonth &&
                                        entry.startDate.hasday
                                            ? 'D '
                                            : '')
                                    }
                                >
                                    {entry.startDate.date}
                                </DayJS>
                            </h2>
                        ) : (
                            ''
                        )}
                    </div>

                    <div
                        className={`${
                            eidx === entries.length - 1
                                ? entry.startDate.hasmonth ||
                                  entry.startDate.hasday
                                    ? 'journeylinelastwithdate'
                                    : 'journeylinelast'
                                : 'journeyline'
                        }`}
                    ></div>

                    <div className='journeyentrywrapper'>
                        <div
                            className={
                                'journeyentrycols' +
                                (entry.isfeatured ? '-feat' : '')
                            }
                        >
                            <img
                                className='journeypic'
                                src={getJourneyEntryPic(entry.mainphoto)}
                                alt=''
                            />
                            <div className='journeytextcontent'>
                                <h3>{switchType(entry.type)}</h3>
                                {entry.notes !== null &&
                                    entry.notes.length > 0 && (
                                        <Fragment>
                                            <hr className='mt' />
                                            <span>{entry.notes}</span>
                                        </Fragment>
                                    )}

                                <hr className='mt-1' />

                                {entry.regimen !== null &&
                                    entry.regimen.ispublic && (
                                        <strong>
                                            <i
                                                className='fa fa-list-ol text-light'
                                                aria-hidden='true'
                                            />
                                            &nbsp;Regimen:{' '}
                                            <Link
                                                to={
                                                    '/regimen/' +
                                                    entry.regimen._id
                                                }
                                            >
                                                {entry.regimen.name}
                                            </Link>
                                        </strong>
                                    )}
                                {auth.isAuthenticated &&
                                    !auth.loading &&
                                    auth.user._id === profile.user._id && (
                                        <div className='block'>
                                            <button
                                                className='btn btn-danger mt fr'
                                                onClick={(e) =>
                                                    deleteEntry(e, entry._id)
                                                }
                                            >
                                                <i className='far fa-trash-alt' />{' '}
                                                Delete
                                            </button>
                                            <Link
                                                to={
                                                    '/editjourneyentry/' +
                                                    entry._id
                                                }
                                                className='btn btn-primary mt'
                                            >
                                                <i className='fas fa-edit text-light' />{' '}
                                                Edit
                                            </Link>
                                        </div>
                                    )}
                            </div>
                        </div>
                    </div>

                    {
                        // in order to have last sticky element stick, add empty row
                        // at end of timeline:
                        eidx === entries.length - 1 && (
                            <Fragment>
                                <div></div>
                                <div></div>
                                <div></div>
                            </Fragment>
                        )
                    }
                </Fragment>
            );
        });

        return data;
    };

    const switchType = (style) => {
        switch (style) {
            case 1:
                return 'Hairstyle';

            case 2:
                return 'Hairwash';

            case 3:
                return 'Trim';

            case 4:
                return 'Haircut';

            case 5:
                return 'Big Chop';

            case 6:
                return 'Dyed Hair';

            case 7:
                return 'Hair Growth';

            case 0:
            default:
                return 'Other';
        }
    };

    const deleteEntry = (e, entryid) => {
        e.preventDefault(); // don't submit form
        deleteJourneyEntry(entryid, history);
    };

    return (
        <Fragment>
            <div className='containermiddle'>
                <Helmet>
                    <title>
                        {APP_NAME} |{' '}
                        {!loading_profile && profile
                            ? profile.user.username + "'s Curl Journey"
                            : 'Member Curl Journey'}
                    </title>
                </Helmet>
                {loading_profile ||
                auth.loading ||
                (profile && profile.user.username_lower !== params.id) ? (
                    <Spinner />
                ) : (
                    <Fragment>
                        <ProfileNav
                            auth={auth}
                            username={
                                profile
                                    ? profile.user.username_lower
                                    : params.id
                            }
                            id={profile ? profile.user._id : null}
                        />
                        {auth.isAuthenticated &&
                        auth.user.username_lower === params.id &&
                        !profile ? (
                            <Fragment>
                                <h2>
                                    Create your profile in order to track your
                                    curl journey.
                                </h2>

                                <Link
                                    to='/createprofile'
                                    className='btn btn-primary my-1'
                                >
                                    Create Profile
                                </Link>
                            </Fragment>
                        ) : error ? (
                            <h2>Error: {error.msg}</h2>
                        ) : !profile ||
                          (profile && profile.journal.entries.length === 0) ? (
                            <div className='header-with-button'>
                                <h2>No journey entries yet. </h2>
                                {auth.isAuthenticated &&
                                    !auth.loading &&
                                    profile &&
                                    auth.user._id === profile.user._id && (
                                        <Link
                                            to={'/addjourneyentry'}
                                            className='btn btn-primary mr-0'
                                        >
                                            <i className='fas fa-plus text-light' />{' '}
                                            Add Entry
                                        </Link>
                                    )}
                            </div>
                        ) : (
                            profile && (
                                <Fragment>
                                    {auth.isAuthenticated &&
                                        !auth.loading &&
                                        auth.user._id === profile.user._id && (
                                            <span
                                                style={{
                                                    display: 'grid',
                                                    alignItems: 'center',
                                                    width: 'fit-content',
                                                    margin: '0 auto 0.5rem auto',
                                                }}
                                            >
                                                <Link
                                                    to={'/addjourneyentry'}
                                                    className='btn btn-large'
                                                >
                                                    <i className='fas fa-plus text-light' />{' '}
                                                    Add Entry
                                                </Link>
                                            </span>
                                        )}

                                    {profile.journal.entries.length ? (
                                        <div className='journeycontainer'>
                                            {generateEntries(
                                                profile.journal.entries
                                            )}
                                        </div>
                                    ) : (
                                        'None'
                                    )}
                                </Fragment>
                            )
                        )}
                    </Fragment>
                )}
            </div>
        </Fragment>
    );
};

Journey.propTypes = {
    getProfileByUsername: PropTypes.func.isRequired,
    deleteJourneyEntry: PropTypes.func.isRequired,
    profile: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    profile: state.profile,
    auth: state.auth,
});

export default connect(mapStateToProps, {
    getProfileByUsername,
    deleteJourneyEntry,
})(Journey);
