import pic1 from '../../../img/hairtypes/1@2x.png';
import pic2a from '../../../img/hairtypes/2A@2x.png';
import pic2b from '../../../img/hairtypes/2B@2x.png';
import pic2c from '../../../img/hairtypes/2C@2x.png';
import pic3a from '../../../img/hairtypes/3A@2x.png';
import pic3b from '../../../img/hairtypes/3B@2x.png';
import pic3c from '../../../img/hairtypes/3C@2x.png';
import pic4a from '../../../img/hairtypes/4A@2x.png';
import pic4b from '../../../img/hairtypes/4B@2x.png';
import pic4c from '../../../img/hairtypes/4C@2x.png';

export const getHairTypePicPath = (type) => {
    switch (type) {
        case '1':
            return pic1;

        case '2A':
            return pic2a;

        case '2B':
            return pic2b;

        case '2C':
            return pic2c;

        case '3A':
            return pic3a;

        case '3B':
            return pic3b;

        case '3C':
            return pic3c;

        case '4A':
            return pic4a;

        case '4B':
            return pic4b;

        case '4C':
            return pic4c;

        default:
            return pic1;
    }
};

// returns array of hair types
export const parseHairTypesIntoArray = (primary, secondary) => {
    if (primary === secondary || (primary && !secondary)) return [primary];
    else return [primary, secondary]; // .sort();
};

export const parseHairTypes = (primary, secondary) => {
    let arr = parseHairTypesIntoArray(primary, secondary);
    return arr.join(', ');
};

export const parseScalpHealth = (scalphealth) => {
    switch (scalphealth) {
        case 1:
            return 'Dry';

        case 2:
            return 'Normal';

        case 3:
            return 'Oily';

        case 0:
        default:
            return 'Not specified';
    }
};

export const parsePorosity = (porosity) => {
    switch (porosity) {
        case 1:
            return 'Low';

        case 2:
            return 'Normal';

        case 3:
            return 'High';

        case 0:
        default:
            return 'Not specified';
    }
};

export const parseHairThickness = (thickness) => {
    switch (thickness) {
        case 1:
            return 'Thin';

        case 2:
            return 'Normal';

        case 3:
            return 'Thick';

        case 0:
        default:
            return 'Not specified';
    }
};

export const parseHairDensity = (type) => {
    switch (type) {
        case 1:
            return 'Low';

        case 2:
            return 'Normal';

        case 3:
            return 'High';

        case 0:
        default:
            return 'Not specified';
    }
};

export const parseHairStrandType = (type) => {
    switch (type) {
        case 1:
            return 'Fine';

        case 2:
            return 'Medium';

        case 3:
            return 'Coarse';

        case 0:
        default:
            return 'Not specified';
    }
};

export const parseHairHealth = (health) => {
    switch (health) {
        case 1:
            return 'Healthy';

        case 2:
            return 'Average';

        case 3:
            return 'Damaged';

        case 0:
        default:
            return 'Not specified';
    }
};

export const parseHairStage = (stage) => {
    switch (stage) {
        case 1:
            return 'Natural (not chemically straightened)';

        case 2:
            return 'Transitioning (natural roots and chemically straightened ends)';

        case 3:
            return 'Relaxed (chemically straightened)';

        case 0:
        default:
            return 'Not specified';
    }
};

export const parseHairLength = (length) => {
    switch (length) {
        case 1:
            return 'Short (1-3 inches when stretched)';

        case 2:
            return 'Medium-short (3-6 inches when stretched)';

        case 3:
            return 'Medium (6-10 inches when stretched)';

        case 4:
            return 'Long (10+ inches when stretched)';

        case 0:
        default:
            return 'Not specified';
    }
};

export const parseStyleType = (style) => {
    switch (style) {
        case 1:
            return 'Wash & Go';

        case 2:
            return 'Twist-Out';

        case 3:
            return 'Braid-Out';

        case 4:
            return 'Bun';

        case 5:
            return 'Protective Style';

        case 0:
        default:
            return 'Other';
    }
};

export const parseHairColored = (iscolortreated) => {
    switch (iscolortreated) {
        case true:
            return 'Dyed Hair';

        case false:
        default:
            return 'Natural Hair Color';
    }
};
