export const switchStyleType = (style) => {
    switch (style) {
        case 1:
            return 'Wash & Go';

        case 2:
            return 'Twist-Out';

        case 3:
            return 'Braid-Out';

        case 4:
            return 'Bun';

        case 5:
            return 'Protective Style';

        case 0:
        default:
            return 'Other';
    }
};
