import React, { Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import Spinner from '../layout/Spinner';
import ProductBox from './ProductBox';
import { getProducts } from '../../actions/product';
import { sampleSize } from 'lodash';

const numToDisplay = 4;

const ProductsSample = ({ getProducts, product: { products, loading } }) => {
    useEffect(() => {
        if (products.length === 0) getProducts();
        // only get products if count changes
    }, [products.length, getProducts]);

    const getRandomProducts = (products) => {
        let filteredProducts = products.filter((product) => {
            return !product.isdraft;
        });

        let data = sampleSize(filteredProducts, numToDisplay);
        return data;
    };

    const getNewlyAddedProducts = (products) => {
        let filteredProducts = products
            .filter((product) => {
                return !product.isdraft;
            })
            .sort((a, b) => {
                let fa = new Date(a.created);
                let fb = new Date(b.created);
                return fb - fa;
            });

        let data = filteredProducts.slice(0, numToDisplay);
        return data;
    };

    return loading ? (
        <Spinner />
    ) : (
        <Fragment>
            <div className='header-with-button'>
                <h2>Discover Products</h2>
                <Link className='btn btn-primary mr-0' to='/products'>
                    View All <i className={'fa fa-arrow-right'} />
                </Link>
            </div>
            <div className='products-wrapper mt mb-2'>
                {getRandomProducts(products).map((product) => (
                    <ProductBox data={product} key={product.name} />
                ))}
            </div>

            <div className='header-with-button'>
                <h2>Recently Added Products</h2>
                <Link
                    className='btn btn-primary mr-0'
                    to='/products?sortby=created'
                >
                    View All <i className={'fa fa-arrow-right'} />
                </Link>
            </div>

            <div className='products-wrapper mt'>
                {getNewlyAddedProducts(products).map((product) => (
                    <ProductBox data={product} key={product.name} />
                ))}
            </div>
        </Fragment>
    );
};

ProductsSample.propTypes = {
    getProducts: PropTypes.func.isRequired,
    product: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    product: state.product,
});

export default connect(mapStateToProps, {
    getProducts,
})(ProductsSample);
