import React, { useState, useEffect } from 'react';
import { Link, Navigate } from 'react-router-dom';
import { connect } from 'react-redux'; // connects component to redux
import { Helmet } from 'react-helmet-async';
import {
    loadCaptchaEnginge,
    LoadCanvasTemplate,
    validateCaptcha,
} from 'react-simple-captcha';
import { APP_NAME } from '../../utils/constants';
import { register } from '../../actions/auth';
import PropTypes from 'prop-types';

const initialState = {
    // default values for the formData state
    name: '',
    email: '',
    username: '',
    password: '',
    password2: '',
};
// @todo11 figure out how to see if registration code is required
// create function component
const Register = ({ register, isAuthenticated }) => {
    useEffect(() => {
        window.scrollTo(0, 0); // scroll to top of form on mount
    }, []);

    // when the state updates, useEffect will keep running in a constant loop, unless
    // you add the [] specified at the end (2nd arg) so that it runs only once when app is mounted
    // This behaves like componentDidMount
    useEffect(() => {
        loadCaptchaEnginge(5, 'black', 'white', 'numbers');
    }, []);

    // A Hook is a special function that lets you “hook into” React features. For example, useState is a Hook that lets you add React state to function components. We’ll learn other Hooks later. useState is a hook, which lets you use state and other React features without writing a class. Here, we declare a new state variable, which we'll call "formData". setFormData is the setter
    // format: [state, function to update state]
    const [formData, setFormData] = useState(initialState);

    // destructure so I don't have to do formData.name, etc...
    const { name, email, username, password, password2 } = formData;

    const onChange = (e) =>
        // ... is the spread operator which makes a copy of formData
        // [e.target.name] generalizes the function so it can apply to any field
        // Update formData by copying existing data then adding the changed value:
        setFormData({ ...formData, [e.target.name]: e.target.value });

    const onSubmit = async (e) => {
        e.preventDefault(); // don't let browser do its default behavior

        let user_captcha_value =
            document.getElementById('user_captcha_input').value;

        if (validateCaptcha(user_captcha_value, false)) {
            register({
                name,
                email,
                username,
                password,
                password2,
            });
        } else {
            alert('Captcha Does Not Match');
        }
    };

    if (isAuthenticated) {
        return <Navigate to='/createprofile' />; // take user directly to profile screen
    }

    return (
        <section className='landing'>
            <Helmet>
                <title>{APP_NAME} | Register</title>
            </Helmet>
            <div className='landing-overlay'>
                <div className='landing-form'>
                    <p className='lead'>
                        <i className='fas fa-user'></i> Create Your Account
                    </p>
                    <form className='form' onSubmit={onSubmit}>
                        <div className='form-group'>
                            <p className='form-header'>Username</p>
                            <small className='form-text mb'>
                                Must be 3 to 30 characters long and can only
                                contain letters and numbers
                            </small>
                            <input
                                type='text'
                                name='username'
                                value={username}
                                onChange={onChange}
                                required
                            />
                        </div>
                        <div className='form-group'>
                            <p className='form-header'>Email Address</p>
                            <input
                                type='email'
                                name='email'
                                value={email}
                                onChange={onChange}
                                required
                            />
                        </div>
                        <div className='form-group'>
                            <p className='form-header'>Password</p>
                            <small className='form-text mb'>
                                Must be at least 8 characters long
                            </small>
                            <input
                                type='password'
                                name='password'
                                value={password}
                                onChange={onChange}
                                minLength='8'
                            />
                        </div>
                        <div className='form-group'>
                            <p className='form-header'>Confirm Password</p>
                            <input
                                type='password'
                                name='password2'
                                value={password2}
                                onChange={onChange}
                                minLength='8' // @todo make 8 a constant and use it in the backend api validator too
                            />
                        </div>
                        <div className='form-group'>
                            <p className='form-header'>Name or Nickname</p>
                            <small className='form-text mb'>
                                This is so we can greet you!
                            </small>
                            <input
                                type='text'
                                name='name'
                                value={name}
                                onChange={onChange}
                                required
                            />
                        </div>
                        <div className='form-group'>
                            <p className='form-header'>Enter Captcha</p>
                            <LoadCanvasTemplate reloadColor='#126262' />
                            <input
                                type='text'
                                className='mt'
                                id='user_captcha_input'
                                required
                            />
                        </div>
                        <div className='form-group'>
                            <small className='mb'>
                                By clicking Register, you agree to our{' '}
                                <a
                                    href='/terms'
                                    target='_blank'
                                    rel='noopener noreferrer'
                                >
                                    Terms &amp; Conditions
                                </a>
                                .
                            </small>
                        </div>
                        <input
                            type='submit'
                            className='btn btn-primary btn-large'
                            value='Register'
                        />
                    </form>
                    <p className='my-1'>
                        Already have an account?{' '}
                        <Link to='/login'>Sign In</Link>
                    </p>
                </div>
            </div>
        </section>
    );
};

Register.propTypes = {
    register: PropTypes.func.isRequired,
    isAuthenticated: PropTypes.bool,
};

const mapStateToProps = (state) => ({
    isAuthenticated /* prop name */:
        state.auth.isAuthenticated /* gets the auth state */,
});

// to use an action, pass it into connect below to allow us to access props.register so we can use it in this component.
// 1st param is state to connect (not needed here). 2nd param is any actions I want to use
export default connect(mapStateToProps, { register })(Register);
