import React from 'react';
import { Link } from 'react-router-dom';
import NavbarLink from '../layout/NavbarLink';

const ProfileNav = ({ auth, username, id }) => {
    return (
        <div className='sticky-header-wrapper'>
            <div className='header-with-button'>
                <h1>{username}</h1>
                {auth.isAuthenticated &&
                    !auth.loading &&
                    auth.user._id &&
                    auth.user._id === id && (
                        <Link
                            to='/editprofile'
                            className='btn btn-primary mr-0'
                        >
                            <i className='fas fa-edit text-light' /> Edit
                            Profile
                        </Link>
                    )}
            </div>
            <nav className='tabbar mb-2'>
                <ul>
                    <li>
                        <NavbarLink path={`/profile/${username}`}>
                            <i className='fas fa-address-card text-light' />{' '}
                            Profile
                        </NavbarLink>
                    </li>

                    <li>
                        <NavbarLink path={`/regimens/${username}`}>
                            <i className='fa fa-list-ol text-light' /> Regimens
                        </NavbarLink>
                    </li>
                    <li>
                        <NavbarLink path={`/journey/${username}`}>
                            <i className='fa fa-timeline text-light' /> Curl
                            Journey
                        </NavbarLink>
                    </li>
                </ul>
            </nav>
        </div>
    );
};

export default ProfileNav;
