import React, { useEffect, useState, Fragment } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Link } from 'react-router-dom';
import Navbar from './components/layout/Navbar';
import Alert from './components/layout/Alert';
import Spinner from './components/layout/Spinner';
import AppRoutes from './components/routing/AppRoutes';
import Logo from './img/logo.svg';
import { APP_NAME, CLIENT_VERSION } from './utils/constants';

// Datadog RUM
import ruminit from './rum';

// Redux
import { Provider } from 'react-redux'; // combines react and redux
import store from './store';
import { loadUser } from './actions/auth';
import setAuthToken from './utils/setAuthToken';

import './App.css';

const App = () => {
    const [userChecked, setUserChecked] = useState(false);

    // when the state updates, useEffect will keep running in a constant loop, unless
    // you add the [] specified at the end (2nd arg) so that it runs only once when app is mounted
    // This behaves like componentDidMount
    useEffect(() => {
        setAuthToken(localStorage.token);
        store.dispatch(loadUser());
        ruminit();
        setUserChecked(true); // used to ensure user token is set if there's a logged in user.
    }, []);

    return (
        <Provider store={store}>
            <BrowserRouter>
                {userChecked ? (
                    <Fragment>
                        <div className='topnotification'>
                            <div className='small allcaps'>
                                Welcome to our soft launch! We can't wait to
                                hear your feedback ☺
                            </div>
                        </div>
                        <Navbar />
                        <Alert />
                        <section className='containerfull'>
                            <AppRoutes />
                        </section>
                        <section className='footer'>
                            <div>
                                <Link to='/' className='logolink flex'>
                                    <img
                                        src={Logo}
                                        alt='logo'
                                        id='logo-footer'
                                    />
                                    {/* <span className='sm'>&#174;</span> */}
                                </Link>
                            </div>
                            <div>
                                Copyright &copy; 2020-{new Date().getFullYear()}{' '}
                                {APP_NAME} &#174;. All Rights Reserved. As an
                                Amazon Associate, we earn from qualifying
                                purchases. v{CLIENT_VERSION} |{' '}
                                <Link to='/about'>About Us</Link> |{' '}
                                <Link to='/terms'>Terms &amp; Conditions</Link>
                            </div>
                            <div>
                                <a
                                    href='https://www.twitter.com/rootsandcurls/'
                                    target='_blank'
                                    rel='noopener noreferrer'
                                >
                                    <i className='fab fa-twitter fa-2x mr' />
                                </a>
                                {'  '}
                                <a
                                    href='https://www.instagram.com/roots.and.curls/'
                                    target='_blank'
                                    rel='noopener noreferrer'
                                >
                                    <i className='fab fa-instagram fa-2x' />
                                </a>
                            </div>
                        </section>
                    </Fragment>
                ) : (
                    <Spinner />
                )}
            </BrowserRouter>
        </Provider>
    );
};

export default App;
