import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import DayJS from 'react-dayjs';
import { connect } from 'react-redux';
import Spinner from '../layout/Spinner';

const CompanyItem = ({
    auth,
    company_loading,
    company: {
        // passed in as prop
        _id,
        name,
        shortname,
        description,
        website,
        urlparam,
        isblackowned,
        updated,
        lasteditedby,
        productcollections,
    },
}) => {
    return (
        <div className='company' key={_id}>
            {auth.loading || company_loading ? (
                <Spinner />
            ) : (
                <Fragment>
                    <div>
                        <Link to={`/products?company=${_id}`}>
                            <h3 className='text-primary mb-0'>{name}</h3>
                        </Link>
                        <span>
                            <strong>Short name: </strong>
                            {shortname}
                        </span>
                        <br />
                        <span>
                            <strong>URL param: </strong>
                            {urlparam}
                        </span>

                        {auth.isAuthenticated && auth.isAdmin && (
                            <p className='small mb-0'>
                                Last edited:{' '}
                                {lasteditedby && lasteditedby.username} on{' '}
                                {updated && (
                                    <DayJS format='dd MM|DD|YY hh:mma'>
                                        {updated}
                                    </DayJS>
                                )}
                            </p>
                        )}
                    </div>

                    <div>
                        <a
                            href={website}
                            target='_blank'
                            rel='noopener noreferrer'
                        >
                            <i className='fas fa-globe fa-2x' />
                        </a>
                    </div>
                    <div>
                        {productcollections.map((line, idx) => (
                            <Fragment key={line._id}>
                                <Link
                                    to={`/products?company=${_id}&collection=${line._id}`}
                                >
                                    {line.name}
                                </Link>
                                <span>
                                    {idx !== productcollections.length - 1
                                        ? ' | '
                                        : ''}
                                </span>
                            </Fragment>
                        ))}
                    </div>
                    <div>
                        {!auth.loading && auth.isAuthenticated && auth.isAdmin && (
                            <Link
                                to={`/admin/editcompany/${_id}`}
                                className='btn btn-light'
                            >
                                <i className='fas fa-edit' />
                            </Link>
                        )}
                    </div>
                </Fragment>
            )}
        </div>
    );
};

CompanyItem.propTypes = {
    company: PropTypes.object.isRequired, // passed in to prop
    company_loading: PropTypes.bool.isRequired,
    auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    auth: state.auth,
    company_loading: state.company.loading,
});

export default connect(mapStateToProps)(CompanyItem);
