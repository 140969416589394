import { MEDIA_URL_BASE } from './constants';
import { CLIENT_VERSION } from './constants';
import { setAlert } from '../actions/alert';
import defaultProfilePic from '../img/defaultProfilePic.png';
import defaultProductPic from '../img/defaultProductPic.png';
import defaultRegimenPic from '../img/defaultRegimenPic.png';

export const truncateString = (string, limit) => {
    if (string.length > limit) {
        return string.substring(0, limit) + '...';
    } else {
        return string;
    }
};

// returns validated url search params object
const checkURLParams = (searchParams, validkeys) => {
    for (const [key, value] of searchParams) {
        //console.log({ key, value }); // e.g. {key: 'ss', value: 'gel'}
        if (!validkeys.includes(key) || !value || value.length === 0) {
            searchParams.delete(key);
        }
    }

    return searchParams;
};

// parses the url to get the filter params
const deleteURLparam = (history, location, key) => {
    const searchParams = new URLSearchParams(location.search);
    if (searchParams.has(key)) {
        searchParams.delete(key);

        // if page num exists in url, reset it to 1:
        let pgkey = 'pg';
        let value = 1;
        if (searchParams.has(pgkey)) {
            searchParams.set(pgkey, value);
        } else {
            searchParams.append(pgkey, value);
        }

        let url = searchParams.toString();

        history(`?${url}`, {
            replace: true,
            isActive: true,
        });
    }
};

const setURLparam = (history, location, kvobj /*, thunk = null*/) => {
    const searchParams = new URLSearchParams(location.search);

    for (var key in kvobj) {
        let value = kvobj[key];
        if (searchParams.has(key)) {
            // only set it if it has a valid value
            if (value) {
                searchParams.set(key, value);
            } else {
                searchParams.delete(key);
            }
        } else if (value) {
            // only add if valid
            searchParams.append(key, value);
        }
    }

    let url = searchParams.toString();
    if (location.search !== url) {
        history(`?${url}`);
    }
};

// returns search string from url where, for ex., the key is 'ss' and the val is the searched query
// note: I handle deletions in case the user enters an invalid/empty param
const getURLParam = (param, location) => {
    let queryparam = location.search || '';
    let str = '';
    if (!queryparam) return str;

    const searchParams = new URLSearchParams(queryparam);
    let val = searchParams.get(param); // e.g. ss=str
    if (val && val.length) {
        str = val;
    }

    return str;
};

const getPageNumParam = (history, location) => {
    let param = getURLParam('pg', location);
    let val = !param ? null : parseInt(param);
    if (!val) {
        val = 1;
        deleteURLparam(history, location, 'pg');
    }
    return val;
};

const dataIsFiltered = (query_str) => {
    let regex = /&?pg=[0-9]/g;

    // strip out page number (based on regex) if present
    let str = query_str ? query_str.replace(regex, '') : '';
    str = str.replace('?', '');
    return str.length > 0;
};

// helper object that stores all URL parsing functions for use in products, ingredients, etc. pages
export const URLhelpers = {
    deleteURLparam,
    setURLparam,
    getURLParam,
    checkURLParams,
    getPageNumParam,
    dataIsFiltered,
};

// used to check client version against server-stored app version. currently, this is only checked on data fetches for regimens, products, members, ingredients, and companies GET requests (which happen when users navigate to most top level pages)
export const checkAppVersion = (res) => (dispatch) => {
    const server_version = res.headers['x-server-version'];

    if (server_version && CLIENT_VERSION !== server_version)
        // !== instead of less than since version numbers are wonky when comparing. the point is, they should be the same; if they aren't, client browser needs to be refreshed.
        dispatch(
            setAlert(
                'Our website has been updated! Refresh the page to get the latest version.',
                'primary',
                true,
                true, // remove all others in case this is called again
                false,
                false
            )
        ); // show first error
};

// parses data. Either it's an array, in which case it will
//  be parsed for displaying in UI and returned, or it's a new-line-separated list,
export const parseArrayOrNewLineList = (data) => {
    if (Array.isArray(data)) {
        let stringtodisplay = '';

        data.forEach((syn, idx) => {
            stringtodisplay += (idx > 0 ? '\n' : '') + syn; // name.trim();
        });

        return stringtodisplay;
    } else {
        // data is a string, so explode it into an array
        let splitarray = data.split('\n');

        return splitarray;
    }
};

export const parseTotalTime = (timeminutes, returnAsString = false) => {
    // returns object with the minutes and hours split out for UI display,
    // or the prettified string if only need to display the string
    let timeobj = {
        minutes: timeminutes % 60,
        hours: Math.floor(timeminutes / 60),
    };

    if (returnAsString) {
        let hours = timeobj.hours ? timeobj.hours + ' hour' : '';
        let hoursPlural = timeobj.hours > 1 ? 's' : '';

        let middle = timeobj.hours && timeobj.minutes ? ', ' : '';

        let minutesPlural = timeobj.minutes > 1 ? 's' : '';
        let minutes = timeobj.minutes ? timeobj.minutes + ' minute' : '';

        return hours + hoursPlural + middle + minutes + minutesPlural;
    } else return timeobj;
};

export const parseLikeCount = (count) => {
    if (count < 1000) {
        return count;
    } else if (count < 1000000) {
        return Math.round((count / 1000) * 10) / 10 + 'k';
    } else {
        return Math.round((count / 1000000) * 10) / 10 + 'm';
    }
};

const ImgTypeEnum = { PROFILE: 1, PRODUCT: 2, REGIMEN: 3, JOURNEYENTRY: 4 };
Object.freeze(ImgTypeEnum); // don't allow new options to be added

export const getUserPic = (imglocation) => {
    return getImageUrl(imglocation, ImgTypeEnum.PROFILE);
};

export const getProductPic = (imglocation) => {
    return getImageUrl(imglocation, ImgTypeEnum.PRODUCT);
};

export const getRegimenPic = (imglocation) => {
    return getImageUrl(imglocation, ImgTypeEnum.REGIMEN);
};

export const getJourneyEntryPic = (imglocation) => {
    return getImageUrl(imglocation, ImgTypeEnum.JOURNEYENTRY);
};

// generic function
const getImageUrl = (path, imgType) => {
    return path && path.length
        ? `${MEDIA_URL_BASE}${path}`
        : getDefaultImg(imgType);
};

const getDefaultImg = (imgType) => {
    switch (imgType) {
        case ImgTypeEnum.PROFILE:
            return defaultProfilePic;
        case ImgTypeEnum.PRODUCT:
            return defaultProductPic;
        case ImgTypeEnum.REGIMEN:
        case ImgTypeEnum.JOURNEYENTRY:
            return defaultRegimenPic;
        default:
            return defaultProfilePic;
    }
};
