// since this is a form,  each input should be a piece of state, so we need useState hook
import React, { Fragment, useState, useEffect } from 'react';
import { Link, Navigate, useParams, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import { APP_NAME } from '../../../utils/constants';
import {
    updateJourneyEntry,
    getCurrentProfile,
} from '../../../actions/profile';
import { getRegimensAutocomplete } from '../../../actions/regimen';
import Autocomplete from '../../layout/Autocomplete';
import Spinner from '../../layout/Spinner';
import UploadJourneyEntryPic from './UploadJourneyEntryPic'; // @todo14 delete acct should delete associated s3 media

// Notes: fields use pattern "fieldname || initialState.fieldname" because certain fields may not be in database for user (namely, when models change). This will help when fetching data for profile when editing it.
// set default state values that will appear in form
const initialState = {
    isfeatured: false,
    type: 0,
    regimen: null,
    mainphoto: '',
    notes: '',
    startDate: {
        // always has a year but may not have month or day (for older events)
        // date example: 2020-11-16T23:44:30.976Z
        date: new Date(),
        hasmonth: false,
        hasday: false,
    },
    endDate: {
        exists: false,
        date: null,
        hasmonth: false,
        hasday: false,
    },
};

const JourneyEntryForm = ({
    // prop types
    //entryid, // journey entry id if it has been saved to db, otherwise null
    profile: { profile, loading_profile },
    regimen: { regimens_autocomplete, loading_autocomplete },
    getRegimensAutocomplete,
    updateJourneyEntry,
    getCurrentProfile, // to fill in fields for editing
    auth,
    // (history prop is the history API which is used to navigate user to other view programatically). used to directly manipulate URL in the browser without reloading the page. route props (match, location and history) are automatically passed on to the component
}) => {
    let params = useParams();
    let history = useNavigate();
    // format: [state, function to update state]
    // Two states added: formData to store form stuff and
    const [formData, setFormData] = useState(initialState);

    // this will allow us to run getCurrentProfile
    useEffect(() => {
        if (!profile && loading_profile) getCurrentProfile(); // if profile prop not set, fetch it by updating the profile state which will update the prop too
        if (!loading_profile && profile && params.id) {
            // update form fields with current journey entry if it exists :)
            // params.id = ID of entry
            const entryidx = profile.journal.entries.findIndex(
                ({ _id }) => _id === params.id
            );

            const profileData = { ...initialState };

            for (const key in profile.journal.entries[entryidx]) {
                if (key in profileData)
                    profileData[key] = profile.journal.entries[entryidx][key];
            }
            setFormData(profileData);
        }
    }, [loading_profile, getCurrentProfile, profile, params.id]);

    useEffect(() => {
        window.scrollTo(0, 0); // scroll to top of form on mount
    }, []);

    useEffect(() => {
        getRegimensAutocomplete();
    }, [getRegimensAutocomplete]);

    const {
        notes,
        isfeatured,
        type,
        regimen,
        mainphoto,
        startDate,
        // endDate,
    } = formData;

    const updateAutocompleteFields = (e) => {
        e.stopPropagation();

        // value is stringified array; parse into array
        if (e.target.name === 'regimen') {
            const parsed = JSON.parse(e.target.value);
            let id = parsed[0]; // returns company object with ID field.
            // since autocomplete sets data as object array,
            // but db field is string with ID, the db will actually just save the id field as desired.

            setFormData({
                ...formData, // impt! need this here so I don't get (un)controlled component err
                regimen: id,
            });
        }
    };

    const onChange = (e) => {
        e.stopPropagation(); // to prevent lastpass bug from throwing error in console

        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const onChangeStartDate = (e) => {
        e.stopPropagation(); // to prevent lastpass bug from throwing error in console

        if (e.target.name === 'startDateYear') {
            let year = e.target.value;
            let currentDate = new Date(startDate.date || null);
            currentDate.setFullYear(year);
            currentDate.setHours(0);
            currentDate.setMinutes(0);
            currentDate.setSeconds(0);
            let currentFormData = { ...startDate };
            currentFormData.date = currentDate;
            setFormData({ ...formData, startDate: currentFormData });
        } else if (e.target.name === 'startDateMonth') {
            let month = e.target.value;
            let currentFormData = { ...startDate };

            if (month > -1) {
                let currentDate = new Date(startDate.date || null);
                currentDate.setMonth(month);
                currentDate.setHours(0);
                currentDate.setMinutes(0);
                currentDate.setSeconds(0);

                currentFormData.date = currentDate;
                currentFormData.hasmonth = true;
            } else {
                currentFormData.hasmonth = false;
            }

            // console.log(currentFormData);
            setFormData({ ...formData, startDate: currentFormData });
        } else if (e.target.name === 'startDateDay') {
            let day = e.target.value;
            let currentFormData = { ...startDate };

            if (day > 0) {
                let currentDate = new Date(startDate.date || null);
                currentDate.setDate(day);
                currentDate.setHours(0);
                currentDate.setMinutes(0);
                currentDate.setSeconds(0);

                currentFormData.date = currentDate;
                currentFormData.hasday = true; // 0 means no day chosen
            } else {
                currentFormData.hasday = false;
            }

            // console.log(currentFormData);
            setFormData({ ...formData, startDate: currentFormData });
        }
    };

    const yearOptions = () => {
        let currentYear = new Date().getFullYear();
        let start = currentYear;
        let end = 1950;
        let options = [];

        for (var y = start; y >= end; y--) {
            options.push(
                <option key={y} value={y}>
                    {y}
                </option>
            );
        }

        return options;
    };

    const dayOptions = () => {
        let start = 1;
        let end = 31;
        let options = [
            <option key={0} value={0}>
                Select date
            </option>,
        ];

        for (var d = start; d <= end; d++) {
            options.push(
                <option key={d} value={d}>
                    {d}
                </option>
            );
        }

        return options;
    };

    const onSubmit = (e) => {
        e.preventDefault();
        updateJourneyEntry(formData, params.id || 'create', history);
    };

    if (!auth.isAuthenticated) {
        return <Navigate to='/login' />;
    }

    return (
        <div className='containermiddle'>
            <Helmet>
                <title>
                    {APP_NAME} | {params.id ? 'Update' : 'Add'} Journey Entry
                </title>
            </Helmet>
            {loading_profile || !profile ? (
                // wait for profile to load
                <Spinner />
            ) : (
                <Fragment>
                    <div className='header-with-button'>
                        <h1 className='mb'>
                            {params.id ? 'Update' : 'Add'} Journey Entry
                        </h1>
                        {profile ? (
                            <Link
                                to={`/journey/${profile.user.username_lower}`}
                                className='btn btn-primary mr-0'
                            >
                                <i className='fas fa-timeline text-light' />{' '}
                                View Journey
                            </Link>
                        ) : (
                            ''
                        )}
                    </div>
                    {profile === null && <h2>Welcome to your Curl Journey!</h2>}

                    {params.id && (
                        <UploadJourneyEntryPic
                            entryid={params.id}
                            photopath={mainphoto}
                            profile={profile}
                        />
                    )}

                    <p className='italicsmsg m0 pt-0'>* = required field</p>

                    <form className='form' onSubmit={onSubmit}>
                        <div className='form-group'>
                            <p className='form-header'>Description</p>
                            <small className='form-text mb'>
                                Enter a short description for this entry.
                            </small>
                            <textarea
                                name='notes'
                                value={notes || initialState.notes}
                                rows={3}
                                maxLength={100}
                                onChange={onChange}
                            />
                        </div>
                        <div className='form-group'>
                            <span>
                                <strong>Feature as major event</strong>{' '}
                                <input
                                    type='checkbox'
                                    name='isfeatured'
                                    value={
                                        isfeatured || initialState.isfeatured
                                    }
                                    checked={
                                        isfeatured || initialState.isfeatured
                                    }
                                    onChange={() => {
                                        setFormData({
                                            ...formData,
                                            isfeatured: !isfeatured,
                                        });
                                    }}
                                    onKeyPress={(e) => {
                                        e.key === 'Enter' && e.preventDefault();
                                    }}
                                />
                            </span>
                        </div>

                        <div className='form-group'>
                            <p className='form-header'>* Event Type</p>
                            <select
                                name='type'
                                value={type || initialState.type}
                                onChange={onChange}
                                onKeyPress={(e) => {
                                    e.key === 'Enter' && e.preventDefault();
                                }}
                            >
                                <option>Enter the type of event</option>
                                <option value={1}>Hairstyle</option>
                                <option value={2}>Hairwash</option>
                                <option value={3}>Trim</option>
                                <option value={4}>Haircut</option>
                                <option value={5}>Big Chop</option>
                                <option value={6}>Dyed Hair</option>
                                <option value={7}>Hair Growth</option>
                                <option value={0}>Other</option>
                            </select>
                        </div>

                        <div className='form-group'>
                            <p className='form-header'>Start Date</p>
                            <div className='datecolumns'>
                                <div>
                                    <small className='form-text mb'>
                                        * Select year
                                    </small>
                                    <select
                                        name='startDateYear'
                                        value={new Date(
                                            startDate.date ||
                                                initialState.startDate.date
                                        ).getFullYear()}
                                        onChange={onChangeStartDate}
                                        onKeyPress={(e) => {
                                            e.key === 'Enter' &&
                                                e.preventDefault();
                                        }}
                                    >
                                        {yearOptions()}
                                    </select>
                                </div>
                                <div>
                                    <small className='form-text mb'>
                                        Select month (optional)
                                    </small>

                                    <select
                                        name='startDateMonth'
                                        value={
                                            startDate.hasmonth
                                                ? new Date(
                                                      startDate.date ||
                                                          initialState.startDate
                                                              .date
                                                  ).getMonth()
                                                : -1
                                        }
                                        onChange={onChangeStartDate}
                                        onKeyPress={(e) => {
                                            e.key === 'Enter' &&
                                                e.preventDefault();
                                        }}
                                    >
                                        <option value={-1}>Select month</option>
                                        <option value={0}>January</option>
                                        <option value={1}>February</option>
                                        <option value={2}>March</option>
                                        <option value={3}>April</option>
                                        <option value={4}>May</option>
                                        <option value={5}>June</option>
                                        <option value={6}>July</option>
                                        <option value={7}>August</option>
                                        <option value={8}>September</option>
                                        <option value={9}>October</option>
                                        <option value={10}>November</option>
                                        <option value={11}>December</option>
                                    </select>
                                </div>
                                <div>
                                    <small className='form-text mb'>
                                        Select date (optional)
                                    </small>
                                    <select
                                        name='startDateDay'
                                        value={
                                            startDate.hasday
                                                ? new Date(
                                                      startDate.date ||
                                                          initialState.startDate
                                                              .date
                                                  ).getDate()
                                                : 0
                                        }
                                        onChange={onChangeStartDate}
                                        onKeyPress={(e) => {
                                            e.key === 'Enter' &&
                                                e.preventDefault();
                                        }}
                                    >
                                        {dayOptions()}
                                    </select>{' '}
                                </div>
                            </div>
                        </div>

                        <div className='form-group'>
                            <p className='form-header'>Tag a public regimen</p>

                            {loading_autocomplete ? (
                                // wait for regimen suggestions to load
                                <Spinner size={0} />
                            ) : (
                                <Autocomplete
                                    cssID={'0'} // index 0 since there is only 1 possible product line field.
                                    stringsName=''
                                    objectsName='regimen'
                                    objectsData={regimen ? [regimen] : []}
                                    placeholder='Enter regimen'
                                    onChangeHandler={updateAutocompleteFields}
                                    suggestions={regimens_autocomplete}
                                    allowNotFoundSuggestions={false}
                                    selectOneMax={true}
                                />
                            )}
                        </div>

                        <div className='admin-form-footer'>
                            <Link
                                className='btn btn-light my-1'
                                to={'/journey/' + profile.user.username_lower}
                            >
                                <i className={'fa fa-arrow-left'} /> Cancel
                            </Link>
                            <input
                                type='submit'
                                value='Save'
                                className='btn btn-primary my-1 mr-0'
                            />
                        </div>
                    </form>
                </Fragment>
            )}
        </div>
    );
};

JourneyEntryForm.propTypes = {
    //entryid: PropTypes.string, // set if updating entry; null otherwise
    updateJourneyEntry: PropTypes.func.isRequired,
    getCurrentProfile: PropTypes.func.isRequired,
    getRegimensAutocomplete: PropTypes.func.isRequired,
    profile: PropTypes.object.isRequired,
    regimen: PropTypes.object.isRequired,
    auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    profile: state.profile,
    regimen: state.regimen, // for regimen autocomplete
    auth: state.auth,
});

export default connect(mapStateToProps, {
    updateJourneyEntry,
    getCurrentProfile,
    getRegimensAutocomplete,
})(JourneyEntryForm);
