// View a single Regimen
import React, { useEffect, Fragment } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet-async';
import { APP_NAME } from '../../utils/constants';
import Spinner from '../layout/Spinner';
import { connect } from 'react-redux';
import { getRegimenById, deleteRegimen } from '../../actions/regimen';
import RegimenItem from '../../components/regimens/RegimenItem';

const RegimenPage = ({
    regimen: { regimen, loading },
    getRegimenById,
    deleteRegimen,
    auth,
}) => {
    let params = useParams();
    let history = useNavigate();

    useEffect(() => {
        getRegimenById(params.id, history);
    }, [getRegimenById, params.id, history]);

    useEffect(() => {
        window.scrollTo(0, 0); // scroll to top of page on mount
    }, []);

    return (
        <div className='containermiddle'>
            <Helmet>
                <title>
                    {APP_NAME} | Regimen{regimen ? ': ' + regimen.name : ''}
                </title>
            </Helmet>
            {loading || regimen === null ? (
                <Spinner />
            ) : (
                <Fragment>
                    <div className='header-with-button'>
                        <h1 className='mb-1'>Regimen Details</h1>
                        <div>
                            {regimen &&
                            auth.isAuthenticated &&
                            !auth.loading &&
                            (auth.user._id === regimen.user._id ||
                                auth.isSuperAdmin) ? (
                                <Fragment>
                                    <button
                                        onClick={() =>
                                            deleteRegimen(regimen._id, history)
                                        }
                                        type='button'
                                        className='btn btn-danger'
                                    >
                                        <i className='far fa-trash-alt' />{' '}
                                        Delete Regimen
                                    </button>
                                    <Link
                                        to={`/editregimen/${regimen._id}`}
                                        className='btn btn-primary mr-0'
                                    >
                                        <i className='fas fa-edit text-light' />{' '}
                                        Edit Regimen
                                    </Link>
                                </Fragment>
                            ) : (
                                ''
                            )}
                        </div>
                    </div>
                    <RegimenItem
                        regimen={regimen}
                        showFull={true}
                        key={regimen._id}
                    />
                </Fragment>
            )}
        </div>
    );
};

RegimenPage.propTypes = {
    regimen: PropTypes.object.isRequired,
    auth: PropTypes.object.isRequired,
    getRegimenById: PropTypes.func.isRequired,
    deleteRegimen: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
    regimen: state.regimen,
    auth: state.auth,
});

export default connect(mapStateToProps, { getRegimenById, deleteRegimen })(
    RegimenPage
);
