import React, { useState, Fragment } from 'react';
import { Navigate } from 'react-router-dom';
import { connect } from 'react-redux'; // connects component to redux
import { Helmet } from 'react-helmet-async';
import { APP_NAME } from '../../utils/constants';
import { forgotPassword } from '../../actions/auth';
import Spinner from '../layout/Spinner';
import PropTypes from 'prop-types';

// create function component
const ForgotPassword = ({
    forgotPassword,
    auth: { loading, isAuthenticated, resetting_pwd, pwd_verification_message },
}) => {
    // format: [state, function to update state]
    const [formData, setFormData] = useState({
        // default values
        email: '',
        submitted: false,
    });

    const { email, submitted } = formData;

    const onChange = (e) =>
        setFormData({ ...formData, [e.target.name]: e.target.value });

    const onSubmit = async (e) => {
        e.preventDefault(); // don't let browser do its default behavior
        forgotPassword(email);
        setFormData({ ...formData, submitted: true });
    };

    if (loading) {
        return (
            <section className='containerfull'>
                <div className='containermiddle'>
                    <Spinner />
                </div>
            </section>
        );
    } else if (isAuthenticated) {
        return <Navigate to='/dashboard' />;
    } else
        return (
            <section className='landing'>
                <Helmet>
                    <title>{APP_NAME} | Forgot Password</title>
                </Helmet>
                <div className='landing-overlay'>
                    <div className='landing-form'>
                        <h1>Reset Your Password</h1>
                        {resetting_pwd ? (
                            <Spinner />
                        ) : (
                            <h2 className='catchy mb-0'>
                                {pwd_verification_message}
                            </h2>
                        )}

                        {!submitted && (
                            <Fragment>
                                <p>
                                    Enter your email address and we'll send you
                                    a link to reset your password.
                                </p>
                                <form className='form' onSubmit={onSubmit}>
                                    <div className='form-group'>
                                        <input
                                            type='text'
                                            placeholder='Enter account email address'
                                            name='email'
                                            value={email}
                                            onChange={onChange}
                                            required
                                        />
                                    </div>
                                    <input
                                        type='submit'
                                        className='btn btn-primary mr-0'
                                        value='Send Link'
                                    />
                                </form>
                            </Fragment>
                        )}
                    </div>
                </div>
            </section>
        );
};

ForgotPassword.propTypes = {
    forgotPassword: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    auth: state.auth,
});

export default connect(mapStateToProps, { forgotPassword })(ForgotPassword);
