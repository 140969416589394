import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';

// Component that supports multi-select checkbox-like toggles.
function Multiselect({
    options,
    selectedOptions,
    cssID,
    stringsName,
    onChangeHandler,
    selectOneMax,
}) {
    const initialState = {
        selectedStrings: [], // used to keep track of user selections. used as value passed up to caller, kept track of with an input that stores the tags and is hidden
    };

    const [completionData, setCompletionData] = useState(initialState);
    const { selectedStrings } = completionData;

    // used to send a change in input data to caller
    const bubbleUpInputChange = useCallback(
        (data) => {
            var input = document.querySelector(
                '#multiselect-' + cssID + '-' + stringsName
            );
            var nativeInputValueSetter = Object.getOwnPropertyDescriptor(
                window.HTMLInputElement.prototype,
                'value'
            ).set;
            nativeInputValueSetter.call(input, JSON.stringify(data));

            var inputEvent = new Event('input', { bubbles: true });
            input.dispatchEvent(inputEvent); // @todo merge this into helper where other bubble funcs appear
        },
        [cssID, stringsName]
    );

    useEffect(() => {
        setCompletionData((completionData) => {
            return {
                ...completionData,
                selectedStrings: selectedOptions,
            };
        });
    }, [selectedOptions]);

    // Event fired when the checkbox is clicked
    const onChange = (e, idx) => {
        // value = true or false string, so parse it
        const isSelected = JSON.parse(e.currentTarget.value);

        let selected;
        let name = options[idx].name;

        if (!isSelected) {
            // if it wasn't selected, select it now
            selected = selectOneMax
                ? [name] // select just this one
                : [...selectedStrings, name]; // add this one to rest
        } else {
            // it was selected, so remove it
            selected = selectOneMax
                ? []
                : selectedStrings.filter((n) => n !== name);
        }

        setCompletionData({
            ...completionData,
            selectedStrings: selected,
        });

        bubbleUpInputChange(selected);
    };

    // Event fired when the div is clicked
    const onClick = (e, idx) => {
        var isSelected = selectedStrings.includes(options[idx].name);
        let selected;
        let name = options[idx].name;

        if (!isSelected) {
            // if it wasn't selected, select it now
            selected = selectOneMax
                ? [name] // select just this one
                : [...selectedStrings, name]; // add this one to rest
        } else {
            // it was selected, so remove it
            selected = selectOneMax
                ? []
                : selectedStrings.filter((n) => n !== name);
        }

        setCompletionData({
            ...completionData,
            selectedStrings: selected,
        });

        bubbleUpInputChange(selected);
    };

    return (
        <div className='multiselect-group'>
            {options.map((option, index) => {
                var isChecked = selectedStrings.includes(option.name);
                return (
                    <div
                        key={'msoption-' + index}
                        className={isChecked ? 'ms-checked' : 'ms-unchecked'}
                        onClick={(e) => onClick(e, index)}
                    >
                        <input
                            type={selectOneMax ? 'radio' : 'checkbox'}
                            name={option.name}
                            value={isChecked}
                            checked={isChecked}
                            onChange={(e) => onChange(e, index)}
                            onKeyPress={(e) => {
                                e.key === 'Enter' && e.preventDefault();
                            }}
                        />
                        <label>{option.name}</label>
                    </div>
                );
            })}
            <input
                type='text'
                style={{
                    display: 'none',
                }}
                id={'multiselect-' + cssID + '-' + stringsName}
                onChange={(e) => {
                    onChangeHandler(e);
                }}
                value={selectedStrings}
                name={stringsName}
            />
        </div>
    );
}

Multiselect.propTypes = {
    options: PropTypes.array.isRequired, // array of all possible options: objects with fields: name (required), id, icon
    selectedOptions: PropTypes.array.isRequired, // array string names of selected options
    cssID: PropTypes.string.isRequired, // unique ID for component
    onChangeHandler: PropTypes.func.isRequired, // function used to update state in caller
    stringsName: PropTypes.string.isRequired, // used to update state
    selectOneMax: PropTypes.bool, // whether only one option can be selected
};

Multiselect.defaultProps = {
    selectOneMax: false,
};

export default Multiselect;
