import axios from 'axios';
import store from '../store';
import { LOGOUT } from '../actions/types';
import { TOKEN_INVALID_STR } from '../utils/constants';
import { isExpired } from 'react-jwt';

const api = axios.create({
    baseURL: '/api',
    headers: {
        'Content-Type': 'application/json',
    },
});

/**
 intercept any error responses from the api and check if the token
 is no longer valid. ie. Token has expired
 If the JSON Web Token expires then it should log the user out 
 and end the authentication of their session.

**/
api.interceptors.request.use(
    (req) => {
        if (req.url !== '/auth') {
            const token = req.headers['x-auth-token'];
            let tokenIsExpired = true;
            if (token) tokenIsExpired = isExpired(token);

            if (tokenIsExpired) {
                // console.log(
                //     'API request: ' + TOKEN_INVALID_STR + '; logging out'
                // );
                store.dispatch({ type: LOGOUT });
            }
        }

        return req;
    },
    (err) => {
        return Promise.reject(err);
    }
);

api.interceptors.response.use(
    (res) => {
        // normally, I'd just return the result (res) and call it a day.
        // but I want to intercept each response to make sure there is a valid token first

        // const token = res.headers['x-auth-token'];
        // let tokenIsExpired = true;
        // if (token) tokenIsExpired = isExpired(token);
        // if (tokenIsExpired || (res.url !== 'auth' && res.method !== 'post'))
        //     store.dispatch({ type: LOGOUT });

        return res;
    },
    (err) => {
        // console.log('API ERROR:', err.response);
        if (
            err.response.data &&
            err.response.data.errors &&
            err.response.data.errors.includes(TOKEN_INVALID_STR)
        ) {
            // Token invalid: log out
            console.log('API response: ' + TOKEN_INVALID_STR + ', logging out');
            store.dispatch({ type: LOGOUT });
        }
        return Promise.reject(err);
    }
);

export default api;
